import React, { useEffect, useState } from "react";
import { Employee } from "../../../../models/Employee/employee.model";
import EmployeeService from "../../../../services/Employee/employee.service";
import { IDropdownOption } from "../../../../services/MetaService/meta.service";
import { Checkbox, Col, Input, Row } from "antd";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import AppLoader from "../../../../shared/components/AppLoader";
import { BaFilterParams } from "../../../../models/BAFilterParams/baFilterParams.model";
import { BaFilterService } from "../../../../services/BAFilter/baFilter.service";
import { debounce } from "lodash";

interface BARMFiltersProps {
  onUpdate: (filterParams: BaFilterParams) => void;
  filterParams: BaFilterParams;
  refreshData: boolean;
}

function BARMFilters({
  filterParams,
  refreshData,
  onUpdate,
}: BARMFiltersProps) {
  const [loading, setLoading] = useState(false);

  const [employeeOptions, setEmployeeOptions] = useState<CheckboxOptionType[]>(
    []
  );
  useEffect(() => {
    if (refreshData) {
      handleFetchEmployees();
    }
  }, [refreshData]);

  const handleFetchEmployees = (search?: string) => {
    setLoading(true);
    BaFilterService.fetchRMs(
      { ...filterParams, search },
      (employees: Employee[]) => {
        setEmployeeOptions(
          employees.map((employee) => ({
            label: <div>{employee.firstName + " " + employee.lastName}</div>,
            value: employee.id as CheckboxValueType,
          }))
        );
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    handleFetchEmployees();
  }, []);
  const handleSearch = debounce((searchText: string) => {
    handleFetchEmployees(searchText);
  }, 500);

  return (
    <div className="ba-rm-filters">
      <Row>
        <Col span={8}>
          <Input.Search
            className="ml-4 mb-5"
            placeholder="Search RM Name"
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Checkbox.Group
          value={filterParams.rmIds}
          onChange={(rmIds) => {
            const params = Object.assign(new BaFilterParams(), filterParams);
            params.rmIds = rmIds as number[];
            onUpdate(params);
          }}
          options={employeeOptions}
        />
      )}
    </div>
  );
}

export default BARMFilters;
