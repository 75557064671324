import React, { useEffect, useRef } from "react";

interface BajajPaymentsProps {}

function BajajPayments(props: BajajPaymentsProps) {
  const formRef = useRef<any>(null);
  const params = new URLSearchParams(window.location.search);
  const fetchedParam = params.get("msg") || "";

  useEffect(() => {
    formRef?.current?.submit();
  }, []);

  return (
    <div className="bajaj-payments">
      <form
        name="frm"
        ref={formRef}
        action="https://payment.bajajfinserv.in/payments/FD_Payment.aspx"
        method="post"
      >
        <input type="hidden" name="msg" value={fetchedParam} />
      </form>
    </div>
  );
}

export default BajajPayments;
