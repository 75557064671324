import React, { useEffect, useState } from "react";
import "./internalUserList.scss";
import { Employee } from "../../../models/Employee/employee.model";
import AppLoader from "../../../shared/components/AppLoader";
import {
  Button,
  Input,
  Select,
  Table,
  TablePaginationConfig,
  Tooltip,
} from "antd";
import { PlusOutlined, PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { generatePath, useHistory, Link } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import EmployeeService from "../../../services/Employee/employee.service";
import { BaFilterParams } from "../../../models/BAFilterParams/baFilterParams.model";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";

interface InternalUserListProps {}

function InternalUserList(props: InternalUserListProps) {
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [rmPagination, setRmPagination] = useState<PaginationDetails>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [contactVisibility, setContactVisibility] = useState(false);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination?.current || 1);
  };

  const handleRow = (employee: Employee, rowIndex: number | undefined) => {
    return {
      onMouseEnter: () => setContactVisibility(true),
      onMouseLeave: () => setContactVisibility(false),
      onClick: (event: any) => {
        history.push(
          generatePath(AppRoutes.INTERNAL_USER_DETAIL, {
            internalUserId: employee.id,
          })
        );
      },
    };
  };
  const handleFetchEmployee = (page?: number) => {
    const filter = Object.assign(new BaFilterParams(), {
      page: page ?? 1,
    });
    setLoading(true);
    EmployeeService.fetchEmployees(
      filter,
      (employees: Employee[], rmPagination: PaginationDetails) => {
        setEmployees(employees);
        setRmPagination(rmPagination);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    handleFetchEmployee(currentPage);
  }, [currentPage]);

  useEffect(() => {
    handleFetchEmployee();
  }, []);

  const columns = [
    {
      title: "NAME",
      dataIndex: "id",
      key: "id",
      render: (text: string, employee: Employee, index: number) => {
        return (
          <div>
            {employee?.firstName} {employee?.lastName}
          </div>
        );
      },
    },
    {
      title: "ROLE",
      dataIndex: "id",
      key: "id",
      render: (id: string, employee: Employee, index: number) => {
        return employee?.role?.name || "NA";
      },
    },
    {
      title: "LOCATION / STATE",
      dataIndex: "id",
      key: "id",
      render: (id: string, employee: Employee, index: number) => {
        return employee?.communicationCityName
          ? employee?.communicationCityName +
              "/" +
              employee?.servingLocations
                .map((location) => location.location?.name)
                .join(", ")
          : "NA";
      },
    },
    {
      title: "NO OF BA",
      dataIndex: "businessAssociateCount",
      key: "businessAssociateCount",
    },
    {
      title: "MONTHLY TARGET / ACHIEVEMENT",
      dataIndex: "monthlyTarget",
      key: "monthlyTarget",
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (id: number, employee: Employee) => (
        <div key={id} className="internal-user-list__icons">
          <Tooltip
            title={
              <div>
                <div>Email</div> <div>{employee?.email}</div>
              </div>
            }
          >
            <MailOutlined />
          </Tooltip>
          <Tooltip
            title={
              <div>
                <div>Mobile</div> <div>{employee?.mobile}</div>
              </div>
            }
          >
            <PhoneOutlined />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div className="internal-user-list">
      <h1 className="text-primary">
        <span>Internal Users</span>
        <Tooltip title="Add">
          <Link to={AppRoutes.INTERNAL_USER_ADD}>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="rounded-btn ml-2"
            />
          </Link>
        </Tooltip>
      </h1>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          {/*<Input.Search
            className="internal-user-list__search"
            placeholder="Search by name"
            style={{ width: 200 }}
          />*/}
          <div className="mt-4">
            <Table
              dataSource={employees}
              columns={columns}
              onRow={handleRow}
              onChange={handleTableChange}
              pagination={{
                total: rmPagination?.totalCount,
                current: rmPagination?.currentPage,
                showTotal: (total: number, range: [number, number]) => (
                  <p>
                    Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                    <b>{`${total.toLocaleString()}`}</b>
                  </p>
                ),
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default InternalUserList;
