import { Table } from "antd";
import React, { FC } from "react";
import {
  Customer,
  SurveyResponses,
} from "../../../models/Customer/customer.model";

interface CustomerProps {
  customer: Customer;
}
const columns = [
  {
    title: "QUESTIONS",
    dataIndex: "questions",
    key: "questions",
    render: (question: string, survey: SurveyResponses, index: number) => {
      return survey?.surveyQuestion?.question ?? "-";
    },
  },
  {
    title: "ANSWERS",
    dataIndex: "answers",
    key: "answers",
    render: (answer: string, survey: SurveyResponses, index: number) => {
      return survey?.answerValue?.map(
        (value) => value?.surveyOptionValue ?? "-"
      );
    },
  },
  {
    title: "POINTS",
    dataIndex: "points",
    key: "points",
  },
  {
    title: "DATE",
    dataIndex: "date",
    key: "date",
  },
];
const sampleData = [
  {
    questions: "Hello, What is the subscription fee?",
    answers: "It's four thousand ",
    points: "35",
    date: "01-01-2022",
  },
];

const CustomerSurvey: FC<CustomerProps> = (props) => {
  const { customer } = props;
  console.log(customer);

  return (
    <div>
      {" "}
      <Table dataSource={customer?.surveyResponses} columns={columns} />
    </div>
  );
};

export default CustomerSurvey;
