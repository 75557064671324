import React from "react";
import "./userPersonalDetails.scss";
import { Col, Row } from "antd";
import { Employee } from "../../../models/Employee/employee.model";

interface UserPersonalDetailsProps {
  employee: Employee;
}

function UserPersonalDetails({ employee }: UserPersonalDetailsProps) {
  return (
    <div className="user-personal-details">
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <div className="user-personal-details__label">Joining Date</div>
          <div className="user-personal-details__value">
            {employee?.joiningDate || "-"}
          </div>
        </Col>
        <Col span={24}>
          <div className="user-personal-details__label">Reporting manager</div>
          <div className="user-personal-details__value">
            {employee?.reportingUser?.firstName}{" "}
            {employee?.reportingUser?.lastName}
          </div>
        </Col>
        <Col span={14}>
          <div className="user-personal-details__label">Address</div>
          <div className="user-personal-details__value">
            {employee?.communicationAddress?.street},{" "}
            {employee?.communicationAddress?.city?.name},{" "}
            {employee?.communicationAddress?.city?.provinceName},
            {employee?.communicationAddress?.zipcode}
          </div>
        </Col>
        <Col span={24}>
          <div className="user-personal-details__label">
            Educational Qualification
          </div>
          <div className="user-personal-details__value">
            {employee?.qualification}
          </div>
        </Col>
        <Col span={24}>
          <div className="user-personal-details__label">Serving Locations</div>
          <div className="user-personal-details__value">
            {employee?.servingLocations?.map((servingLocation) => (
              <span
                key={servingLocation?.id}
                className="user-personal-details__location-item"
              >
                {servingLocation?.location?.name}
              </span>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default UserPersonalDetails;
