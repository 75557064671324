import React from "react";

const BAProfileStatus = [
  {
    title: "Pending Credential Verification",
    description: "The user has been created, but hasn't logged in even once",
    color: "#EA907A",
  },
  {
    title: "Email Verified",
    description: "The email of the user has been verified with OTP",
    color: "#7268BC",
  },
  {
    title: "Mobile Verified",
    description: "The mobile of the user has been verified with OTP",
    color: "#79A3B1",
  },
  {
    title: "Credentials Verified",
    description: "The user has logged in for the first time",
    color: "#D62222",
  },
  {
    title: "Adhaar Completed",
    description: "The aadhaar number has been verified",
    color: "#E066BA",
  },

  {
    title: "Personal Details Complete",
    description:
      "The personal details such as First name last name, gender, DOB has been completed",
    color: "#829828",
  },
  {
    title: "PAN Completed",
    description: "PAN verification is completed for the user",
    color: "#6891D5",
  },

  {
    title: "Qualification Details Complete",
    description:
      "Educational and professional qualifications have been completed for this user",
    color: "#1CB5B0",
  },
  {
    title: "Bank Details Verification Complete",
    description: "Penny Drop verification has been done on bank account",
    color: "#049372",
  },
  {
    title: "Bank Details Complete",
    description:
      "The user has inputted their bank details, and bank_details record is created and linked",
    color: "#60BC12",
  },

  {
    title: "References Complete",
    description: "The user has entered two references (only for BA)",
    color: "#68BC93",
  },
  {
    title: "Profile Pic Uploaded ",
    description: "Profile Pic of the user is uploaded",
    color: "#3B6FB7",
  },
];
const CustomerProfileStatus = [
  {
    title: "Pending Credential Verification",
    description: "The user has been created, but hasn't logged in even once",
    color: "#EA907A",
  },
  {
    title: "Email Verified",
    description: "The email of the user has been verified with OTP",
    color: "#7268BC",
  },
  {
    title: "Mobile Verified",
    description: "The mobile of the user has been verified with OTP",
    color: "#79A3B1",
  },
  {
    title: "Credentials Verified",
    description: "The user has logged in for the first time",
    color: "#D62222",
  },
  {
    title: "Adhaar Verified",
    description: "The aadhaar number has been verified",
    color: "#E066BA",
  },

  {
    title: "Aadhaar Document Uploaded",
    description: "The aadhaar document has been Uploaded",
    color: "#049372",
  },
  {
    title: "PAN Verified",
    description: "PAN verification is completed for the user",
    color: "#6891D5",
  },

  {
    title: "PAN Document Uploaded",
    description: "PAN Document has been Uploaded",
    color: "#1CB5B0",
  },
  {
    title: "Personal Details Complete",
    description:
      "The personal details such as First name last name, gender, DOB has been completed",
    color: "#829828",
  },

  {
    title: "Profile Pic Uploaded ",
    description: "Profile Pic of the user is uploaded",
    color: "#3B6FB7",
  },
];

const BAprofileStatuses = [
  {
    title: "Under Review",
    description: "Admin has to review the BA profile",
    color: "#1B9AD3",
  },

  {
    title: "Pending E-Sign BA",
    description: "The BA has not yet signed the legality agreement",
    color: "#D78E15",
  },
  {
    title: "Pending E-Sign Admin",
    description: " BA has completed the e-sign. Admin yet to e-sign",
    color: "#D78E15",
  },

  {
    title: "Active",
    description:
      "Both the parties have signed the agreement. The BA is allowed to source the business",
    color: "#26AD10",
  },
  {
    title: "Inactive",
    description: "The BA is not part of the organisation",
    color: "#D62222",
  },
  {
    title: "Incomplete",
    description: "BA not yet completed the profile",
    color: "#3114AE",
  },
  { title: "Rejected", description: " BA profile rejected", color: "#D55353" },
];

export { BAProfileStatus, CustomerProfileStatus, BAprofileStatuses };
