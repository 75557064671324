export const ApiRoutes = {
  BASE_URL: process.env.REACT_APP_API_BASE_URL,
  SIGN_IN: "/user_management/employee/sign_in",
  VERIFY_EMAIL: "/user_management/employee/verify_email",
  VERIFY_MOBILE: "/user_management/employee/verify_mobile",
  RESET_PASSWORD: "/user_management/employee/reset_password",
  UPDATE_PASSWORD:
    "/user_management/employee/business_associates/:id/update_password",
  SEND_OTP: "/user_management/employee/send_otp",
  INACTIVE_STATUS:
    "user_management/employee/business_associates/assign_customers/:id",
  SIGN_OUT: "/user_management/employee/sign_out",
  ROLES: "/meta/roles",
  PROVINCES: "/meta/provinces",
  CITIES: "/meta/provinces/:provinceId/cities",
  MULTIPLE_CITIES: "/meta/cities",
  LOCATIONS: "/meta/provinces/:provinceId/cities/:cityId/locations/:baId",
  SPECIALITIES: "/meta/specialities",
  UPLOAD_FILES: "/file_management/uploaded_files",
  BUSINESS_ASSOCIATES: "/user_management/employee/business_associates",
  DOWNLOAD_BA: "/user_management/employee/getcsv",
  EMPLOYEES: "/user_management/employee/employees",
  MY_LEARNING: "/content_management/employee/learning_modules",
  MY_LEARNING_MODLUE_TYPES: "/content_management/shared/learning_module_types",
  BANNER_LIST: "/banner_management/employee/banners",
  SURVEY_QUESTION_LIST: "/survey_management/employee/survey_questions",
  PRODUCT_NAME: "/banner_management/employee/banner_list_categories",
  ADD_BANNER: "/banner_management/employee/banners",
  UPDATE_BANNER: "/banner_management/employee/banners/:id",
  UPDATE_SURVEY_QUESTION: "/survey_management/employee/survey_questions/:id",
  ADD_SURVEY_QUESTION: "/survey_management/employee/survey_questions",
  FAQS_LIST: "/content_management/employee/faqs",
  ADD_COURSE: "/content_management/employee/learning_modules",
  EDIT_COURSE: "/content_management/employee/learning_modules/:moduleId",
  UPDATE_AXIS_BANK: "/order_management/employee/upload_orders",
  UPLOAD_YES_BANK_DOCUMENT: "/order_management/employee/upload_orders",

  ASSIGN_RM: "/user_management/employee/business_associates/assign_rm",
  ASSIGN_CUSTOMER_RM: "/user_management/employee/customers/assign_rm",
  ASSIGN_BA: "/user_management/employee/customers/assign_ba",
  CUSTOMERS: "/user_management/employee/customers",
  ORDERS: "/order_management/employee/orders",
  DOWNLOAD_ORDERS: "order_management/employee/download_orders",
  DOWNLOAD_PRODUCT_REQUESTS_LOGS:
    "product_management/employee/product_requests_logs",
  CATEGORIES: "/meta/product_categories",
  PRODUCT_TYPE_META: "/meta/meta_product_types",
  SERVICE_PROVIDERS: "/product_management/employee/service_providers",
  SERVICE_PROVIDER_PRODUCTS: "/product_management/employee/products",
  BROKERAGE_GROUPS: "/meta/brokerage_groups",
  ASSIGN_BROKERAGE_GROUP:
    "/user_management/employee/business_associates/assign_brokerage_group",
  COMMISSIONS: "/commission_management/employee/commissions",
  BUSINESS_CREDIT_POINTS: "/commission_management/employee/commissions",
  BA_NOTES:
    "/user_management/employee/business_associates/:businessAssociateId/notes",
  CUSTOMER_NOTES: "/user_management/employee/customers/:customerId/notes",
  BA_RMS:
    "/user_management/employee/business_associates/ba_relationship_managers",
  BA_STATES: "/user_management/employee/business_associates/ba_states",
  BA_LOCALITIES: "/user_management/employee/business_associates/ba_localities",
  BA_CITIES: "/user_management/employee/business_associates/ba_cities",
  BA_PROFILE_COMPLETION_STATUSES:
    "/user_management/employee/business_associates/ba_profile_completion_statuses",
  BA_STATUSES: "/user_management/employee/business_associates/ba_statuses",
  CUSTOMER_NAME: "/user_management/employee/customer_names",
  CUSTOMER_BAS: "/user_management/employee/customer_bas",
  CUSTOMER_CITIES: "/user_management/employee/customer_cities",
  CUSTOMER_STATES: "/user_management/employee/customer_states",
  PRODUCT_REQUESTS: "/product_management/employee/product_requests",
  PRODUCT_REQUEST_UPDATES:
    "/product_management/employee/product_request_updates",
  HEALTH_INSURANCE_ORDER: "/order_management/employee/ha_orders",
  ACTIVITY_LOG: "audit_management/employee/activity_logs",
  NOTIFICATION: "/notification_management/employee/notifications",
};
