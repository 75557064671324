import React, { useEffect, useState } from "react";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import { City } from "../../../../models/City/city.model";
import { Checkbox, Col, Input, Row } from "antd";
import { BaFilterParams } from "../../../../models/BAFilterParams/baFilterParams.model";
import { BaFilterService } from "../../../../services/BAFilter/baFilter.service";
import { debounce } from "lodash";
import AppLoader from "../../../../shared/components/AppLoader";

interface BACityFiltersProps {
  filterParams: BaFilterParams;
  refreshData: boolean;
  onUpdate: (filterParams: BaFilterParams) => void;
}

function BACityFilters({
  onUpdate,
  filterParams,
  refreshData,
}: BACityFiltersProps) {
  const [cityOptions, setCityOptions] = useState<CheckboxOptionType[]>([]);
  const [loading, setLoading] = useState(false);

  const handleFetchCities = (search?: string) => {
    setLoading(true);
    BaFilterService.fetchCities(
      { ...filterParams, search, cityIds: [] },
      (cities: City[]) => {
        const city = cities.map((city) => ({
          label: city.name,
          value: city.id as CheckboxValueType,
        }));
        city?.sort((a, b) => a?.label?.localeCompare(b?.label ?? "") as any);
        setCityOptions(city);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    handleFetchCities();
  }, []);

  useEffect(() => {
    if (refreshData) {
      handleFetchCities();
    }
  }, [refreshData]);
  const handleSearch = debounce((searchText: string) => {
    handleFetchCities(searchText);
  }, 500);

  return (
    <div>
      <Row>
        <Col span={8}>
          <Input.Search
            className="ml-4 mb-5"
            placeholder="Search City Name"
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Checkbox.Group
          value={filterParams.cityIds}
          onChange={(cityIds) => {
            const params = Object.assign(new BaFilterParams(), filterParams);
            params.cityIds = cityIds as number[];
            onUpdate(params);
          }}
          options={cityOptions}
        />
      )}
    </div>
  );
}

export default BACityFilters;
