import React, { useEffect, useState } from "react";
import "./productRequestList.scss";
import ProductRequestService from "../../../services/ProductRequest/productRequest.service";
import { ProductRequest } from "../../../models/ProductRequest/productRequest.model";
import { Button, Col, Input, Row, Table, Tooltip } from "antd";
import { generatePath, useHistory } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { PlusOutlined } from "@ant-design/icons";
import AppLoader from "../../../shared/components/AppLoader";
import { Customer } from "../../../models/Customer/customer.model";
import moment from "moment";
import { debounce } from "lodash";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import useFilters from "../../../shared/hooks/useFilters";

interface ProductRequestListProps {}

function ProductRequestList(props: ProductRequestListProps) {
  const history = useHistory();

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [productRequests, setProductRequests] = useState<ProductRequest[]>([]);

  const [loading, setLoading] = useState(false);

  const handleFetchProductRequests = (searchText: string) => {
    setLoading(true);
    ProductRequestService.fetchProductRequests(
      searchText,
      (productRequests: ProductRequest[]) => {
        setProductRequests(productRequests);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    handleFetchProductRequests("");
    const prevFilter = getParams();
    console.log({ prevFilter });
  }, []);

  const handleRow = (
    productRequest: ProductRequest,
    rowIndex: number | undefined
  ) => {
    return {
      onClick: (event: any) => {
        history.push(
          generatePath(AppRoutes.PRODUCT_REQUEST_DETAIL, {
            productRequestId: productRequest.id,
          })
        );
      },
    };
  };

  const columns = [
    {
      title: "CUSTOMER",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "CUSTOMER ID",
      dataIndex: "customerId",
      key: "customerId",
    },
    {
      title: "PRODUCT TYPE",
      dataIndex: "metaProductTypeName",
      key: "metaProductTypeName",
    },
    {
      title: "PRODUCT REQUEST ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "SERVICE PROVIDER",
      dataIndex: "serviceProviderName",
      key: "serviceProviderName",
    },
    {
      title: "SANCTIONED AMOUNT",
      dataIndex: "amount",
      key: "amount",
      render: (amount: number) => <span>{amount ? `₹${amount}` : "--"}</span>,
    },
    {
      title: "DATE OF APPLICATION",
      dataIndex: "dateOfApplication",
      key: "dateOfApplication",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <span className="text-capitalize">{removeUnderscore(status)}</span>
      ),
    },
  ];
  const handleProductRequestsLogs = () => {
    setDownloadLoading(true);
    ProductRequestService.downloadProductRequestsLogs(
      () => {},
      () => {},
      () => {
        setDownloadLoading(false);
      }
    );
  };
  const handleSearch = debounce((searchText: string) => {
    handleFetchProductRequests(searchText);
  }, 500);

  const { getParams, setParams } = useFilters();

  return (
    <div className="product-request-list">
      <Row align="middle" gutter={[30, 0]}>
        <Col span={6}>
          <h1 className="text-primary mb-0">Product Requests</h1>
        </Col>
        <Col span={10}>
          <Input.Search
            className="product-request-list__search"
            placeholder="Search by Customer ID/name or Request ID"
            onChange={(e) => {
              handleSearch(e.target.value);
              setParams({ search: e.target.value });
            }}
          />
        </Col>
        <Col span={8} className="text-right">
          <Button
            type="primary"
            loading={downloadLoading}
            onClick={handleProductRequestsLogs}
          >
            Download AuthBridge Logs
          </Button>
        </Col>
      </Row>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          <div className="mt-4">
            <Table
              dataSource={productRequests}
              columns={columns}
              onRow={handleRow}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductRequestList;
