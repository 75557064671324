import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { Line } from "@ant-design/charts";

const CustomerLineGraph = () => {
  const data = [
    {
      month: "Jan",
      value: 0,
    },
    {
      month: "Feb",
      value: 100,
    },
    {
      month: "Mar",
      value: 200,
    },
    {
      month: "April",
      value: 300,
    },
    {
      month: "May",
      value: 250,
    },
    {
      month: "Jun",
      value: 100,
    },
    {
      month: "July",
      value: 300,
    },
    {
      month: "Aug",
      value: 50,
    },
    {
      month: "Sept",
      value: 100,
    },
    {
      month: "Oct",
      value: 150,
    },
    {
      month: "Nov",
      value: 300,
    },
    {
      month: "Dec",
      value: 200,
    },
  ];
  const config = {
    data,
    xField: "month",
    yField: "value",
    label: {},
    point: {
      size: 2,
      shape: "circle",
      style: {
        fill: "#5B8FF9",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#5B8FF9",
          fill: "red",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  };
  return <Line {...config} />;
};

export default CustomerLineGraph;
