import axiosInstance from "../../interceptor/axiosInstance";
import { Employee } from "../../models/Employee/employee.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { PaginationDetails } from "../../models/Pagination/pagination.model";
import { BaFilterParams } from "../../models/BAFilterParams/baFilterParams.model";

export default class EmployeeService {
  static fetchEmployees(
    filter: BaFilterParams,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void,
    page?: number
  ) {
    axiosInstance
      .get(ApiRoutes.EMPLOYEES, { params: filter })
      .then((response) => {
        const employees = deserialize(Employee, response.data["employees"]);
        const rmPagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(employees, rmPagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showEmployee(
    employeeId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.EMPLOYEES + "/" + employeeId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const employee = deserialize(Employee, response.data["employee"]);
        employee.setFormValues();
        onSuccess(employee);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createEmployee(
    employee: Employee,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const employeeJSON = serialize(employee);
    axiosInstance
      .post(ApiRoutes.EMPLOYEES, employeeJSON)
      .then((response) => {
        Notification({
          message: "Internal User created",
          type: NotificationTypes.SUCCESS,
        });
        const employee = deserialize(Employee, response.data["employee"]);
        onSuccess(employee);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateEmployee(
    employee: Employee,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.EMPLOYEES + "/" + employee.id;
    const employeeJSON = serialize(employee);
    axiosInstance
      .put(API_URL, employeeJSON)
      .then((response) => {
        Notification({
          message: "Internal User updated",
          type: NotificationTypes.SUCCESS,
        });
        const employee = deserialize(Employee, response.data["employee"]);
        onSuccess(employee);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteEmployee(
    employeeId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.EMPLOYEES + "/" + employeeId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
