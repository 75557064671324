import React, { useState } from "react";
import { Row, Col, Modal, Drawer } from "antd";
import checkStatus from "../../../assets/images/checkStatus.png";
import AssignBusinessAssociate from "../AssignBusinessAssociate";

import { BusinessAssociateService } from "../../../services/BusinessAssociateService/businessAssociate.service";

interface IndivualBAInactiveModalProps {
  isModalVisible: boolean;
  currentBaId: number;
  closeHandler: () => void;
}
function IndivualBAInactiveModal({
  isModalVisible,
  currentBaId,
  closeHandler,
}: // setIsModalVisible,
IndivualBAInactiveModalProps) {
  const [showBA, setshowBA] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const handleBA = () => {
    setshowBA(!showBA);
  };

  const handleCancel = () => {
    closeHandler();
    setUpdateLoading(true);
    BusinessAssociateService.setBAInactiveStatus(
      Number(currentBaId),
      { assignToRm: true, baId: null },
      () => {},
      () => {},
      () => {
        setUpdateLoading(false);
        closeHandler();
      }
    );
  };
  const handleInactiveStatus = () => {
    setshowBA(true);
  };

  return (
    <div>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        onOk={handleInactiveStatus}
        okText="Assign BA"
      >
        <Row className="text-center " gutter={[10, 10]}>
          <Col span={24}>
            <img src={checkStatus} alt="Logo" className="check_status__logo" />
          </Col>
          <Col span={24}>
            <h3 className="text-primary">{`Status Changed to Inactive`}</h3>
          </Col>
          <Col
            span={24}
          >{`All the customers  tagged to this account will be auto assigned to RM role. No need for further customer to another BA`}</Col>
          <Col span={24}>
            <h4 className="text-primary mt-3">{`Would you like to reassign mannually ?`}</h4>
          </Col>
        </Row>
      </Modal>
      <Drawer
        width="50vw"
        onClose={handleBA}
        visible={showBA}
        destroyOnClose
        footer={null}
      >
        <AssignBusinessAssociate
          closeHandler={closeHandler}
          currentBaId={currentBaId || 0}
        />
      </Drawer>
    </div>
  );
}

export default IndivualBAInactiveModal;
