import React from "react";
import { Typography, Row, Col } from "antd";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import "./thirdPartyVerifications.scss";
import { maskNumber } from "../../../shared/utils/maskNumber";
interface ThirdPartyVerificationsProps {
  businessAssociate: BusinessAssociate;
}

function ThirdPartyVerifications({
  businessAssociate,
}: ThirdPartyVerificationsProps) {
  return (
    <div className="third-party-verifications">
      <h4 className="text-primary text-italic mb-4">
        All Details are verified from 3rd Party without any modifications.
      </h4>
      <div className="third-party-verifications__adhaar-verification">
        <Row gutter={[20, 10]}>
          <Col span={24}>
            <h4 className="text-primary">Adhaar Verification</h4>
            <div className="adhaar-verification__label text-grey">
              Adhaar Number
            </div>
            <div className="adhaar-verification__value">
              {maskNumber(businessAssociate?.aadharNumber || "-")}
            </div>
          </Col>
          <Col span={24}>
            <div className="adhaar-verification__label  text-grey">Address</div>
            <div className="adhaar-verification__value">
              {businessAssociate?.addresses[0]?.street || "-"} ,
              {businessAssociate?.addresses[0]?.city?.name || "-"} ,
              {businessAssociate?.addresses[0]?.city?.provinceName || "-"} ,
              {businessAssociate?.addresses[0]?.zipcode || "-"}
            </div>
          </Col>
        </Row>
      </div>
      <hr className="mt-5" />
      <div className="third-party-verifications__pan-verification mt-5">
        <Row gutter={[20, 10]}>
          <Col span={24}>
            <h4 className="text-primary">Pan Verification</h4>
            <div className="pan-verification__label text-grey">Pan Number</div>
            <div className="pan-verification__value">
              {" "}
              {maskNumber(businessAssociate?.panNumber || "-")}
            </div>
          </Col>
          <Col span={24}>
            <div className="pan-verification__name text-grey">First Name</div>
            <div className="pan-verification__name_value">
              {businessAssociate?.firstName ?? "-"}
            </div>
          </Col>
          <Col span={24}>
            <div className="pan-verification__label text-grey">Last Name</div>
            <div className="pan-verification__value">
              {businessAssociate?.lastName ?? "-"}
            </div>
          </Col>
          <Col span={24}>
            <div className="pan-verification__label text-grey">DOB</div>
            <div className="pan-verification__value"></div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default ThirdPartyVerifications;
