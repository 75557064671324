import React from "react";
import "./orderGeneralDetails.scss";
import { Order } from "../../../../models/Order/order.model";
import { Col, Row } from "antd";
import moment from "moment";
import { ProductTypesEnum } from "../../../../enums/productTypes.enum";
import { removeUnderscore } from "../../../../shared/utils/removeUnderscore";
import { useLocation } from "react-router-dom";

interface OrderGeneralDetailsProps {
  order: Order;
}

function OrderGeneralDetails({ order }: OrderGeneralDetailsProps) {
  return (
    <div className="order-general-details">
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <div className="order-general-details__label">Order ID</div>
          <div className="order-general-details__value">{order?.id || "-"}</div>
        </Col>
        <Col span={24}>
          <div className="order-general-details__label">Customer name</div>
          <div className="order-general-details__value">
            {order?.customerName || "-"}
          </div>
        </Col>
        <Col span={24}>
          <div className="order-general-details__label">Product Name</div>
          <div className="order-general-details__value">
            {removeUnderscore(order?.product?.name ?? "") || "-"}
          </div>
        </Col>
        {order?.orderType == ProductTypesEnum.CREDIT_SERVICES && (
          <>
            <Col span={24}>
              <div className="order-general-details__label">Category Type</div>
              <div className="order-general-details__value">
                {order?.orderType ?? "-"}
              </div>
            </Col>
            <Col span={24}>
              <div className="order-general-details__label">
                Transaction Number
              </div>
              <div className="order-general-details__value">
                {order?.transactionId?.[0] || "-"}
              </div>
            </Col>
          </>
        )}

        {order?.orderType == ProductTypesEnum.CAREER_GUIDE && (
          <Col span={24}>
            <div className="order-general-details__label">
              Post Guidance Session Booked
            </div>
            <div className="order-general-details__value">
              {order?.orderDetails?.sessionBooked ? "Yes" : "No"}
            </div>
          </Col>
        )}
        <Col span={24}>
          <div className="order-general-details__label">Date</div>
          <div className="order-general-details__value">
            {moment(order?.createdAt).format("DD/MM/YYYY") || "-"}
          </div>
        </Col>
        <Col span={24}>
          <div className="order-general-details__label">Total amount</div>

          <div className="order-general-details__value">
            {order?.orderType == ProductTypesEnum.BAJAJ_FINSERV_FD
              ? order?.orderDetails?.amount
                ? `₹${order?.orderDetails?.amount}`
                : "--"
              : order?.orderType == ProductTypesEnum.CAREER_GUIDE
              ? order?.orderDetails?.totalAmount
                ? `₹${order?.orderDetails?.totalAmount}`
                : "--"
              : order?.product?.amount
              ? `₹${Number(order?.product?.amount)}`
              : "--"}
          </div>
        </Col>
        {order?.orderType === ProductTypesEnum.CAREER_GUIDE && (
          <React.Fragment>
            <Col span={24}>
              <div className="order-general-details__label">Email</div>
              <div className="order-general-details__value">
                {order?.orderDetails?.email || "-"}
              </div>
            </Col>
            <Col span={24}>
              <div className="order-general-details__label">Mobile</div>
              <div className="order-general-details__value">
                {order?.orderDetails?.mobile || "-"}
              </div>
            </Col>
            <Col span={24}>
              <div className="order-general-details__label">Website URL</div>
              <div className="order-general-details__value">
                {order?.orderDetails?.websiteUrl || "-"}
              </div>
            </Col>
          </React.Fragment>
        )}
        {order?.orderType === ProductTypesEnum.CREDIT_SERVICES && (
          <Col span={24}>
            <div className="order-general-details__label">
              Bureau Report Status
            </div>
            <div className="order-general-details__value text-capitalize">
              {order?.bureauReportStatus?.split("_").join(" ")}
            </div>
          </Col>
        )}
        <Col span={24}>
          <div className="order-general-details__label">Status</div>
          <div className="order-general-details__value text-capitalize">
            {order?.orderStatus?.split("_").join(" ")}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default OrderGeneralDetails;
