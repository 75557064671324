import React, { useState } from "react";
import axiosInstance from "../../../interceptor/axiosInstance";
import { Faqs } from "../../../models/MyProfileSetting/myProfileSetting.model";
import { Banner } from "../../../models/Banner/banner.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../../shared/components/Notification";
import { ApiRoutes } from "../../../routes/routeConstants/apiRoutes";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { Category } from "../../../models/Category/category.model";
import {
  SurveyQuestionsForm,
  SurveyQuestions,
} from "../../../models/SurveyQuestions/surveyQuestions.model";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";

const SurveyQuestionsService = () => {
  const [loading, setLoading] = useState(false);
  const [surveyQuestionList, setSurveyQuestionList] = useState<
    SurveyQuestions[]
  >([]);
  const fetchSurveyQuestionsList = async (
    currentPage: number,
    onSuccess: Function
  ) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(ApiRoutes.SURVEY_QUESTION_LIST, {
        params: {
          page: currentPage,
        },
      });
      const data = deserialize(
        SurveyQuestions,
        response.data["survey_questions"] as SurveyQuestions[]
      );
      const surveyPagination = deserialize(
        PaginationDetails,
        response.data["meta"]
      );
      setSurveyQuestionList(data);

      onSuccess(data, surveyPagination);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const addSurveyQuestions = async (
    questionValues: SurveyQuestionsForm,
    onSuccess: Function
  ) => {
    setLoading(true);
    const data = serialize(SurveyQuestionsForm, questionValues);
    try {
      const response = await axiosInstance.post(ApiRoutes.ADD_SURVEY_QUESTION, {
        survey_question: data,
      });
      if (response) {
        Notification({
          message: "New Question Created!",
          description: response.data?.message,
          type: NotificationTypes.SUCCESS,
        });
        const data = deserialize(SurveyQuestionsForm, response.data["banner"]);
      }
      onSuccess(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const updateSurveyQuestions = async (
    surveyValues: SurveyQuestions,
    surveyId: string,
    onSuccess: Function
  ) => {
    const data = serialize(SurveyQuestionsForm, surveyValues);
    setLoading(true);
    try {
      const response = await axiosInstance.put(
        ApiRoutes.UPDATE_SURVEY_QUESTION.replace(":id", surveyId),
        {
          survey_question: data,
        }
      );
      if (response) {
        Notification({
          message: "Survey Question Updated!",
          description: response.data?.message,
          type: NotificationTypes.SUCCESS,
        });
        onSuccess(response);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    fetchSurveyQuestionsList,
    addSurveyQuestions,
    surveyQuestionList,
    loading,
    updateSurveyQuestions,
  };
};
export default SurveyQuestionsService;
