import { Table } from "antd";
import moment from "moment";
import React, { FC } from "react";
import { BureauReport } from "../../../models/BureauReport/bureauReport.model";
import {
  Customer,
  SurveyResponses,
} from "../../../models/Customer/customer.model";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";

interface CustomerBureauReportProps {
  customer: Customer;
}
const columns = [
  {
    title: "ORDER ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "PRODUCT NAME",
    dataIndex: "productName",
    key: "productName",
    render: (_: string, report: BureauReport) =>
      removeUnderscore(report?.product?.name ?? ""),
  },
  {
    title: "TRANSACTION NO",
    dataIndex: "transactionId",
    key: "transactionId",
  },
  {
    title: "SCORE",
    dataIndex: "score",
    key: "score",
  },
  {
    title: "AMOUNT",
    dataIndex: "date",
    key: "date",
    render: (_: string, report: BureauReport) =>
      Number(report?.product?.amount) ?? "-",
  },
  {
    title: "DATE",
    dataIndex: "date",
    key: "date",
    render: (date: string, report: BureauReport) =>
      moment(date).format("DD-MM-YYYY"),
  },
  {
    title: "STATUS",
    dataIndex: "orderStatus",
    key: "orderStatus",
    render: (status: string, report: BureauReport) => (
      <span className={status.replace("_", "-")}>
        {removeUnderscore(status ?? "")}
      </span>
    ),
  },
];

const CustomerBureauReport: FC<CustomerBureauReportProps> = (props) => {
  const { customer } = props;
  console.log(customer);

  return (
    <div>
      {" "}
      <Table dataSource={customer?.bureauReport} columns={columns} />
    </div>
  );
};

export default CustomerBureauReport;
