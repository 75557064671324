import React, { useEffect, useState } from "react";
import "./serviceProviderList.scss";
import { ServiceProvider } from "../../../models/ServiceProvider/serviceProvider.model";
import ServiceProviderService from "../../../services/ServiceProvider/serviceProvider.service";
import AppLoader from "../../../shared/components/AppLoader";
import { Table } from "antd";
import { RelationshipManager } from "../../../models/BusinessAssociate/businessAssociate.model";
import { generatePath, useHistory } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
interface ServiceProviderListProps {}

function ServiceProviderList(props: ServiceProviderListProps) {
  const history = useHistory();

  const [serviceProviders, setServiceProviders] = useState<ServiceProvider[]>(
    []
  );

  const [loading, setLoading] = useState(false);

  const handleRow = (
    serviceProvider: ServiceProvider,
    rowIndex: number | undefined
  ) => {
    return {
      onClick: (event: any) => {
        history.push(
          generatePath(AppRoutes.SERVICE_PROVIDER_DETAIL, {
            serviceProviderId: serviceProvider.id,
          })
        );
      },
    };
  };

  useEffect(() => {
    setLoading(true);
    ServiceProviderService.fetchServiceProviders(
      (serviceProviders: ServiceProvider[]) => {
        setServiceProviders(serviceProviders);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "RM NAME",
      dataIndex: "relationshipManager",
      key: "relationshipManager",
      render: (relationshipManager: RelationshipManager) =>
        relationshipManager
          ? relationshipManager?.firstName + " " + relationshipManager?.lastName
          : "-",
    },
    {
      title: "NO OF PRODUCTS",
      dataIndex: "productCount",
      key: "productCount",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <div className="text-capitalize">{status}</div>
      ),
    },
  ];

  return (
    <div className="service-provider-list">
      <h1 className="text-primary">Service Providers</h1>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          <div>
            <Table
              dataSource={serviceProviders}
              columns={columns}
              onRow={handleRow}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ServiceProviderList;
