export enum ProfileStatusEnum {
  INCOMPLETE = "incomplete",
  UNDER_REVIEW = "under_review",
  PENDING_E_SIGN_BA = "pending_e_sign_ba",
  PENDING_E_SIGN_ADMIN = "pending_e_sign_admin",
  ACTIVE = "active",
  INACTIVE = "inactive",
  REJECTED = "rejected",
  COMPLETED = "completed",
  PROFILE = "profile",
  PAYMENT = "payment",
  VIDEO_KYC = "videoKyc",
  EXPIRED = "expired",
}
