import React, { useEffect, useState } from "react";
import "./brokerageGroupList.scss";
import AppLoader from "../../../shared/components/AppLoader";
import { Table, Popover } from "antd";
import { BrokerageGroup } from "../../../models/BrokerageGroup/brokerageGroup.model";
import BrokerageGroupService from "../../../services/BrokerageGroup/brokerageGroup.service";

interface BrokerageGroupListProps {}

function BrokerageGroupList(props: BrokerageGroupListProps) {
  const [brokerageGroups, setBrokerageGroups] = useState<BrokerageGroup[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    BrokerageGroupService.fetchBrokerageGroups(
      (brokerageGroups: BrokerageGroup[]) => {
        setBrokerageGroups(brokerageGroups);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  const columns = [
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (name: string, brokerageGroup: BrokerageGroup) => (
        <Popover
          content={
            <div>
              <div className="text-bold">{name}</div>
              {brokerageGroup?.brokerageWeights?.map((brokerageWeight, i) => (
                <div key={i}>
                  <div className="text-primary text-bold">
                    {brokerageWeight?.typeName}
                  </div>
                  <ul className="brokerage-group-list__ul">
                    {brokerageWeight?.productWeights?.map(
                      (productWeight, j) => (
                        <li key={j}>
                          <span>{productWeight?.productName}</span>
                          <span className="ml-2 brokerage-group-list__percentage">
                            BA Brokerage {productWeight?.weight}
                          </span>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              ))}
            </div>
          }
        >
          {name}
        </Popover>
      ),
    },
    {
      title: "NO OF BA ASSIGNED",
      dataIndex: "noOfBas",
      key: "noOfBas",
    },
    {
      title: "NO OF PRODUCTS",
      dataIndex: "noOfProducts",
      key: "noOfProducts",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <div className="text-capitalize">{status}</div>
      ),
    },
  ];

  return (
    <div className="brokerage-group-list">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          <div>
            <Table dataSource={brokerageGroups} columns={columns} />
          </div>
        </div>
      )}
    </div>
  );
}

export default BrokerageGroupList;
