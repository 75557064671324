import React, { useEffect, useState } from "react";
import { Row, Col, Button, Upload } from "antd";
import { FieldArray, FieldArrayRenderProps, Form, Formik } from "formik";
import PageTitle from "../../../shared/components/PageTitle";
import InputField from "../../../shared/components/InputField";
import DropdownField from "../../../shared/components/DropdownField";
import { InboxOutlined } from "@ant-design/icons";
import "./surveyQuestionForm.scss";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import deleteIcon from "../../../assets/images/delete.png";
import { DropdownModeEnum } from "../../../enums/dropdownMode.enum";
import {
  SurveyQuestions,
  SurveyOptions,
  SurveyQuestionsForm as SurveyQuestionsFormModel,
} from "../../../models/SurveyQuestions/surveyQuestions.model";
import SurveyQuestionsService from "../../../services/MyProfile/SurveyQuestions/surveyQuestions.service";
import { values } from "lodash";
import { surveyQuestionValidation } from "./surveyQuestionValidation";
interface SurveyQuestionsFormProps {
  handleFormVisibility: () => void;
  editSurvey: SurveyQuestions | undefined;
  onSuccess: () => void;
}

function SurveyQuestionsForm(props: SurveyQuestionsFormProps) {
  const { handleFormVisibility, editSurvey, onSuccess } = props;
  const { Dragger } = Upload;
  const [formLoading, setFormLoading] = useState(false);
  const [formValues, setFormValues] = useState(new SurveyQuestionsFormModel());
  const [answerType, setAnswerType] = useState("boolean");
  const [answerSum, setAnswerSum] = useState();
  const { addSurveyQuestions, updateSurveyQuestions, loading } =
    SurveyQuestionsService();
  const handleUpdateValue = (values: SurveyQuestionsFormModel) => {
    if (values.questionType === "slider") {
      const data =
        values.questionType === "slider" &&
        values.surveyOptions?.map((val) => {
          return {
            ...val,
            answer: String(val.answer) + "-" + String(val.answer1),
          };
        });
      const newData = Object.assign(new SurveyQuestionsFormModel(), {
        ...values,
        surveyOptions: data,
      });
      if (newData) {
        newData?.surveyOptions?.forEach((val) => delete val?.answer1);
        return newData;
      }
    }
    return;
  };

  const handleSubmit = (values: SurveyQuestionsFormModel) => {
    const updatedValue = handleUpdateValue(values);

    // if (values.questionType === "slider") {
    //   const data =
    //     values.questionType === "slider" &&
    //     values.surveyOptions?.map((val) => {
    //       return {
    //         ...val,
    //         answer: String(val.answer) + "-" + String(val.answer1),
    //       };
    //     });
    //   const newData = Object.assign(new SurveyQuestionsFormModel(), {
    //     ...values,
    //     surveyOptions: data,
    //   });
    //   if(updatedData)
    //   updatedData = newData?.surveyOptions?.forEach(
    //     (val) => delete val?.answer1
    //   );
    // }

    editSurvey?.id
      ? updateSurveyQuestions(
          updatedValue?.questionType == "slider" ? updatedValue : values,
          editSurvey?.id?.toString() ?? "",
          () => {
            handleFormVisibility();
            onSuccess();
          }
        )
      : addSurveyQuestions(
          updatedValue?.questionType == "slider" ? updatedValue : values,
          () => {
            handleFormVisibility();
            onSuccess();
          }
        );
  };

  const answerTypeOption = [
    {
      label: "Boolean",
      value: "boolean",
    },
    {
      label: "Single Select",
      value: "single_select",
    },
    {
      label: "Multi Select",
      value: "multi_select",
    },

    {
      label: "Slider",
      value: "slider",
    },
  ];

  const handleAddAnswerPoints = (arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.push(new SurveyQuestions());
  };

  useEffect(() => {
    setFormValues({ ...formValues, surveyOptions: [new SurveyOptions()] });

    if (editSurvey?.id) {
      const newData =
        editSurvey?.id &&
        editSurvey?.surveyOptions?.map((val) => {
          const firstValue = val?.answer?.split("-")[0];
          const secondValue = val?.answer?.split("-")[1];
          return {
            ...val,
            answer: String(firstValue),
            answer1: String(secondValue),
          };
        });
      setFormValues(
        Object.assign({
          ...editSurvey,
          questionBannerImageId: editSurvey?.questionBannerImage?.id,
          surveyOptions: newData,
        })
      );

      setAnswerType(editSurvey?.questionType ?? "");
    }
  }, []);

  return (
    <div className="survey-questions-form">
      <div className="survey-questions__heading">
        <h1 className="text-primary mb-5">
          {editSurvey?.id ? "Edit Survey Question" : "New Survey Question"}{" "}
        </h1>
      </div>
      <div>
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={surveyQuestionValidation}
        >
          {({ values, errors, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <Row gutter={[20, 20]}>
                  <Col span={12}>
                    <InputField
                      title="Question"
                      type="textarea"
                      name="question"
                      placeholder="Enter Question"
                      required
                    />
                  </Col>
                  <Col span={12}>
                    <AttachmentUpload
                      accept="image/jpeg,image/png,image/jpg"
                      name={"questionBannerImageId"}
                      placeholder="Upload File"
                      title="Upload Banner Image"
                      setFieldValue={setFieldValue}
                      value={values?.questionBannerImage}
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Answer Type"
                      name="questionType"
                      showArrow
                      options={answerTypeOption}
                      placeHolder="Select Answer Type"
                      value={values?.questionType}
                      onChange={(value) => {
                        setAnswerType(value);
                        setFieldValue("questionType", value);
                        values.surveyOptions?.splice(
                          0,
                          values.surveyOptions.length
                        );
                      }}
                    />
                  </Col>
                </Row>
                {
                  <>
                    {values?.surveyOptions &&
                      values?.surveyOptions?.length > 0 && (
                        <Row gutter={[20, 20]} className="mb-2 ">
                          <Col span={12}>Answers</Col>
                          <Col span={12} className="survey-heading">
                            Points
                          </Col>
                        </Row>
                      )}

                    {answerType === "boolean" ? (
                      <FieldArray name="surveyOptions">
                        {(arrayHelpers) => {
                          return (
                            <div>
                              <Row gutter={[20, 10]}>
                                <Col span={12}>
                                  <InputField
                                    type="text"
                                    name={`surveyOptions[${0}].answer]`}
                                    placeholder="Enter Answer"
                                    defaultValue={"Yes"}
                                    disabled
                                  />
                                </Col>{" "}
                                <Col span={11}>
                                  <InputField
                                    type="number"
                                    name={`surveyOptions[${0}].points]`}
                                    required={answerType === "boolean"}
                                    placeholder="Enter point"
                                    onChange={(e: any) => {
                                      setFieldValue(
                                        `surveyOptions[${0}].points]`,
                                        e.target.value.replace(/\D/g, "")
                                      );
                                      setFieldValue(
                                        `surveyOptions[${0}].answer]`,
                                        "Yes"
                                      );
                                      setFieldValue(
                                        `surveyOptions[${1}].answer]`,
                                        "No"
                                      );
                                    }}
                                  />
                                </Col>
                                <Col span={12}>
                                  <InputField
                                    type="text"
                                    name={`surveyOptions[${1}].answer]`}
                                    placeholder="Enter Answer"
                                    defaultValue={"No"}
                                    // value={
                                    //   values &&
                                    //   values?.surveyOptions &&
                                    //   values?.surveyOptions[1].answer
                                    // }
                                    disabled
                                  />
                                </Col>{" "}
                                <Col span={11}>
                                  <InputField
                                    type="number"
                                    name={`surveyOptions[${1}].points]`}
                                    placeholder="Enter point"
                                    required={answerType === "boolean"}
                                    onChange={(e: any) => {
                                      setFieldValue(
                                        `surveyOptions[${1}].points]`,
                                        e.target.value.replace(/\D/g, "")
                                      );
                                    }}
                                  />
                                </Col>
                              </Row>
                            </div>
                          );
                        }}
                      </FieldArray>
                    ) : (
                      ""
                    )}

                    {/* multi and sungle select */}
                    <FieldArray name="surveyOptions">
                      {(arrayHelpers) => {
                        return (
                          answerType !== "boolean" && (
                            <div>
                              {values.surveyOptions &&
                                values.surveyOptions.map(
                                  (answerPoint: any, index: number) => {
                                    return (
                                      <Row gutter={[20, 10]} key={index}>
                                        {" "}
                                        {answerType !== "slider" && (
                                          <Col span={12}>
                                            <InputField
                                              type="text"
                                              name={`surveyOptions[${index}].answer]`}
                                              placeholder="Enter Answer"
                                            />
                                          </Col>
                                        )}
                                        {answerType === "slider" && (
                                          <>
                                            {" "}
                                            <Col span={6}>
                                              <InputField
                                                type="text"
                                                name={`surveyOptions[${index}].answer]`}
                                                placeholder="Enter Range"
                                              />
                                            </Col>
                                            <span className="mt-2">-</span>
                                            <Col span={6}>
                                              <InputField
                                                type="text"
                                                name={`surveyOptions[${index}].answer1]`}
                                                placeholder="Enter Range"
                                              />
                                            </Col>
                                          </>
                                        )}
                                        <Col span={10}>
                                          <InputField
                                            type="number"
                                            name={`surveyOptions[${index}].points`}
                                            placeholder="Enter point"
                                            onChange={(e: any) => {
                                              setFieldValue(
                                                `surveyOptions[${index}].points`,
                                                e.target.value.replace(
                                                  /\D/g,
                                                  ""
                                                )
                                              );
                                            }}
                                          />
                                        </Col>
                                        {values.surveyOptions &&
                                          values.surveyOptions?.length > 1 && (
                                            <Col span={1}>
                                              <img
                                                className="survey-questions__delete-btn"
                                                onClick={() =>
                                                  arrayHelpers.remove(index)
                                                }
                                                src={deleteIcon}
                                                alt=""
                                              />
                                            </Col>
                                          )}
                                      </Row>
                                    );
                                  }
                                )}
                              <Col span={6}>
                                <h4
                                  className="text-primary survey-questions__add-answer"
                                  onClick={() =>
                                    handleAddAnswerPoints(arrayHelpers)
                                  }
                                >
                                  + Add Answers
                                </h4>
                              </Col>
                            </div>
                          )
                        );
                      }}
                    </FieldArray>

                    {/* {answerType !== "boolean" &&
                      answerPoints.map((val, index) => {
                        return (
                          <Row gutter={[20, 10]} key={index}>
                            {" "}
                            <Col span={12}>
                              <InputField
                                type="text"
                                name={`answerPoint[${index}.answer]`}
                                placeholder="Enter Answer"
                              />
                            </Col>{" "}
                            <Col span={11}>
                              <InputField
                                type="text"
                                name={`answerPoint[${index}.point`}
                                placeholder="Enter point"
                              />
                            </Col>
                            {answerPoints?.length > 1 ? (
                              <Col span={1}>
                                <img
                                  className="survey-questions__delete-btn"
                                  onClick={() =>
                                    handleDeleteAnswerPoints(index)
                                  }
                                  src={deleteIcon}
                                  alt=""
                                />
                              </Col>
                            ) : (
                              ""
                            )}
                            {answerPoints.length - 1 === index ? (
                              <Col span={6}>
                                <h4
                                  className="text-primary survey-questions__add-answer"
                                  onClick={()=>handleAddAnswerPoints()}
                                >
                                  + Add Answers
                                </h4>
                              </Col>
                            ) : (
                              ""
                            )}
                          </Row>
                        );
                      })} */}
                  </>
                }

                <Row gutter={[10, 10]} className="mt-4 mr-3">
                  <Col span={22} className="text-right mt-4 ml-1">
                    <Button type="text" onClick={handleFormVisibility}>
                      Cancel
                    </Button>
                  </Col>
                  <Col span={2} className="text-right mt-4">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      disabled={
                        !isValid ||
                        loading ||
                        !dirty ||
                        (answerType !== "boolean" &&
                          values.surveyOptions &&
                          values.surveyOptions.length < 2)
                      }
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default SurveyQuestionsForm;
