import { serializable, alias, object, list, primitive } from "serializr";

class ModifiedBy {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("first_name", primitive()))
  firstName?: number;
  @serializable(alias("last_name", primitive()))
  lastName?: number;
}
export default class ActivityLog {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("created_at", primitive()))
  createdAt?: string;
  @serializable(alias("activity_type", primitive()))
  activityType?: string;
  @serializable(alias("activity_location_latitude", primitive()))
  activityLocationLatitude?: string;
  @serializable(alias("activity_location_longitude", primitive()))
  activityLocationLongitude?: string;
  @serializable(alias("modified_user_role", primitive()))
  modifiedUserRole?: string;
  @serializable(alias("modified_by", object(ModifiedBy)))
  modifiedBy?: ModifiedBy;
  @serializable(alias("user_id", primitive()))
  userId?: number;
  @serializable(alias("user_type", primitive()))
  userType?: string;
  @serializable(alias("activity_text", primitive()))
  ActivityText?: string;
}
