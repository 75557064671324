import * as Yup from "yup";

export const customerFormValidation = Yup.object().shape({
  firstName: Yup.string().nullable().required("First name is required!"),
  lastName: Yup.string().nullable().required("Last name is required!"),
  email: Yup.string().email("E-mail is not valid!"),
  mobile: Yup.string()
    .required("Mobile number is required!")
    .min(10, "Please enter 10 digits")
    .max(10, "Please enter 10 digits"),
  gender: Yup.string().nullable(),
  dob: Yup.string(),
  profession: Yup.string().nullable(),
  residentialAddress: Yup.object().shape({
    street: Yup.string().nullable(),
    provinceId: Yup.string().nullable(),
    cityId: Yup.string().nullable(),
    zipcode: Yup.string().nullable(),
  }),
  aadhaarNumber: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, "Please enter only numbers")
    .min(12, "Please enter exactly 12 digits")
    .max(12, "Please enter exactly 12 digits"),
  panNumber: Yup.string()
    .nullable()
    .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Please enter valid PAN number!"),
});
