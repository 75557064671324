import React from "react";
import "./productRequestDocuments.scss";
import { ProductRequest } from "../../../models/ProductRequest/productRequest.model";
import { Row, Col } from "antd";

interface ProductRequestDocumentsProps {
  productRequest: ProductRequest;
}

function ProductRequestDocuments({
  productRequest,
}: ProductRequestDocumentsProps) {
  return (
    <div className="product-request-documents">
      <Row className="mb-4">
        <Col span={5} className="text-bold">
          FILE TYPE
        </Col>
        <Col span={19} className="text-bold">
          LINK
        </Col>
      </Row>
      <Row gutter={[0, 20]} align="middle">
        {productRequest?.groupedAttachments?.map((groupedAttachment, i) => (
          <React.Fragment key={i}>
            <Col span={5} className="text-capitalize text-bold">
              {groupedAttachment?.attachmentType?.split("_").join(" ")}
            </Col>
            <Col span={19}>
              {groupedAttachment?.attachments?.map((attachment, j) => (
                <div className="mb-2" key={j}>
                  {attachment?.attachmentUrl && (
                    <a href={attachment?.attachmentUrl} target="_blank">
                      Click here
                    </a>
                  )}
                </div>
              ))}
            </Col>
          </React.Fragment>
        ))}
      </Row>
    </div>
  );
}

export default ProductRequestDocuments;
