import React, { useState } from "react";
import "./updateCommissionStatus.scss";
import { Form, Formik, FormikValues } from "formik";
import InputField from "../../../shared/components/InputField";
import { Button, Row, Col } from "antd";
import { updateCommissionFormValidation } from "./updateCommissionFormValidation";
import DropdownField from "../../../shared/components/DropdownField";
import { CommissionTypeEnum } from "../../../enums/commissionType.enum";
import CommissionService from "../../../services/Commission/commission.service";

interface UpdateCommissionStatusProps {
  totalAmount: string;
  selectedCommissionIds: number[];
  onSuccess: () => void;
}

function UpdateCommissionStatus({
  totalAmount,
  selectedCommissionIds,
  onSuccess,
}: UpdateCommissionStatusProps) {
  const [formValues, setFormValues] = useState({
    transactionReference: "",
    status: undefined,
  });

  const [formLoading, setFormLoading] = useState(false);

  const statusOptions = [
    { label: "Accrued", value: CommissionTypeEnum.ACCRUED },
    { label: "Pending", value: CommissionTypeEnum.PENDING },
    { label: "Settled", value: CommissionTypeEnum.SETTLED },
  ];

  const handleSubmit = (values: FormikValues) => {
    setFormLoading(true);
    CommissionService.updateCommission(
      selectedCommissionIds,
      values.transactionReference,
      values.status,
      () => {
        onSuccess();
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  return (
    <div className="update-commission-status">
      <h2 className="text-primary"> Change Status </h2>
      <p className="text-bold text-primary">
        Total pending payment value: ₹{totalAmount}
      </p>
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        validationSchema={updateCommissionFormValidation}
      >
        {({ values, errors, isValid, dirty, setFieldValue }) => {
          return (
            <Form>
              <Row gutter={[20, 0]}>
                <Col span={24}>
                  <DropdownField
                    title="Status"
                    name="status"
                    options={statusOptions}
                    value={values.status}
                    setFieldValue={setFieldValue}
                    placeHolder="Select Status"
                  />
                </Col>
                <Col span={24}>
                  <InputField
                    title="Transaction Reference"
                    type="textarea"
                    name="transactionReference"
                  />
                </Col>
              </Row>
              <div className="text-right">
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!isValid || !dirty || formLoading}
                  loading={formLoading}
                >
                  Update Status
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default UpdateCommissionStatus;
