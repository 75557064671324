import React from "react";
import "./customerTermsAndConditions.scss";

interface CustomerTermsAndConditionsProps {}

function CustomerTermsAndConditions(props: CustomerTermsAndConditionsProps) {
  return (
    <div className="customer-terms-and-conditions">
      <h2 className="text-primary">Terms and Conditions</h2>
      <p>
        By accessing the Platform or availing any service provided therein, (i.e
        Proprietary services from MyLead Fintech Pvt. Ltd towards fulfillment of
        life’s goals delivered vide its proprietary mobile application
        “FinoBuddy” and the Business Associates connected with MyLead Fintech
        Pvt. Ltd) from MyLead Fintech as applicable, I hereby expressly agree to
        be bound by Terms of Use, Privacy policy of the Platform (defined
        hereinafter) and following terms and conditions:
        <ol>
          <br />
          <li>
            I acknowledge that MyLead Fintech Pvt. Ltd[“MLFT”] vide its
            proprietary “FinoBuddy” Mobile application/website/Mobile
            Applications [hereinafter referred to as “Platform”] is a digital
            platform, engaged by its Product Partners (i.e. Banks, Non-Banking
            Financial Companies (NBFCs), Housing Finance Companies (HFC) and
            Financial institutions, governed by RBI/NHB and various
            non-financial partners governed under applicable law of the land), 
            to provide services of customer acquisition, preliminary credit and
            request processing support activities, fulfilment services and
            post-acquisition services as applicable and governed by service
            agreement with these Product Partners.  MLFT is also a Corporate
            Agent (Composite) registered under Companies Act, 2013 to solicit
            and service the products. 
          </li>
          <li>
            I understand that I would be required to share and submit Personal
            identifiable information and sensitive personal information
            [collectively referred as “Personal information”] on the Platform in
            connection with my loan/investment / other services, as applicable
            and that, use and processing of my Personal Information shall be
            governed by the Privacy policy of this Platform.
          </li>
          <li>
            I confirm that I have read the information on various products,
            including MITC (Most Important Terms and Conditions) as applicable,
            applicable fees and changes, if any and as available on this
            Platform. I affirm that all information provided by me are correct,
            complete, and up-to-date and no information as required to process
            my application has been withheld. I will keep MLFT informed of any
            change in the information provided by me.
          </li>
          <li>
            I agree and authorize MLFT to share my information with its
            respective Product Partners in connection with my application.
            Further, I authorize that I may be contacted by MLFT or its Partners
            vide email, SMS, phone call, WhatsApp or any other mode of
            communication as provided/available.
          </li>

          <li>
            I authorize MLFT and its business associates to share all
            information provided by me or obtained as a result of my product
            application on the Platform, including but not limited to my family
            details, existing loans, repayment history, declared income details
            for verification purposes with other third parties including but not
            limited to, service providers, banks, financial institutions, credit
            bureaus, statutory bodies, or any other party as they deem
            necessary. I shall not hold MLFT, its affiliates, group companies or
            its business associates, liable for use/sharing of the information
            as mentioned above.
          </li>
          <li>
            I accept that my product application is subject to approval.
            approval/rejection of my application and Sanction of facility
            granted to me is the sole decision of the Partners of MLFT. MLFT
            holds no warranty and does not make any representation or commitment
            of approval/rejection of my application or the sanction of request
            or a commitment to fulfill the offerings on the Platform.
          </li>
          <li>
            I understand that my application may be rejected by a Partner of
            MLFT, without specifically assigning a reason to reject the
            application. MLFT or the Product Partners will have no warranty or
            liability for such rejections.
          </li>
          <li>
            I acknowledge that any facility limit of any particular facility
            issued shall be absolute discretion of the Partners and I may not
            always receive my indicated choice of product variant. 
          </li>
          <li>
            Facility pricing shown are minimum available and may vary depending
            on my personal circumstances, request amount and MLFT’s partner
            approval criteria. Relevant calculators are provided on the Platform
            are only as self-help planning tools. Results depend on many
            factors, including the assumptions I provide. MLFT does not
            guarantee its accuracy or applicability to my facility application.
          </li>
          <li>
            Any decision to apply for a facility is solely at my own risk and
            will hold MLFT harmless for any loss or damage that I may suffer,
            whether directly or indirectly. Display of products of various
            Partners on the Platform does not in any way imply, or constitute
            any endorsement, recommendation, advice, or approval from MLFT in
            favour/against the Partners and/or their products.
            <br />
            <li>
              I agree and consent to receive communications in relation to
              customized recommendations, promotional offers, information on
              various other products and services available on this Platform by
              MLFT via email, SMS, WhatsApp, phone call, App notifications or
              any other means of communication. I expressly waive any
              registration made I under DND/NCPR list under TRAI regulations,
              when contacted via SMS or phone call.
            </li>
            <br /> I undertake to access any information, material, documents on
            Platform, provided by itself or any third party but accessible to
            the Client, or avail any Service only through the authorised access
            provided by MLFT.
            <br />
            I undertake to ensure that any material/information/data uploaded by
            me on the Platform is strictly of nature and context as required by
            the Platform for the relevant Service and is not in violation of any
            applicable law.
            <br />
            I am 18 years of age or over. I understand that If I am under 18 and
            if wish to access or use the Services, my parents or legal guardian
            must acknowledge and agree to this T&C. Should my parents or legal
            guardian fail to agree or acknowledge the T&C, I shall immediately
            discontinue its use.
            <br />
          </li>
        </ol>
      </p>
      <h4>User Declaration</h4>

      <h4>Indemnity </h4>
      <p>
        You agree to indemnify and hold us and our affiliates, successors and
        assigns, officers, directors, employees, agents, representatives,
        licensors, advertisers, suppliers, and operational service providers
        harmless from and against any and all losses, expenses, damages, costs
        and expenses (including attorneys’ fees), resulting from your use of the
        Services and / or any violation of this T&C including any Prohibited
        Conduct. We reserve the right to assume the exclusive defense and
        control of any demand, claim or action arising hereunder or in
        connection with the Website/Mobile Application and all negotiations for
        settlement or compromise. You agree to fully cooperate with us in the
        defense of any such demand, claim, action, settlement or compromise
        negotiations, as requested by us.
      </p>
      <h4>Use of Material and Information </h4>
      <p>
        The content (material, information, data, news, etc.) contained on this
        mobile application / website is provided for general information only
        and should not be construed as any kind of advice or be used as a basis
        for making business/commercial decisions. You are requested to exercise
        caution and/or seek independent professional advice before entering any
        investment or financial obligation based on the content contained on
        this Site. Images used on this Site and the models photographed in them
        are for representative purposes only.
        <br />
        The content on this Site should NOT be regarded as an offer,
        solicitation, invitation, advice or recommendation to buy or sell
        financial instruments/ non-financial products and services of MLFT, its
        group companies, business partners, or any of its affiliates. <br />
        Products and Services are available only at the discretion of MLFT,
        subject to the individual contractual terms and conditions of the
        respective entities’ products and services basis which such products &
        services are offered and such products and services may be withdrawn or
        amended at any time without notice. In the event of any conflict between
        the terms and conditions of specific products and services displayed on
        the mobile application/mobile application/website vis-à-vis the terms
        and conditions laid down by MLFT’s business partner or MLFT’s group
        company or affiliate (‘Product owner’), the terms and conditions laid
        down by the Product owner shall prevail.
      </p>
      <h4>No Representation or Warranty </h4>
      <p>
        No information sent to any user through this mobile application / Site
        or available on this mobile application / Site shall constitute any
        representation or warranty by MLFT, or its Group Companies, Subsidiaries
        or Affiliates regarding the credit-worthiness, financial performance or
        prospects, solvency, or viability of any company or other legal entity
        or the business carried on by such entity. <br />
        This Site may contain statements of various professionals/ experts/
        analysts, etc. MLFT does not represent/ endorse the accuracy,
        reliability of any of statements/ information by any such person. It is
        the responsibility of the user of this Site to independently verify and
        evaluate the accuracy, completeness, reliability and usefulness of any
        statements, services or other information provided on this Site. All
        information in this Site is being provided under the condition and
        understanding that the same is not being interpreted or relied on as
        legal, accounting, tax, financial, investment or other professional
        advice, or as advice on specific facts or matters. MLFT may at any time
        (without being obliged to do so) update, edit, alter and or remove any
        information in whole or in part that may be available on this Site and
        shall not be held responsible for all or any actions that may
        subsequently result in any loss, damage and or liability. Nothing
        contained herein is to be construed as any advice or recommendation to
        use any product or process. Though MLFT will endeavour that information
        contained on this Site is obtained from sources which are reliable,
        however MLFT does not warrant such information’s completeness or
        accuracy. <br />
        MLFT does not represent or warrant that the Mobile application / website
        will be uninterruptedly available. Please note that (a) the Mobile
        application / website may not meet your requirements; (b) your access
        may be interrupted; (c) there may be delays, failures, errors or
        omissions or loss of transmitted information, that viruses or other
        contaminating or destructive properties may be transmitted or that
        damage may occur to your computer system. It is clarified that internet
        transactions may be subject to interruption, transmission blackout,
        delayed transmission and incorrect data transmission. MLFT is not liable
        for malfunctions in communications facilities (that may affect the
        accuracy or timeliness of messages and transactions you may initiate.
        <br />
        You have sole responsibility for ensuring adequate protection and back
        up of data and/or equipment and for undertaking reasonable and
        appropriate precautions to scan for computer viruses or other
        destructive properties. MLFT makes no representations or warranties
        regarding the accuracy, functionality or performance of any third-party
        software or website or application that may be used relating to the
        Mobile application / website. Payment Terms
      </p>
      <h4>Payment Terms</h4>
      <p>
        I agree to pay the charges for the Services as stipulated by MLFT from
        time to time, as applicable. I understand that there may be additional
        charges for use of payment gateway or bank charges which may be
        applicable. MLFT shall not be liable to refund in case of any
        cancellation, rejection or no credit information report can be generated
        based on the information provided by me. The charges shall be inclusive
        of all taxes, fee including payment gateway charges if any. I undertake
        that the payment instrument used by me belongs to me or I am fully
        authorized to make use of such instruments to make payments. I
        understand the payment gateways services for such payment may not belong
        to MLFT and may be provided by third-parties. I shall not hold MLFT
        responsible for the error and  MLFT shall not be obligated to indemnify
        me for any loss, damage, claim suffered by me due to use of such payment
        instruments and/or payment gateways.
      </p>
      <h4>Limitation of Liability </h4>
      <p>
        MLFT, its group companies, business partners, subsidiary companies, its
        affiliates, and their directors and employees accept no liability for
        any loss or damage arising directly or indirectly (including special,
        incidental or consequential, punitive, or exemplary loss, dam age or
        expenses) from your use of this Mobile application / website or any
        linked Mobile application / website howsoever arising, and including any
        loss, damage or expense arising from, but not limited to, any defect,
        error, omission, interruption, imperfection, fault, mistake or
        inaccuracy with this Mobile application / website, its contents or
        associated services, or due to any unavailability of the Mobile
        application / website or any part thereof or any contents or associated
        services. MLFT reserves the right to change the information provided on
        or via this Mobile application / website, including the terms of this
        disclaimer, at any time and without notice. However, MLFT shall not
        undertake to update the content contained on this Site from time to
        time. You are obliged to exercise your independent diligence on the
        information provided on or via this Mobile application / website before
        arriving at any decision and you will be solely responsible for your
        actions. MLFT shall not be held responsible for all or any actions that
        may subsequently result in any loss, damage and or liability on account
        of such change in the information on this Mobile application / website.
      </p>
      <h4>Intellectual Property Rights </h4>
      <p>
        Nothing contained in our mobile application/website should be construed
        as granting any license or right to use any Trade Marks displayed on our
        mobile application/website. MLFT, retains all rights (including
        copyrights, trademarks, patents as well as any other intellectual
        property right) in relation to all information provided on or via this
        Site (including all texts, graphics and logos). Users are prohibited
        from using, modifying, copying, distributing, transmitting, displaying,
        publishing, selling, licensing, creating derivative works or using any
        Content available on or through the mobile application/website for any
        purpose without written permission of MLFT of such or such other
        parties. The materials on this mobile application/website are protected
        by copyright and no part of such materials may be modified, reproduced,
        stored in a retrieval system, transmitted (in any form or by any means),
        copied, distributed, used for creating derivative works or used in any
        other way for commercial or public purposes without the prior written
        consent of MLFT.
      </p>
      <h4>Linked Mobile application/websites</h4>
      <p>
        This Site may contain links to other mobile application/websites of
        group companies, business partners, collaborators Subsidiary companies
        and Affiliate companies of MLFT. This Site may also contain links to
        external mobile application/websites, having further linked mobile
        application/websites, controlled or offered by third parties
        (Non-Affiliates of MLFT), to help you find relevant mobile
        application/websites, services and/or products which may be of interest
        to you, quickly and easily. The contents displayed or products /
        services offered on such linked mobile application/websites or any
        quality of the products/ services are not endorsed, verified or
        monitored by MLFT. MLFT is also not responsible for the owners or
        operators of such external mobile application/websites or for any
        products or services they supply or for the contents of their mobile
        application/websites and do not give or enter into any conditions,
        warranties, express or implied; or other terms or representations in
        relation to any of these or accept any liability in relation to any of
        these (including any liability arising out of any claim that the content
        of any external mobile application/website to which this mobile
        application/website includes a link infringes the intellectual property
        rights of any third party).
      </p>
      <h4>Governing Law </h4>
      <p>
        This Site and disclaimer shall be governed by and construed in
        accordance with all applicable laws of India. All disputes arising out
        of or relating to this disclaimer or use of this mobile
        application/website shall be submitted to the exclusive jurisdiction of
        the courts of Mumbai. By accessing this mobile application / website and
        any of its webpages, it is deemed that you are agreeing to the terms set
        out above.
      </p>
      <br />

      <h4>Privacy Policy </h4>

      <h4>Overview </h4>
      <p>
        In this policy, <br />
        <ul>
          <li>“We,” “us,” and “our” means MyLead Fintech Pvt. Ltd(“MLFT”);</li>
          <li>
            {" "}
            The “Site” means any web site, mobile site, or application we
            provide that includes a link to this Online Privacy Policy;{" "}
          </li>
          <li>
            The “Service” means any information, documents, assistance, or
            services we may provide to you through our Site;{" "}
          </li>
          <li>
            You” and “your” mean anyone who visits, accesses, or uses our mobile
            application (Finobuddy) / website (myleadfintech.com) or obtains
            Services from us through our website(finobuddy.com);
          </li>
        </ul>
        At MLFT we are strongly committed to protect the personal and financial
        information that you submit to us and endeavour to protect it from
        unauthorized use. To protect your personal information from unauthorized
        access and use, we use security measures that comply with the applicable
        laws.
      </p>

      <h4>Collection of Information </h4>
      <p>
        While using this Site or availing the products and services, MLFT and
        its affiliates/ subsidiaries may become privy to the personal
        information of its users, including information that is of a
        confidential & sensitive nature.
        <br /> MLFT is strongly committed to protecting the privacy of its users
        and has taken all necessary and reasonable measures to protect the
        confidentiality of the user information and its transmission through the
        world wide web and it shall not be held liable for disclosure of the
        confidential information when in accordance with this Privacy Commitment
        or in terms of the agreements, if any, with the Users.
        <br />
        In addition, like most Site, we use small bits of data called ‘cookies’
        stored on user’s computers to stimulate a continuous connection. Cookies
        allow us storage of information about your preferences and certain
        session information that allow you to move to different pages of our
        secure site without having to re-enter your information. Any information
        collected is stored in secure databases protected via a variety of
        access controls and is treated as confidential information by us. You
        should be responsible in safe custody and usage of the username and
        password by maintaining strict confidentiality of the same and ensure
        that you do not knowingly or accidentally share, provide and facilitate
        unauthorized use of it by any other person.{" "}
      </p>
      <h4>Use and disclosure of Personal & Financial Information </h4>
      <p>
        We understand clearly that You and Your Personal Information is one of
        our most important assets. We store and process Your Information
        including any sensitive financial information collected (as defined
        under the Information Technology Act, 2000), if any, on computers that
        may be protected by physical as well as reasonable technological
        security measures and procedures in accordance with Information
        Technology Act 2000 and Rules there under. If You object to Your
        Information being transferred or used in this way, please do not provide
        the details of your information on the Mobile application/website. MLFT
        may, for the purpose of rendering its services, collect personal
        information such as:{" "}
        <ol>
          <li>
            Name, gender, residential / correspondence address, telephone
            number, date of birth, marital status, email address;
          </li>
          <li>Other contact information;</li>
          <li>Aadhaar, PAN, KYC Status, Signature and Photograph;</li>
          <li>Bank account or other payment instrument details; </li>
          <li>Any other detail for providing services.</li>
        </ol>
        MLFT offers online platform for offering financial and non financial
        products and services to you and to carry out the transaction that
        collects such personal data or information to process your transaction
        requests. The information so collected may be shared with other group
        companies/ affiliates/subsidiaries/Product owner etc. solely for the
        purpose of processing your transaction requests or serving you better.
        <br />
        When you register with this Site, you agree that we or any of our
        affiliate/ group companies/subsidiaries may contact you from time to
        time to provide the offers/ information of such products/ services.
        <br /> The information collected shall be used for the purpose for which
        it has been collected. <br />
        We will protect your personal information against unauthorized use,
        dissemination or publication in the same way we would protect our
        confidential information of similar nature. However, we may be required
        to disclose your personal and financial information to the statutory
        authorities relating to any legal process that may be initiated by such
        authorities in accordance with applicable laws. <br />
        We may use the personal information to improve our services to you and
        to keep you updated about our new products or other information that may
        be of interest to you. We may share information during normal business
        operations, such as providing services you have subscribed for, and any
        activity related to these services. It may become necessary for MLFT to
        disclose your personal information to its Business Associates and Life
        Coach’s during normal business operations for the above referred
        purpose. However, these parties would be required to use the information
        obtained from MLFT for such purposes exclusively. MLFT will endeavour to
        take all reasonable steps to ensure that the confidentiality of your
        information is maintained by imposing strict confidentiality standards
        on all the third parties to whom it discloses such information.
        <br /> We may occasionally invite selected third parties to participate
        in offers we feel would be attractive to customers of MLFT. If you
        desire MLFT to limit such sharing whereby you would not like to be
        informed of offers available you may contact us at
        [support@finobuddy.com] or unsubscribe to such services. Otherwise, MLFT
        will use the customer/user information to improve the visitor experience
        on the mobile application / site and make subsequent offers to the
        visitor on products which may be of interest to him/her.
        <br /> Notwithstanding whether your telephone/mobile is registered with
        NDNC and or notwithstanding the Telecom Regulatory Authority of India
        [TRAI] restrictions/guidelines on unsolicited telecalls, by your
        accessing this Site fully or in an incomplete manner or by
        enquiring/verifying any of the products/services offered by MLFT and or
        your making a request to call back, it is deemed that you have expressly
        authorized MLFT to call back or send you text messages or Chatbots for
        solicitation and procurement of various products and services of MLFT.
        In this regard you shall not have any complaint nor complain to TRAI as
        to any alleged unsolicited calls by MLFT to you. <br />
        MLFT may from time to time, during the course of your utilisation of the
        Site, require access to certain additional information only after
        obtaining Your additional explicit consent. Such additional information
        may include: (i) Your SMS information stored on your device, (ii) Your
        location information (IP address, longitude and latitude information),
        for verifying the location to verify location and to check the
        feasibility of MLFT Network’s serviceability, (iii) Your device and/or
        call log details/ contact details, to prevent fraud and to stop
        unauthorized device access of MLFT Network on your behalf, and (iv) Your
        email details/access to verify your credentials including your conduct
        on online platform. <br />
        Your information is collected for providing better service in respect of
        MLFT to you, and to comply with applicable laws / regulations (if any).
        You hereby agree and acknowledge that MLFT may, to the extent permitted
        by applicable law/regulations, share or process your information in
        connection with the usage of the services of MLFT and collected by MLFT
        from you while accessing the MLFT Network, Group Companies, Corporate
        Partners, subsidiary companies, its affiliates and/or any third party,
        including but not limited to the purposes, such as, to complete the
        transaction initiated by you, to render service to you and/or to enhance
        the MLFT experience for you, to offer new products in terms of manner of
        such collection, usage and storage is governed by the Privacy Terms
        stated herein the agreement. <br />
        We and our group companies/affiliates/subsidiaries will
        share/transfer/convey some or all of your personal information
         including but not limited to identity information as defined under
        Aadhaar (Targeted Delivery of Financial and Other Subsidies, Benefits
        and Services) Act, 2016, with another business entity to carry out any
        business activity or reorganization, amalgamation, restructuring of
        business or for any other reason whatsoever. Once you provide your
        information to us, you provide such information to us and our affiliate
        and we and our affiliate may use such information to provide you various
        services with respect to your transaction conducted on the Site. <br />
        You authorise MLFT to exchange, share, part with all information related
        to the details and transaction history of the customers/users to its
        banks/financial institutions/credit bureaus/agencies/regulators
        participation in any telecommunication or electronic clearing network as
        may be required by law, customary practice, credit reporting,
        statistical analysis and credit scoring, verification or risk management
        and shall not hold MLFT liable for use or disclosure of this
        information. Other mobile application/websites that you may access via
        this Site may have different privacy policies and access to such mobile
        application/websites will not be subject to this privacy policy. We
        recommend that you read the privacy statement of each such mobile
        application/website to find out how they protect your personal
        information. <br /> We use an SSL certificate to secure the information
        you share with us and its transmission. Because no data transmission is
        completely secure, and no system of physical or electronic security is
        impenetrable, we cannot guarantee the security of the information you
        send to us or the security of our servers, networks or databases. By
        using the mobile application / website and its Services, you: (a) agree
        to assume all risk relating to the information sent to us or collected
        by us when you access, visit and/or use the mobile application / website
        and its Services (including without limitation your personally
        identifiable information or other Registration Information); and (b)
        agree that we are not responsible for any loss of such information or
        any consequences that may result from any loss of such information.{" "}
        <br /> Due to changes in legislation or enhancements to functionality
        and content on the mobile application / website, we may make changes to
        privacy policy (without being obliged to do so) and would reflect those
        changes in this privacy policy statement. Hence you are requested to go
        through the privacy policy statement on a regular basis. <br />
        Please note that this privacy policy does not create any contractual or
        other legal rights in or on behalf of any party, nor is it intended to
        do so. <br />
        By agreeing to avail the service offered by MLFT, you have agreed to the
        collection and use of your Sensitive Personal Data or Information by
        MLFT. You always have the right to refuse or withdraw your consent to
        share/dissemination of your Sensitive Personal Data or Information by
        contacting [support@finobuddy.com]. However, in such an event, you would
        no longer avail the services of MLFT. Once we have received notification
        that you have withdrawn your consent, we will no longer process your
        information for the purpose(s) to which you originally consented unless
        we are required to retain your data for a legal or regulatory purpose.
      </p>
      <h4>Offers and Recommendations </h4>
      <p>
        I authorize MLFT to use my credit information to provide personalized
        offer and/or recommend Products and Services based on the eligibility
        creteria of such Products and Services to me from time to time. I
        acknowledge receiving notification of offers, to be contacted by MLFT
        through various communication channels and contact details provided by
        me to MLFT.  I expressly waive any restriction rights available to me or
        any NDNC request made by me under the Telecom Commercial Communications
        Customer Preference Regulations in relation to solicitation, offers made
        by MLFT. I acknowledge and agree that any credit facility provided to me
        pursuant to any activities on the Site shall be governed by credit
        criteria of such lending partners and there may be additional terms and
        conditions which shall govern such transactions conducted by me on the
        Site.{" "}
      </p>
      <h4>Other terms and conditions </h4>
      <p>
        <ol>
          <li>
            {" "}
             I agree that the terms and conditions may be amended from time to
            time without any notice; which changes shall be available on the
            Site. I understand that MLFT may at its sole discretion may amend,
            discontinue the Services in part or whole as it deems fit at any
            time without a requirement of prior notice or consent.
          </li>
          <li>
               I understand that MLFT reserves all the rights to suspend,
            terminate, my account at its sole discretion at any time for any
            reason but not limiting to breach of the terms of use by me, of if
            the information provided by me is incorrect, obtained or is intended
            to be used for fraudulent purposes.
          </li>
          <li>
             Any disputes relating to the credit information can be raised with
            respective bureau on their mobile application/website.{" "}
          </li>
          <li>
             I understand that MLFT is only acting as a facilitator for
            providing a Platform for me to access my credit information and MLFT
            does not warrant that the functions contained in on the Site will be
            uninterrupted or error free, free of viruses or other harmful
            components, but shall take commercially reasonable efforts to
            correct the delays in operation of the Site.
          </li>
          <li>
             In this document; “MLFT”, <br /> “Company” means MyLead FinTech
            Private Limited; <br /> The “Site” means any web site, mobile site,
            or application of MLFT <br /> The “Service/s” means any information,
            documents, assistance, or services we may provide to you through our
            Site including but not limiting to credit information; and <br />
            “I” “me” “my” and “myself” “Customer” mean anyone who visits,
            accesses, or uses our Site or requests Services from us through our
            Site. <br />
            “Bureau” shall mean credit information company which is authorized
            under the Laws of India to provide comprehensive credit information
            by collecting, collating and issuing credit information, pertaining
            to both commercial and consumer borrowers, to a closed user group of
            members. In relation to provision of the Services mentioned herein;
            Bureau shall mean “TransUnion CIBIL”.  <br />  <br />
            If you require any further information, please do not hesitate to
            contact [support@finobuddy.com]
          </li>
        </ol>
      </p>
    </div>
  );
}

export default CustomerTermsAndConditions;
