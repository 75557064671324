
import {ProductTypeDetail} from '../ProductTypeDetail/productTypeDetail.model';

import {serializable, alias, object, list, primitive} from 'serializr';

export class ServiceProvider { 

	@serializable(alias('id', primitive()))
	id?: number;

	@serializable(alias('name', primitive()))
	name?: string;

	@serializable(alias('product_category', primitive()))
	productCategory?: string;

	@serializable(alias('product_count', primitive()))
	productCount?: number;

	@serializable(alias('product_type_detail', object(ProductTypeDetail)))
	productTypeDetail?: ProductTypeDetail;

}