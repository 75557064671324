import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import { BaFilterParams } from "../../models/BAFilterParams/baFilterParams.model";
import { Employee } from "../../models/Employee/employee.model";
import { Province } from "../../models/Province/province.model";
import { City } from "../../models/City/city.model";
import { Location } from "../../models/Location/location.model";

export class BaFilterService {
  static fetchRMs(
    filterParams: BaFilterParams,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const filterJSON = serialize(
      Object.assign(new BaFilterParams(), filterParams)
    );
    axiosInstance
      .get(ApiRoutes.BA_RMS, {
        params: filterJSON,
      })
      .then((response) => {
        const employees = deserialize(Employee, response.data["employees"]);
        onSuccess(employees);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchProvinces(
    filterParams: BaFilterParams,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const filterJSON = serialize(
      Object.assign(new BaFilterParams(), filterParams)
    );
    axiosInstance
      .get(ApiRoutes.BA_STATES, {
        params: filterJSON,
      })
      .then((response) => {
        const provinces = deserialize(Province, response.data["provinces"]);
        onSuccess(provinces);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchCities(
    filterParams: BaFilterParams,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const filterJSON = serialize(
      Object.assign(new BaFilterParams(), filterParams)
    );
    axiosInstance
      .get(ApiRoutes.BA_CITIES, {
        params: filterJSON,
      })
      .then((response) => {
        const cities = deserialize(City, response.data["cities"]);
        onSuccess(cities);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchLocalitites(
    filterParams: BaFilterParams,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const filterJSON = serialize(
      Object.assign(new BaFilterParams(), filterParams)
    );
    axiosInstance
      .get(ApiRoutes.BA_LOCALITIES, {
        params: filterJSON,
      })
      .then((response) => {
        const locations = deserialize(Location, response.data["locations"]);
        onSuccess(locations);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchProfileCompletionStatuses(
    filterParams: BaFilterParams,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const filterJSON = serialize(
      Object.assign(new BaFilterParams(), filterParams)
    );
    axiosInstance
      .get(ApiRoutes.BA_PROFILE_COMPLETION_STATUSES, {
        params: filterJSON,
      })
      .then((response) => {
        onSuccess(response.data["profile_completion_statuses"]);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchStatuses(
    filterParams: BaFilterParams,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const filterJSON = serialize(
      Object.assign(new BaFilterParams(), filterParams)
    );
    axiosInstance
      .get(ApiRoutes.BA_STATUSES, {
        params: filterJSON,
      })
      .then((response) => {
        onSuccess(response.data["statuses"]);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
}
