import { alias, list, primitive, serializable } from "serializr";

export class CustomerLoan {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("emi", primitive()))
  emi?: string;

  @serializable(alias("institution", primitive()))
  institution?: string;

  @serializable(alias("user_id", primitive()))
  userId?: string;

  @serializable(alias("user_type", primitive()))
  userType?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("loan_types", list(primitive())))
  loanTypes?: string[];

  @serializable(alias("updated_at", primitive()))
  updatedAt?: string;
}
