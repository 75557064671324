import { serializable, alias, object, list, primitive } from "serializr";

/*
* product_name: "HEPPY SILVER (1A)"
type_name: "HealthAssure"
weight: 0.15
*/

class ProductWeight {
  @serializable(alias("product_name", primitive()))
  productName?: string;

  @serializable(alias("weight", primitive()))
  weight?: string;
}

class BrokerageWeight {
  @serializable(alias("type_name", primitive()))
  typeName?: string;

  @serializable(alias("product_weights", list(object(ProductWeight))))
  productWeights?: ProductWeight[];
}

export class BrokerageGroup {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("no_of_bas", primitive()))
  noOfBas?: number;

  @serializable(alias("no_of_products", primitive()))
  noOfProducts?: number;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("brokerage_weights", list(object(BrokerageWeight))))
  brokerageWeights?: BrokerageWeight[];
}
