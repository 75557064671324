import React, { useEffect, useState } from "react";
import "./baStatusFilters.scss";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import { ProfileStatusEnum } from "../../../../enums/profileStatus.enum";
import { Checkbox } from "antd";
import { BaFilterParams } from "../../../../models/BAFilterParams/baFilterParams.model";
import { BaFilterService } from "../../../../services/BAFilter/baFilter.service";

interface BAStatusFiltersProps {
  filterParams: BaFilterParams;
  refreshData: boolean;
  onUpdate: (filterParams: BaFilterParams) => void;
}

function BAStatusFilters({
  filterParams,
  refreshData,
  onUpdate,
}: BAStatusFiltersProps) {
  const [statusOptions, setStatusOptions] = useState<CheckboxOptionType[]>([]);

  const [loading, setLoading] = useState(false);

  const handleFetchStatuses = () => {
    setLoading(true);
    BaFilterService.fetchStatuses(
      filterParams,
      (statuses: string[]) => {
        setStatusOptions(
          [...new Set(statuses) as any].map((status) => ({
            label: status.split("_").join(" "),
            value: status as CheckboxValueType,
          }))
        );
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    handleFetchStatuses();
  }, []);

  useEffect(() => {
    if (refreshData) {
      handleFetchStatuses();
    }
  }, [refreshData]);

  return (
    <div className="ba-status-filters">
      <Checkbox.Group
        value={filterParams.statuses}
        onChange={(statuses) => {
          const params = Object.assign(new BaFilterParams(), filterParams);
          params.statuses = statuses as string[];
          onUpdate(params);
        }}
        options={statusOptions}
      />
    </div>
  );
}

export default BAStatusFilters;
