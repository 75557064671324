
import {serializable, alias, object, list, primitive} from 'serializr';

export class ContactPerson { 

	@serializable(alias('description', primitive()))
	description?: string;

	@serializable(alias('email', primitive()))
	email?: string;

	@serializable(alias('mobile', primitive()))
	mobile?: string;

	@serializable(alias('name', primitive()))
	name?: string;

}