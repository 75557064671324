import React from "react";

import {
  notificationColumns,
  sampleNotificationTable,
} from "./notificationTable";
import TableComponent from "../../../shared/components/TableComponent";
import "./notifications.scss";

interface NotificationsProps {}

function Notifications(props: NotificationsProps) {
  return (
    <div className="notifications">
      <div>
        <TableComponent
          className="notifications__table"
          // onRow={handleRowClick}
          // loading={loading}
          columns={notificationColumns}
          dataSource={sampleNotificationTable}
          rowKey="notificationsId"
        />
      </div>
    </div>
  );
}

export default Notifications;
