import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import { store } from "../../store";
import {
  AUTHENTICATED,
  UNAUTHENTICATED,
} from "../../store/definitions/authConstants";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { Employee } from "../../models/Employee/employee.model";
import { OtpTypeEnum } from "../../enums/otpType.enum";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class AuthService {
  static loginUser(
    employee: Employee,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const employeeJSON = {
      employee: serialize(employee),
    };
    axiosInstance
      .post(ApiRoutes.SIGN_IN, employeeJSON)
      .then((response) => {
        const employee = deserialize(Employee, response.data["employee"]);
        localStorage.setItem("token", JSON.stringify(response.data["token"]));
        localStorage.setItem("employee", JSON.stringify(employee));
        store.dispatch({
          type: AUTHENTICATED,
          payload: {
            authenticated: true,
            employee,
          },
        });
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
  static verifyOTP(
    otp: string,
    otpType: OtpTypeEnum,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      otpType === OtpTypeEnum.EMAIL
        ? ApiRoutes.VERIFY_EMAIL
        : ApiRoutes.VERIFY_MOBILE;
    let otpJSON = {};
    if (otpType === OtpTypeEnum.EMAIL) {
      otpJSON = {
        email_otp: otp,
      };
    } else {
      otpJSON = {
        mobile_otp: otp,
      };
    }
    axiosInstance
      .post(API_URL, otpJSON)
      .then((response) => {
        Notification({
          type: NotificationTypes.SUCCESS,
          message: `${
            otpType === OtpTypeEnum.EMAIL ? "Email" : "Mobile"
          } verified. `,
        });
        onSuccess();

        // OnboardingService.showBADetails(
        //   () => {
        //     onSuccess();
        //   },
        //   () => {},
        //   () => {}
        // );
      })
      .catch((error) => {
        Notification({
          message: "Invalid OTP",
          type: NotificationTypes.ERROR,
        });
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static sendOTP(
    employee: Employee,
    otpType: OtpTypeEnum,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    let employeeJSON = {};
    if (otpType === OtpTypeEnum.EMAIL) {
      employeeJSON = {
        email: employee.email,
      };
    } else {
      employeeJSON = {
        mobile: employee.mobile,
      };
    }
    axiosInstance
      .post(ApiRoutes.SEND_OTP, employeeJSON)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static resetPassword(
    employee: Employee,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const employeeJSON = { employee: serialize(employee) };
    axiosInstance
      .post(ApiRoutes.RESET_PASSWORD, employeeJSON)
      .then((response) => {
        Notification({
          message: "Password Successfully Changed!",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static logout(onSuccess: Function, onError: Function, onFinal: () => void) {
    const employee = localStorage.getItem("employee");
    if (employee) {
      return axiosInstance
        .delete(ApiRoutes.SIGN_OUT)
        .then((response) => {
          localStorage.clear();
          store.dispatch({
            type: UNAUTHENTICATED,
            payload: {
              authenticated: false,
            },
          });
          onSuccess();
        })
        .catch((error) => {
          onError(error);
        })
        .finally(() => {
          onFinal();
        });
    }
  }
}
