import React, { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import { Order } from "../../models/Order/order.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";

const AxisBankService = () => {
  const [loading, setLoading] = useState(false);

  const updateAxisBankReport = async (
    formValues: Order,
    onSuccess: Function
  ) => {
    setLoading(true);
    try {
      const formData = serialize(formValues);
      const response = await axiosInstance.post(
        ApiRoutes.UPDATE_AXIS_BANK,
        formData
      );

      if (response.data?.success) {
        Notification({
          message: "Profile Updated!",
          description: response.data?.message,
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
        return true;
      }
      Notification({
        message: "Profile not Updated!",
        description: response.data?.message,
        type: NotificationTypes.ERROR,
      });
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return {
    updateAxisBankReport,
    loading,
  };
};

export default AxisBankService;
