import React, { FC, useState } from "react";
import { Router, Switch, Route, Redirect, useHistory } from "react-router-dom";
import { createBrowserHistory } from "history";
import { AppRoutes as appRoutes } from "./routeConstants/appRoutes";
import AuthWrapper from "../views/Auth/AuthWrapper";
import isAuthenticated from "../shared/components/HOC/requireAuth";
import { RouterProps } from "../shared/types/route.type";
import AuthContainer from "../store/container/AuthContainer";
import { AuthReducerProps } from "../store/reducers/authReducer";
import AppLoader from "../shared/components/AppLoader";
import Dashboard from "../views/Dashboard";
import AppSidebar from "../shared/components/AppSidebar";
import BAList from "../views/IndividualBA/BAList";
import BADetail from "../views/BADetail";
import InternalUserList from "../views/InternalUsers/InternalUserList";
import InternalUserDetail from "../views/InternalUsers/InternalUserDetail";
import InternalUserForm from "../views/InternalUsers/InternalUserForm";
import CustomerList from "../views/Customers/CustomerList";
import CustomerDetail from "../views/Customers/CustomerDetail";
import CustomerForm from "../views/Customers/CustomerForm";
import OrderList from "../views/Orders/OrderList";
import MetaConfigurationWrapper from "../views/MetaConfiguration/MetaConfigurationWrapper";
import ServiceProviderList from "../views/ServiceProviders/ServiceProviderList";
import ServiceProviderDetail from "../views/ServiceProviders/ServiceProviderDetail";
import TermsAndConditions from "../views/TermsAndConditions";
import PrivacyPolicy from "../views/PrivacyPolicy";
import ProductRequestList from "../views/ProductRequest/ProductRequestList";
import ProductRequestDetail from "../views/ProductRequest/ProductRequestDetail";
import BajajPayments from "../views/BajajPayments";
import OrderDetail from "../views/Orders/OrderDetail";
import CustomerTermsAndConditions from "../views/CustomerTermsAndConditions";
import LearningList from "../views/MyLearning/LearningList";
import LearningModuleForm from "../views/MyLearning/LearningModuleForm";
import Settings from "../views/Settings";
import ActivityLog from "../views/ActivityLog";
import Settlements from "../views/Settlements";
import { Button, Col, Drawer, Popover, Row } from "antd";
import { BellOutlined } from "@ant-design/icons";
import "./routes.scss";
import NotificationBar from "../shared/components/NotificationBar";
import bellIcon from "../assets/images/bell.png";
import NotificationWrapper from "../shared/components/NotificationWrapper";

interface AppRoutesProps extends AuthReducerProps {}
export const appHistory = createBrowserHistory();

const AppRoutes = ({ authenticated, setAuthenticated }: AppRoutesProps) => {
  const [loading, setLoading] = useState(false);

  const routes: RouterProps[] = [
    { exact: false, path: appRoutes.AUTH, component: AuthWrapper },
    {
      exact: true,
      path: appRoutes.TERMS_AND_CONDITIONS,
      component: TermsAndConditions,
    },
    {
      exact: true,
      path: appRoutes.CUSTOMER_TERMS_AND_CONDITIONS,
      component: CustomerTermsAndConditions,
    },
    { exact: true, path: appRoutes.PRIVACY_POLICY, component: PrivacyPolicy },
    {
      exact: true,
      path: appRoutes.HOME,
      component: isAuthenticated(Dashboard),
    },
    {
      exact: true,
      path: appRoutes.INDIVIDUAL_BA,
      component: isAuthenticated(BAList),
    },
    {
      exact: true,
      path: appRoutes.INDIVIDUAL_BA_DETAIL,
      component: isAuthenticated(BADetail),
    },
    {
      exact: true,
      path: appRoutes.INTERNAL_USERS,
      component: isAuthenticated(InternalUserList),
    },
    {
      exact: true,
      path: appRoutes.INTERNAL_USER_ADD,
      component: isAuthenticated(InternalUserForm),
    },
    {
      exact: true,
      path: appRoutes.INTERNAL_USER_EDIT,
      component: isAuthenticated(InternalUserForm),
    },
    {
      exact: true,
      path: appRoutes.INTERNAL_USER_DETAIL,
      component: isAuthenticated(InternalUserDetail),
    },
    {
      exact: true,
      path: appRoutes.CUSTOMERS,
      component: isAuthenticated(CustomerList),
    },
    {
      exact: true,
      path: appRoutes.CUSTOMER_ADD,
      component: isAuthenticated(CustomerForm),
    },
    {
      exact: true,
      path: appRoutes.CUSTOMER_DETAIL,
      component: isAuthenticated(CustomerDetail),
    },
    {
      exact: true,
      path: appRoutes.ORDERS,
      component: isAuthenticated(OrderList),
    },
    {
      exact: true,
      path: appRoutes.ORDER_DETAIL,
      component: isAuthenticated(OrderDetail),
    },
    {
      exact: true,
      path: appRoutes.META_CONFIGURATION,
      component: isAuthenticated(MetaConfigurationWrapper),
    },
    {
      exact: true,
      path: appRoutes.SERVICE_PROVIDERS,
      component: isAuthenticated(ServiceProviderList),
    },
    {
      exact: true,
      path: appRoutes.SERVICE_PROVIDER_DETAIL,
      component: isAuthenticated(ServiceProviderDetail),
    },
    {
      exact: true,
      path: appRoutes.PRODUCT_REQUESTS,
      component: isAuthenticated(ProductRequestList),
    },
    {
      exact: true,
      path: appRoutes.MY_LEARNING,
      component: isAuthenticated(LearningList),
    },

    {
      exact: true,
      path: appRoutes.PRODUCT_REQUEST_DETAIL,
      component: isAuthenticated(ProductRequestDetail),
    },
    {
      exact: true,
      path: appRoutes.BAJAJ_PAYMENTS,
      component: BajajPayments,
    },
    {
      exact: true,
      path: appRoutes.SETTINGS,
      component: Settings,
    },
    {
      exact: true,
      path: appRoutes.ACTIVITY_LOG,
      component: ActivityLog,
    },
    {
      exact: true,
      path: appRoutes.SETTLEMENTS,
      component: Settlements,
    },
  ];

  return loading ? (
    <AppLoader loading={loading} />
  ) : (
    <div>
      <Router history={appHistory}>
        <AppSidebar />
        <div className="app-wrapper">
          <NotificationWrapper />
          <Switch>
            {routes.map((route, index) => (
              <Route key={index} {...route} component={route.component as FC} />
            ))}
            <Route path="*" render={() => <Redirect to={appRoutes.LOGIN} />} />
          </Switch>
        </div>
      </Router>
    </div>
  );
};

export default AuthContainer(AppRoutes);
