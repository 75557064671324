import { Table } from "antd";
import React, { FC } from "react";
import { Customer } from "../../../models/Customer/customer.model";
import { Product } from "../../../models/Product/product.model";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import "./customerProduct.scss";
interface CustomerProductsProps {
  customer: Customer;
}
const columns = [
  {
    title: "ORDER ID",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "PRODUCT",
    dataIndex: "product",
    key: "product",
    render: (product: string) => removeUnderscore(product),
  },
  {
    title: "AMOUNT",
    dataIndex: "amount",
    key: "amount",
  },
  {
    title: "DATE",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    render: (status: string) => {
      return (
        <span className={status.replace("_", "-")}>
          {removeUnderscore(status)}
        </span>
      );
    },
  },
];

const sampleData = [
  {
    orderId: "1",
    product: "Heppy Gold",
    amount: "4000",
    date: "01-01-2022",
    status: "Active",
  },
];

const CustomerProducts: FC<CustomerProductsProps> = (props) => {
  const { customer } = props;
  return (
    <div className="customer-product">
      {" "}
      <Table dataSource={customer?.product} columns={columns} />
    </div>
  );
};

export default CustomerProducts;
