import { serializable, alias, object, list, primitive } from "serializr";

/* Banner List Section */

class BannerAttachment {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("attachment_url", primitive()))
  attachmentUrl?: string;
}
class BannerModifiedBy {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("first_name", primitive()))
  firstName?: string;
  @serializable(alias("last_name", primitive()))
  lastName?: string;
}
export class Banner {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("row_position", primitive()))
  rowPosition?: number;
  @serializable(alias("slider_position", primitive()))
  sliderPosition?: number;
  @serializable(alias("banner_attachment", object(BannerAttachment)))
  bannerAttachment?: BannerAttachment;
  @serializable(alias("viewable_user_type", primitive()))
  viewableUserType?: string;
  @serializable(alias("modified_by", object(BannerModifiedBy)))
  modifiedBy?: BannerModifiedBy;
  @serializable(alias("updated_at", primitive()))
  updatedAt?: string;
}

/* FAQS list Section */

class FaqProductType {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("name", primitive()))
  name?: string;
}
class FaqAttachment {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("attachment_url", primitive()))
  attachmentUrl?: string;
}
class FaqModifiedBy {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("first_name", primitive()))
  firstName?: string;
  @serializable(alias("last_name", primitive()))
  lastName?: string;
}
export class Faqs {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("faq_name", primitive()))
  faqName?: string;
  @serializable(alias("product_type", object(FaqProductType)))
  productType?: FaqProductType;
  @serializable(alias("faq_attachment", object(FaqAttachment)))
  faqAttachment?: FaqAttachment;
  @serializable(alias("faq_message", primitive()))
  faqMessage?: string;
  @serializable(alias("viewable_user_type", primitive()))
  viewableUserType?: string;
  @serializable(alias("modified_by", object(FaqModifiedBy)))
  modifiedby?: FaqModifiedBy;
  @serializable(alias("updated_at", primitive()))
  updatedAt?: string;
}
