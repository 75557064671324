import { ReferenceContact } from "../ReferenceContact/referenceContact.model";
import { BankDetail } from "../BankDetail/bankDetail.model";
import { Qualification } from "../Qualification/qualification.model";
import { Address } from "../Address/address.model";
import { serializable, alias, object, list, primitive } from "serializr";
import { AddressTypeEnum } from "../../enums/addressType.enum";
import { QualificationTypeEnum } from "../../enums/qualificationType.enum";
import { FileAttachment } from "../FileAttachment/fileAttachment.model";
import { EmploymentDetail } from "../EmploymentDetail/employmentDetail.model";
import { FamilyMember } from "../FamilyMember/familyMember.model";
import { BAAdditionalInfo } from "../BAAdditionalInfo/baAdditionalInfo.model";
import { Location as LocationModel } from "../Location/location.model";
export class BAInactiveStatus {
  @serializable(alias("assign_to_rm", primitive()))
  assignToRm?: boolean;
  @serializable(alias("ba_id", primitive()))
  baId?: number | null;
}
export class BusinessAssociatePassword {
  @serializable(alias("new_password", primitive()))
  newPassword?: string;

  @serializable(alias("confirm_password", primitive()))
  confirmPassword?: string;
}

export class RelationshipManager {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;
}

export class BusinessAssociate {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("uid", primitive()))
  uid?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("mobile", primitive()))
  mobile?: string;

  @serializable(alias("otp", primitive()))
  otp?: string;

  @serializable(alias("no_of_clients", primitive()))
  noOfClients?: number;

  @serializable(alias("organization", primitive()))
  organization?: string;

  @serializable(alias("last_login_time", primitive()))
  lastLoginTime?: string;

  @serializable(alias("relationship_manager", object(RelationshipManager)))
  relationshipManager?: RelationshipManager;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("password_reset_otp", primitive()))
  passwordResetOTP?: string;

  @serializable(alias("password", primitive()))
  password?: string;

  @serializable(alias("profile_pic_id", primitive()))
  profilePicId?: number;

  @serializable(alias("latitude", primitive()))
  latitude?: number;

  @serializable(alias("longitude", primitive()))
  longitude?: number;

  @serializable(alias("association", primitive()))
  association?: number;

  @serializable(alias("profile_pic", object(FileAttachment)))
  profilePic?: FileAttachment;

  @serializable(alias("aadhaar_number", primitive()))
  aadharNumber?: string;

  @serializable(alias("pan_number", primitive()))
  panNumber?: string;

  @serializable(alias("profile_pic_url", primitive()))
  profilePicUrl?: string;

  @serializable(alias("brokerage_group_id", primitive()))
  brokerageGroupId?: string;

  @serializable(alias("brokerage_group_name", primitive()))
  brokerageGroupName?: string;

  @serializable(alias("aadhaar_front_page", object(FileAttachment)))
  aadharFrontPage?: FileAttachment;

  @serializable(alias("aadhaar_back_page", object(FileAttachment)))
  aadharBackPage?: FileAttachment;

  @serializable(alias("other_identity_proof", object(FileAttachment)))
  otherIdentityProof?: FileAttachment;

  @serializable(alias("other_identity_proof_id", primitive()))
  otherIdentityProofId?: number;

  @serializable(alias("aadhaar_front_page_id", primitive()))
  aadharFrontPageId?: number;

  @serializable(alias("aadhaar_back_page_id", primitive()))
  aadharBackPageId?: number;
  @serializable(alias("is_aadhaar_verified", primitive()))
  isAadhaarVerified?: boolean;

  @serializable(alias("pan_card_id", primitive()))
  panCardId?: number;

  @serializable(alias("pan_card", object(FileAttachment)))
  panCard?: FileAttachment;

  @serializable(alias("profile_completion_status", primitive()))
  profileCompletionStatus?: string;

  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("grant_type", primitive()))
  grantType?: string = "password";

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("city", primitive()))
  city?: string;

  @serializable(alias("gender", primitive()))
  gender?: string;

  @serializable(alias("dob", primitive()))
  dob?: string;

  @serializable(alias("source", primitive()))
  source?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("addresses", list(object(Address))))
  addresses: Address[] = [];

  @serializable(alias("marital_status", primitive()))
  maritalStatus?: string;

  @serializable(alias("citizenship", primitive()))
  citizenship?: string;

  @serializable(alias("constituency", primitive()))
  constituency?: string;

  @serializable(alias("date_of_anniversary", primitive()))
  dateOfAnniversary?: string;

  @serializable(alias("date_of_discussion", primitive()))
  dateOfDiscussion?: string;
  @serializable(alias("date_of_registration", primitive()))
  dateOfRegistration?: string;

  @serializable(alias("insurance", primitive()))
  insurance?: string;
  @serializable(alias("location", object(LocationModel)))
  location?: LocationModel;

  @serializable(alias("employment_detail", object(EmploymentDetail)))
  employmentDetail?: EmploymentDetail;

  @serializable(alias("additional_info", object(BAAdditionalInfo)))
  additionalInfo?: BAAdditionalInfo;

  @serializable(alias("family_details", list(object(FamilyMember))))
  familyDetails?: FamilyMember[];

  @serializable(alias("qualifications", list(object(Qualification))))
  qualifications?: Qualification[] = [
    Object.assign(new Qualification(), {
      ...new Qualification(),
      qualificationType: QualificationTypeEnum.EDUCATIONAL,
    }),
    Object.assign(new Qualification(), {
      ...new Qualification(),
      qualificationType: QualificationTypeEnum.PROFESSIONAL,
    }),
  ];

  @serializable(alias("bank_detail", object(BankDetail)))
  bankDetail?: BankDetail = new BankDetail();

  residentialAddress?: Address = Object.assign(new Address(), {
    ...new Address(),
    addressType: AddressTypeEnum.RESIDENTIAL,
  });

  communicationAddress?: Address = Object.assign(new Address(), {
    ...new Address(),
    addressType: AddressTypeEnum.COMMUNICATION,
  });

  @serializable(alias("reference_contacts", list(object(ReferenceContact))))
  referenceContacts: ReferenceContact[] = [new ReferenceContact()];

  getAddresses() {
    this.communicationAddress = this.addresses.find(
      (address) => address.addressType === AddressTypeEnum.COMMUNICATION
    );
    if (this.communicationAddress?.city) {
      this.communicationAddress.cityId = this.communicationAddress.city.id;
      this.communicationAddress.provinceId =
        this.communicationAddress?.city.provinceId;
    }
    this.residentialAddress = this.addresses.find(
      (address) => address.addressType === AddressTypeEnum.RESIDENTIAL
    );
    if (this.residentialAddress?.city) {
      this.residentialAddress.cityId = this.residentialAddress.city.id;
      this.residentialAddress.provinceId =
        this.residentialAddress?.city.provinceId;
    }
  }

  setAddresses() {
    this.addresses = [];
    if (this.communicationAddress) {
      this.addresses.push(this.communicationAddress);
    }
    if (this.residentialAddress) {
      this.addresses.push(this.residentialAddress);
    }
  }

  getRelationshipManagerName() {
    return (
      this.relationshipManager?.firstName +
      " " +
      this.relationshipManager?.lastName
    );
  }
}
