import React from "react";
import "./dashboard.scss";
import { Row, Col, Card, Menu, Dropdown, Table, Select, Button } from "antd";
import BAOnboarding from "./BAOnboardingGraph";
import CustomerLineGraph from "./CustomerLineGraph";
// import CustomerHeatGraph from "./CustomersHeatGraph/index";
import Ribbon from "antd/lib/badge/Ribbon";
import { Form, Formik } from "formik";

interface DashboardProps {}

const dashboardData = [
  {
    label: "BA Leads",
    value: "1345",
  },
  {
    label: "Business Associate",
    value: "657",
  },
  {
    label: "Customer Leads",
    value: "4163",
  },
  {
    label: "Products Purchase",
    value: "53",
  },
  {
    label: "Enquiries",
    value: "132",
  },
];
const columns = [
  {
    title: "",
    dataIndex: "sideData",
    key: "sideData",
  },
  {
    title: "MTT",
    dataIndex: "mtt",
    key: "mtt",
  },
  {
    title: "YTD",
    dataIndex: "ytd",
    key: "ytd",
  },
  {
    title: "TIME PERIOD 1",
    dataIndex: "timePeriod1",
    key: "timePeriod1",
  },
  {
    title: "TIME PERIOD 2",
    dataIndex: "timePeriod2",
    key: "timePeriod2",
  },
];

const tableData = [
  {
    sideData: "No of  Live BA",
    mtt: 234,
    ytd: 972,
    timePeriod1: 17,
    timePeriod2: 20,
  },
  {
    sideData: "No of  Live BA in progress",
    mtt: 234,
    ytd: 827,
    timePeriod1: 17,
    timePeriod2: 20,
  },
  {
    sideData: "Product 1",
    mtt: 826,
    ytd: 827,
    timePeriod1: 17,
    timePeriod2: 20,
  },
  {
    sideData: "No of  Live BA",
    mtt: 826,
    ytd: 972,
    timePeriod1: 14,
    timePeriod2: 20,
  },
  {
    sideData: "No of  Live BA in progress",
    mtt: 826,
    ytd: 972,
    timePeriod1: 14,
    timePeriod2: 20,
  },
];

function Dashboard(props: DashboardProps) {
  return (
    <div className="dashboard ">
      <h1 className="text-primary"> Dashboard</h1>

      <div className="dashboard-data">
        <Row>
          {dashboardData.map((data) => {
            return (
              <Col span={4}>
                <Card>
                  <h1 className="text-primary">{data.value}</h1>
                  <p>{data.label}</p>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
      <div className="dashboard__onboarding mt-5">
        <BAOnboarding />
      </div>

      <div className="dashboard__customer_line_graph  mt-5">
        <div className="dashboard__customer_line_graph_filters mb-5">
          <Formik
            enableReinitialize
            initialValues={() => {}}
            onSubmit={() => {}}
          >
            {({ values, errors, isValid, dirty, setFieldValue }) => {
              return (
                <Form>
                  <Select placeholder="HealthAssure" options={dashboardData} />
                  <Select placeholder="YTD" options={dashboardData} />

                  <Button type="primary" htmlType="submit">
                    Apply
                  </Button>
                </Form>
              );
            }}
          </Formik>
        </div>

        <CustomerLineGraph />
      </div>
      <div className=" dashboard__customer_heat_graph mt-5">
        {/* <CustomerHeatGraph /> */}
      </div>
      <div className="dashboard__customer_table mt-4">
        <Table
          dataSource={tableData}
          columns={columns}
          bordered
          pagination={false}
        />
      </div>
    </div>
  );
}

export default Dashboard;
