import React, { useEffect, useState } from "react";
import { Col } from "antd";
import InputField from "../InputField";
import DropdownField from "../DropdownField";
import MetaService, {
  IDropdownOption,
} from "../../../services/MetaService/meta.service";
import { Province } from "../../../models/Province/province.model";
import { City } from "../../../models/City/city.model";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import { Employee } from "../../../models/Employee/employee.model";
import { Customer } from "../../../models/Customer/customer.model";

interface AddressFieldProps {
  required?: boolean;
  provinceKey: string;
  provinceValue?: number;
  cityKey: string;
  cityValue?: number;
  zipcodeKey: string;
  streetKey: string;
  setFieldValue: Function;
  values?: BusinessAssociate | Employee | Customer;
  onCityChange?: (value: any, option: any) => void;
}

function AddressField({
  values,
  setFieldValue,
  provinceKey,
  provinceValue,
  cityKey,
  cityValue,
  zipcodeKey,
  streetKey,
  required,
  onCityChange,
}: AddressFieldProps) {
  const [provinceOptions, setProvinceOptions] = useState<IDropdownOption[]>([]);

  const [cityOptions, setCityOptions] = useState<IDropdownOption[]>([]);

  const handleChangeProvince = (provinceId: number) => {
    MetaService.fetchCities(
      provinceId,
      (cities: City[]) => {
        setCityOptions(
          cities.map((city) => ({
            label: city.name,
            value: city.id,
          }))
        );
      },
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    MetaService.fetchProvinces(
      (provinces: Province[]) => {
        setProvinceOptions(
          provinces.map((province) => ({
            label: province.name,
            value: province.id,
          }))
        );
      },
      () => {},
      () => {}
    );
  }, []);

  useEffect(() => {
    if (provinceValue) {
      handleChangeProvince(provinceValue);
    }
  }, [provinceValue]);
  return (
    <React.Fragment>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <InputField
          required={required}
          title="Address"
          type="text"
          name={streetKey}
          placeholder="Enter Address"
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <DropdownField
          showSearch
          required={required}
          title="State"
          name={provinceKey}
          options={provinceOptions}
          onChange={(value) => {
            setFieldValue(provinceKey, value);
            setFieldValue(cityKey, undefined);
          }}
          value={provinceValue}
          setFieldValue={setFieldValue}
          placeHolder="Select State"
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <DropdownField
          showSearch
          required={required}
          title="City"
          name={cityKey}
          options={cityOptions}
          value={cityValue}
          setFieldValue={setFieldValue}
          onChange={onCityChange}
          placeHolder="Select City"
        />
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <InputField
          required={required}
          title="Zipcode"
          type="text"
          name={zipcodeKey}
          placeholder="Enter Zipcode"
        />
      </Col>
    </React.Fragment>
  );
}

export default AddressField;
