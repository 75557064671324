import React, { FC, useState, useEffect } from "react";
import { Form, Formik, FormikValues } from "formik";
import { Row, Col, Button } from "antd";
import RadioField from "../../../shared/components/RadioField";
import { ProductTypesEnum } from "../../../enums/productTypes.enum";
import "./axisBankForm.scss";
import UploadField from "../../../shared/components/UploadField";
import { Order } from "../../../models/Order/order.model";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import { Product } from "../../../models/Product/product.model";
import AxisBankService from "../../../services/AxisBankService/axisBank.service";
import { axisBankValidation } from "./axisBankValidationSchema";
import { ApplicableUser } from "../../../enums/applicableUser.enum";

interface AxisBankFormProps {
  handleForm: () => void;
}

const AxisBankForm: FC<AxisBankFormProps> = (props) => {
  const { handleForm } = props;
  const [formValues, setFormValues] = useState(new Order());
  const { loading, updateAxisBankReport } = AxisBankService();
  const [userRadioValue, setUserRadioValue] = useState<ProductTypesEnum>(
    ProductTypesEnum.SAVING_ACCOUNT_AXIS_BANK
  );
  const reportOptions = [
    {
      label: "Saving Account Report",
      value: ProductTypesEnum.SAVING_ACCOUNT_AXIS_BANK,
    },
    {
      label: "Current Account Report",
      value: ProductTypesEnum.CURRENT_ACCOUNT_AXIS_BANK,
    },
  ];

  const handleSubmit = (values: Order) => {
    updateAxisBankReport(values, () => {
      handleForm();
    });
  };
  return (
    <div className="axis-bank-form">
      <h2 className="text-primary">Upload Report</h2>
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        validationSchema={axisBankValidation}
      >
        {({ values, errors, isValid, dirty, setFieldValue }) => {
          return (
            <Form>
              <Row gutter={[20, 20]}>
                <Col span={24}>
                  <RadioField
                    name="productType"
                    options={reportOptions}
                    value={values?.productType}
                    setFieldValue={setFieldValue}
                  />
                </Col>
                <Col span={24}>
                  <AttachmentUpload
                    title="Upload File"
                    accept=".xlsx, .xls, .csv"
                    name="uploadedFileId"
                    placeholder="Browse Files"
                    setFieldValue={setFieldValue}
                  />
                </Col>

                <div className="axis-bank-form__btn">
                  <Col span={12}>
                    <Button type="text" onClick={handleForm}>
                      Cancel
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      disabled={!dirty || !isValid}
                    >
                      Save
                    </Button>
                  </Col>
                </div>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AxisBankForm;
