import React from "react";
import { Row, Col, Card, Menu, Dropdown } from "antd";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";

import "./BADashboard.scss";

import BADashboardGraph from "../BADashboardGraph";
interface BADashboardProps {
  businessAssociate: BusinessAssociate;
  onUpdateSuccess: (ba: BusinessAssociate) => void;
}

const BAdashboardData = [
  {
    label: "City/Locality",
    value: "Andheri",
  },
  {
    label: "On-going Leads",
    value: "163",
  },
  {
    label: "Completed Leads",
    value: "257",
  },
  {
    label: "Loan Amount In-progress",
    value: "₹3,25,000",
  },
  {
    label: "Success ratio",
    value: "79%",
  },
  {
    label: "Target vs Achivement",
    value: "₹5,00,000",
  },
];

function BADashboard(props: BADashboardProps) {
  return (
    <div className="ba-dashboard">
      <div className="ba-dashboard__data">
        <Row>
          {BAdashboardData.map((data) => {
            return (
              <Col span={8}>
                <Card>
                  <h1 className="text-primary">{data.value}</h1>
                  <p>{data.label}</p>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
      <div className="dashboad-onboarding mt-5">
        <BADashboardGraph />
      </div>
    </div>
  );
}

export default BADashboard;
