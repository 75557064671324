import React, { useState } from "react";
import { Row, Col, Button, Upload } from "antd";
import { Form, Formik } from "formik";
import PageTitle from "../../../shared/components/PageTitle";
import InputField from "../../../shared/components/InputField";
import DropdownField from "../../../shared/components/DropdownField";
import { Notification } from "../../../models/Notification/notification.model";
import { InboxOutlined } from "@ant-design/icons";
import "./faqForm.scss";

interface FAQProps {
  handleFormVisibility: () => void;
}
function FAQ(props: FAQProps) {
  const { handleFormVisibility } = props;
  const { Dragger } = Upload;
  const [formLoading, setFormLoading] = useState(false);
  const [formValues, setFormValues] = useState(new Notification());
  const [productType, setproductType] = useState();

  const handleSubmit = (values: Notification) => {};

  const productOptions = [
    {
      label: "Health Assure",
      value: "healthAssure",
    },
    {
      label: "Bajaj Finance",
      value: "bajajFinance",
    },
    {
      label: "Will Service",
      value: "willService",
    },
    {
      label: "Career Guide",
      value: "careerGuide",
    },
  ];

  return (
    <div className="faq-form">
      <div className="faq__heading">
        <h2 className="text-primary mb-5">New FAQ </h2>
      </div>
      <div className="faq__form">
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
        >
          {({ values, errors, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <Row gutter={[30, 20]}>
                  <Col span={12}>
                    <InputField
                      title="FAQ Name"
                      type="text"
                      name="FAQName"
                      placeholder="Enter FAQ Name"
                      required
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Select Product"
                      name="product"
                      options={productOptions}
                      value={productType}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Content Type"
                      required
                      onChange={(value) => {
                        setproductType(value);
                      }}
                    />
                  </Col>
                  <Col span={24}>
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                      </p>
                      <p className="ant-upload-text">
                        Drag and drop files here
                      </p>
                      <p className="ant-upload-hint">
                        or <br />
                        <span className="primary"> Browse File</span>
                      </p>
                    </Dragger>
                  </Col>
                  <Col span={24}>
                    <InputField
                      title="Message"
                      type="textarea"
                      name="message"
                      placeholder="Enter Message"
                    />
                  </Col>

                  <Col span={21} className="text-right mt-4 ml-1">
                    <Button
                      type="text"
                      loading={formLoading}
                      disabled={!isValid || formLoading}
                      onClick={handleFormVisibility}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col span={3} className="text-right mt-4">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={formLoading}
                      disabled={!isValid || formLoading}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default FAQ;
