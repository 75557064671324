import React, { useEffect, useState } from "react";
import "./orderDetail.scss";
import { Col, Row, Tabs } from "antd";
import { useParams } from "react-router-dom";
import { Order } from "../../../models/Order/order.model";
import OrderGeneralDetails from "./OrderGeneralDetails";
import OrderProductDetails from "./OrderProductDetails";
import OrderService from "../../../services/Order/order.service";
import PageTitle from "../../../shared/components/PageTitle";
import AppLoader from "../../../shared/components/AppLoader";
import { ProductTypesEnum } from "../../../enums/productTypes.enum";
import OrderBajajProductDetails from "./OrderBajajProductDetails";

const { TabPane } = Tabs;

interface OrderDetailProps {}

interface OrderDetailParams {
  orderId: string;
  productType: string;
}

function OrderDetail(props: OrderDetailProps) {
  const [order, setOrder] = useState<Order>();

  const params: OrderDetailParams = useParams();

  const [loading, setLoading] = useState(false);

  const orderDetailTabs = [
    {
      title: "Order Details",
      component: order ? <OrderGeneralDetails order={order} /> : null,
    },
    {
      title: "Product Details",
      component:
        order && order?.orderType === ProductTypesEnum.HEALTH_ASSURE ? (
          <OrderProductDetails order={order} />
        ) : order && order?.orderType === ProductTypesEnum.BAJAJ_FINSERV_FD ? (
          <OrderBajajProductDetails order={order} />
        ) : (
          ""
        ),
    },
  ];

  useEffect(() => {
    if (params.orderId) {
      OrderService.showOrder(
        Number(params.orderId),
        (order: Order) => {
          setOrder(order);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, []);

  return (
    <div className="order-detail">
      <PageTitle title="Order Details" />
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <Tabs
              defaultActiveKey="0"
              size="small"
              style={{ marginBottom: 32 }}
            >
              {orderDetailTabs.map(({ title, component }, i) =>
                component ? (
                  <TabPane tab={title} key={i}>
                    {component}
                  </TabPane>
                ) : null
              )}
            </Tabs>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default OrderDetail;
