import React, { useEffect, useState } from "react";
import "./productRequestDetail.scss";
import { useParams } from "react-router-dom";
import ProductRequestService from "../../../services/ProductRequest/productRequest.service";
import { ProductRequest } from "../../../models/ProductRequest/productRequest.model";
import { Tabs } from "antd";
import ProductRequestApplicationDetails from "../ProductRequestApplicationDetails";
import ProductRequestDocuments from "../ProductRequestDocuments";
import ProductRequestComments from "../ProductRequestComments";
import PageTitle from "../../../shared/components/PageTitle";
import { Product } from "../../../models/Product/product.model";

interface ProductRequestDetailProps {}

function ProductRequestDetail(props: ProductRequestDetailProps) {
  const params: { productRequestId?: string } = useParams();

  const [productRequest, setProductRequest] = useState<ProductRequest>();

  const [loading, setLoading] = useState(false);

  const handleUpdateSuccess = (productRequest: ProductRequest) =>
    setProductRequest(productRequest);

  const productRequestTabs = [
    {
      title: "Application Details",
      component: productRequest ? (
        <ProductRequestApplicationDetails
          productRequest={productRequest}
          onUpdateSuccess={handleUpdateSuccess}
        />
      ) : null,
    },
    {
      title: "Documents",
      component: productRequest ? (
        <ProductRequestDocuments productRequest={productRequest} />
      ) : null,
    },
    {
      title: "Update/Feedback Comments",
      component: productRequest ? (
        <ProductRequestComments
          productRequest={productRequest}
          onUpdateSuccess={handleUpdateSuccess}
        />
      ) : null,
    },
  ];

  useEffect(() => {
    if (params.productRequestId) {
      setLoading(true);
      ProductRequestService.showProductRequest(
        Number(params.productRequestId),
        (productRequest: ProductRequest) => {
          setProductRequest(productRequest);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, []);

  return (
    <div className="product-request-detail">
      <PageTitle title={`Product Request (${productRequest?.id || ""})`} />
      <Tabs defaultActiveKey="0" size="small" style={{ marginBottom: 32 }}>
        {productRequestTabs.map(({ title, component }, i) => (
          <Tabs.TabPane tab={title} key={i}>
            {component}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
}

export default ProductRequestDetail;
