import axiosInstance, { getHeaders } from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import {
  BusinessAssociate,
  BAInactiveStatus,
} from "../../models/BusinessAssociate/businessAssociate.model";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import axios from "axios";
import { BaFilterParams } from "../../models/BAFilterParams/baFilterParams.model";
import { PaginationDetails } from "../../models/Pagination/pagination.model";

export class BusinessAssociateService {
  static fetchBusinessAssociates(
    filterParams: BaFilterParams,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const filterJSON = serialize(filterParams);
    axiosInstance
      .get(ApiRoutes.BUSINESS_ASSOCIATES, {
        params: {
          ...filterJSON,
        },
      })
      .then((response) => {
        const businessAssociates = deserialize(
          BusinessAssociate,
          response.data["business_associates"]
        );
        const baPagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(businessAssociates, baPagination);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static showBusinessAssociate(
    baId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.BUSINESS_ASSOCIATES + "/" + baId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const businessAssociate = deserialize(
          BusinessAssociate,
          response.data["business_associate"]
        );
        onSuccess(businessAssociate);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
  static modifyAdditionalInfoBA(
    baId: number,
    updatedBA: BusinessAssociate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.BUSINESS_ASSOCIATES + "/" + baId;
    const baJSON = serialize(updatedBA);

    axiosInstance
      .put(API_URL, baJSON)
      .then((response) => {
        Notification({
          type: NotificationTypes.SUCCESS,
          message: "Profile details updated!",
        });

        const businessAssociate = deserialize(
          BusinessAssociate,
          response.data["business_associate"]
        );
        onSuccess(businessAssociate);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadBAs(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.DOWNLOAD_BA, {
        responseType: "blob",
      })
      .then((response) => {
        onSuccess();
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement("a");
        // link.href = url;
        // link.setAttribute("download", "Business Associates.csv"); //or any other extension
        // document.body.appendChild(link);
        // link.click();
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static assignRM(
    baIds: number[],
    employeeId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ASSIGN_RM;
    axiosInstance
      .put(API_URL, {
        business_associate_ids: baIds,
        employee_id: employeeId,
      })
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
  static assignCustomerRM(
    customerIds: number[],
    employeeId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ASSIGN_CUSTOMER_RM;
    axiosInstance
      .put(API_URL, {
        customer_ids: customerIds,
        employee_id: employeeId,
      })
      .then((response) => {
        Notification({
          message: "RM Assigned!",
          description: response.data?.message,
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static assignBrokerageGroup(
    baIds: number[],
    brokerageGroupId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ASSIGN_BROKERAGE_GROUP;
    axiosInstance
      .put(API_URL, {
        business_associate_ids: baIds,
        brokerage_group_id: brokerageGroupId,
      })
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static inviteBusinessAssociate(
    businessAssociate: BusinessAssociate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const baJSON = {
      business_associate: serialize(businessAssociate),
    };
    axiosInstance
      .post(ApiRoutes.BUSINESS_ASSOCIATES, baJSON)
      .then((response) => {
        Notification({
          type: NotificationTypes.SUCCESS,
          message: "BA invited!",
        });
        const businessAssociate = deserialize(
          BusinessAssociate,
          response.data["business_associate"]
        );
        onSuccess(businessAssociate);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateBusinessAssociate(
    businessAssociate: BusinessAssociate,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    businessAssociate.addresses = [];
    if (businessAssociate?.communicationAddress?.cityId) {
      businessAssociate.addresses.push(businessAssociate.communicationAddress);
    }
    if (businessAssociate?.residentialAddress?.cityId) {
      businessAssociate.addresses.push(businessAssociate.residentialAddress);
    }
    const baJSON = {
      business_associate: serialize(businessAssociate),
    };
    const API_URL = ApiRoutes.BUSINESS_ASSOCIATES + "/" + businessAssociate.id;
    axiosInstance
      .put(API_URL, baJSON)
      .then((response) => {
        Notification({
          type: NotificationTypes.SUCCESS,
          message: "Profile details updated!",
        });
        const businessAssociate = deserialize(
          BusinessAssociate,
          response.data["business_associate"]
        );
        onSuccess(businessAssociate);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
  static setBAInactiveStatus(
    id: number,
    baInactiveStatus: BAInactiveStatus,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const baJSON = serialize(BAInactiveStatus, baInactiveStatus);

    axiosInstance
      .post(ApiRoutes.INACTIVE_STATUS.replace(":id", id.toString()), baJSON)
      .then((response) => {
        Notification({
          type: NotificationTypes.SUCCESS,
          message: "Profile details updated!",
        });
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
  static updatePassword(
    baId: string,
    password: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const baJSON = { password: password };
    axiosInstance
      .put(ApiRoutes.UPDATE_PASSWORD.replace(":id", baId), baJSON)
      .then((response) => {
        Notification({
          message: "Password Successfully Updated!",
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
}
