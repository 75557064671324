import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { Category } from "../../models/Category/category.model";
import { deserialize, serialize } from "serializr";

export default class CategoryService {
  static fetchCategories(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.CATEGORIES)
      .then((response) => {
        const categories = deserialize(
          Category,
          response.data["product_categories"]
        );
        onSuccess(categories);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showCategory(
    categoryId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CATEGORIES + "/" + categoryId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const category = deserialize(Category, response.data["category"]);
        onSuccess(category);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createCategory(
    category: Category,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const categoryJSON = serialize(category);
    axiosInstance
      .post(ApiRoutes.CATEGORIES, categoryJSON)
      .then((response) => {
        const category = deserialize(Category, response.data["category"]);
        onSuccess(category);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateCategory(
    category: Category,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CATEGORIES + "/" + category.id;
    const categoryJSON = serialize(category);
    axiosInstance
      .put(API_URL, categoryJSON)
      .then((response) => {
        const category = deserialize(Category, response.data["category"]);
        onSuccess(category);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteCategory(
    categoryId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CATEGORIES + "/" + categoryId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
