import React from "react";
import "./pageTitle.scss";
import { LeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

interface PageTitleProps {
  title: string;
}

function PageTitle({ title }: PageTitleProps) {
  const history = useHistory();

  const handleGoBack = () => history.goBack();

  return (
    <div className="page-title">
      <h1 className="text-primary">
        <LeftOutlined
          className="mr-2 ba-list__back-icon"
          onClick={handleGoBack}
        />
        {title}
      </h1>
    </div>
  );
}

export default PageTitle;
