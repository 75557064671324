import React, { useEffect, useState } from "react";
// import "./baCommissionList.scss";

import { Button, Col, Drawer, Row, Table } from "antd";
import { CommissionTypeEnum } from "../../../enums/commissionType.enum";
import { Commission } from "../../../models/Commission/commission.model";
import AppLoader from "../../../shared/components/AppLoader";
import moment from "moment";
import { Employee } from "../../../models/Employee/employee.model";
import BusinessCreditService from "../../../services/BusinessCreditPoints/businessCreditPoints.service";
import UpdateCommissionStatus from "../../BADetail/UpdateCommissionStatus";
// import UpdateCommissionStatus from "../UpdateCommissionStatus";

interface BusinessCreditPointsProps {
  employee: Employee;
}

function BusinessCreditPoints({ employee }: BusinessCreditPointsProps) {
  const [loading, setLoading] = useState(false);

  const [activeStatus, setActiveStatus] = useState(CommissionTypeEnum.ACCRUED);

  const [totalAmount, setTotalAmount] = useState("");

  const [creditPoints, setCreditPoints] = useState<Commission[]>([]);

  const [showUpdateStatus, setShowUpdateSuccess] = useState(false);

  const [selectedCommissionIds, setSelectedCommissionIds] = useState<number[]>(
    []
  );

  const handleToggleUpdateStatus = () =>
    setShowUpdateSuccess(!showUpdateStatus);

  const handleFetchBusinessCreditPoints = () => {
    setLoading(true);
    BusinessCreditService.fetchBusinessCreditPoints(
      employee?.id ?? 0,
      activeStatus,
      (creditPoints: Commission[], totalAmount: string) => {
        setCreditPoints(creditPoints);
        setTotalAmount(totalAmount);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleUpdateSuccess = () => {
    handleFetchBusinessCreditPoints();
    setShowUpdateSuccess(false);
    setSelectedCommissionIds([]);
  };

  const rowSelection = {
    selectedRowKeys: selectedCommissionIds,
    onChange: (selectedRowKeys: React.Key[], commissions: Commission[]) => {
      const commissionIds = commissions.map((commission) => commission.id ?? 0);
      setSelectedCommissionIds(commissionIds);
    },
  };

  useEffect(() => {
    handleFetchBusinessCreditPoints();
  }, [activeStatus]);

  const columns = [
    {
      title: "CUSTOMER ID",
      dataIndex: "customerId",
      key: "customerId",
    },
    {
      title: "CUSTOMER NAME",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "PRODUCT NAME",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "PRODUCT VALUE",
      dataIndex: "productValue",
      key: "productValue",
      render: (value: string) => `₹${value}`,
    },
    {
      title: "TRANSACTION DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: "BROKERAGE AMOUNT",
      dataIndex: "brokerageValue",
      key: "brokerageValue",
      render: (value: string) => `₹${value}`,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <div className="text-capitalize">{status}</div>
      ),
    },
  ];

  return (
    <div className="business-credit-points">
      <Row className="business-credit-points__header" gutter={[20, 0]}>
        <Col span={12} className="business-credit-points__btn-group">
          <Button
            onClick={() => setActiveStatus(CommissionTypeEnum.ACCRUED)}
            className={
              activeStatus === CommissionTypeEnum.ACCRUED ? "active" : ""
            }
          >
            Accrued
          </Button>
          <Button
            onClick={() => setActiveStatus(CommissionTypeEnum.PENDING)}
            className={
              activeStatus === CommissionTypeEnum.PENDING ? "active" : ""
            }
          >
            Pending
          </Button>
          <Button
            onClick={() => setActiveStatus(CommissionTypeEnum.SETTLED)}
            className={
              activeStatus === CommissionTypeEnum.SETTLED ? "active" : ""
            }
          >
            Settled
          </Button>
        </Col>
        <Col span={12} className="text-right">
          <Button
            type="primary"
            onClick={handleToggleUpdateStatus}
            disabled={selectedCommissionIds.length === 0}
          >
            Update Status
          </Button>
        </Col>
      </Row>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div className="mt-4">
          <Table
            rowKey={(record) => record.id || Math.random()}
            rowSelection={{
              preserveSelectedRowKeys: true,
              type: "checkbox",
              ...rowSelection,
            }}
            dataSource={creditPoints}
            columns={columns}
            size="small"
          />
          <Drawer
            width="50vw"
            onClose={handleToggleUpdateStatus}
            visible={showUpdateStatus}
            destroyOnClose
            footer={null}
          >
            <UpdateCommissionStatus
              totalAmount={totalAmount}
              selectedCommissionIds={selectedCommissionIds}
              onSuccess={handleUpdateSuccess}
            />
          </Drawer>
        </div>
      )}
    </div>
  );
}

export default BusinessCreditPoints;
