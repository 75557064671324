import React from "react";
import "./comingSoon.scss";
import comingSoon from "../../../assets/images/comingSoon.png";

interface ComingSoonProps {}

function ComingSoon(props: ComingSoonProps) {
  return (
    <div className="coming-soon">
      <img src={comingSoon} alt="Coming soon" />
    </div>
  );
}

export default ComingSoon;
