import React from "react";
import AppRoutes from "./routes";
import { Provider } from "react-redux";
import { store } from "./store";
import "antd/dist/antd.css";
import "../src/styles/_main.scss";
import {
  AUTHENTICATED,
  UNAUTHENTICATED,
} from "./store/definitions/authConstants";
import { Employee } from "./models/Employee/employee.model";

const token = localStorage.getItem("token");
const employee = Object.assign(
  new Employee(),
  JSON.parse(localStorage.getItem("employee") || "{}")
);
if (token) {
  store.dispatch({
    type: AUTHENTICATED,
    payload: {
      authenticated: true,
      employee,
    },
  });
} else {
  store.dispatch({
    type: UNAUTHENTICATED,
    payload: {
      authenticated: false,
    },
  });
}

const App = () => {
  return (
    <Provider store={store}>
      <div className="App">
        <AppRoutes />
      </div>
    </Provider>
  );
};

export default App;
