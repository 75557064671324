import axiosInstance from "../../interceptor/axiosInstance";
import { Customer } from "../../models/Customer/customer.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { FilterOptions } from "../CustomerFilter/customerFilter.service";
import { PaginationDetails } from "../../models/Pagination/pagination.model";
import { BaFilterParams } from "../../models/BAFilterParams/baFilterParams.model";

export default class CustomerService {
  static fetchCustomers(
    filterParams: BaFilterParams,

    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const param = serialize(BaFilterParams, filterParams);
    axiosInstance
      .get(ApiRoutes.CUSTOMERS, {
        params: { ...param },
      })
      .then((response) => {
        const customers = deserialize(Customer, response.data["customers"]);
        const customersPagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        onSuccess(customers, customersPagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showCustomer(
    customerId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CUSTOMERS + "/" + customerId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const customer = deserialize(Customer, response.data["customer"]);
        const customerPagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        customer.getAddresses();
        onSuccess(customer, customerPagination);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static assignBA(
    customerIds: number[],
    baId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ASSIGN_BA;
    axiosInstance
      .put(API_URL, {
        customer_ids: customerIds,
        business_associate_id: baId,
      })
      .then((response) => {
        Notification({
          message: "BA Assigned!",
          description: response.data?.message,
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static createCustomer(
    customer: Customer,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const customerJSON = { customer: serialize(customer) };
    axiosInstance
      .post(ApiRoutes.CUSTOMERS, customerJSON)
      .then((response) => {
        const customer = deserialize(Customer, response.data["customer"]);
        onSuccess(customer);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateCustomer(
    customer: Customer,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CUSTOMERS + "/" + customer.id;
    const customerJSON = serialize(customer);
    axiosInstance
      .put(API_URL, customerJSON)
      .then((response) => {
        const customer = deserialize(Customer, response.data["customer"]);
        onSuccess(customer);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteCustomer(
    customerId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CUSTOMERS + "/" + customerId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
