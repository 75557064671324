import React from "react";
import "./metaConfigurationWrapper.scss";
import { Tabs } from "antd";
import CategoryList from "../CategoryList";
import ProductTypeList from "../ProductTypeList";
import BrokerageGroupList from "../BrokerageGroupList";

const { TabPane } = Tabs;

interface MetaConfigurationWrapperProps {}

function MetaConfigurationWrapper(props: MetaConfigurationWrapperProps) {
  const configTabs = [
    { title: "Category", component: <CategoryList /> },
    {
      title: "Product Type",
      component: <ProductTypeList />,
    },
    { title: "Brokerage Groups", component: <BrokerageGroupList /> },
  ];

  return (
    <div className="meta-configuration-wrapper">
      <h1 className="text-primary">Meta Configuration</h1>
      <Tabs defaultActiveKey="0" size="small" style={{ marginTop: 32 }}>
        {configTabs.map(({ title, component }, i) => (
          <TabPane tab={title} key={i}>
            {component}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
}

export default MetaConfigurationWrapper;
