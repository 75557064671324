import React, { useEffect, useState } from "react";
import { Table, TablePaginationConfig } from "antd";
import "./banners.scss";
import { Banner as BannerModel } from "../../../models/Banner/banner.model";
import BannerService from "../../../services/MyProfile/Banner/banner.service";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import AppLoader from "../../../shared/components/AppLoader";
import { bannercolumns } from "./bannerTable";

interface BannerProps {
  onEdit: (banner: BannerModel) => void;
  updateForm: boolean;
}

function Banner(props: BannerProps) {
  const { onEdit, updateForm } = props;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [bannerPagination, setBannerPagination] = useState<PaginationDetails>();
  const { bannerList, fetchBannerList, loading } = BannerService();

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination?.current || 1);
  };

  const handleFetchBannerList = () => {
    fetchBannerList(
      currentPage,
      (data: BannerModel[], bannerPagination: PaginationDetails) => {
        setBannerPagination(bannerPagination);
      }
    );
  };
  useEffect(() => {
    handleFetchBannerList();
  }, []);

  useEffect(() => {
    handleFetchBannerList();
  }, [updateForm]);

  const handleRow = (banner: BannerModel) => {
    return {
      onClick: (event: any) => {
        return onEdit(banner);
      },
    };
  };
  useEffect(() => {
    handleFetchBannerList();
  }, [currentPage]);

  return (
    <div className="banner">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Table
          className="banner__table"
          columns={bannercolumns}
          dataSource={bannerList}
          rowKey="bannerId"
          onRow={handleRow}
          onChange={handleTableChange}
          pagination={{
            total: bannerPagination?.totalCount,
            current: bannerPagination?.currentPage,
            showTotal: (total: number, range: [number, number]) => (
              <p>
                Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                <b>{`${total.toLocaleString()}`}</b>
              </p>
            ),
          }}
        />
      )}
    </div>
  );
}

export default Banner;
