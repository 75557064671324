import { serializable, alias, object, list, primitive } from "serializr";

export class BAAdditionalInfo {
  @serializable(alias("children_education", primitive()))
  childrenEducation?: string;
  @serializable(alias("vehicle", primitive()))
  vehicle?: string;

  @serializable(alias("assets", primitive()))
  assets?: string;

  @serializable(alias("investments", primitive()))
  investments?: string;
  @serializable(alias("other_liabilities", primitive()))
  otherLiabilities?: string;
}
