import React, { useState, useEffect } from "react";
import "./serviceProviderProducts.scss";
import { ServiceProvider } from "../../../models/ServiceProvider/serviceProvider.model";
import { Product } from "../../../models/Product/product.model";
import ServiceProviderService from "../../../services/ServiceProvider/serviceProvider.service";
import AppLoader from "../../../shared/components/AppLoader";
import { Table } from "antd";
import ServiceProviderProductDetail from "../ServiceProviderProductDetail";

interface ServiceProviderProductsProps {
  serviceProvider?: ServiceProvider;
}

function ServiceProviderProducts({
  serviceProvider,
}: ServiceProviderProductsProps) {
  const [loading, setLoading] = useState(false);

  const [products, setProducts] = useState<Product[]>([]);

  const [activeProductId, setActiveProductId] = useState<number>();

  const handleRow = (product: Product, rowIndex: number | undefined) => {
    return {
      onClick: (event: any) => {
        setActiveProductId(product.id);
      },
    };
  };

  useEffect(() => {
    if (serviceProvider?.id) {
      setLoading(true);
      ServiceProviderService.fetchProducts(
        serviceProvider.id,
        (products: Product[]) => {
          setProducts(products);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, []);

  const columns = [
    {
      title: "PRODUCT NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "PRODUCT TYPE",
      dataIndex: "productType",
      key: "productType",
    },
    {
      title: "PRODUCT CODE",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "PRICE",
      dataIndex: "amount",
      key: "amount",
      render: (amount: string) => <div>₹{amount}</div>,
    },
    {
      title: "PURCHASABLE PRODUCT",
      dataIndex: "purchasableProduct",
      key: "purchasableProduct",
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: string) => (
        <span className="text-capitalize">{status}</span>
      ),
    },
  ];

  return (
    <div className="service-provider-products">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          {activeProductId ? (
            <ServiceProviderProductDetail
              serviceProvider={serviceProvider}
              productId={activeProductId}
              onBack={() => setActiveProductId(undefined)}
            />
          ) : (
            <div>
              <Table
                dataSource={products}
                columns={columns}
                onRow={handleRow}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ServiceProviderProducts;
