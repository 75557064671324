import React, { useEffect, useState } from "react";
import "./baDetail.scss";
import {
  Row,
  Tabs,
  Col,
  Select,
  Button,
  Popover,
  Input,
  Drawer,
  Tooltip,
} from "antd";
import { BusinessAssociate } from "../../models/BusinessAssociate/businessAssociate.model";
import { useHistory, useParams } from "react-router-dom";
import { BusinessAssociateService } from "../../services/BusinessAssociateService/businessAssociate.service";
import BAPersonalDetails from "./BaPersonalDetails";
import BADocuments from "./BADocuments";
import AppLoader from "../../shared/components/AppLoader";
import { LeftOutlined, PlusOutlined } from "@ant-design/icons";
import { ProfileStatusEnum } from "../../enums/profileStatus.enum";
import BACommissionList from "./BACommissionList";
import { Note } from "../../models/Note/note.model";
import NoteService from "../../services/Note/note.service";
import moment from "moment";
import avatarPlaceholder from "../../assets/images/userAvatarPlaceholder.jpeg";
import BAActivityLog from "./BAActivityLog";
import BADashboard from "./BADashboard";
import ThirdPartyVerifications from "./ThirdPartyVerification";
import ChangePassword from "../ChangePassword";
import IndivualBAInactiveModal from "../IndividualBA/IndivualBAInactiveModal";
import BAAdditionalInfo from "./BAAdditionalInfo";
import ComingSoon from "../../shared/components/ComingSoon";
import organizationImage from "../../assets/images/organization.png";
import contactImage from "../../assets/images/contact.png";
import emailImage from "../../assets/images/email.png";
import phoneImage from "../../assets/images/phone.png";
import alertIcon from "../../assets/images/alert.png";
import { ProfileCompletionStatusEnum } from "../../enums/profileCompletionStatus.enum";
import { IDropdownOption } from "../../services/MetaService/meta.service";
import { values } from "lodash";

const { TabPane } = Tabs;

interface BADetailProps {}

interface BADetailParams {
  baId: string;
}

function BADetail(props: BADetailProps) {
  const history = useHistory();

  const params: BADetailParams = useParams();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentBaId, setCurrentBaId] = useState<number>();
  const [loginTimeDifference, setLoginTimeDifference] = useState<number>();

  const [businessAssociate, setBusinessAssociate] =
    useState<BusinessAssociate>();

  const [notes, setNotes] = useState<Note[]>([]);

  const [noteText, setNoteText] = useState<string>();

  const [locationOption, setLocationOption] = useState<any[]>([]);
  const [seletedLocality, setSelectedLocality] = useState<string>("");

  const [loading, setLoading] = useState(false);

  const [noteFormLoading, setNoteFormLoading] = useState(false);

  const [updateLoading, setUpdateLoading] = useState(false);

  const handleUpdateSuccess = (ba: BusinessAssociate) => {
    setBusinessAssociate(ba);
  };

  const [form, setForm] = useState(false);

  const handleForm = () => setForm(!form);
  const handleGoBack = () => history.goBack();

  useEffect(() => {
    if (params.baId) {
      setLoading(true);
      BusinessAssociateService.showBusinessAssociate(
        Number(params.baId),
        (ba: BusinessAssociate) => {
          setBusinessAssociate(ba);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, []);

  const handleFetchBANotes = () => {
    if (businessAssociate?.id) {
      NoteService.fetchNotes(
        businessAssociate?.id,
        "ba",
        (notes: Note[]) => {
          setNotes(notes);
        },
        () => {},
        () => {}
      );
    }
  };

  useEffect(() => {
    handleFetchBANotes();

    const data = businessAssociate?.addresses?.map((address, index) => ({
      label: `${address?.city?.name},${address?.city?.provinceName}`,
      value: index,
    }));
    if (data) {
      setSelectedLocality(data[0]?.label);
      setLocationOption(data);
    }
  }, [businessAssociate]);

  const handleChangeTab = () => {};

  const profileStatuses = [
    { label: "Incomplete", value: ProfileStatusEnum.INCOMPLETE },
    { label: "Under Review", value: ProfileStatusEnum.UNDER_REVIEW },
    { label: "Pending E-Sign BA", value: ProfileStatusEnum.PENDING_E_SIGN_BA },
    {
      label: "Pending E-Sign Admin",
      value: ProfileStatusEnum.PENDING_E_SIGN_ADMIN,
    },
    { label: "Active", value: ProfileStatusEnum.ACTIVE },
    { label: "Inactive", value: ProfileStatusEnum.INACTIVE },
    { label: "Rejected", value: ProfileStatusEnum.REJECTED },
  ];

  const handleUpdateStatus = (status: string) => {
    if (status == ProfileStatusEnum.INACTIVE) setIsModalVisible(true);
    const ba = Object.assign(new BusinessAssociate(), {
      ...businessAssociate,
      status,
    });
    setUpdateLoading(true);
    BusinessAssociateService.updateBusinessAssociate(
      ba,
      (ba: BusinessAssociate) => {
        setBusinessAssociate(ba);
        setCurrentBaId(ba.id);
      },
      () => {},
      () => {
        setUpdateLoading(false);
      }
    );
  };

  const handleCreateNotes = () => {
    if (businessAssociate?.id) {
      setNoteFormLoading(true);
      NoteService.createNotes(
        businessAssociate.id,
        "ba",
        Object.assign(new Note(), {
          noteText,
        }),
        (note: Note) => {
          handleFetchBANotes();
        },
        () => {},
        () => {
          setNoteText(undefined);
          setNoteFormLoading(false);
        }
      );
    }
  };

  const baDetailTabs = [
    {
      title: "Dashboard",
      component: businessAssociate ? (
        <BADashboard
          businessAssociate={businessAssociate}
          onUpdateSuccess={handleUpdateSuccess}
        />
      ) : undefined,
    },
    {
      title: "Personal Details",
      component: businessAssociate ? (
        <BAPersonalDetails
          businessAssociate={businessAssociate}
          onUpdateSuccess={handleUpdateSuccess}
        />
      ) : undefined,
    },
    {
      title: "Additional Info",
      component: businessAssociate ? (
        <BAAdditionalInfo
          businessAssociate={businessAssociate}
          onUpdateSuccess={handleUpdateSuccess}
        />
      ) : undefined,
    },
    {
      title: "Documents",
      component: businessAssociate ? (
        <BADocuments
          businessAssociate={businessAssociate}
          onUpdateSuccess={handleUpdateSuccess}
        />
      ) : undefined,
    },
    { title: "Customers", component: <ComingSoon /> },
    {
      title: "Commission",
      component: businessAssociate ? (
        <BACommissionList businessAssociate={businessAssociate} />
      ) : undefined,
    },
    {
      title: "Activity Log",
      component: businessAssociate ? (
        <BAActivityLog businessAssociate={businessAssociate} />
      ) : undefined,
    },
    {
      title: "3rd Party Verifications",
      component: businessAssociate ? (
        <ThirdPartyVerifications businessAssociate={businessAssociate} />
      ) : undefined,
    },
  ];

  const contactDetails = [
    {
      title: "Mobile",
      value: businessAssociate?.mobile,
      image: phoneImage,
    },
    {
      title: "Email",
      value: businessAssociate?.email,
      image: emailImage,
    },

    {
      title: "Organization",
      value: businessAssociate?.organization,
      image: organizationImage,
    },
    {
      title: "Assigned RM",
      value:
        businessAssociate?.relationshipManager?.firstName +
        " " +
        businessAssociate?.relationshipManager?.lastName,
      image: contactImage,
    },
  ];

  useEffect(() => {
    const handleLoginActivity = () => {
      if (businessAssociate?.lastLoginTime) {
        const lastLoginTime = moment(businessAssociate?.lastLoginTime);
        setLoginTimeDifference(moment().diff(lastLoginTime, "week"));
      }
    };
    handleLoginActivity();
  }, [businessAssociate]);
  return (
    <div className="ba-detail">
      <Row>
        {businessAssociate?.firstName && (
          <Col span={12}>
            <h1 className="text-primary">
              <LeftOutlined
                className="mr-2 ba-list__back-icon"
                onClick={handleGoBack}
              />
              {businessAssociate?.firstName +
                " " +
                businessAssociate?.lastName ?? ""}
            </h1>
          </Col>
        )}
        {loading ? (
          ""
        ) : (
          <Col span={11} className="text-right mt-1 ">
            <Button type="primary" onClick={handleForm}>
              Reset Password
            </Button>
            <Drawer
              width="35vw"
              onClose={handleForm}
              visible={form}
              destroyOnClose
              footer={null}
            >
              <ChangePassword baId={params.baId} onUpdate={handleForm} />
            </Drawer>
          </Col>
        )}
      </Row>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Row gutter={[20, 20]}>
          <Col span={17}>
            <Tabs
              defaultActiveKey="1"
              size="small"
              style={{ marginBottom: 32 }}
              onChange={handleChangeTab}
            >
              {baDetailTabs.map(({ title, component }, i) => (
                <TabPane tab={title} key={i}>
                  {component}
                </TabPane>
              ))}
            </Tabs>
          </Col>
          <Col span={7}>
            <div className="ba-detail__profile-card">
              <img
                className="ba-detail__profile-pic"
                src={
                  businessAssociate?.profilePic?.attachmentUrl ??
                  avatarPlaceholder
                }
                alt="Profile picture"
              />
              <h3 className="text-primary mb-0">
                {businessAssociate?.firstName} {businessAssociate?.lastName}
              </h3>
              <p className="ba_uia">{businessAssociate?.uid}</p>
              <div className="mt-2">
                <Row
                  gutter={[30, 30]}
                  justify="start"
                  className="ba-details__status"
                >
                  <Col span={5}>
                    {" "}
                    <Select
                      loading={updateLoading}
                      className={`ba-detail__status-select  ${businessAssociate?.status}`}
                      options={profileStatuses}
                      dropdownMatchSelectWidth={false}
                      defaultValue={businessAssociate?.status}
                      style={{ width: 120 }}
                      onChange={handleUpdateStatus}
                    />
                  </Col>{" "}
                  <Col span={4} />
                  <Col span={5} className="ml-4">
                    {" "}
                    <Select
                      className="ba-detail__status-select new"
                      options={locationOption?.length > 0 ? locationOption : []}
                      dropdownMatchSelectWidth={false}
                      value={seletedLocality}
                      placeholder={!seletedLocality && "Address Unavailable"}
                      style={{ width: 150 }}
                      onChange={(value) => {
                        setSelectedLocality(value);
                      }}
                    />
                  </Col>
                  {businessAssociate?.status === "active" && (
                    <span className="text-italic text-active active-message">
                      This BA is active and can able to access in this
                      application
                    </span>
                  )}
                </Row>
              </div>
              <Row className="mt-3">
                {contactDetails?.map((details) => {
                  return (
                    <Col span={5}>
                      <Tooltip
                        color={"#F3B926"}
                        title={
                          <div className="text-primary-black ba-details__tooltip">
                            <div>{details?.title}</div>{" "}
                            <h4>{details?.value}</h4>
                          </div>
                        }
                      >
                        <img
                          src={details?.image}
                          className="contact-image"
                          alt={details?.title}
                        />
                      </Tooltip>
                    </Col>
                  );
                })}
              </Row>
              {loginTimeDifference && loginTimeDifference > 1 ? (
                <div className="mt-5 mb-5 alert-image">
                  <img src={alertIcon} alt="alert" />{" "}
                  <span className="text-danger">
                    {`${loginTimeDifference} Weeks of no activity`}
                  </span>
                </div>
              ) : null}
              <div className="ba-detail__notes text-left mt-3">
                <h3 className="text-primary">
                  Notes
                  <Popover
                    content={
                      <div className="ba-detail__popover-content">
                        <p className="text-bold text-primary">Notes</p>
                        <Input.TextArea
                          style={{ width: "300px" }}
                          value={noteText}
                          onChange={(e) => setNoteText(e.target.value)}
                        />
                        <div className="mt-3 text-right">
                          <Button
                            type="primary"
                            disabled={!noteText}
                            loading={noteFormLoading}
                            onClick={handleCreateNotes}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    }
                    trigger="click"
                  >
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      className="rounded-btn ml-2"
                    />
                  </Popover>
                </h3>
                {notes.map((note, i) => (
                  <div key={i} className="ba-detail__note">
                    <Row gutter={[20, 20]}>
                      <Col span={6}>
                        <img src={avatarPlaceholder} alt="User" />
                      </Col>
                      <Col span={18}>
                        <div className="ba-detail__note-label">
                          {note?.notedBy?.firstName} {note?.notedBy?.lastName}
                        </div>
                        <div className="ba-detail__note-value">
                          {moment(note.createdAt).format("DD/MM/YYYY hh:mm a")}
                        </div>
                      </Col>
                      <Col span={24}>{note.noteText}</Col>
                    </Row>
                  </div>
                ))}
              </div>
            </div>
          </Col>
          {isModalVisible ? (
            <IndivualBAInactiveModal
              isModalVisible={isModalVisible}
              currentBaId={currentBaId || 0}
              closeHandler={() => setIsModalVisible(false)}
            />
          ) : (
            ""
          )}
        </Row>
      )}
    </div>
  );
}

export default BADetail;
