import axiosInstance from "../../interceptor/axiosInstance";
import {
  MyLearning,
  MyLearningModuleForm,
  MyLearningModuleTypes,
  MyLearningModuleType,
} from "../../models/MyLearning/myLearning.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import React, { useState } from "react";
import { PaginationDetails } from "../../models/Pagination/pagination.model";

const MyLearningService = () => {
  const [loading, setloading] = useState(false);
  const [learningData, setLearningData] = useState<MyLearning[]>([]);

  const [learningModuleType, setLearningModuleType] = useState<
    MyLearningModuleType[]
  >([]);

  const fetchLearningData = async (
    currentPage: number,
    onSuccess: Function,
    onFinal: Function
  ) => {
    setloading(true);
    try {
      const response = await axiosInstance.get(ApiRoutes.MY_LEARNING, {
        params: {
          page: currentPage,
        },
      });
      if (response) {
        const data = deserialize(
          MyLearning,
          response.data["learning_modules"] as MyLearning[]
        );
        const myLearningPagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );
        setLearningData(data);
        onSuccess(myLearningPagination);
        return;
      }
    } catch (error) {
    } finally {
      setloading(false);
      onFinal();
    }
  };

  const addNewCourse = async (
    addCourseData: MyLearningModuleForm,
    onSuccess: () => void
  ) => {
    setloading(true);
    try {
      const courseData = serialize(MyLearningModuleForm, addCourseData);

      const response = await axiosInstance.post(ApiRoutes.ADD_COURSE, {
        learning_module: courseData,
      });

      if (response.data) {
        Notification({
          message: "Learning Module Updated!",
          description: response.data?.message,
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      }
    } catch (error) {
    } finally {
      setloading(false);
    }
  };
  const updateLearningModule = async (
    id: number,
    addCourseData: MyLearningModuleForm,
    onSuccess: () => void
  ) => {
    setloading(true);
    try {
      const courseData = serialize(MyLearningModuleForm, addCourseData);

      const response = await axiosInstance.put(
        ApiRoutes.ADD_COURSE + `/${id}`,
        {
          learning_module: courseData,
        }
      );

      if (response.data) {
        Notification({
          message: "Learning Module Updated!",
          description: response.data?.message,
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      }
    } catch (error) {
    } finally {
      setloading(false);
    }
  };

  const fetchLearningModuleTypes = async (onSuccess: Function) => {
    setloading(true);
    try {
      const response = await axiosInstance.get(
        ApiRoutes.MY_LEARNING_MODLUE_TYPES
      );
      if (response.data) {
        const moduleTypes = deserialize(
          MyLearningModuleType,
          response.data["learning_module_types"]
        );

        onSuccess(moduleTypes);

        setLearningModuleType(response?.data);

        return;
      }
    } catch (error) {
    } finally {
      setloading(false);
    }
  };

  const editMyLearningModule = async (
    updatedModuleData: MyLearning,
    moduleId: number,
    onSuccess: () => void
  ) => {
    setloading(true);
    try {
      const courseData = serialize(MyLearning, updatedModuleData);

      const response = await axiosInstance.put(
        ApiRoutes.EDIT_COURSE.replace(":moduleId", moduleId.toString()),
        courseData
      );

      if (response.data) {
        Notification({
          message: "Status Updated!",
          description: response.data?.message,
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      }
    } catch (error) {
    } finally {
      setloading(false);
    }
  };

  return {
    learningData,
    fetchLearningData,
    addNewCourse,
    loading,
    fetchLearningModuleTypes,
    learningModuleType,
    editMyLearningModule,
    updateLearningModule,
  };
};

export default MyLearningService;
