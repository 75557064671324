import React from "react";
import commingSoonIcon from "../../assets/images/comingSoon.png";
import "./settlements.scss";

interface Settlements {}

function Settlements(props: Settlements) {
  return (
    <div className="settlements">
      <div className="settlements__banner">
        <img src={commingSoonIcon} alt="comming soon.." />
      </div>
    </div>
  );
}

export default Settlements;
