import { Button, Col, Row, Tabs } from "antd";
import React, { FC, useEffect } from "react";
import "./customersFilter.scss";
import DetailList from "./DetailList";
import CustomerFilterService from "../../../services/CustomerFilter/customerFilter.service";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { BaFilterParams } from "../../../models/BAFilterParams/baFilterParams.model";
import useFilters from "../../../shared/hooks/useFilters";

const { TabPane } = Tabs;

interface CustomersFilterProps {
  applyFilter: () => void;
  resetFilter: () => void;
  onSubmit: (filterParams: BaFilterParams, type?: string) => void;
}

const CustomersFilter: FC<CustomersFilterProps> = (props) => {
  const { applyFilter, resetFilter, onSubmit } = props;
  const { getParams, setParams } = useFilters();

  const [activeKey, setActiveKey] = useState("0");

  const [type, setType] = useState("");

  const [filterParams, setFilterParams] = useState<BaFilterParams>(
    new BaFilterParams()
  );

  const handleUpdateFilters = (filterParams: BaFilterParams, type?: string) => {
    setFilterParams(filterParams);
    setType(type ?? "");
    if (type == "reset") onSubmit(filterParams, type);
  };
  useEffect(() => {
    setFilterParams(getParams());
  }, []);
  const handleApplyFilter = () => {
    onSubmit(filterParams, type);
  };
  const filterTabs = [
    {
      title: "Customer Name",
      component: (
        <DetailList
          resetFilter={resetFilter}
          applyFilter={handleApplyFilter}
          activeKey={activeKey}
          index="0"
          service={CustomerFilterService.fetchCustomerNames}
          searchTitle={"Search Customer Name"}
          filterParams={filterParams}
          onUpdate={handleUpdateFilters}
          filterType={"names"}
        />
      ),
    },
    {
      title: "Business Associate",
      component: (
        <DetailList
          resetFilter={resetFilter}
          applyFilter={handleApplyFilter}
          activeKey={activeKey}
          index="1"
          service={CustomerFilterService.fetchCustomerBAS}
          searchTitle={"Search Business Associate"}
          filterParams={filterParams}
          onUpdate={handleUpdateFilters}
          filterType={"baIds"}
        />
      ),
    },
    {
      title: "City",
      component: (
        <DetailList
          resetFilter={resetFilter}
          applyFilter={handleApplyFilter}
          activeKey={activeKey}
          index="2"
          service={CustomerFilterService.fetchCustomerCities}
          searchTitle={"Search City Name"}
          filterParams={filterParams}
          onUpdate={handleUpdateFilters}
          filterType={"cityIds"}
        />
      ),
    },
    {
      title: "State",
      component: (
        <DetailList
          resetFilter={resetFilter}
          applyFilter={handleApplyFilter}
          activeKey={activeKey}
          index="3"
          service={CustomerFilterService.fetchCustomerStates}
          searchTitle={"Search State Name"}
          filterParams={filterParams}
          onUpdate={handleUpdateFilters}
          filterType={"stateIds"}
        />
      ),
    },
    {
      title: "Relationship Manager",
      component: (
        <DetailList
          resetFilter={resetFilter}
          applyFilter={handleApplyFilter}
          activeKey={activeKey}
          index="4"
          service={CustomerFilterService.fetchRMs}
          searchTitle={"Search RM"}
          filterParams={filterParams}
          onUpdate={handleUpdateFilters}
          filterType={"rmIds"}
        />
      ),
    },
  ];

  return (
    <div className="customers-filter">
      <h2 className="text-primary ml-2 mb-2">Filters</h2>
      <Tabs
        tabPosition="left"
        activeKey={activeKey}
        onChange={(activeKey) => {
          setActiveKey(activeKey);
        }}
      >
        {filterTabs.map((tab, i) => (
          <TabPane tab={tab.title} key={i}>
            {tab.component}
          </TabPane>
        ))}
      </Tabs>

      {/* <Row justify="end" className="mt-5">
        <Col>
          <Button onClick={resetFilter}>Reset</Button>
        </Col>
        <Col>
          <Button type="primary" className="ml-2" onClick={applyFilter}>
            Apply
          </Button>
        </Col>
      </Row> */}
    </div>
  );
};

export default CustomersFilter;
