import React from "react";
import "./OrderBajajProductDetails.scss";
import { Order } from "../../../../models/Order/order.model";
import { Col, Row, Typography, Space } from "antd";
const { Title, Text } = Typography;

interface OrderBajajProductDetailsProps {
  order: Order;
}

function OrderBajajProductDetails({ order }: OrderBajajProductDetailsProps) {
  return (
    <div className="bajaj-order-product-details">
      <div className="bajaj-order-product-details__depositor_details">
        <Title level={5} className="text-primary">
          Depositor Details
        </Title>
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <div className="bajaj-order-product-details__depositor_details__label primary-grey ">
              Depositor Name
            </div>
            <div className="bajaj-order-product-details__depositor_details__value">
              {order?.customerName}
            </div>
          </Col>
          <Col span={24}>
            <div className="bajaj-order-product-details__depositor_details__label primary-grey ">
              FD Depositor Type
            </div>
            <div className="bajaj-order-product-details__depositor_details__value ">
              {order?.orderType}
            </div>
          </Col>
          <Col span={24}>
            <div className="bajaj-order-product-details__depositor_details__label primary-grey ">
              DOB
            </div>
            <div className="bajaj-order-product-details__depositor_details__value ">
              {order?.orderDetails?.KYCDetails?.dob || "--"}
            </div>
          </Col>

          <Col span={24}>
            <div className="bajaj-order-product-details__depositor_details__label primary-grey ">
              Gender
            </div>
            <div className="bajaj-order-product-details__depositor_details__value ">
              {order?.orderDetails?.KYCDetails?.gender || "--"}
            </div>
          </Col>
          <Col span={24}>
            <div className="bajaj-order-product-details__depositor_details__label primary-grey ">
              PAN
            </div>
            <div className="bajaj-order-product-details__depositor_details__value ">
              {order?.orderDetails?.KYCDetails?.pan || "--"}
            </div>
          </Col>
          <Col span={24}>
            <div className="bajaj-order-product-details__depositor_details__label primary-grey ">
              Address
            </div>
            <div className="bajaj-order-product-details__depositor_details__value ">
              {order?.orderDetails?.KYCDetails?.address || "--"}
            </div>
          </Col>
        </Row>
      </div>
      <hr className="mt-5 mb-5"></hr>
      <div className="bajaj-order-product-details__deposit_details">
        <Title level={5} className="text-primary">
          Deposit Details
        </Title>
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <div className="bajaj-order-product-details__Deposit_details__label primary-grey ">
              Deposit Amount
            </div>
            <div className="bajaj-order-product-details__Deposit_details__value">
              {`₹` + order?.orderDetails?.amount || "--"}
            </div>
          </Col>
          <Col span={24}>
            <div className="bajaj-order-product-details__Deposit_details__label primary-grey ">
              Interest Rate
            </div>
            <div className="bajaj-order-product-details__Deposit_details__value ">
              {order?.orderDetails?.interestRate || "--"}
            </div>
          </Col>
          <Col span={24}>
            <div className="bajaj-order-product-details__Deposit_details__label primary-grey ">
              Tenure
            </div>
            <div className="bajaj-order-product-details__Deposit_details__value ">
              {order?.orderDetails?.tenure + ` Months` || "--"}
            </div>
          </Col>
          <Col span={24}>
            <div className="bajaj-order-product-details__Deposit_details__label primary-grey ">
              Interest Payout Frequency
            </div>
            <div className="bajaj-order-product-details__Deposit_details__value ">
              {order?.orderDetails?.interestPayoutFrequency || "--"}
            </div>
          </Col>
        </Row>
      </div>
      <hr className=" mt-5 mb-5"></hr>
      <div className="bajaj-order-product-details__nominee_details">
        <Title level={5} className="text-primary">
          Nominee Details
        </Title>
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <div className="bajaj-order-product-details__nominee_details__label primary-grey ">
              Nominee Name
            </div>
            <div className="bajaj-order-product-details__nominee_details__value">
              {order?.orderDetails?.NomineeDetails?.nomineeName || "--"}
            </div>
          </Col>
          <Col span={24}>
            <div className="bajaj-order-product-details__nominee_details__label primary-grey ">
              DOB
            </div>
            <div className="bajaj-order-product-details__nominee_details__value">
              {order?.orderDetails?.NomineeDetails?.nomineeDob || "--"}
            </div>
          </Col>
          <Col span={24}>
            <div className="bajaj-order-product-details__nominee_details__label primary-grey ">
              Address
            </div>
            <div className="bajaj-order-product-details__nominee_details__value ">
              {order?.orderDetails?.NomineeDetails?.nomineeAddressLine1 || "--"}
            </div>
          </Col>
          <Col span={24}>
            <div className="bajaj-order-product-details__nominee_details__label primary-grey ">
              FD receipt
            </div>
            <div className="bajaj-order-product-details__nominee_details__value ">
              {order?.orderDetails?.NomineeDetails?.fdReceipt || "--"}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default OrderBajajProductDetails;
