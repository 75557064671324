import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Row, Col, Button, Upload } from "antd";
import InputField from "../../shared/components/InputField";
import "./changePassword.scss";
import { passwordValidation } from "./passwordValidation";
import { BusinessAssociateService } from "../../services/BusinessAssociateService/businessAssociate.service";
import { BusinessAssociatePassword } from "../../models/BusinessAssociate/businessAssociate.model";

interface ChangePasswordProps {
  onUpdate?: () => void;
  baId?: string;
}

function ChangePassword(props: ChangePasswordProps) {
  const { onUpdate, baId } = props;
  const [formLoading, setFormLoading] = useState(false);

  const [formValues, setFormValues] = useState<BusinessAssociatePassword>(
    new BusinessAssociatePassword()
  );
  const [form, setForm] = useState(false);

  const handleSubmit = (values: BusinessAssociatePassword) => {
    setFormLoading(true);
    BusinessAssociateService.updatePassword(
      baId ?? "",
      values?.confirmPassword ?? "",
      () => {
        onUpdate && onUpdate();
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  return (
    <div className="change-password-form">
      <div className="change-password__heading">
        <h2 className="text-primary">Change Password</h2>
      </div>
      <div className="change-password__form">
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={passwordValidation}
        >
          {({ values, errors, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <InputField
                      title="New Password"
                      type="password"
                      name="newPassword"
                      placeholder="Enter Password"
                      required
                    />
                  </Col>
                  <Col span={24}>
                    <InputField
                      title="Confirm Password"
                      type="password"
                      name="confirmPassword"
                      placeholder="Enter Password"
                      required
                    />
                  </Col>

                  <Col span={24} className="text-right">
                    <Button type="text" onClick={onUpdate}>
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={formLoading}
                      disabled={!isValid || formLoading || !dirty}
                    >
                      Save
                    </Button>
                  </Col>
                  {/* <Col span={5} className="text-right mt-4 "></Col> */}
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default ChangePassword;
