import * as Yup from "yup";
const regMatch =
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

export const bannerFormValidation = Yup.object().shape({
  viewableUserType: Yup.string().required("User Type is required!"),
  bannerImageId: Yup.string().required("Banner Image is required"),
  provinceIds: Yup.string().required("State is required"),
  cityIds: Yup.string().required("City is required"),

  redirectUrl: Yup.string()
    .matches(regMatch, "Website should be a valid URL")
    .required("Please enter URL"),
});
