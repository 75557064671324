import { alias, primitive, serializable } from "serializr";

export class EmploymentDetail {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("company_name", primitive()))
  companyName?: string;

  @serializable(alias("company_address", primitive()))
  companyAddress?: string;

  @serializable(alias("job_type", primitive()))
  jobType?: string;

  @serializable(alias("user_id", primitive()))
  userId?: string;

  @serializable(alias("user_type", primitive()))
  userType?: string;

  @serializable(alias("business_name", primitive()))
  businessName?: string;

  @serializable(alias("business_nature", primitive()))
  businessNature?: string;

  @serializable(alias("industry_type", primitive()))
  industryType?: string;

  @serializable(alias("designation", primitive()))
  designation?: string;
  @serializable(alias("years_of_service", primitive()))
  yearsOfService?: string;
  @serializable(alias("gross_monthly_income", primitive()))
  grossMonthlyIncome?: string;
  @serializable(alias("monthly_business_expense", primitive()))
  monthlyBusinessExpense?: string;
}
