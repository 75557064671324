import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { Note } from "../../models/Note/note.model";
import { deserialize, serialize } from "serializr";

export default class NoteService {
  static fetchNotes(
    id: number,
    type: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL =
      type === "ba"
        ? ApiRoutes.BA_NOTES.replace(":businessAssociateId", id.toString())
        : ApiRoutes.CUSTOMER_NOTES.replace(":customerId", id.toString());
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const notes = deserialize(Note, response.data["notes"]);
        onSuccess(notes);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createNotes(
    id: number,
    type: String,
    note: Note,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const noteJSON = serialize(note);
    const API_URL =
      type === "ba"
        ? ApiRoutes.BA_NOTES.replace(":businessAssociateId", id.toString())
        : ApiRoutes.CUSTOMER_NOTES.replace(":customerId", id.toString());

    axiosInstance
      .post(API_URL, noteJSON)
      .then((response) => {
        const notes = deserialize(Note, response.data["notes"]);
        onSuccess(notes);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
