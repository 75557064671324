import { serializable, alias, object, list, primitive } from "serializr";

export class ProductTypeMeta {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("category_name", primitive()))
  categoryName?: string;

  @serializable(alias("type_name", primitive()))
  typeName?: string;

  @serializable(alias("description", primitive()))
  description?: string;
}
