import moment from "moment";
import React from "react";

const receivedNotificationColumn = [
  {
    title: "RECEIVED FROM",
    dataIndex: "receivedFromName",
    key: "receivedFromName",
    render: (name: string) => (
      <span className="text-capitalize">{name ?? ""}</span>
    ),
  },
  {
    title: "DATE",
    dataIndex: "receivedDate",
    key: "receivedDate",
    render: (date: string) => moment(date).format("DD/MM/YYYY"),
  },
  {
    title: "TIME",
    dataIndex: "receivedTime",
    key: "receivedTime",
    render: (time: string) => moment(time).format("hh:mm a"),
  },

  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    render: (status: string) => (
      <span className={`${status} text-capitalize`}>{status}</span>
    ),
  },
  {
    title: "NOTIFICATION CONTENT",
    dataIndex: "notificationContent",
    key: "notificationContent",
  },
];

export { receivedNotificationColumn };
