import React, { useEffect, useState } from "react";
import "./assignBrokerageGroup.scss";
import BrokerageGroupService from "../../../services/BrokerageGroup/brokerageGroup.service";
import { BrokerageGroup } from "../../../models/BrokerageGroup/brokerageGroup.model";
import AppLoader from "../../../shared/components/AppLoader";
import { Row, Col, Radio, Button } from "antd";
import { BusinessAssociateService } from "../../../services/BusinessAssociateService/businessAssociate.service";

interface AssignBrokerageGroupProps {
  selectedBAIds: number[];
  onSuccess: () => void;
}

function AssignBrokerageGroup({
  selectedBAIds,
  onSuccess,
}: AssignBrokerageGroupProps) {
  const [brokerageGroups, setBrokerageGroups] = useState<BrokerageGroup[]>([]);

  const [selectedBrokerageGroup, setSelectedBrokerageGroup] =
    useState<BrokerageGroup>();

  const [loading, setLoading] = useState(false);

  const [formLoading, setFormLoading] = useState(false);

  const handleSubmit = () => {
    if (selectedBrokerageGroup?.id) {
      setFormLoading(true);
      BusinessAssociateService.assignBrokerageGroup(
        selectedBAIds,
        selectedBrokerageGroup.id,
        () => {
          onSuccess();
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    setLoading(true);
    BrokerageGroupService.fetchBrokerageGroups(
      (brokerageGroups: BrokerageGroup[]) => {
        setBrokerageGroups(brokerageGroups);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <div className="assign-brokerage-group">
      <h2 className="text-primary">Assign Brokerage Group</h2>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          <Row>
            <Col span={20}>
              <Radio.Group
                className="assign-rm__radio-group"
                onChange={(event) => {
                  const brokerageGroup = brokerageGroups.find(
                    (brokerageGroup) => brokerageGroup.id === event.target.value
                  );
                  setSelectedBrokerageGroup(brokerageGroup);
                }}
              >
                {brokerageGroups.map((brokerageGroup, i) => (
                  <Radio value={brokerageGroup?.id} key={brokerageGroup?.id}>
                    {brokerageGroup?.name}
                  </Radio>
                ))}
              </Radio.Group>
            </Col>
            {selectedBrokerageGroup && (
              <Col span={12} className="assign-brokerage-group__show-wrapper">
                <div className="text-bold">{selectedBrokerageGroup?.name}</div>
                {selectedBrokerageGroup?.brokerageWeights?.map(
                  (brokerageWeight, i) => (
                    <div key={i}>
                      <div className="text-primary text-bold">
                        {brokerageWeight?.typeName}
                      </div>
                      <ul className="assign-brokerage-group__ul">
                        {brokerageWeight?.productWeights?.map(
                          (productWeight, j) => (
                            <li key={j}>
                              <span>{productWeight?.productName}</span>
                              <span className="ml-2 assign-brokerage-group__percentage">
                                BA Brokerage {productWeight?.weight}
                              </span>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )
                )}
              </Col>
            )}
          </Row>
          <div className="mt-4 text-right">
            <Button
              type="primary"
              loading={formLoading}
              onClick={handleSubmit}
              disabled={!selectedBrokerageGroup || formLoading}
            >
              Assign
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AssignBrokerageGroup;
