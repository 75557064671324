import React, { useState } from "react";
import { Formik, Form } from "formik";
import InputField from "../../../shared/components/InputField";
import { loginFormValidation } from "./loginFormValidation";
import { Button } from "antd";
import { withRouter } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import "./loginForm.scss";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import logo from "../../../assets/images/logoDark.png";
import AuthService from "../../../services/AuthService/auth.service";
import { Employee } from "../../../models/Employee/employee.model";

const LoginForm = () => {
  const [formValues, setFormValues] = useState(new Employee());

  const [formLoading, setFormLoading] = useState(false);

  const history = useHistory();

  const handleSubmit = (values: Employee) => {
    const employee = Object.assign(new Employee(), values);
    setFormLoading(true);
    AuthService.loginUser(
      employee,
      (employee: Employee) => {
        history.push(AppRoutes.HOME);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  return (
    <div className="login-form">
      <div className="login-form__logo ">
        <img src={logo} alt="Logo" />
      </div>
      <h1 className="mt-5">Login</h1>
      <Formik
        initialValues={formValues}
        onSubmit={handleSubmit}
        validationSchema={loginFormValidation}
      >
        {({ values, errors, isValid, dirty, setFieldValue }) => {
          return (
            <Form>
              <InputField
                title="Email/Phone"
                type="text"
                name="email"
                placeholder="Enter email"
              />
              <InputField
                title="Password"
                type="password"
                name="password"
                placeholder="Enter password"
              />

              <div className="login-form__forgot-password text-right">
                <Link to={AppRoutes.FORGOT_PASSWORD}>Forgot Password?</Link>
              </div>
              <Button
                className="mt-3 text-bold"
                type="primary"
                htmlType="submit"
                disabled={!isValid || !dirty || formLoading}
                loading={formLoading}
              >
                Log in
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default withRouter(LoginForm);
