import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize, serialize } from "serializr";
import { ProductRequest } from "../../models/ProductRequest/productRequest.model";
import { ProductRequestUpdate } from "../../models/ProductRequestUpdate/productRequestUpdate.model";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";

export default class ProductRequestService {
  static fetchProductRequests(
    searchText: string = "",
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.PRODUCT_REQUESTS, {
        params: {
          search_text: searchText,
        },
      })
      .then((response) => {
        const productRequests = deserialize(
          ProductRequest,
          response.data["product_requests"]
        );
        onSuccess(productRequests);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showProductRequest(
    productRequestId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.PRODUCT_REQUESTS + "/" + productRequestId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const productRequests = deserialize(
          ProductRequest,
          response.data["product_request"]
        );
        onSuccess(productRequests);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateProductRequest(
    productRequest: ProductRequest,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.PRODUCT_REQUESTS + "/" + productRequest.id;
    const productRequestJSON = {
      product_request: serialize(productRequest),
    };
    axiosInstance
      .put(API_URL, productRequestJSON)
      .then((response) => {
        Notification({
          message: "Product Request updated",
          type: NotificationTypes.SUCCESS,
        });
        const productRequests = deserialize(
          ProductRequest,
          response.data["product_request"]
        );
        onSuccess(productRequests);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchProductRequestUpdates(
    productRequestId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.PRODUCT_REQUEST_UPDATES, {
        params: {
          product_request_id: productRequestId,
        },
      })
      .then((response) => {
        const productRequestUpdates = deserialize(
          ProductRequestUpdate,
          response.data["product_request_updates"]
        );
        onSuccess(productRequestUpdates);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createProductRequestUpdate(
    productRequestId: number,
    commentText: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .post(ApiRoutes.PRODUCT_REQUEST_UPDATES, {
        product_request_id: productRequestId,
        update_text: commentText,
      })
      .then((response) => {
        Notification({
          message: "Comment added",
          type: NotificationTypes.SUCCESS,
        });
        const productRequestUpdate = deserialize(
          ProductRequestUpdate,
          response.data["product_request_update"]
        );
        onSuccess(productRequestUpdate);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static downloadProductRequestsLogs(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.DOWNLOAD_PRODUCT_REQUESTS_LOGS, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        const date = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
        link.setAttribute("download", `AuthBridge_(${date}).log`);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }
}
