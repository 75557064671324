import { Drawer } from "antd";
import React, { FC, Fragment } from "react";
import { useState } from "react";
import { ReactElement } from "react";
import "./filter.scss";

interface ModalController {
  openModal: () => void;
  closeModal: () => void;
}

interface FilterProps {
  renderTrigger: (modalController: ModalController) => ReactElement;
  renderModalContent: (modalController: ModalController) => ReactElement;
}

const Filter: FC<FilterProps> = (props) => {
  const { renderTrigger, renderModalContent } = props;

  const [visibility, setVisibility] = useState(false);

  const handleOpenModal = () => setVisibility(true);

  const handleCloseModal = () => setVisibility(false);

  const modalControllerProps = {
    openModal: handleOpenModal,
    closeModal: handleCloseModal,
  };

  return (
    <Fragment>
      {renderTrigger(modalControllerProps)}
      <Drawer
        visible={visibility}
        onClose={handleCloseModal}
        destroyOnClose
        width="65vw"
      >
        {renderModalContent(modalControllerProps)}
      </Drawer>
    </Fragment>
  );
};

export default Filter;
