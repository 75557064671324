import React, { useState } from "react";
import "./baForm.scss";
import { Form, Formik } from "formik";
import { Button, Col, Row } from "antd";
import InputField from "../../../shared/components/InputField";
import { baFormValidation } from "./baFormValidation";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import { BusinessAssociateService } from "../../../services/BusinessAssociateService/businessAssociate.service";

interface BAFormProps {
  onSuccess: (ba: BusinessAssociate) => void;
}

function BAForm({ onSuccess }: BAFormProps) {
  const [formValues, setFormValues] = useState(new BusinessAssociate());

  const [formLoading, setFormLoading] = useState(false);

  const handleSubmit = (values: BusinessAssociate) => {
    const ba = Object.assign(new BusinessAssociate(), values);
    setFormLoading(true);
    BusinessAssociateService.inviteBusinessAssociate(
      ba,
      (ba: BusinessAssociate) => {
        onSuccess(ba);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  return (
    <div className="ba-form">
      <h2 className="text-primary">New Business Associate</h2>
      <div className="ba-form__form">
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={baFormValidation}
        >
          {({ values, errors, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <Row gutter={[20, 0]}>
                  <Col span={24}>
                    <InputField
                      title="First name"
                      type="text"
                      name="firstName"
                      placeholder="Enter First name"
                      required
                    />
                  </Col>
                  <Col span={24}>
                    <InputField
                      title="Last name"
                      type="text"
                      name="lastName"
                      placeholder="Enter Last name"
                      required
                    />
                  </Col>
                  <Col span={24}>
                    <InputField
                      title="Email"
                      type="email"
                      name="email"
                      placeholder="Enter email"
                      required
                    />
                  </Col>
                  <Col span={24}>
                    <InputField
                      title="Mobile"
                      type="text"
                      name="mobile"
                      placeholder="Enter Mobile"
                      required
                    />
                  </Col>
                  <Col span={24} className="text-right mt-4">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={formLoading}
                      disabled={!isValid || formLoading}
                    >
                      Send Invite
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default BAForm;
