import { serializable, alias, object, list, primitive } from "serializr";
import { FileAttachment } from "../FileAttachment/fileAttachment.model";

export class BankDetail {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("statement_id", primitive()))
  statementId?: number;

  @serializable(alias("cancelled_cheque_id", primitive()))
  cancelledChequeId?: number;

  @serializable(alias("account_number", primitive()))
  accountNumber?: string;

  @serializable(alias("bank_name", primitive()))
  bankName?: string;

  @serializable(alias("branch_name", primitive()))
  branchName?: string;

  @serializable(alias("account_holder_name", primitive()))
  accountHolderName?: string;

  @serializable(alias("ifsc_code", primitive()))
  ifscCode?: string;

  @serializable(alias("statement", object(FileAttachment)))
  statement?: FileAttachment;

  @serializable(alias("cancelled_cheque", object(FileAttachment)))
  cancelledCheque?: FileAttachment;
}
