import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { ServiceProvider } from "../../models/ServiceProvider/serviceProvider.model";
import { deserialize, serialize } from "serializr";
import { Product } from "../../models/Product/product.model";

export default class ServiceProviderService {
  static fetchServiceProviders(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.SERVICE_PROVIDERS)
      .then((response) => {
        const serviceProviders = deserialize(
          ServiceProvider,
          response.data["product_types"]
        );
        onSuccess(serviceProviders);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showServiceProvider(
    serviceProviderId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SERVICE_PROVIDERS + "/" + serviceProviderId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const serviceProvider = deserialize(
          ServiceProvider,
          response.data["product_type"]
        );
        onSuccess(serviceProvider);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchProducts(
    serviceProviderId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.SERVICE_PROVIDER_PRODUCTS, {
        params: {
          service_provider_id: serviceProviderId,
        },
      })
      .then((response) => {
        const products = deserialize(Product, response.data["products"]);
        onSuccess(products);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showProduct(
    productId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.SERVICE_PROVIDER_PRODUCTS + "/" + productId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const product = deserialize(Product, response.data["product"]);
        onSuccess(product);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
