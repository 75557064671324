import React, { useEffect, useState } from "react";
import { Product } from "../../../models/Product/product.model";
import ServiceProviderService from "../../../services/ServiceProvider/serviceProvider.service";
import { ServiceProvider } from "../../../models/ServiceProvider/serviceProvider.model";
import { LeftOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import AppLoader from "../../../shared/components/AppLoader";
import "./serviceProviderProductDetail.scss";

interface ServiceProviderProductDetailProps {
  productId: number;
  serviceProvider?: ServiceProvider;
  onBack: () => void;
}

function ServiceProviderProductDetail({
  productId,
  serviceProvider,
  onBack,
}: ServiceProviderProductDetailProps) {
  const [product, setProduct] = useState<Product>();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    ServiceProviderService.showProduct(
      productId,
      (product: Product) => {
        setProduct(product);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <div className="service-provider-product-detail">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          <h2 className="text-primary">
            <LeftOutlined
              className="mr-2 ba-list__back-icon"
              onClick={onBack}
            />
            {product?.name}
          </h2>
          <Row gutter={[0, 15]}>
            {product?.details?.map((productDetail, i) => (
              <Col span={24} key={i}>
                {productDetail?.type !== "pop-up" && (
                  <div className="service-provider-product-detail__label">
                    {productDetail?.label}
                  </div>
                )}
                <div
                  className={
                    productDetail?.type === "pop-up"
                      ? ""
                      : "service-provider-product-detail__value"
                  }
                >
                  {productDetail?.type === "pop-up" && productDetail?.value ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: productDetail.value.replace(/\n/g, "<br />"),
                      }}
                    />
                  ) : (
                    productDetail?.value ?? "-"
                  )}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
}

export default ServiceProviderProductDetail;
