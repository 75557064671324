import React, { useEffect, useState } from "react";
import "./learningList.scss";
import { MyLearning } from "../../../models/MyLearning/myLearning.model";
import AppLoader from "../../../shared/components/AppLoader";
import {
  Button,
  Input,
  Select,
  Table,
  Tooltip,
  Drawer,
  TablePaginationConfig,
  Popover,
} from "antd";
import { PlusOutlined, PhoneOutlined, MailOutlined } from "@ant-design/icons";
import { generatePath, useHistory, Link } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import MyLearningService from "../../../services/MyLearning/myLearning.service";
import profileStatus from "../../../shared/components/ProfileStatus";
import LearningModuleForm from "../LearningModuleForm";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import moment from "moment";
import { ProfileStatusEnum } from "../../../enums/profileStatus.enum";
import { ContentTypeEnum } from "../../../enums/contentType.enum";
import Modal from "antd/lib/modal/Modal";
import { divide } from "lodash";
interface LearningListProps {}
function LearningList(props: LearningListProps) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [notes, setNotes] = useState("");
  const [editLearning, setEditLearning] = useState<MyLearning>();
  const [myLearningPagination, setMyLearningPagination] =
    useState<PaginationDetails>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { fetchLearningData, learningData, editMyLearningModule } =
    MyLearningService();
  const handleRow = (learning: MyLearning, rowIndex: number | undefined) => {
    return {
      onClick: (event: any) => {
        setEditLearning(learning);
        handleLearningForm();
      },
    };
  };
  const handleShowContent = () => {
    setShowContent(!showContent);
  };

  const handleUpdateStatus = (learning: MyLearning, status: string) => {
    const myLearning = Object.assign(new MyLearning(), {
      ...learning,
      status: status === ProfileStatusEnum.ACTIVE ? true : false,
    });

    editMyLearningModule(myLearning, learning?.id ?? 1, () => {
      handleFetchLearningData();
    });
  };
  const handleFetchLearningData = () => {
    setLoading(true);
    fetchLearningData(
      currentPage,
      (myLearningPagination: PaginationDetails) => {
        setMyLearningPagination(myLearningPagination);
      },
      () => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    handleFetchLearningData();
  }, []);

  useEffect(() => {
    handleFetchLearningData();
  }, [currentPage]);
  const handleTableChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination?.current || 1);
  };

  const profileStatus = [
    { label: "Active", value: ProfileStatusEnum.ACTIVE },
    { label: "Inactive", value: ProfileStatusEnum.INACTIVE },
  ];

  const columns = [
    {
      title: " MODULE NAME",
      dataIndex: "moduleName",
      key: "moduleName",
      render: (text: string, learning: MyLearning, index: number) => {
        return <div>{learning?.moduleName} </div>;
      },
    },
    {
      title: "CONTENT TYPE",
      dataIndex: "contentType",
      key: "contentType",
      render: (contentType: string, learning: MyLearning, index: number) => {
        return contentType != ContentTypeEnum.TEXT_ONLY ? (
          <a
            className="text-capitalize"
            target="_blank"
            href={
              contentType == ContentTypeEnum.VIDEO_URL
                ? learning?.externalLink
                : contentType === ContentTypeEnum.PDF
                ? learning?.pdfContent?.attachmentUrl
                : ""
            }
            onClick={(e) => e.stopPropagation()}
          >
            {contentType ? contentType.split("_").join(" ") : "-"}
          </a>
        ) : (
          <div
            className="text-capitalize"
            onClick={(e) => {
              e.stopPropagation();
              setNotes(learning?.notes ?? "No Content Available");
              handleShowContent();
            }}
          >
            {contentType ? contentType.split("_").join(" ") : "-"}
          </div>
        );
      },
    },
    {
      title: "USER VIEW",
      dataIndex: "userView",
      key: "userView",
      render: (text: string, learning: MyLearning, index: number) => {
        return (
          <div className="text-capitalize">
            {learning?.userView?.split("_").join(" ")}{" "}
          </div>
        );
      },
    },
    {
      title: "CREATED DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (id: string, learning: MyLearning, index: number) => {
        return moment(id).format("DD/MM/YYYY") || "NA";
      },
    },
    {
      title: "CREATED BY",
      dataIndex: "createdByName",
      key: "createdByName",
      render: (id: string, learning: MyLearning, index: number) => {
        return learning?.createdByName || "NA";
      },
    },

    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: string, learning: MyLearning, index: number) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Select
              className={`my-learning-list__status-select ${
                learning?.status ? "active" : "inactive"
              } `}
              options={profileStatus}
              dropdownMatchSelectWidth={false}
              value={learning?.status ? "active" : "inactive"}
              style={{ width: 140 }}
              onChange={(value) => {
                handleUpdateStatus(learning, value);
              }}
            />
          </div>
        );
      },
    },
  ];

  const [showLearningForm, setshowLearningForm] = useState(false);

  const handleLearningForm = () => {
    setshowLearningForm(!showLearningForm);
  };

  const handleFormClose = () => {
    setshowLearningForm(false);
    setEditLearning(undefined);
  };

  const onEdit = () => {
    setshowLearningForm(!showLearningForm);
  };
  return (
    <div className="my-learning-list">
      <h1 className="text-primary">
        <span>My Learning</span>

        <Tooltip title="Add">
          <Button
            onClick={handleLearningForm}
            type="primary"
            icon={<PlusOutlined />}
            className="rounded-btn ml-2"
          />
        </Tooltip>
      </h1>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          <Drawer
            width="65vw"
            onClose={handleFormClose}
            visible={showLearningForm}
            destroyOnClose
            footer={null}
          >
            <LearningModuleForm
              learning={editLearning ?? new MyLearning()}
              handleLearningForm={handleFormClose}
              onLearningUpdate={handleFetchLearningData}
            />
          </Drawer>
          <div className="mt-4">
            <Table
              dataSource={learningData}
              columns={columns}
              pagination={{
                total: myLearningPagination?.totalCount,
                current: myLearningPagination?.currentPage,
                showTotal: (total: number, range: [number, number]) => (
                  <p>
                    Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                    <b>{`${total.toLocaleString()}`}</b>
                  </p>
                ),
              }}
              onChange={handleTableChange}
              onRow={handleRow}
            />
          </div>
          <Modal
            onCancel={handleShowContent}
            visible={showContent}
            destroyOnClose
            footer={null}
            className="ba-personal-details__modal"
          >
            <h4 className="text-primary">Notes</h4>
            <p className="notes">{notes}</p>
          </Modal>
        </div>
      )}
    </div>
  );
}

export default LearningList;
