import { deserialize } from "serializr";
import axiosInstance from "../../interceptor/axiosInstance";
import { City } from "../../models/City/city.model";
import { Employee } from "../../models/Employee/employee.model";
import { Province } from "../../models/Province/province.model";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";

export interface FilterOptions {
  names?: string[];
  ba_ids?: string[];
  cities?: string[];
  states?: string[];
  search_text?: string;
}

class CustomerFilter {
  static async fetchCustomerNames(
    filterParams: FilterOptions,
    onSuccess: (customers: Employee[]) => void,
    onFinal: () => void
  ) {
    try {
      const URL = ApiRoutes.CUSTOMER_NAME;
      const { data } = await axiosInstance.get(URL, {
        params: filterParams,
      });
      const customers = deserialize(Employee, data["customers"] as Employee[]);
      onSuccess(customers);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async fetchCustomerBAS(
    filterParams: FilterOptions,
    onSuccess: (bas: Employee[]) => void,
    onFinal: () => void
  ) {
    try {
      const URL = ApiRoutes.CUSTOMER_BAS;
      const { data } = await axiosInstance.get(URL, {
        params: filterParams,
      });
      const bas = deserialize(
        Employee,
        data["business_associates"] as Employee[]
      );
      onSuccess(bas);
    } catch (error) {
    } finally {
      onFinal();
    }
  }
  static async fetchRMs(
    filterParams: FilterOptions,
    onSuccess: (bas: Employee[]) => void,
    onFinal: () => void
  ) {
    try {
      const URL = ApiRoutes.BA_RMS;
      const { data } = await axiosInstance.get(URL, {
        params: filterParams,
      });
      const bas = deserialize(Employee, data["employees"] as Employee[]);
      onSuccess(bas);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async fetchCustomerCities(
    filterParams: FilterOptions,
    onSuccess: (cities: City[]) => void,
    onFinal: () => void
  ) {
    try {
      const URL = ApiRoutes.CUSTOMER_CITIES;
      const { data } = await axiosInstance.get(URL, {
        params: filterParams,
      });
      const cities = deserialize(City, data["cities"] as City[]);
      onSuccess(cities);
    } catch (error) {
    } finally {
      onFinal();
    }
  }

  static async fetchCustomerStates(
    filterParams: FilterOptions,
    onSuccess: (states: Province[]) => void,
    onFinal: () => void
  ) {
    try {
      const URL = ApiRoutes.CUSTOMER_STATES;
      const { data } = await axiosInstance.get(URL, {
        params: filterParams,
      });
      const states = deserialize(Province, data["provinces"] as Province[]);
      onSuccess(states);
    } catch (error) {
    } finally {
      onFinal();
    }
  }
}

export default CustomerFilter;
