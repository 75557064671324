import * as Yup from "yup";
import { ContentTypeEnum } from "../../../enums/contentType.enum";

export const myLearningModuleFormValidation = Yup.object().shape({
  moduleName: Yup.string().nullable().required("Module name is required!"),
  userView: Yup.string().required("Applicable user is required"),
  learningModuleTypeId: Yup.string().required("Module Type is required"),
  provinceIds: Yup.string().required("State is required"),
  cityIds: Yup.string().required("City is required"),
  contentType: Yup.string().required("Content type is required"),

  // externalLink: Yup.string().when("contentType", {
  //   is: (contentType) => contentType === ContentTypeEnum.VIDEO_URL,
  //   then: Yup.string().required("External Link is required"),
  //   otherwise: Yup.string(),
  // }),
  // notes: Yup.string().when("contentType", {
  //   is: (contentType) => contentType === ContentTypeEnum.TEXT_ONLY,
  //   then: Yup.string().required("Text Note is required"),
  //   otherwise: Yup.string(),
  // }),

  // checkboxGroup: Yup.array().required(
  //   "At least one checkbox is required"
  // ),
  // date: Yup.date().default(() => new Date()),
  // email: Yup.string().email("Invalid email").required("Required"),
  // contentType: Yup.string().required("Content type is required."),
  // provinceIds: Yup.string().ensure().required("State is required!"),
  // cityIds: Yup.string().ensure().required("City is required!"),
});
