import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/charts";
import { Select } from "antd";

import "./baDashboardGraph.scss";
const { Option } = Select;

const BADashboardGraph: React.FC = () => {
  var status = [
    {
      name: "Heppy Silver(1A)",
      month: "Jan",
      reviewData: 12,
    },
    {
      name: "Heppy Silver(1A)",
      month: "Feb",
      reviewData: 23,
    },
    {
      name: "Heppy Silver(1A)",
      month: "Mar",
      reviewData: 34,
    },
    {
      name: "Heppy Silver(1A)",
      month: "Apr",
      reviewData: 99,
    },
    {
      name: "Heppy Silver(1A)",
      month: "May",
      reviewData: 52,
    },
    {
      name: "Heppy Silver(1A)",
      month: "Jun",
      reviewData: 35,
    },
    {
      name: "Heppy Silver(1A)",
      month: "Jul",
      reviewData: 37,
    },
    {
      name: "Heppy Silver(1A)",
      month: "Aug",
      reviewData: 42,
    },
    {
      name: "Heppy Silver(1A)",
      month: "Sept",
      reviewData: 35,
    },
    {
      name: "Heppy Silver(1A)",
      month: "Oct",
      reviewData: 35,
    },
    {
      name: "Heppy Silver(1A)",
      month: "Nov",
      reviewData: 45,
    },
    {
      name: "Heppy Silver(1A)",
      month: "Dec",
      reviewData: 39,
    },
    {
      name: "Heppy Silver(3A) ",
      month: "Jan",
      reviewData: 18,
    },
    {
      name: "Heppy Silver(3A) ",
      month: "Feb",
      reviewData: 28,
    },
    {
      name: "Heppy Silver(3A) ",
      month: "Mar",
      reviewData: 39,
    },
    {
      name: "Heppy Silver(3A) ",
      month: "Apr",
      reviewData: 81,
    },
    {
      name: "Heppy Silver(3A) ",
      month: "May",
      reviewData: 47,
    },
    {
      name: "Heppy Silver(3A) ",
      month: "Jun",
      reviewData: 20,
    },
    {
      name: "Heppy Silver(3A) ",
      month: "Jul",
      reviewData: 74,
    },
    {
      name: "Heppy Silver(3A) ",
      month: "Aug",
      reviewData: 45,
    },
    {
      name: "Heppy Silver(3A) ",
      month: "Sept",
      reviewData: 88,
    },
    {
      name: "Heppy Silver(3A) ",
      month: "Oct",
      reviewData: 92,
    },
    {
      name: "Heppy Silver(3A) ",
      month: "Nov",
      reviewData: 35,
    },
    {
      name: "Heppy Silver(3A) ",
      month: "Dec",
      reviewData: 35,
    },
  ];
  var config = {
    data: status,
    isGroup: true,
    xField: "month",
    yField: "reviewData",
    seriesField: "name",

    label: {
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
  };
  const OnboardingStatus = [
    { label: "Heppy Silver(1A)", value: "UnderReview" },
    { label: "Pending e-sign BA ", value: "PendingE-signBA" },
    { label: "Pending e-sign Admin", value: "PendingE-signAdmin" },
    { label: "Heppy Silver(3A) ", value: "Heppy Silver(3A) " },

    { label: "Heppy Silver(1A)", value: "heppySilver1A" },
    { label: "Heppy Silver(3A) ", value: "heppySilver3A" },
  ];
  const OnboardingYear = [
    { label: "2020", value: "2020" },
    { label: "2019", value: "2019" },
    { label: "2018", value: "2018" },
    { label: "2017", value: "2017" },
  ];

  return (
    <>
      <div className="ba-dashboard-graph">
        <h3>Products</h3>
        <Column {...config} />
      </div>
    </>
  );
};

export default BADashboardGraph;
