import * as Yup from "yup";

export const passwordValidation = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, "Password has to be longer than 6 characters!")
    .required("New Password is required!"),
  confirmPassword: Yup.string()
    .required("Password confirmation is required!")
    .oneOf([Yup.ref("newPassword"), ""], "Passwords must match!"),
});
