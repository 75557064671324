import { alias, primitive, serializable } from "serializr";

export class ProductRequestUpdate {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("update_text", primitive()))
  updateText?: string;

  @serializable(alias("created_by_id", primitive()))
  createdById?: string;

  @serializable(alias("created_by_type", primitive()))
  createdByType?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("created_by_name", primitive()))
  createdByName?: string;
}
