import { serializable, alias, object, list, primitive } from "serializr";

export class Qualification {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("qualification_type", primitive()))
  qualificationType?: string;

  @serializable(alias("institution_name", primitive()))
  institutionName?: string;

  @serializable(alias("speciality_name", primitive()))
  specialityName?: string;

  @serializable(alias("register_number", primitive()))
  registerNumber?: string;

  @serializable(alias("year_of_completion", primitive()))
  yearOfCompletion?: string;

  @serializable(alias("certificate_id", primitive()))
  certificateId?: number;
}
