import { serializable, alias, object, list, primitive } from "serializr";

class ProductDetail {
  @serializable(alias("label", primitive()))
  label?: string;

  @serializable(alias("type", primitive()))
  type?: string;

  @serializable(alias("value", primitive()))
  value?: string;
}

export class Product {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("date", primitive()))
  date?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("product_type", primitive()))
  productType?: string;

  @serializable(alias("code", primitive()))
  code?: string;

  @serializable(alias("purchasable_product", primitive()))
  purchasableProduct?: string;

  @serializable(alias("status", primitive()))
  status?: string;
  @serializable(alias("product", primitive()))
  product?: string;

  @serializable(alias("amount", primitive()))
  amount?: string;
  // @ts-ignore-start

  @serializable(alias("details", list(object(ProductDetail))))
  details?: ProductDetail[];
  // @ts-ignore-end

  setOrderDetails() {
    this.details = [];
    return this.details;
  }
}
