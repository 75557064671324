import React, { useState, useEffect } from "react";
import { Tabs, Tooltip, Button, Drawer, Table } from "antd";
import SettingsBanner from "../Settings/Banner";
import SettingsFAQ from "../Settings/FAQ";
import SettingsNotification from "./Notifications";
import { PlusOutlined } from "@ant-design/icons";
import AppLoader from "../../shared/components/AppLoader";
import NotificationForm from "../Settings/NotificationForm";
import BannerForm from "./BannerForm";
import "../Settings/settings.scss";
import FAQForm from "./FAQForm";
import SurveyQuestions from "./SurveyQuestions";
import SurveyQuestionsForm from "./SurveyQuestionsForm";
import { Banner } from "../../models/Banner/banner.model";
import BannerService from "../../services/MyProfile/Banner/banner.service";
import { SurveyQuestions as SurveyQuestionsModel } from "../../models/SurveyQuestions/surveyQuestions.model";
import ReceivedNotification from "./ReceivedNotification";
import useFilters from "../../shared/hooks/useFilters";
import { useLocation } from "react-router-dom";
const { TabPane } = Tabs;

interface SettingsProps {}

function Settings(props: SettingsProps) {
  const location = useLocation();
  const [form, setForm] = useState(false);

  const [loading, setLoading] = useState(false);
  const [editBanner, setEditBanner] = useState<Banner>();
  const [editSurvey, setEditSurvey] = useState<SurveyQuestionsModel>();
  const [currentTab, setCurrentTab] = useState<string>("0");
  const [updateForm, setupdateForm] = useState(false);

  const { getParams, setParams } = useFilters();

  const handleForm = () => {
    setForm(!form);
    if (editSurvey) setEditSurvey(undefined);
    setForm(!form);
    if (editBanner) setEditBanner(undefined);
  };
  const handleUpdateForm = () => {
    setupdateForm(!updateForm);
  };
  const configTabs = [
    {
      key: "0",
      title: "Banner",
      component: (
        <SettingsBanner
          onEdit={(updatedBanner: Banner) => {
            setEditBanner(updatedBanner);
            handleForm();
          }}
          updateForm={updateForm}
        />
      ),
      hash: "banner",
    },
    {
      key: "1",
      title: "FAQ",
      component: <SettingsFAQ />,
      hash: "faq",
    },
    {
      key: "2",
      title: "Notifications",
      component: <SettingsNotification />,
      hash: "notifications",
    },
    {
      key: "3",
      title: "Survey Questions",
      component: (
        <SurveyQuestions
          onEdit={(updatedSurvey: SurveyQuestionsModel) => {
            setEditSurvey(updatedSurvey);
            handleForm();
          }}
          updateForm={updateForm}
        />
      ),
      hash: "survey-questions",
    },
    {
      key: "4",
      title: "Received Notifications",
      component: <ReceivedNotification />,
      hash: "received-notifications",
    },
  ];

  useEffect(() => {
    const tabFromHash = configTabs.find(
      (tab) => tab.hash === location.hash.replace("#", "")
    );
    if (tabFromHash) setCurrentTab(tabFromHash?.key);
  }, [location.hash]);

  const handleTabChange = (value: string) => {
    const hash = configTabs?.find((tab, index) => tab?.key === value)?.hash;

    setParams({}, hash);

    setCurrentTab(value);
  };

  const handleFormChange = () => {
    if (editSurvey) setEditSurvey(undefined);
    setForm(!form);
    if (editBanner) setEditBanner(undefined);
  };

  return (
    <div className="setting-wrapper">
      <h1 className="text-primary">
        <span> Settings</span>
        <Tooltip title="Add">
          <Button
            onClick={handleForm}
            type="primary"
            icon={<PlusOutlined />}
            className="rounded-btn ml-2"
          />
        </Tooltip>
      </h1>
      <Tabs
        defaultActiveKey="0"
        activeKey={currentTab}
        size="small"
        style={{ marginTop: 32 }}
        onChange={handleTabChange}
      >
        {configTabs.map(({ title, component }, i) => (
          <TabPane tab={title} key={i}>
            {component}
          </TabPane>
        ))}
      </Tabs>

      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          <Drawer
            width="70vw"
            onClose={handleFormChange}
            visible={form}
            destroyOnClose
            footer={null}
          >
            {currentTab == "0" ? (
              <BannerForm
                handleFormVisibility={handleForm}
                editBanner={editBanner ?? new Banner()}
                onSuccess={handleUpdateForm}
              />
            ) : currentTab == "1" ? (
              <FAQForm handleFormVisibility={handleForm} />
            ) : currentTab == "2" ? (
              <NotificationForm handleFormVisibility={handleForm} />
            ) : (
              <SurveyQuestionsForm
                handleFormVisibility={handleForm}
                editSurvey={editSurvey ?? new SurveyQuestionsModel()}
                onSuccess={handleUpdateForm}
              />
            )}
          </Drawer>
        </div>
      )}
    </div>
  );
}

export default Settings;
