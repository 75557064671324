import React, { useEffect, useState } from "react";
import { Table, Select, TablePaginationConfig } from "antd";
import { receivedNotificationColumn } from "./receivedNotificationTable";
import TableComponent from "../../../shared/components/TableComponent";
import MyProfileSettingService from "../../../services/MyProfileSettingService/myProfileSetting.service";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import Notifications from "../../../services/Notification/notification.service";
import AppLoader from "../../../shared/components/AppLoader";
import { Notification } from "../../../models/Notification/notification.model";
import useFilters from "../../../shared/hooks/useFilters";
import { BaFilterParams } from "../../../models/BAFilterParams/baFilterParams.model";
import { useLocation } from "react-router-dom";

interface ReceivedNotificationProps {}

function ReceivedNotification(props: ReceivedNotificationProps) {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>();
  const [pagination, setPagination] = useState<PaginationDetails>();
  const { getParams, setParams } = useFilters();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleFetchNotification = (paramFilter: BaFilterParams) => {
    setLoading(true);

    Notifications.fetchNotifications(
      paramFilter,
      (notifications: Notification[], pagination: PaginationDetails) => {
        setNotifications(notifications);
        setPagination(pagination);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    handleFetchNotification(getParams());
  }, [location.state]);
  useEffect(() => {
    handleFetchNotification(getParams());
  }, []);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current,
    };
    setParams(updatedFilters);
    handleFetchNotification(updatedFilters);
    if (pagination?.current) setCurrentPage(pagination?.current);
  };
  return (
    <div className="received-notification">
      {loading ? (
        <AppLoader loading />
      ) : (
        <div>
          <Table
            className="received-notification__table"
            // onRow={handleRowClick}
            loading={loading}
            columns={receivedNotificationColumn}
            dataSource={notifications}
            rowKey="notificationId"
            pagination={{
              total: pagination?.totalCount,
              current: pagination?.currentPage,
              showTotal: (total: number, range: [number, number]) => (
                <p>
                  Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                  <b>{`${total.toLocaleString()}`}</b>
                </p>
              ),
            }}
            onChange={handleTableChange}
          />
        </div>
      )}
    </div>
  );
}

export default ReceivedNotification;
