import React, { useEffect, useState } from "react";
import OrderService from "../../../services/Order/order.service";
import { Order } from "../../../models/Order/order.model";
import AppLoader from "../../../shared/components/AppLoader";
import {
  Button,
  Col,
  Input,
  Row,
  Select,
  Table,
  Drawer,
  TablePaginationConfig,
  Modal,
} from "antd";
import moment from "moment";
import { ProductTypesEnum } from "../../../enums/productTypes.enum";
import { useHistory, generatePath } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { Console } from "console";
import uploadFileIcon from "../../../assets/images/uploadFile.png";
import "./orderList.scss";
import AxisBankForm from "../AxisBankForm";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { debounce } from "lodash";
import useFilters from "../../../shared/hooks/useFilters";
import { OrderFilterParams } from "../../../models/OrderFilter/orderFilter";
import CustomModal from "../../../shared/components/CustomModal";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { Form, Formik } from "formik";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";

interface OrderListProps {}

function OrderList(props: OrderListProps) {
  const history = useHistory();
  const { getParams, setParams } = useFilters();
  const prevFilter = getParams();
  const { productTypeValue } = prevFilter;

  const [loading, setLoading] = useState(false);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [isDownloadVisible, setIsDownloadVisible] = useState(false);

  const [orders, setOrders] = useState<Order[]>([]);
  const [orderPagination, setOrderPagination] = useState<PaginationDetails>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [productType, setProductType] = useState(
    productTypeValue ? productTypeValue : ProductTypesEnum.HEALTH_ASSURE
  );

  const [searchText, setSearchText] = useState("");
  const [form, setForm] = useState(false);
  const handleForm = () => setForm(!form);

  const [formValues, setFormValues] = useState({
    searchText,
    productType,
  });

  const [showYesBankModal, setShowYesBankModal] = useState(false);

  const toggleYesBankModel = () => setShowYesBankModal(!showYesBankModal);

  const handleUploadYesBankDocument = (values: Order) => {
    setLoading(true);
    OrderService.uploadYesBankDocument(
      { ...values, productType: formValues?.productType },
      () => {
        toggleYesBankModel();
        handleFetchOrders(getParams());
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const productTypeOptions = [
    { label: "Health Assure", value: ProductTypesEnum.HEALTH_ASSURE },
    { label: "Career Guide", value: ProductTypesEnum.CAREER_GUIDE },
    { label: "Bajaj Finserv FD", value: ProductTypesEnum.BAJAJ_FINSERV_FD },
    {
      label: "Saving Account Axis Bank ",
      value: ProductTypesEnum.SAVING_ACCOUNT_AXIS_BANK,
    },
    {
      label: "Current Account Axis Bank ",
      value: ProductTypesEnum.CURRENT_ACCOUNT_AXIS_BANK,
    },
    {
      label: "Bureau Report",
      value: ProductTypesEnum.CREDIT_SERVICES,
    },
    {
      label: "Will Services",
      value: ProductTypesEnum.WILL_SERVICES,
    },
    {
      label: "Saving Account by Yes Bank",
      value: ProductTypesEnum.SAVING_ACCOUNT_YES_BANK,
    },
    {
      label: "Credit Card by Yes Bank",
      value: ProductTypesEnum.CREDIT_CARD_YES_BANK,
    },
  ];

  const columns =
    formValues.productType === ProductTypesEnum.HEALTH_ASSURE
      ? [
          {
            title: "ORDER ID",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "CUSTOMER NAME",
            dataIndex: "customerName",
            key: "customerName",
            width: "18%",
          },
          {
            title: "PRODUCT",
            dataIndex: "id",
            key: "id",
            render: (_: string, order: Order, index: number) => {
              return order?.product?.name?.replace("_", " ") || "-";
            },
          },
          {
            title: "PRODUCT VARIENT",
            dataIndex: "id",
            key: "id",
            render: (id: string, order: Order, index: number) => {
              return order?.product?.code || "-";
            },
          },
          {
            title: "AMOUNT",
            dataIndex: "id",
            key: "id",
            render: (id: string, order: Order, index: number) => {
              return (
                (order?.product?.amount && `₹${order?.product?.amount}`) || "--"
              );
            },
          },
          {
            title: "DATE",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date: string) => {
              return <div>{moment(date).format("DD/MM/YYYY")}</div>;
            },
          },
          {
            title: "STATUS",
            dataIndex: "orderStatus",
            key: "orderStatus",
            width: "20%",
            render: (orderStatus: string) => {
              return (
                <div className={`${orderStatus} text-capitalize`}>
                  {orderStatus?.split("_").join(" ")}
                </div>
              );
            },
          },
        ]
      : formValues.productType === ProductTypesEnum.CAREER_GUIDE
      ? [
          {
            title: "ORDER ID",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "CUSTOMER NAME",
            dataIndex: "customerName",
            key: "customerName",
            width: "18%",
          },
          {
            title: "PRODUCT",
            dataIndex: "id",
            key: "id",
            render: (id: string, order: Order, index: number) => {
              return order?.product?.name || "-";
            },
          },
          {
            title: "PRODUCT VARIENT",
            dataIndex: "id",
            key: "id",
            render: (id: string, order: Order, index: number) => {
              return order?.product?.code || "-";
            },
          },
          {
            title: "AMOUNT",
            dataIndex: "amount",
            key: "amount",
            render: (id: string, order: Order, index: number) => {
              return formValues.productType === ProductTypesEnum.CAREER_GUIDE &&
                order?.orderDetails?.totalAmount
                ? `₹${order?.orderDetails?.totalAmount}`
                : "--";
            },
          },
          {
            title: "DATE",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date: string) => {
              return <div>{moment(date).format("DD/MM/YYYY")}</div>;
            },
          },
          {
            title: "STATUS",
            dataIndex: "orderStatus",
            key: "orderStatus",
            width: "20%",
            render: (orderStatus: string) => {
              return (
                <div className={`${orderStatus} text-capitalize `}>
                  {orderStatus?.split("_").join(" ")}
                </div>
              );
            },
          },
        ]
      : formValues.productType === ProductTypesEnum.SAVING_ACCOUNT_AXIS_BANK ||
        formValues.productType === ProductTypesEnum.CURRENT_ACCOUNT_AXIS_BANK
      ? [
          {
            title: "APPLICATION ID",
            dataIndex: "applicationId",
            key: "applicationId",
            render: (_: string, order: Order) => {
              return order?.orderDetails?.applicationId;
            },
          },
          {
            title: "CUSTOMER NAME",
            dataIndex: "customerName",
            key: "customerName",
            width: "20%",
          },
          {
            title: "BA ID",
            dataIndex: "businessAssociateUid",
            key: "businessAssociateUid",
            render: (_: string, order: Order) => {
              return order?.orderDetails?.businessAssociateUid;
            },
          },
          {
            title: "PRODUCT TYPE",
            dataIndex: "productType",
            render: (_: string, order: Order) => {
              return order?.product?.name;
            },
          },
          {
            title: "DATE",
            dataIndex: "date",
            key: "date",
            render: (createdAt: string, order: Order) => {
              return <div>{moment(order?.createdAt).format("DD/MM/YYYY")}</div>;
            },
          },
          {
            title: "STATUS",
            dataIndex: "status",
            key: "status",
            render: (status: string, order: Order) => {
              return (
                <div
                  className={`${order?.orderDetails?.status} text-capitalize `}
                >
                  {order?.orderDetails?.status?.split("_").join(" ")}
                </div>
              );
            },
          },
        ]
      : formValues.productType === ProductTypesEnum.BAJAJ_FINSERV_FD
      ? [
          {
            title: "ORDER ID",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "CUSTOMER NAME",
            dataIndex: "customerName",
            key: "customerName",
          },
          {
            title: "PRODUCT",
            dataIndex: "id",
            key: "id",
            render: (id: string, order: Order, index: number) => {
              return order?.product?.name?.replace("_", " ") || "-";
            },
          },
          {
            title: "PRODUCT VARIENT",
            dataIndex: "id",
            key: "id",
            render: (id: string, order: Order, index: number) => {
              return order?.product?.code || "-";
            },
          },
          {
            title: "AMOUNT",
            dataIndex: "id",
            key: "id",
            render: (id: string, order: Order, index: number) => {
              return order?.orderDetails?.amount
                ? `₹${order?.orderDetails?.amount}`
                : "--";
            },
          },
          {
            title: "DATE",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date: string) => {
              return <div>{moment(date).format("DD/MM/YYYY")}</div>;
            },
          },
          {
            title: "STATUS",
            dataIndex: "orderStatus",
            key: "orderStatus",

            render: (orderStatus: string) => {
              return (
                <div className={`${orderStatus} text-capitalize`}>
                  {orderStatus?.split("_").join(" ")}
                </div>
              );
            },
          },
        ]
      : formValues.productType === ProductTypesEnum.CREDIT_SERVICES ||
        formValues.productType === ProductTypesEnum.WILL_SERVICES
      ? [
          {
            title: "ORDER ID",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "CUSTOMER NAME",
            dataIndex: "customerName",
            key: "customerName",
            width: "18%",
          },
          {
            title: "PRODUCT Name",
            dataIndex: "id",
            key: "id",
            render: (_: string, order: Order, index: number) => {
              return (
                <span className="text-capitalize">
                  {" "}
                  {order?.product?.name?.replace("_", " ") || "-"}
                </span>
              );
            },
          },
          {
            title: "CATEGORY TYPE",
            dataIndex: "id",
            key: "id",
            render: (id: string, order: Order, index: number) => {
              return order?.orderType || "-";
            },
          },
          {
            title: "TRANSACTION NO",
            dataIndex: "transactionId",
            key: "transactionId",
            render: (id: string, order: Order, index: number) => {
              return order?.transactionId ?? "-";
            },
          },
          {
            title: "AMOUNT",
            dataIndex: "id",
            key: "id",
            render: (id: string, order: Order, index: number) => {
              return (
                (order?.product?.amount &&
                  `₹${Number(order?.product?.amount)}`) ||
                "--"
              );
            },
          },
          {
            title: "DATE",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date: string) => {
              return <div>{moment(date).format("DD/MM/YYYY")}</div>;
            },
          },
          {
            title: "STATUS",
            dataIndex: "orderStatus",
            key: "orderStatus",
            width: "20%",
            render: (orderStatus: string) => {
              return (
                <div className={`${orderStatus} text-capitalize`}>
                  {orderStatus?.split("_").join(" ")}
                </div>
              );
            },
          },
        ]
      : formValues.productType === ProductTypesEnum.SAVING_ACCOUNT_YES_BANK ||
        formValues.productType === ProductTypesEnum.CREDIT_CARD_YES_BANK
      ? [
          {
            title: "ORDER ID",
            dataIndex: "id",
            key: "id",
          },
          {
            title: "CUSTOMER NAME",
            dataIndex: "customerName",
            key: "customerName",
            width: "18%",
          },
          {
            title: "DATE",
            dataIndex: "createdAt",
            key: "createdAt",
            render: (date: string) => {
              return <div>{moment(date).format("DD/MM/YYYY")}</div>;
            },
          },
          {
            title: "CUSTOMER MOBILE",
            dataIndex: "customerMobile",
            key: "customerMobile",
          },
          {
            title: "STATUS",
            dataIndex: "orderStatus",
            key: "orderStatus",
            width: "20%",
            render: (orderStatus: string, order: Order) => {
              return (
                <div
                  className={`${order?.orderDetails?.yesBankStatus} text-capitalize`}
                >
                  {removeUnderscore(order?.orderDetails?.yesBankStatus ?? "")}
                </div>
              );
            },
          },
        ]
      : [];

  const handleFetchOrders = (param: OrderFilterParams) => {
    setFormValues({
      searchText,
      productType,
    });
    setLoading(true);
    const para = Object.assign(new OrderFilterParams(), param);

    OrderService.fetchOrders(
      para?.search ?? "",
      para?.productTypeValue ?? "",
      para?.page ?? 1,
      (orders: Order[], ordersPagination: PaginationDetails) => {
        setOrders(orders);
        setOrderPagination(ordersPagination);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current,
    };
    setParams(updatedFilters);
    handleFetchOrders({ ...updatedFilters });
    setCurrentPage(pagination?.current || 1);
  };

  const handleRow = (order: Order, rowIndex: number | undefined) => {
    return {
      onClick: (event: any) => {
        if (
          formValues.productType != ProductTypesEnum.SAVING_ACCOUNT_AXIS_BANK &&
          formValues.productType != ProductTypesEnum.CURRENT_ACCOUNT_AXIS_BANK
        ) {
          history.push(
            generatePath(AppRoutes.ORDER_DETAIL, {
              orderId: order.id,
            })
          );
        }
      },
    };
  };

  const handleDownloadOrders = () => {
    setDownloadLoading(true);
    OrderService.downloadOrders(
      formValues.productType,
      () => {
        setIsDownloadVisible(true);
      },
      () => {},
      () => {
        setDownloadLoading(false);
      }
    );
  };

  useEffect(() => {
    const prevFilter = getParams();

    const { productTypeValue } = prevFilter;

    const updatedFilters = {
      ...prevFilter,
      productTypeValue: productTypeValue ?? productType,
    };

    setParams(updatedFilters);
    setSearchText(updatedFilters?.search);

    handleFetchOrders(updatedFilters);
  }, []);

  const handleSearch = (searchText: string) => {
    setSearchText(searchText);
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      search: searchText,
    };
    setParams(updatedFilters);
    handleFetchOrders({ ...updatedFilters });
  };

  return (
    <div className="order-list">
      <div className="mt-4">
        <Row align="middle">
          <Col span={8}>
            <h1 className="text-primary">
              <span>Orders</span>
              {formValues.productType ===
                ProductTypesEnum.SAVING_ACCOUNT_AXIS_BANK ||
              formValues.productType ===
                ProductTypesEnum.CURRENT_ACCOUNT_AXIS_BANK ? (
                <span className="uploadIcon ml-5">
                  <img src={uploadFileIcon} onClick={handleForm}></img>
                </span>
              ) : (
                ""
              )}
            </h1>
          </Col>
          <Col span={16} className="text-right">
            {(formValues?.productType ===
              ProductTypesEnum.SAVING_ACCOUNT_YES_BANK ||
              formValues?.productType ===
                ProductTypesEnum.CREDIT_CARD_YES_BANK) && (
              <Button
                type="primary"
                className="mr-5"
                onClick={toggleYesBankModel}
              >
                {formValues?.productType ===
                ProductTypesEnum.SAVING_ACCOUNT_YES_BANK
                  ? "Import file (Yes Bank SA)"
                  : "Import File (Yes Bank Credit Card)"}
              </Button>
            )}
            
            {(formValues?.productType ===
              ProductTypesEnum.BAJAJ_FINSERV_FD &&
              <Button
                type="primary"
                className="mr-5"
                onClick={toggleYesBankModel}
              >
               Import file (Bajaj FD)
              </Button>
            )}
            
            <Button
              type="primary"
              loading={downloadLoading}
              onClick={handleDownloadOrders}
            >
              {formValues?.productType === ProductTypesEnum.HEALTH_ASSURE
                ? "Download HA Orders"
                : formValues?.productType === ProductTypesEnum.CAREER_GUIDE
                ? "Download CG Orders"
                : formValues?.productType === ProductTypesEnum.BAJAJ_FINSERV_FD
                ? "Download Bajaj FD"
                : formValues?.productType ===
                  ProductTypesEnum.SAVING_ACCOUNT_AXIS_BANK
                ? "Download Axis Bank SA"
                : formValues?.productType ===
                  ProductTypesEnum.CURRENT_ACCOUNT_AXIS_BANK
                ? "Download Axis Bank CA"
                : formValues?.productType === ProductTypesEnum.CREDIT_SERVICES
                ? "Download Bureau Report"
                : formValues?.productType === ProductTypesEnum.WILL_SERVICES
                ? "Download Will Services"
                : formValues?.productType ===
                  ProductTypesEnum.SAVING_ACCOUNT_YES_BANK
                ? "Download Yes Bank SA"
                : formValues?.productType ===
                  ProductTypesEnum.CREDIT_CARD_YES_BANK
                ? "Download Yes Bank Credit Card"
                : ""}
            </Button>
          </Col>
        </Row>
        <Row className="mt-4 mb-4">
          <Input.Search
            className="order-list__search"
            placeholder="Search by name"
            style={{ width: 200 }}
            value={getParams()?.search}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
          <Select
            className="select-filter ml-2"
            options={productTypeOptions}
            dropdownMatchSelectWidth={false}
            dropdownClassName="order-list__dropdown"
            defaultValue={productType}
            style={{ width: 200 }}
            value={getParams()?.productTypeValue}
            onChange={(value) => {
              setProductType(value);
              const prevFilter = getParams();
              const updatedFilters = {
                ...prevFilter,
                page: 1,
                productTypeValue: value,
              };
              setParams(updatedFilters);
            }}
          />
          <Button
            className="secondary-btn ml-3"
            onClick={() => {
              handleFetchOrders(getParams());
            }}
          >
            Apply
          </Button>
        </Row>
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Table
            rowKey={(record) => record.id || Math.random()}
            rowSelection={{
              preserveSelectedRowKeys: true,
              type: "checkbox",
            }}
            dataSource={orders}
            columns={columns}
            onRow={handleRow}
            pagination={{
              total: orderPagination?.totalCount,
              current: orderPagination?.currentPage,
              showTotal: (total: number, range: [number, number]) => (
                <p>
                  Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                  <b>{`${total.toLocaleString()}`}</b>
                </p>
              ),
            }}
            onChange={handleTableChange}
          />
        )}
      </div>
      <CustomModal
        title={
          "Your request will be processed and you will receive an email shortly"
        }
        isCancelButton={false}
        visible={isDownloadVisible}
        okHandler={() => setIsDownloadVisible(false)}
        type={NotificationTypes.SUCCESS}
      />
      <Drawer
        width="62vw"
        onClose={handleForm}
        visible={form}
        destroyOnClose
        footer={null}
      >
        <AxisBankForm handleForm={() => setForm(false)} />
      </Drawer>
      <Modal
        onCancel={toggleYesBankModel}
        visible={showYesBankModal}
        destroyOnClose
        footer={null}
        className="ba-documents__modal"
      >
        <Formik
          enableReinitialize
          initialValues={new Order()}
          onSubmit={handleUploadYesBankDocument}
        >
          {({ values, errors, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <AttachmentUpload
                  accept="application/vnd.ms-excel.sheet.macroEnabled.12,.xls,.xlsx"
                  name="uploadedFileId"
                  placeholder="Upload File"
                  setFieldValue={setFieldValue}
                />
                <div className="text-right mt-5">
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={!values?.uploadedFileId}
                  >
                    Upload
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}

export default OrderList;
