import { Button, Col, Drawer, Modal, Row } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useHistory } from "react-router";
import bellIcon from "../../../assets/images/bell.png";
import { BaFilterParams } from "../../../models/BAFilterParams/baFilterParams.model";
import { Notification } from "../../../models/Notification/notification.model";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import Notifications from "../../../services/Notification/notification.service";
import CustomModal from "../CustomModal";
import NotificationBar from "../NotificationBar";
import "./notificationWrapper.scss";

interface NotificationWrapperProps {}
const NotificationWrapper: FC<NotificationWrapperProps> = (props) => {
  const employeeValue = localStorage.getItem("employee");
  const history = useHistory();

  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [pagination, setPagination] = useState<PaginationDetails>();
  const [notificationVisibility, setNotificationVisibility] = useState(false);

  const toggleShowMessage = () => {
    setShowMessage(!showMessage);
  };
  const toggleNotification = () => {
    setNotificationVisibility(!notificationVisibility);
  };

  return (
    <Row>
      <Col span={24} className="text-right">
        {employeeValue ? (
          <img
            onClick={toggleNotification}
            src={bellIcon}
            alt="notification"
            className="app-header__notification-btn"
          ></img>
        ) : (
          ""
        )}
      </Col>

      <Drawer
        className="notification-wrapper__drawer"
        width="35vw"
        onClose={toggleNotification}
        visible={notificationVisibility}
        destroyOnClose
        footer={null}
      >
        <NotificationBar
          handleNotificationVisibility={toggleNotification}
          loading={loading}
        />
      </Drawer>
      <CustomModal
        title={"No new notification to be shown"}
        isCancelButton={false}
        visible={showMessage}
        okHandler={toggleShowMessage}
        iconRequired={false}
      />
    </Row>
  );
};

export default NotificationWrapper;
