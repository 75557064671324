import React, { useEffect, useState } from "react";
import "./productRequestComments.scss";
import { ProductRequest } from "../../../models/ProductRequest/productRequest.model";
import ProductRequestService from "../../../services/ProductRequest/productRequest.service";
import { ProductRequestUpdate } from "../../../models/ProductRequestUpdate/productRequestUpdate.model";
import AppLoader from "../../../shared/components/AppLoader";
import { Input, Table, Row, Col, Button, Drawer } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import moment from "moment";
import { Form, Formik } from "formik";
import DropdownField from "../../../shared/components/DropdownField";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import { FileAttachment } from "../../../models/FileAttachment/fileAttachment.model";

interface ProductRequestCommentsProps {
  productRequest: ProductRequest;
  onUpdateSuccess: (productRequest: ProductRequest) => void;
}

function ProductRequestComments({
  productRequest,
  onUpdateSuccess,
}: ProductRequestCommentsProps) {
  const [formValues, setFormValues] = useState<ProductRequest>(
    new ProductRequest()
  );

  const [commentText, setCommentText] = useState<string>();

  const [productRequestUpdates, setProductRequestUpdates] = useState<
    ProductRequestUpdate[]
  >([]);

  const [loading, setLoading] = useState(false);

  const [formLoading, setFormLoading] = useState(false);

  const [uploadLoading, setUploadLoading] = useState(false);

  const [showDocumentUploadForm, setShowDocumentUploadForm] = useState(false);

  const [selectedDocumentType, setSelectedDocumentType] =
    useState("address_proof");

  const documentTypes = [
    { label: "Aadhaar front Proof", value: "aadhaar_front_proof" },
    { label: "Aadhaar back Proof", value: "aadhaar_back_proof" },
    { label: "Other Identity Proof", value: "other_identity_proof" },
    { label: "Additional Identity Proof", value: "additional_identity_proof" },
    { label: "Address Proof", value: "address_proof" },
    { label: "Additional address Proof", value: "additional_address_proof" },
    { label: "Residence Proof", value: "residence_proof" },
    { label: "Business Proof", value: "business_proof" },
    { label: "Cibil Score Proof", value: "cibil_score_proof" },
    { label: "IT Returns Proof", value: "it_returns_proof" },
    { label: "Office ID Proof", value: "office_id_proof" },
    { label: "Experience Proof", value: "experience_proof" },
    { label: "HR Official detail Proof", value: "hr_official_detail_proof" },
    {
      label: "Employment additional document",
      value: "employment_additional_document",
    },
    { label: "Salary Slip", value: "salary_slip" },
    { label: "CTC letter", value: "ctc_letter" },
    { label: "Incentive document", value: "incentive_document" },
    { label: "Bank Proof", value: "bank_proof" },
    { label: "Employment details", value: "employment_details" },
    { label: "Income Proof", value: "income_proof" },
    { label: "Property Proof", value: "property_proof" },
    { label: "Other Proof", value: "other_proof" },
  ];

  const handleToggleDocumentForm = () =>
    setShowDocumentUploadForm(!showDocumentUploadForm);

  const handleFetchUpdates = () => {
    if (productRequest.id) {
      setLoading(true);
      ProductRequestService.fetchProductRequestUpdates(
        productRequest.id,
        (productRequestUpdates: ProductRequestUpdate[]) => {
          setProductRequestUpdates(productRequestUpdates);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  };

  const handleAddUpdate = () => {
    if (productRequest.id && commentText) {
      setFormLoading(true);
      ProductRequestService.createProductRequestUpdate(
        productRequest.id,
        commentText,
        (productRequestUpdate: ProductRequestUpdate) => {
          setCommentText(undefined);
          setProductRequestUpdates([
            productRequestUpdate,
            ...productRequestUpdates,
          ]);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  const handleSubmit = (values: ProductRequest) => {
    setFormValues(values);
    const request = Object.assign(new ProductRequest(), {
      ...values,
      id: productRequest.id,
    });
    setUploadLoading(true);
    ProductRequestService.updateProductRequest(
      request,
      (productRequest: ProductRequest) => {
        setShowDocumentUploadForm(false);
        onUpdateSuccess(productRequest);
      },
      () => {},
      () => {
        setUploadLoading(false);
      }
    );
  };

  useEffect(() => {
    handleFetchUpdates();
  }, []);

  const columns = [
    {
      title: "DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: "SUBMITTED BY",
      dataIndex: "createdByName",
      key: "createdByName",
    },
    {
      title: "ROLE",
      dataIndex: "createdByType",
      key: "createdByType",
    },
    {
      title: "DESCRIPTION",
      dataIndex: "updateText",
      key: "updateText",
    },
  ];

  return (
    <div className="product-request-comments">
      <h2 className="text-primary">All activity</h2>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          <Row gutter={[10, 0]}>
            <Col span={10} className="product-request-comments__update-wrapper">
              <Input
                className="product-request-comments__update-input"
                placeholder="Write comment"
                value={commentText}
                onChange={(e) => setCommentText(e.target.value)}
              />
              <Button
                type="primary"
                icon={<PaperClipOutlined />}
                onClick={handleToggleDocumentForm}
              />
            </Col>
            <Col span={8}>
              <Button
                loading={formLoading}
                disabled={!commentText || formLoading}
                htmlType="button"
                className="secondary-btn"
                onClick={handleAddUpdate}
              >
                Submit
              </Button>
            </Col>
          </Row>
          <div className="mt-4">
            <Table dataSource={productRequestUpdates} columns={columns} />
          </div>
        </div>
      )}
      <Drawer
        onClose={handleToggleDocumentForm}
        visible={showDocumentUploadForm}
        destroyOnClose
        footer={null}
        width="40%"
        className="product-request-comment__drawer"
      >
        <h2 className="text-primary"> Select Document</h2>
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={null}
        >
          {({ values, errors, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <DropdownField
                  showSearch
                  title="Document Type"
                  name="documentType"
                  options={documentTypes}
                  value={selectedDocumentType}
                  onChange={(value) => {
                    setSelectedDocumentType(value);
                  }}
                  setFieldValue={setFieldValue}
                  placeHolder="Select Document Type"
                />
                <AttachmentUpload
                  accept="image/jpeg,image/png,image/jpg, application/pdf"
                  name={selectedDocumentType}
                  placeholder="Upload File"
                  onChange={(attachmentId: number) => {
                    if (values?.productRequestAttachments) {
                      const attachment = new FileAttachment();
                      attachment.attachmentUploadId = attachmentId;
                      attachment.attachmentType = selectedDocumentType;
                      setFieldValue("productRequestAttachments", [attachment]);
                    }
                  }}
                  setFieldValue={setFieldValue}
                />
                <div className="text-right mt-5">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={uploadLoading}
                    loading={uploadLoading}
                  >
                    Upload
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Drawer>
    </div>
  );
}

export default ProductRequestComments;
