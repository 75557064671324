import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import { Province } from "../../models/Province/province.model";
import { ReactNode } from "react";
import { City } from "../../models/City/city.model";
import { Degree } from "../../models/Degree/degree.model";
import { Location } from "../../models/Location/location.model";
import { Role } from "../../models/Role/role.model";

export interface IDropdownOption {
  label?: string;
  value?: string | number;
  optionalLabel?: string | ReactNode;
}

export default class MetaService {
  static fetchProvinces(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.PROVINCES)
      .then((response) => {
        const provinces = deserialize(Province, response.data["provinces"]);
        onSuccess(provinces);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchRoles(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.ROLES)
      .then((response) => {
        const roles = deserialize(Role, response.data["roles"]);
        onSuccess(roles);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchCities(
    provinceId: number | [],
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.CITIES.replace(
      ":provinceId",
      provinceId.toString()
    );
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const cities = deserialize(City, response.data["cities"]);
        onSuccess(cities);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchMultipleCities(
    provinceId: [],
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.MULTIPLE_CITIES;
    axiosInstance
      .get(API_URL, {
        params: {
          province_ids: provinceId,
        },
      })
      .then((response) => {
        const cities = deserialize(City, response.data["cities"]);
        onSuccess(cities);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchLocations(
    provinceId: number,
    cityId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.LOCATIONS.replace(
      ":provinceId",
      provinceId.toString()
    ).replace(":cityId", cityId.toString());
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const locations = deserialize(Location, response.data["locations"]);
        onSuccess(locations);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }

  static fetchDegrees(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.SPECIALITIES)
      .then((response) => {
        const degrees = deserialize(Degree, response.data["specialities"]);
        onSuccess(degrees);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
}
