import { serializable, alias, object, primitive, list } from "serializr";

export class MyLearningModuleType {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("name", primitive()))
  name?: string;
  @serializable(alias("learning_modules_count", primitive()))
  learningModulesCount?: number;
}
export class MyLearningModuleTypes {
  @serializable(alias("appointment_types", list(object(MyLearningModuleType))))
  LearningModuleTypes: MyLearningModuleType[] = [];
}
class PDFContent {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("attachment_url", primitive()))
  attachmentUrl?: string;
}

export class MyLearningModuleForm {
  @serializable(alias("module_name", primitive()))
  moduleName?: string;
  @serializable(alias("learning_module_type_id", primitive()))
  learningModuleTypeId?: number;
  @serializable(alias("status", primitive()))
  status?: boolean;

  @serializable(alias("user_view", primitive()))
  userView?: string;
  @serializable(alias("attachment_upload_id", primitive()))
  attachmentUploadId?: number;
  @serializable(alias("content_type", primitive()))
  contentType?: string;
  @serializable(alias("province_ids", list(primitive())))
  provinceIds?: [];
  @serializable(alias("city_ids", list(primitive())))
  cityIds?: [];
  @serializable(alias("description", primitive()))
  description?: string;
  @serializable(alias("external_link", primitive()))
  externalLink?: string;
  @serializable(alias("notes", primitive()))
  notes?: string;
  @serializable(alias("pdf_content_id", primitive()))
  pdfContentId?: number;
  @serializable(alias("pdf_content", object(PDFContent)))
  pdfContent?: PDFContent;
}

class ProvinceModule {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("learning_module_id", primitive()))
  learningModuleId?: number;
  @serializable(alias("province_id", primitive()))
  provinceId?: number;
}
class CityModule {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("learning_module_id", primitive()))
  learningModuleId?: number;
  @serializable(alias("city_id", primitive()))
  cityId?: number;
}
export class MyLearning {
  @serializable(alias("module_name", primitive()))
  moduleName?: string;
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("notes", primitive()))
  notes?: string;
  // @serializable(alias("city_learning_modules", list(primitive())))
  // provinceIds?: [];
  // @serializable(alias("city_learning_modules", list(primitive())))
  // cityIds?: [];

  @serializable(alias("content_type", primitive()))
  contentType?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;
  @serializable(alias("created_by_name", primitive()))
  createdByName?: string;
  @serializable(alias("external_link", primitive()))
  externalLink?: string;
  @serializable(alias("status", primitive()))
  status?: boolean;
  @serializable(alias("user_view", primitive()))
  userView?: string;
  @serializable(alias("pdf_content", object(PDFContent)))
  pdfContent?: PDFContent;
  @serializable(alias("learning_module_type_id", primitive()))
  learningModuleTypeId?: number;
  @serializable(
    alias("province_learning_modules", list(object(ProvinceModule)))
  )
  provinceLearningModule: ProvinceModule[] = [];
  @serializable(alias("city_learning_modules", list(object(CityModule))))
  cityLearningModules: CityModule[] = [];
}
