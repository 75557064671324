import axiosInstance from "../../interceptor/axiosInstance";
import { Order } from "../../models/Order/order.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { PaginationDetails } from "../../models/Pagination/pagination.model";

export default class OrderService {
  static fetchOrders(
    searchText: string,
    productType: string,
    currentPage: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.ORDERS, {
        params: {
          search_text: searchText,
          product_type: productType,
          page: currentPage,
        },
      })
      .then((response) => {
        const orders = deserialize(Order, response.data["orders"]);

        const ordersPagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );

        onSuccess(orders, ordersPagination);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showOrder(
    orderId: number,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ORDERS + "/" + orderId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const order = deserialize(Order, response.data["order"]);
        onSuccess(order);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createOrder(
    order: Order,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const orderJSON = serialize(order);
    axiosInstance
      .post(ApiRoutes.ORDERS, orderJSON)
      .then((response) => {
        const order = deserialize(Order, response.data["order"]);
        onSuccess(order);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateOrder(
    order: Order,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ORDERS + "/" + order.id;
    const orderJSON = serialize(order);
    axiosInstance
      .put(API_URL, orderJSON)
      .then((response) => {
        const order = deserialize(Order, response.data["order"]);
        onSuccess(order);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteOrder(
    orderId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.ORDERS + "/" + orderId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static downloadOrders(
    productType: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.DOWNLOAD_ORDERS, {
        responseType: "blob",
        params: {
          product_type: productType,
        },
      })
      .then((response) => {
        onSuccess();
        // console.log("type", typeof response.data);
        // console.log("data", response.data);
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement("a");
        // link.href = url;
        // const date = new Date().toJSON().slice(0, 10).replace(/-/g, "/");
        // link.setAttribute("download", `Orders (${date}).xlsx`);
        // document.body.appendChild(link);
        // link.click();
      })
      .catch((error) => {
        onError();
      })
      .finally(onFinal);
  }

  static uploadYesBankDocument(
    formValues: Order,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const orderJSON = serialize(Order, formValues);

    axiosInstance
      .post(ApiRoutes.UPLOAD_YES_BANK_DOCUMENT, orderJSON)
      .then((response) => {
        Notification({
          message: "Document uploaded",
          description: response.data?.message,
          type: NotificationTypes.SUCCESS,
        });
        onSuccess();
      })
      .catch((error) => {
        Notification({
          message: "Unable to upload document",
          description: (error as Error).message,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
