import React, { useEffect } from "react";
import { Table, Select } from "antd";
import { faqColumns, sampleFAQTable } from "./faqTable";
import TableComponent from "../../../shared/components/TableComponent";
import MyProfileSettingService from "../../../services/MyProfileSettingService/myProfileSetting.service";
import "../FAQ/faq.scss";

interface FAQProps {}

function FAQ(props: FAQProps) {
  const { bannerList, fetchBannerList, loading } = MyProfileSettingService();

  /*  useEffect(() => {
    if (bannerList) fetchBannerList();
  }, []); */
  return (
    <div className="faq">
      <div>
        <TableComponent
          className="faq__table"
          // onRow={handleRowClick}
          loading={loading}
          columns={faqColumns}
          dataSource={sampleFAQTable}
          rowKey="faqId"
        />
      </div>
    </div>
  );
}

export default FAQ;
