import React, { useEffect, FC, useState, useRef } from "react";
import { Form, Formik, FormikProps } from "formik";
import InputField from "../../../shared/components/InputField";
import { Button, Col, Row } from "antd";
import "./baAdditionaInfoForm.scss";
import { useHistory, useParams } from "react-router-dom";
import DropdownField from "../../../shared/components/DropdownField";
import deleteIcon from "../../../assets/images/delete.png";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import { FamilyMember } from "../../../models/FamilyMember/familyMember.model";
import { BusinessAssociateService } from "../../../services/BusinessAssociateService/businessAssociate.service";
import { baAdditionalInfoFormValidation } from "./baAdditionalInfoFormValidation";
import {
  citizenship,
  gender,
  industryType,
  jobType,
  maritalStatus,
  numberList,
} from "./BAAdditionalInfoValues";

interface BAAdditionalInfoFormProps {
  businessAssociate: BusinessAssociate;
  onUpdateSuccess: (ba: BusinessAssociate) => void;

  handleFormVisibility: () => void;
}
interface BAAdditionalInfoFormParams {
  baId: string;
}

const BAAdditionalInfoForm: FC<BAAdditionalInfoFormProps> = (props) => {
  const { businessAssociate, handleFormVisibility, onUpdateSuccess } = props;

  const params: BAAdditionalInfoFormParams = useParams();

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(businessAssociate);
  const [familymembers, setFamilyMembers] = useState([new FamilyMember()]);
  const formikRef = useRef<FormikProps<BusinessAssociate>>(null);

  const handleSubmit = (values: BusinessAssociate) => {
    if (params.baId) {
      setLoading(true);

      BusinessAssociateService.modifyAdditionalInfoBA(
        Number(params.baId),
        values,
        (ba: BusinessAssociate) => {
          onUpdateSuccess(ba);
        },
        () => {},
        () => {
          setLoading(false);
          handleFormVisibility();
        }
      );
    }
  };

  const handleAddMembers = () => {
    setFamilyMembers([...familymembers, new FamilyMember()]);
  };
  const handleRemoveMembers = (index: number) => {
    if (!formikRef.current || !formikRef.current?.values.familyDetails) return;
    const list = [...formikRef.current?.values.familyDetails];
    list.splice(index, 1);
    setFamilyMembers(list);
    formikRef.current.setFieldValue("familyDetails", list);
  };

  return (
    <div className="ba-additional-info-form">
      <h2 className="text-primary">Additional Details</h2>
      <div className="ba-additional-info-form__form">
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          innerRef={formikRef}
          validationSchema={baAdditionalInfoFormValidation}
        >
          {({
            values,
            errors,
            isValid,
            dirty,
            setFieldValue,
            handleChange,
          }) => {
            return (
              <Form>
                <Row gutter={[20, 5]}>
                  <Col span={12}>
                    <DropdownField
                      title="Nos of clients in current business"
                      name="noOfClients"
                      placeHolder="Number of Client"
                      options={numberList}
                      value={values?.noOfClients}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Marital Status"
                      name="maritalStatus"
                      options={maritalStatus}
                      value={values?.maritalStatus}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Marital Status"
                    />
                  </Col>

                  <Col span={12}>
                    <DropdownField
                      title="Citizenship"
                      name="citizenship"
                      options={citizenship}
                      value={values?.citizenship}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Citizenship"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Gender"
                      name="gender"
                      options={gender}
                      value={values?.gender}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Gender"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Constitution"
                      type="text"
                      name="constituency"
                      placeholder="Enter Constitution Name"
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Current Employment type"
                      name="jobType"
                      options={jobType}
                      value={values?.employmentDetail?.jobType}
                      setFieldValue={setFieldValue}
                      placeHolder="Select  Employment type"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Company Name"
                      type="text"
                      name="companyName"
                      placeholder="Enter Company Name"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Business Name"
                      type="text"
                      name="businessName"
                      placeholder="Enter Business Name "
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Nature of Business"
                      type="text"
                      name="businessNature"
                      placeholder="Enter Nature of Business "
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Industry type"
                      name="industryType"
                      options={industryType}
                      value={values?.employmentDetail?.industryType}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Industry type"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Designation"
                      type="text"
                      name="designation"
                      placeholder="Enter Designation "
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Years in Business / service"
                      name="yearsOfService"
                      options={numberList}
                      value={values?.employmentDetail?.yearsOfService}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Years in Business / service"
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Gross monthly income"
                      type="number"
                      name="grossMonthlyIncome"
                      placeholder="Enter Gross monthly income "
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Monthly expense in business"
                      type="number"
                      name="monthlyBusinessExpense"
                      placeholder="Enter Monthly expense in business "
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Date of discussion"
                      type="date"
                      name="dateOfDiscussion"
                    />
                  </Col>

                  <Col span={12}></Col>
                  <Col
                    span={24}
                    className="ba-additional-info-form__family-member "
                  >
                    <span className="text-grey opacity-1 family-member__name">
                      Name
                    </span>
                    <span className="text-grey opacity-1 ml-5 family-member__age">
                      Age
                    </span>
                  </Col>
                  {familymembers.map((members, index) => {
                    return (
                      <>
                        <Col span={9}>
                          <InputField
                            type="text"
                            name={`familyDetails[${index}].name`}
                            key={index}
                          />
                        </Col>
                        <Col span={3}>
                          <InputField
                            type="number"
                            name={`familyDetails[${index}].age`}
                            key={index}
                          />
                        </Col>
                        {familymembers?.length > 1 && (
                          <Col span={2} key={index}>
                            <img
                              className="delete-icon"
                              src={deleteIcon}
                              key={index}
                              onClick={() => {
                                handleRemoveMembers(index);
                              }}
                            />
                          </Col>
                        )}
                        <Col span={10}></Col>
                      </>
                    );
                  })}
                  {familymembers?.length < 10 && (
                    <Col span={24}>
                      <Button
                        type="primary"
                        className="mb-5"
                        onClick={handleAddMembers}
                      >
                        Add
                      </Button>
                    </Col>
                  )}

                  <Col span={12}>
                    <InputField
                      title="Anniversary date"
                      type="date"
                      name="dateOfAnniversary"
                    />
                  </Col>

                  <Col span={12}>
                    <InputField
                      title="Children education"
                      type="text"
                      name="childrenEducation"
                      placeholder="Enter Children education "
                    />
                  </Col>

                  <Col span={12}>
                    <InputField
                      title="Vehicle"
                      type="text"
                      name="vehicle"
                      placeholder="Enter Vehicle "
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Assets"
                      type="text"
                      name="assets"
                      placeholder="Enter Assets "
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Inversements"
                      type="text"
                      name="inversements"
                      placeholder="Enter Inversements "
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Insurance"
                      type="text"
                      name="insurance"
                      placeholder="Enter Insurance "
                    />
                  </Col>
                  <Col span={12}>
                    <InputField
                      title="Other Liabilities"
                      type="text"
                      name="otherLiabilities"
                      placeholder="Enter Other Liabilities "
                    />
                  </Col>

                  <Col span={21} className="text-right mt-5">
                    <Button
                      type="text"
                      onClick={handleFormVisibility}
                      // disabled={!isValid || loading}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col span={3} className="text-right mt-5">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      disabled={!isValid || loading}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
                {/*  */}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default BAAdditionalInfoForm;
