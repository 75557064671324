import React, { useEffect, useState } from "react";
import { Category } from "../../../models/Category/category.model";
import CategoryService from "../../../services/Category/category.service";
import AppLoader from "../../../shared/components/AppLoader";
import { Table } from "antd";
interface CategoryListProps {}

function CategoryList(props: CategoryListProps) {
  const [categories, setCategories] = useState<Category[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    CategoryService.fetchCategories(
      (categories: Category[]) => {
        setCategories(categories);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  const columns = [
    {
      title: "CATEGORY NAME",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "TYPE",
      dataIndex: "categoryType",
      key: "categoryType",
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <div className="category-list">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          <div>
            <Table dataSource={categories} columns={columns} />
          </div>
        </div>
      )}
    </div>
  );
}

export default CategoryList;
