import React from "react";
import "./customerPersonalDetails.scss";
import { Customer } from "../../../models/Customer/customer.model";
import { Row, Col } from "antd";
import { maskNumber } from "../../../shared/utils/maskNumber";

interface CustomerPersonalDetailsProps {
  customer: Customer;
}

function CustomerPersonalDetails({ customer }: CustomerPersonalDetailsProps) {
  return (
    <div className="customer-personal-details">
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <div className="customer-personal-details__label">Address</div>
          <div className="customer-personal-details__value">
            {customer?.residentialAddress?.street || "-"}{" "}
            {customer?.residentialAddress?.city?.name || "-"}{" "}
            {customer?.residentialAddress?.city?.provinceName || "-"}{" "}
            {customer?.residentialAddress?.zipcode || "-"}
          </div>
        </Col>
        <Col span={12}>
          <div className="customer-personal-details__label">Zipcode</div>
          <div className="customer-personal-details__value">
            {customer?.addresses?.map((val, index) => {
              return (
                <div>
                  {index === customer?.addresses?.length - 1 &&
                    `${val?.zipcode ?? "-"} `}
                </div>
              );
            })}
          </div>
        </Col>

        <Col span={12}>
          <div className="customer-personal-details__label">DOB</div>
          <div className="customer-personal-details__value">
            {customer?.dob || "-"}
          </div>
        </Col>

        <Col span={12}>
          <div className="customer-personal-details__label">Occupation</div>
          <div className="customer-personal-details__value text-capitalize">
            {customer?.profession
              ? customer?.profession.split("_").join(" ")
              : "-"}
          </div>
        </Col>
        <Col span={12}>
          <div className="customer-personal-details__label">Gross Salary</div>
          <div className="customer-personal-details__value text-capitalize">
            {customer?.annualIncome
              ? customer?.annualIncome.split("_").join(" ")
              : "-"}
          </div>
        </Col>
        <Col span={12}>
          <div className="customer-personal-details__label">Aadhaar Number</div>
          <div className="customer-personal-details__value">
            {maskNumber(customer?.aadhaarNumber?.replace(/ /g, "") || "-")}
          </div>
        </Col>
        <Col span={12}>
          <div className="customer-personal-details__label">PAN number</div>
          <div className="customer-personal-details__value">
            {customer?.panNumber || "-"}
          </div>
        </Col>
        <Col span={24}>
          <div className="customer-personal-details__label">
            Requirement Notes
          </div>
          <div className="customer-personal-details__value">
            {customer?.requirements
              ?.map((requirement) => requirement.description)
              .join(", ")}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CustomerPersonalDetails;
