import React, { useEffect, useState } from "react";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import MetaService from "../../../../services/MetaService/meta.service";
import { Province } from "../../../../models/Province/province.model";
import { Checkbox, Col, Input, Row } from "antd";
import AppLoader from "../../../../shared/components/AppLoader";
import { BaFilterParams } from "../../../../models/BAFilterParams/baFilterParams.model";
import { BaFilterService } from "../../../../services/BAFilter/baFilter.service";
import { debounce } from "lodash";
interface BAStateFiltersProps {
  filterParams: BaFilterParams;
  refreshData?: boolean;
  onUpdate: (filterParams: BaFilterParams) => void;
}

function BAStateFilters({
  filterParams,
  refreshData,
  onUpdate,
}: BAStateFiltersProps) {
  const [loading, setLoading] = useState(false);

  const [provinceOptions, setProvinceOptions] = useState<CheckboxOptionType[]>(
    []
  );

  const handleFetchProvinces = (search?: string) => {
    setLoading(true);
    BaFilterService.fetchProvinces(
      { ...filterParams, search, stateIds: [] },
      (provinces: Province[]) => {
        const province = provinces.map((province) => ({
          label: province.name,
          value: province.id as CheckboxValueType,
        }));
        province?.sort(
          (a, b) => a?.label?.localeCompare(b?.label ?? "") as any
        );
        setProvinceOptions(province);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    handleFetchProvinces();
  }, []);

  useEffect(() => {
    if (refreshData) {
      handleFetchProvinces();
    }
  }, [refreshData]);
  const handleSearch = debounce((searchText: string) => {
    handleFetchProvinces(searchText);
  }, 500);

  return (
    <div>
      <Row>
        <Col span={8}>
          <Input.Search
            className="ml-4 mb-5"
            placeholder="Search State Name"
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Checkbox.Group
          value={filterParams.stateIds}
          onChange={(stateIds) => {
            const params = Object.assign(new BaFilterParams(), filterParams);
            params.stateIds = stateIds as number[];
            onUpdate(params);
          }}
          options={provinceOptions}
        />
      )}
    </div>
  );
}

export default BAStateFilters;
