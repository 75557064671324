import * as Yup from "yup";

Yup.addMethod<Yup.ObjectSchema<any>>(
  Yup.array,
  "unique",
  function (
    message,
    message1,
    mapper = (a: any) => a,
    mapper1 = (a1: any) => a1
  ) {
    return this.test("unique", message, function (list) {
      // console.log(list?.length === new Set(list?.map(mapper)).size);
      // console.log({
      //   list,
      //   length: list.length,
      //   newSet: new Set(list?.map(mapper)).size,
      // });

      let condition = list?.every((elem: any, index: number) =>
        console.log(elem?.answer1, elem?.answer)
      );

      return (
        list?.length === new Set(list?.map(mapper)).size &&
        list?.length === new Set(list?.map(mapper1)).size
      );
    });
  }
);
/* declare module "yup" {
  interface NumberSchema<T extends number | null | undefined = number> {
      length(length: number, message: string): NumberSchema<T>;
  }
} */

// Yup.addMethod<Yup.ObjectSchema<any>>(
//   Yup.array,

//   "unique",

//   function (message: any, mapper = (a: any) => a) {
//     return this.test("unique", message, function (list: any) {
//       return list?.length === new Set(list?.map(mapper)).size;
//     });
//   }
// );

const arrayValidation: any = Yup.array().of(
  Yup.object().shape({
    answer1: Yup.string(),
  })
);

export const surveyQuestionValidation = Yup.object().shape({
  question: Yup.string().required("Question is required!"),
});
