import React, { useEffect } from "react";
import { DatePicker, TimePicker } from "antd";
import { ErrorMessage, Field } from "formik";
import Error from "../Error";
import "./datePickerField.scss";
import moment, { Moment } from "moment";

interface DatePickerFieldProps {
  name: string;
  title?: string;
  setFieldValue?: Function;
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  onChange?: (date: any, dateString: string) => void;
  disabledDate?: (date: Moment) => boolean;
  picker?: any;
  format?: string;
  required?: boolean;
  mode?: "date" | "year";
  type?: "time" | "date";
}

function DatePickerField(props: DatePickerFieldProps) {
  const {
    title,
    name,
    format,
    picker,
    mode,
    disabledDate,
    setFieldValue,
    defaultValue,
    onChange,
    disabled,
    type,
    required,
  } = props;

  const handleChange = (date: any, dateString: any) => {
    if (setFieldValue) {
      if (date) {
        type === "date"
          ? setFieldValue(name, date.format("YYYY-MM-DD"))
          : setFieldValue(name, date.format("YYYY-MM-DD HH:mm:ss"));
      } else {
        setFieldValue(name, null);
      }
    }
  };

  return (
    <Field name={name}>
      {({ field, form: { touched, errors }, meta }: any) => (
        <div className="date-picker-field">
          {title && (
            <div className="date-picker-field__title">
              {title} {required && <sup className="text-danger">*</sup>}
            </div>
          )}
          {type === "time" ? (
            <TimePicker
              className={`date-picker-${name}`}
              disabled={disabled}
              onChange={onChange || handleChange}
              disabledDate={disabledDate ? disabledDate : undefined}
              value={defaultValue ? moment(defaultValue) : undefined}
              format="hh:mm:ss a"
              name={name}
            />
          ) : (
            <DatePicker
              className={`date-picker-${name}`}
              disabled={disabled}
              picker={picker}
              onChange={onChange || handleChange}
              disabledDate={disabledDate ? disabledDate : undefined}
              value={defaultValue ? moment(defaultValue) : undefined}
              format={format || "DD/MM/YYYY"}
              name={name}
            />
          )}
          <ErrorMessage name={name}>
            {(message: string) => <Error message={message} />}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
}

export default DatePickerField;
