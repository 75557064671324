import React from "react";
import "./serviceProviderBasicDetails.scss";
import { ServiceProvider } from "../../../models/ServiceProvider/serviceProvider.model";
import { Row, Col, Tooltip } from "antd";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";

interface ServiceProviderBasicDetailsProps {
  serviceProvider?: ServiceProvider;
}

function ServiceProviderBasicDetails({
  serviceProvider,
}: ServiceProviderBasicDetailsProps) {
  return (
    <div className="service-provider-basic-details">
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <div className="service-provider-basic-details__label">
            Service Provider Name
          </div>
          <div className="service-provider-basic-details__value">
            {serviceProvider?.name || "-"}
          </div>
        </Col>
        <Col span={24}>
          <div className="service-provider-basic-details__label">
            Legal Entity Name
          </div>
          <div className="service-provider-basic-details__value">
            {serviceProvider?.productTypeDetail?.legalEntityName || "-"}
          </div>
        </Col>
        <Col span={24}>
          <div className="service-provider-basic-details__label">Email</div>
          <div className="service-provider-basic-details__value">
            {serviceProvider?.productTypeDetail?.email || "-"}
          </div>
        </Col>
        <Col span={24}>
          <div className="service-provider-basic-details__label">Mobile</div>
          <div className="service-provider-basic-details__value">
            {serviceProvider?.productTypeDetail?.mobile || "-"}
          </div>
        </Col>
        <Col span={24}>
          <div className="service-provider-basic-details__label">Address</div>
          <div className="service-provider-basic-details__value">
            {serviceProvider?.productTypeDetail?.address || "-"}
          </div>
        </Col>
        <Col span={24}>
          <Row gutter={[15, 0]}>
            <Col span={5}>
              <div className="service-provider-basic-details__label">Logo</div>
              <div className="service-provider-basic-details__value">
                {serviceProvider?.productTypeDetail?.logo ? (
                  <img
                    src={serviceProvider?.productTypeDetail?.logo}
                    alt="Logo"
                  />
                ) : (
                  "-"
                )}
              </div>
            </Col>
            <Col span={5}>
              <div className="service-provider-basic-details__label">
                Banner
              </div>
              <div className="service-provider-basic-details__value">
                {serviceProvider?.productTypeDetail?.banner ? (
                  <img
                    src={serviceProvider?.productTypeDetail?.banner}
                    alt="Banner"
                  />
                ) : (
                  "-"
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className="service-provider-basic-details__label">
            Contract Persons
          </div>
          {serviceProvider?.productTypeDetail?.contactPersons?.map(
            (contactPerson, i) => (
              <Row
                gutter={[20, 0]}
                align="middle"
                key={i}
                className="service-provider-basic-details__contact-person"
              >
                <Col span={6}>
                  <div className="service-provider-basic-details__name">
                    {contactPerson?.name}
                  </div>
                  <div className="service-provider-basic-details__description">
                    {contactPerson?.description}
                  </div>
                </Col>
                <Col span={8}>
                  <Tooltip
                    title={
                      <div>
                        <div>Email</div> <div>{contactPerson?.email}</div>
                      </div>
                    }
                  >
                    <MailOutlined />
                  </Tooltip>
                  <Tooltip
                    title={
                      <div>
                        <div>Mobile</div> <div>{contactPerson?.mobile}</div>
                      </div>
                    }
                  >
                    <PhoneOutlined />
                  </Tooltip>
                </Col>
              </Row>
            )
          )}
        </Col>
      </Row>
    </div>
  );
}

export default ServiceProviderBasicDetails;
