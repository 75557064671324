import { Button, Col, Row } from "antd";
import { useLocation, generatePath } from "react-router-dom";
import React, { FC, useEffect, useState } from "react";
import "./notificationBar.scss";
import { useHistory } from "react-router";

import avatarProfileImage from "../../../assets/images/userAvatarPlaceholder.jpeg";
import Notifications from "../../../services/Notification/notification.service";
import { Notification } from "../../../models/Notification/notification.model";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import AppLoader from "../AppLoader";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { BaFilterParams } from "../../../models/BAFilterParams/baFilterParams.model";
import moment from "moment";

interface NotificationBarProps {
  notifications?: Notification[];
  loading: boolean;
  handleNotificationVisibility: () => void;
}
const NotificationBar: FC<NotificationBarProps> = (props) => {
  const { handleNotificationVisibility } = props;
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [pagination, setPagination] = useState<PaginationDetails>();
  const history = useHistory();

  const handleFetchNotification = (
    filter?: BaFilterParams,
    loading?: boolean
  ) => {
    setLoading(loading ?? true);
    Notifications.fetchNotifications(
      filter ?? new BaFilterParams(),
      (values: Notification[], pagination: PaginationDetails) => {
        setNotifications([...notifications, ...values]);
        setPagination(pagination);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };
  useEffect(() => {
    handleFetchNotification();
  }, []);

  const handleRow = (notification: Notification) => {
    const notificationParam = { ...notification, status: "read" };

    Notifications.updateNotification(
      notificationParam,
      (notification: Notification[], pagination: PaginationDetails) => {
        handleNotificationVisibility();

        history.push({
          pathname: AppRoutes.SETTINGS,
          hash: "received-notifications",
          state: { updatedNotification: notification },
        });
      },
      () => {},
      () => {}
    );
  };
  const sample = [];

  return loading ? (
    <AppLoader loading={loading} />
  ) : (
    <div className="notification-bar">
      <h2 className="text-primary">Notifications</h2>

      {notifications.length > 0 ? (
        notifications?.map((val) => {
          return (
            <>
              <Row
                className={`mb-1 notification-bar__value ${
                  val?.status == "unread" ? `message-unread` : ""
                }`}
                onClick={() => handleRow(val)}
              >
                <Col span={3}>
                  <img
                    className="profile-pic"
                    src={val?.profilePic?.attachmentUrl ?? avatarProfileImage}
                    alt="image"
                  />
                </Col>
                <Col span={12}>
                  <p className="text-primary  notification-bar__title">
                    {val?.receivedFromName ?? ""}
                  </p>
                </Col>
                <Col span={9} className="text-right">
                  <span className="text-grey text-italic">
                    {moment(val?.receivedTime).format("MM/DD/YYYY  hh:mm a")}
                  </span>
                </Col>

                <Col span={3}></Col>
                <Col span={21}>{val?.notificationContent ?? ""}</Col>
              </Row>
            </>
          );
        })
      ) : (
        <h3 className="text-primary text-center mt-5">
          No new Notification to show
        </h3>
      )}
      {pagination?.nextPage && notifications.length > 0 && (
        <h4
          className="text-center text-primary pointer mt-2"
          onClick={() => {
            handleFetchNotification(
              {
                ...new BaFilterParams(),
                page: pagination?.nextPage,
              },
              false
            );
          }}
        >
          Show More
        </h4>
      )}
    </div>
  );
};

export default NotificationBar;
