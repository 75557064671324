import { AUTHENTICATED, UNAUTHENTICATED } from "../definitions/authConstants";
import { ActionProps } from "../../shared/types/action.type";
import { Employee } from "../../models/Employee/employee.model";

export const setAuthenticated = (employee: Employee): ActionProps => ({
  type: AUTHENTICATED,
  payload: {
    authenticated: true,
    employee,
  },
});

export const setUnAuthenticated = (): ActionProps => {
  return {
    type: UNAUTHENTICATED,
    payload: {
      authenticated: false,
      user: null,
    },
  };
};
