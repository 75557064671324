import React, { useState } from "react";
import "./customerForm.scss";
import PageTitle from "../../../shared/components/PageTitle";
import { Form, Formik } from "formik";
import { internalUserFormValidation } from "../../InternalUsers/InternalUserForm/internalUserFormValidation";
import { Button, Col, Row } from "antd";
import InputField from "../../../shared/components/InputField";
import DropdownField from "../../../shared/components/DropdownField";
import AddressField from "../../../shared/components/AddressField";
import { DropdownModeEnum } from "../../../enums/dropdownMode.enum";
import { customerFormValidation } from "./customerFormValidation";
import { Customer } from "../../../models/Customer/customer.model";
import DatePickerField from "../../../shared/components/DatePickerField";
import CustomerService from "../../../services/Customer/customer.service";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";

interface CustomerFormProps {}

function CustomerForm(props: CustomerFormProps) {
  const [formValues, setFormValues] = useState(new Customer());

  const [formLoading, setFormLoading] = useState(false);

  const history = useHistory();

  const genderOptions = [
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Others", value: "other" },
  ];

  const annualIncomeOptions = [
    { label: "Upto 5 Lakhs", value: "upto_5_lakhs" },
    { label: "5 Lakhs - 25 Lakhs", value: "upto_5_lakhs_25_lakhs" },
    { label: "25 Lakhs - 1 Crore", value: "upto_25_lakhs_1_crore" },
    { label: "Greater than 1 Crore", value: "greater_than_1_crore" },
  ];

  const professionOptions = [
    { label: "SALARIED", value: "salaried" },
    { label: "HOUSEWIFE", value: "housewife" },
    { label: "STUDENT", value: "student" },
    { label: "RETIRED / PENSIONER", value: "retired_pensioner" },
    { label: "AGRICULTURIST", value: "agriculturist" },
    {
      label: "INDV - SELF EMPLOYED - PROFESSIONAL",
      value: "indv_self_employed_professional",
    },
    {
      label: "INDV - SELF EMPLOYED - NON-PROFESSIONAL",
      value: "indv_self_employed_non_professional",
    },
    {
      label: "POLITICIAN",
      value: "politician",
    },
    {
      label: "FIN SERVICES -STOCK BROKERS/INVESMENT/MUTUAL FUND",
      value: "fin_services_stock_brokers_investment_mutual_fund",
    },
    {
      label: "BUILDERS AND REAL ESTATE,INFRASTRUCTURE DEVELOPERS",
      value: "builders_and_real_estate_infra_developers",
    },
    {
      label: "IMPORTERS / EXPORTERS",
      value: "importers_exporters",
    },
    {
      label: "SERVICE SECTOR",
      value: "service_sector",
    },
    {
      label: "SELF EMPLOYED - PROFESSIONAL",
      value: "self_employed_professional",
    },
    {
      label: "SELF EMPLOYED - NON-PROFESSIONAL / TRADERS",
      value: "self_employed_non_professional",
    },
    {
      label: "JEWELLER / BULLION TRADER",
      value: "jeweller_bullion_trader",
    },
    {
      label: "MANUFACTURING",
      value: "manufacturing",
    },
    {
      label: "PUBLIC UTILITIES AND SERVICES",
      value: "public_utilities_services",
    },
    {
      label: "ARMS/WEAPONS/ANTIQUES - DEALERS OR MANUFACTURERS",
      value: "arms_weapons_antiques_dealers_manufacturers",
    },
    {
      label: "GOVERNMENT / SEMI-GOVERNMENT / LOCAL BODIES",
      value: "government_semi_government_local_bodies",
    },
    { label: "OTHERS - PLEASE SPECIFY", value: "other" },
  ];

  const handleSubmit = (values: Customer) => {
    const customer = Object.assign(new Customer(), values);
    customer.setAddresses();
    setFormLoading(true);
    CustomerService.createCustomer(
      customer,
      () => {
        history.push(AppRoutes.CUSTOMERS);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  return (
    <div className="customer-form">
      <PageTitle title="Customer" />
      <div className="customer-form__form-wrapper">
        <h3 className="text-primary"> Customer Details</h3>
        <div className="customer-form__form-card">
          <Formik
            enableReinitialize
            initialValues={formValues}
            onSubmit={handleSubmit}
            validationSchema={customerFormValidation}
          >
            {({ values, errors, isValid, dirty, setFieldValue }) => {
              return (
                <Form>
                  <Row gutter={[20, 0]}>
                    <Col span={12}>
                      <InputField
                        title="First name"
                        type="text"
                        name="firstName"
                        placeholder="Enter First name"
                        required
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Last name"
                        type="text"
                        name="lastName"
                        placeholder="Enter Last name"
                        required
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Email"
                        type="email"
                        name="email"
                        placeholder="Enter email"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        required
                        title="Phone"
                        type="text"
                        name="mobile"
                        placeholder="Enter Phone"
                      />
                    </Col>
                    <Col span={12}>
                      <DropdownField
                        title="Gender"
                        name="gender"
                        options={genderOptions}
                        value={values.gender}
                        setFieldValue={setFieldValue}
                        placeHolder="Select Gender"
                      />
                    </Col>
                    <Col span={12}>
                      <DatePickerField
                        title="DOB"
                        name="dob"
                        placeholder=""
                        /*disabledDate={(d) => {
                                const age = moment.duration(moment().diff(d));
                                return age.years() < 18;
                              }}*/
                        defaultValue={values.dob}
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                    <AddressField
                      provinceKey="residentialAddress.provinceId"
                      provinceValue={values?.residentialAddress?.provinceId}
                      cityKey="residentialAddress.cityId"
                      cityValue={values?.residentialAddress?.cityId}
                      setFieldValue={setFieldValue}
                      values={values}
                      zipcodeKey="residentialAddress.zipcode"
                      streetKey="residentialAddress.street"
                      onCityChange={(value) => {
                        setFieldValue("residentialAddress.cityId", value);
                      }}
                    />
                    <Col span={12}>
                      <DropdownField
                        title="Occupation"
                        name="profession"
                        options={professionOptions}
                        value={values.profession}
                        setFieldValue={setFieldValue}
                        placeHolder="Select Occupation"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Aadhaar number"
                        type="text"
                        name="aadhaarNumber"
                        placeholder="Enter Aadhaar"
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="PAN"
                        type="text"
                        name="panNumber"
                        placeholder="Enter PAN"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFieldValue(
                            "panNumber",
                            e.target.value.toUpperCase()
                          );
                        }}
                      />
                    </Col>
                    <Col span={24} className="text-right mt-4">
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={formLoading}
                        disabled={!isValid || formLoading}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default CustomerForm;
