import * as Yup from "yup";

export const resetPasswordFormValidation = Yup.object().shape({
  passwordResetOTP: Yup.string()
    .min(4, "OTP should have only 4 digits!")
    .max(4, "OTP should have only 4 digits!")
    .required("OTP is required!"),
  password: Yup.string()
    .min(6, "Password has to be longer than 6 characters!")
    .required("Password is required!"),
  confirmPassword: Yup.string()
    .required("Password confirmation is required!")
    .oneOf([Yup.ref("password"), ""], "Passwords must match!"),
});
