import React, { useEffect, useState } from "react";
import "./serviceProviderDetail.scss";
import PageTitle from "../../../shared/components/PageTitle";
import { useParams } from "react-router-dom";
import { ServiceProvider } from "../../../models/ServiceProvider/serviceProvider.model";
import ServiceProviderService from "../../../services/ServiceProvider/serviceProvider.service";
import { Tabs } from "antd";
import CategoryList from "../../MetaConfiguration/CategoryList";
import ProductTypeList from "../../MetaConfiguration/ProductTypeList";
import BrokerageGroupList from "../../MetaConfiguration/BrokerageGroupList";
import ServiceProviderBasicDetails from "../ServiceProviderBasicDetails";
import ServiceProviderProducts from "../ServiceProviderProducts";
const { TabPane } = Tabs;

interface ServiceProviderDetailProps {}

interface ServiceProviderDetailParams {
  serviceProviderId: string;
}

function ServiceProviderDetail(props: ServiceProviderDetailProps) {
  const params: ServiceProviderDetailParams = useParams();

  const [loading, setLoading] = useState(false);

  const [serviceProvider, setServiceProvider] = useState<ServiceProvider>();

  const configTabs = [
    {
      title: "Basic Details",
      component: (
        <ServiceProviderBasicDetails serviceProvider={serviceProvider} />
      ),
    },
    {
      title: "Products",
      component: <ServiceProviderProducts serviceProvider={serviceProvider} />,
    },
  ];

  useEffect(() => {
    if (params.serviceProviderId) {
      setLoading(true);
      ServiceProviderService.showServiceProvider(
        Number(params.serviceProviderId),
        (serviceProvider: ServiceProvider) => {
          setServiceProvider(serviceProvider);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, []);

  return (
    <div className="service-provider-detail">
      <PageTitle title={serviceProvider?.name || "Service Provider"} />
      <Tabs defaultActiveKey="0" size="small" style={{ marginTop: 32 }}>
        {configTabs.map(({ title, component }, i) => (
          <TabPane tab={title} key={i}>
            {component}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
}

export default ServiceProviderDetail;
