import React, { useState } from "react";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import { Button, Modal } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { Form, Formik } from "formik";
import DropdownField from "../../../shared/components/DropdownField";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import "./baDocuments.scss";
import { BusinessAssociateService } from "../../../services/BusinessAssociateService/businessAssociate.service";

interface BADocumentsProps {
  businessAssociate: BusinessAssociate;
  onUpdateSuccess: (ba: BusinessAssociate) => void;
}

function BADocuments({ businessAssociate, onUpdateSuccess }: BADocumentsProps) {
  const [showDocumentForm, setShowDocumentForm] = useState(false);

  const [formValues, setFormValues] = useState(businessAssociate);

  const [formLoading, setFormLoading] = useState(false);

  const handleToggleDocumentForm = () => setShowDocumentForm(!showDocumentForm);

  const [selectedDocumentType, setSelectedDocumentType] =
    useState("aadharFrontPageId");

  const documentTypes = [
    { label: "Aadhar Front Side", value: "aadharFrontPageId" },
    { label: "Aadhar Back Side", value: "aadharBackPageId" },
    { label: "Other Identity Proof", value: "otherIdentityProofId" },
    { label: "PAN", value: "panCardId" },
    { label: "Bank Statement", value: "bankDetail.statementId" },
    { label: "Cancelled Cheque", value: "bankDetail.cancelledChequeId" },
  ];

  const handleSubmit = (values: BusinessAssociate) => {
    setFormValues(values);
    const ba = Object.assign(new BusinessAssociate(), values);
    setFormLoading(true);
    BusinessAssociateService.updateBusinessAssociate(
      ba,
      (ba: BusinessAssociate) => {
        setShowDocumentForm(false);
        onUpdateSuccess(ba);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  return (
    <div className="ba-documents">
      <div className="ba-documents__upload-document">
        <Button type="primary" onClick={handleToggleDocumentForm}>
          <PaperClipOutlined /> Upload Document
        </Button>
      </div>
      <div className="ba-documents__files">
        <table>
          <thead>
            <th className="ba-documents__th">FILE TYPE</th>
            <th className="ba-documents__th">LINK</th>
          </thead>
          <tbody>
            {businessAssociate?.aadharFrontPage && (
              <tr>
                <td>Aadhar Front Side</td>
                <td>
                  <a
                    href={businessAssociate?.aadharFrontPage?.attachmentUrl}
                    target="_blank"
                  >
                    Click to view
                  </a>
                </td>
              </tr>
            )}
            {businessAssociate?.aadharBackPage && (
              <tr>
                <td>Aadhar Back Side</td>
                <td>
                  <a
                    href={businessAssociate?.aadharBackPage?.attachmentUrl}
                    target="_blank"
                  >
                    Click to view
                  </a>
                </td>
              </tr>
            )}
            {businessAssociate?.otherIdentityProof && (
              <tr>
                <td>Other Identity Proof</td>
                <td>
                  <a
                    href={businessAssociate?.otherIdentityProof?.attachmentUrl}
                    target="_blank"
                  >
                    Click to view
                  </a>
                </td>
              </tr>
            )}
            {businessAssociate?.panCard && (
              <tr>
                <td>PAN</td>
                <td>
                  <a
                    href={businessAssociate?.panCard?.attachmentUrl}
                    target="_blank"
                  >
                    Click to view
                  </a>
                </td>
              </tr>
            )}
            {businessAssociate?.bankDetail?.statement && (
              <tr>
                <td>Bank Statement</td>
                <td>
                  <a
                    href={
                      businessAssociate?.bankDetail?.statement?.attachmentUrl
                    }
                    target="_blank"
                  >
                    Click to view
                  </a>
                </td>
              </tr>
            )}
            {businessAssociate?.bankDetail?.cancelledCheque && (
              <tr>
                <td>Cancelled Cheque</td>
                <td>
                  <a
                    href={
                      businessAssociate?.bankDetail?.cancelledCheque
                        ?.attachmentUrl
                    }
                    target="_blank"
                  >
                    Click to view
                  </a>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Modal
        onCancel={handleToggleDocumentForm}
        visible={showDocumentForm}
        destroyOnClose
        footer={null}
        className="ba-documents__modal"
      >
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={null}
        >
          {({ values, errors, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <DropdownField
                  title="Document Type"
                  name="documentType"
                  options={documentTypes}
                  value={selectedDocumentType}
                  onChange={(value) => {
                    setSelectedDocumentType(value);
                  }}
                  setFieldValue={setFieldValue}
                  placeHolder="Select Document Type"
                />
                <AttachmentUpload
                  accept="image/jpeg,image/png,image/jpg, application/pdf"
                  name={selectedDocumentType}
                  placeholder="Upload File"
                  setFieldValue={setFieldValue}
                />
                <div className="text-right mt-5">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={formLoading}
                    loading={formLoading}
                  >
                    Upload
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}

export default BADocuments;
