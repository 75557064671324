import React, { useEffect, useState } from "react";
import "./appSidebar.scss";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import logo from "../../../assets/images/logoLight.png";
import { Menu, Popover, Drawer } from "antd";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import dashboardIcon from "../../../assets/images/dashboard.png";
import individualBAIcon from "../../../assets/images/individualBa.png";
import internalUsersIcon from "../../../assets/images/internalUsers.png";
import customersIcon from "../../../assets/images/customers.png";
import ordersIcon from "../../../assets/images/customers.png";
import metaConfigurationIcon from "../../../assets/images/metaConfiguration.png";
import serviceProviderIcon from "../../../assets/images/serviceProvider.png";
import logoutIcon from "../../../assets/images/logOut.png";
import chanagePasswordIcon from "../../../assets/images//changePassword.png";
import settingIcon from "../../../assets/images/settings.png";
import myProfileIcon from "../../../assets/images/myProfile.png";
import myOrderIcon from "../../../assets/images/orders.png";
import myLearningIcon from "../../../assets/images/my-learning.png";
import activityLogIcon from "../../../assets/images/activity-log.png";
import productRequestIcon from "../../../assets/images/product-requests.png";
import settlementsIcon from "../../../assets/images/settlements.png";
import ChangePassword from "../../../views/ChangePassword";
import Settlements from "../../../views/Settlements";

interface AppSidebarProps extends AuthReducerProps {}

function AppSidebar({ authenticated, setUnAuthenticated }: AppSidebarProps) {
  const history = useHistory();

  const [pathname, setPathname] = useState<string>(history.location.pathname);
  const [form, setForm] = useState(false);

  const [ispopOverVisible, setIspopOverVisible] = useState(false);
  const hideSidebarRoutes = [
    AppRoutes.TERMS_AND_CONDITIONS,
    AppRoutes.PRIVACY_POLICY,
    AppRoutes.CUSTOMER_TERMS_AND_CONDITIONS,
    AppRoutes.BAJAJ_PAYMENTS,
  ];

  const handleForm = () => setForm(!form);

  const handlePopOverVisibility = () => setIspopOverVisible(!ispopOverVisible);

  const handleClick = ({ key }: any) => {
    if (key !== "logout") {
      history.push(key);
    } else {
      localStorage.clear();
      setUnAuthenticated();
      history.push(AppRoutes.LOGIN);
    }
  };

  useEffect(() => {
    return history.listen((location) => {
      setPathname(location.pathname);
    });
  }, [history]);

  const popupContent = (
    <div onClick={handlePopOverVisibility}>
      <Menu
        className="app-sidebar__popup"
        onClick={handleClick}
        defaultSelectedKeys={["dashboard"]}
        selectedKeys={[pathname]}
        mode="vertical"
      >
        <Menu.Item disabled key="#">
          <img src={myProfileIcon} alt="My Profile" />
          <span className="app-sidebar__menu-title">My Profile</span>
        </Menu.Item>
        <Menu.Item key="#" onClick={handleForm}>
          <img src={chanagePasswordIcon} alt="Change Password" />
          <span className="app-sidebar__menu-title">Change Password</span>
        </Menu.Item>
        <Menu.Item key={AppRoutes.SETTINGS}>
          <img src={settingIcon} alt="Setting" />
          <span className="app-sidebar__menu-title">Setting</span>
        </Menu.Item>

        <Menu.Item key="logout" className="app-sidebar__logout-item">
          <img src={logoutIcon} alt="Logout" />
          <span className="app-sidebar__menu-title">Logout</span>
        </Menu.Item>
      </Menu>
    </div>
  );

  return authenticated && !hideSidebarRoutes.includes(pathname) ? (
    <div className="app-sidebar">
      <div className="app-sidebar__content">
        <img src={logo} className="app-sidebar__logo" alt="Logo" />
        <Menu
          className="app-sidebar__menubar"
          onClick={handleClick}
          defaultSelectedKeys={["dashboard"]}
          selectedKeys={[pathname]}
          mode="vertical"
        >
          <Menu.Item key={AppRoutes.HOME}>
            <img src={dashboardIcon} alt="Dashboard" />
            <span className="app-sidebar__menu-title">Dashboard</span>
          </Menu.Item>
          <Menu.Item key={AppRoutes.INDIVIDUAL_BA}>
            <img src={individualBAIcon} alt="Individual BA" />
            <span className="app-sidebar__menu-title">Individual BA</span>
          </Menu.Item>
          <Menu.Item key={AppRoutes.INTERNAL_USERS}>
            <img src={internalUsersIcon} alt="Internal Users" />
            <span className="app-sidebar__menu-title">Internal Users</span>
          </Menu.Item>
          <Menu.Item key={AppRoutes.CUSTOMERS}>
            <img src={customersIcon} alt="Customers" />
            <span className="app-sidebar__menu-title">Customers</span>
          </Menu.Item>
          <Menu.Item key={AppRoutes.ORDERS}>
            <img src={myOrderIcon} alt="Orders" />
            <span className="app-sidebar__menu-title">Orders</span>
          </Menu.Item>
          <Menu.Item key={AppRoutes.META_CONFIGURATION}>
            <img src={metaConfigurationIcon} alt="Meta Configuration" />
            <span className="app-sidebar__menu-title">Meta Configuration</span>
          </Menu.Item>
          <Menu.Item key={AppRoutes.SERVICE_PROVIDERS}>
            <img src={serviceProviderIcon} alt="Service Providers" />
            <span className="app-sidebar__menu-title">Service Providers</span>
          </Menu.Item>
          <Menu.Item key={AppRoutes.PRODUCT_REQUESTS}>
            <img src={productRequestIcon} alt="Product Requests" />
            <span className="app-sidebar__menu-title">Product Requests</span>
          </Menu.Item>
          <Menu.Item key={AppRoutes.SETTLEMENTS}>
            <img src={settlementsIcon} alt="Settlements" />
            <span className="app-sidebar__menu-title">Settlements</span>
          </Menu.Item>
          <Menu.Item key={AppRoutes.MY_LEARNING}>
            <img src={myLearningIcon} alt="My Learning" />
            <span className="app-sidebar__menu-title">My learning</span>
          </Menu.Item>
          <Menu.Item key={AppRoutes.ACTIVITY_LOG}>
            <img src={activityLogIcon} alt="Activity Log" />
            <span className="app-sidebar__menu-title">Activity Log</span>
          </Menu.Item>
          <Menu.Item key="#">
            <img src={myProfileIcon} alt="My Profile" />
            <Popover
              placement={"topRight"}
              content={popupContent}
              trigger="click"
              visible={ispopOverVisible}
              onVisibleChange={() => setIspopOverVisible(!ispopOverVisible)}
            >
              <span className="app-sidebar__menu-title">My Profile</span>
            </Popover>
          </Menu.Item>
        </Menu>
        <Drawer
          width="35vw"
          onClose={handleForm}
          visible={form}
          destroyOnClose
          footer={null}
        >
          <ChangePassword />
        </Drawer>
      </div>
    </div>
  ) : null;
}

export default AuthContainer(AppSidebar);
