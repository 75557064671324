import React from "react";
import "./privacyPolicy.scss";

interface PrivacyPolicyProps {}

function PrivacyPolicy(props: PrivacyPolicyProps) {
  return (
    <div className="privacy-policy">
      <h2 className="text-primary">Privacy Policy</h2>
      <h4>Overview</h4>
      <p>
        In this policy,
        <ul>
          <li>“We,” “us,” and “our” means MyLead Fintech Pvt. Ltd(“MLFT”);</li>
          <li>
            The “Site” means any web site, mobile site, or application we
            provide that includes a link to this Online Privacy Policy;
          </li>
          <li>
            The “Service” means any information, documents, assistance, or
            services we may provide to you through our Site; and
          </li>
          <li>
            “You” and “your” mean anyone who visits, accesses, or uses our
            mobile application (Finobuddy) / website (myleadfintech.com) or
            obtains Services from us through our website(finobuddy.com).
          </li>
        </ul>
      </p>
      <p>
        At MLFT we are strongly committed to protect the personal and financial
        information that you submit to us and endeavour to protect it from
        unauthorized use. To protect your personal information from unauthorized
        access and use, we use security measures that comply with the applicable
        laws.
      </p>
      <h4>Collection of Information </h4>
      <p>
        While using this Site or availing the products and services, MLFT and
        its affiliates/ subsidiaries may become privy to the personal
        information of its users, including information that is of a
        confidential & sensitive nature. MLFT is strongly committed to
        protecting the privacy of its users and has taken all necessary and
        reasonable measures to protect the confidentiality of the user
        information and its transmission through the world wide web and it shall
        not be held liable for disclosure of the confidential information when
        in accordance with this Privacy Commitment or in terms of the
        agreements, if any, with the Users. In addition, like most Site, we use
        small bits of data called ‘cookies’ stored on user’s computers to
        stimulate a continuous connection. Cookies allow us storage of
        information about your preferences and certain session information that
        allow you to move to different pages of our secure site without having
        to re-enter your information. Any information collected is stored in
        secure databases protected via a variety of access controls and is
        treated as confidential information by us. You should be responsible in
        safe custody and usage of the username and password by maintaining
        strict confidentiality of the same and ensure that you do not knowingly
        or accidentally share, provide and facilitate unauthorized use of it by
        any other person.
      </p>
      <h4>Use and disclosure of Personal & Financial Information</h4>
      <p>
        We understand clearly that You and Your Personal Information is one of
        our most important assets. We store and process Your Information
        including any sensitive financial information collected (as defined
        under the Information Technology Act, 2000), if any, on computers that
        may be protected by physical as well as reasonable technological
        security measures and procedures in accordance with Information
        Technology Act 2000 and Rules there under. If You object to Your
        Information being transferred or used in this way, please do not provide
        the details of your information on the Mobile application/website. MLFT
        may, for the purpose of rendering its services, collect personal
        information such as:
      </p>
      <ol>
        <li>
          Name, gender, residential / correspondence address, telephone number,
          date of birth, marital status, email address or;
        </li>
        <li>Other contact information;</li>
        <li>Aadhaar, PAN, KYC Status, Signature and Photograph;</li>
        <li>Bank account or other payment instrument details;</li>
        <li>Any other detail for providing services.</li>
      </ol>
      <p>
        MLFT offers online platform for offering financial and non financial
        products and services to you and to carry out the transaction that
        collects such personal data or information to process your transaction
        requests. The information so collected may be shared with other group
        companies/ affiliates/subsidiaries/Product owner etc. solely for the
        purpose of processing your transaction requests or serving you better.
        When you register with this Site, you agree that we or any of our
        affiliate/ group companies/subsidiaries may contact you from time to
        time to provide the offers/ information of such products/ services. The
        information collected shall be used for the purpose for which it has
        been collected. We will protect your personal information against
        unauthorized use, dissemination or publication in the same way we would
        protect our confidential information of similar nature. However, we may
        be required to disclose your personal and financial information to the
        statutory authorities relating to any legal process that may be
        initiated by such authorities in accordance with applicable laws. We may
        use the personal information to improve our services to you and to keep
        you updated about our new products or other information that may be of
        interest to you. We may share information during normal business
        operations, such as providing services you have subscribed for, and any
        activity related to these services. It may become necessary for MLFT to
        disclose your personal information to its Business Associates and Life
        Coach’s during normal business operations for the above referred
        purpose. However, these parties would be required to use the information
        obtained from MLFT for such purposes exclusively. MLFT will endeavour to
        take all reasonable steps to ensure that the confidentiality of your
        information is maintained by imposing strict confidentiality standards
        on all the third parties to whom it discloses such information. We may
        occasionally invite selected third parties to participate in offers we
        feel would be attractive to customers of MLFT. If you desire MLFT to
        limit such sharing whereby you would not like to be informed of offers
        available you may contact us at [support@finobuddy.com] or unsubscribe
        to such services. Otherwise, MLFT will use the customer/user information
        to improve the visitor experience on the mobile application / site and
        make subsequent offers to the visitor on products which may be of
        interest to him/her. Notwithstanding whether your telephone/mobile is
        registered with NDNC and or notwithstanding the Telecom Regulatory
        Authority of India [TRAI] restrictions/guidelines on unsolicited
        telecalls, by your accessing this Site fully or in an incomplete manner
        or by enquiring/verifying any of the products/services offered by MLFT
        and or your making a request to call back, it is deemed that you have
        expressly authorized MLFT to call back or send you text messages or
        Chatbots for solicitation and procurement of various products and
        services of MLFT. In this regard you shall not have any complaint nor
        complain to TRAI as to any alleged unsolicited calls by MLFT to you.
        MLFT may from time to time, during the course of your utilisation of the
        Site, require access to certain additional information only after
        obtaining Your additional explicit consent. Such additional information
        may include: (i) Your SMS information stored on your device, (ii) Your
        location information (IP address, longitude and latitude information),
        for verifying the location to verify location and to check the
        feasibility of MLFT Network’s serviceability, (iii) Your device and/or
        call log details/ contact details, to prevent fraud and to stop
        unauthorized device access of MLFT Network on your behalf, and (iv) Your
        email details/access to verify your credentials including your conduct
        on online platform. Your information is collected for providing better
        service in respect of MLFT to you, and to comply with applicable laws /
        regulations (if any). You hereby agree and acknowledge that MLFT may, to
        the extent permitted by applicable law/regulations, share or process
        your information in connection with the usage of the services of MLFT
        and collected by MLFT from you while accessing the MLFT Network, Group
        Companies, Corporate Partners, subsidiary companies, its affiliates
        and/or any third party, including but not limited to the purposes, such
        as, to complete the transaction initiated by you, to render service to
        you and/or to enhance the MLFT experience for you, to offer new products
        in terms of manner of such collection, usage and storage is governed by
        the Privacy Terms stated herein the agreement. We and our group
        companies/affiliates/subsidiaries will share/transfer/convey some or all
        of your personal information  including but not limited to identity
        information as defined under Aadhaar (Targeted Delivery of Financial and
        Other Subsidies, Benefits and Services) Act, 2016, with another business
        entity to carry out any business activity or reorganization,
        amalgamation, restructuring of business or for any other reason
        whatsoever. Once you provide your information to us, you provide such
        information to us and our affiliate and we and our affiliate may use
        such information to provide you various services with respect to your
        transaction conducted on the Site. You authorise MLFT to exchange,
        share, part with all information related to the details and transaction
        history of the customers/users to its banks/financial
        institutions/credit bureaus/agencies/regulators participation in any
        telecommunication or electronic clearing network as may be required by
        law, customary practice, credit reporting, statistical analysis and
        credit scoring, verification or risk management and shall not hold MLFT
        liable for use or disclosure of this information. Other mobile
        application/websites that you may access via this Site may have
        different privacy policies and access to such mobile
        application/websites will not be subject to this privacy policy. We
        recommend that you read the privacy statement of each such mobile
        application/website to find out how they protect your personal
        information. We use an SSL certificate to secure the information you
        share with us and its transmission. Because no data transmission is
        completely secure, and no system of physical or electronic security is
        impenetrable, we cannot guarantee the security of the information you
        send to us or the security of our servers, networks or databases. By
        using the mobile application / website and its Services, you: (a) agree
        to assume all risk relating to the information sent to us or collected
        by us when you access, visit and/or use the mobile application / website
        and its Services (including without limitation your personally
        identifiable information or other Registration Information); and (b)
        agree that we are not responsible for any loss of such information or
        any consequences that may result from any loss of such information. Due
        to changes in legislation or enhancements to functionality and content
        on the mobile application / website, we may make changes to privacy
        policy (without being obliged to do so) and would reflect those changes
        in this privacy policy statement. Hence you are requested to go through
        the privacy policy statement on a regular basis. Please note that this
        privacy policy does not create any contractual or other legal rights in
        or on behalf of any party, nor is it intended to do so. By agreeing to
        avail the service offered by MLFT, you have agreed to the collection and
        use of your Sensitive Personal Data or Information by MLFT. You always
        have the right to refuse or withdraw your consent to share/dissemination
        of your Sensitive Personal Data or Information by contacting
        [support@finobuddy.com]. However, in such an event, you would no longer
        avail the services of MLFT. Once we have received notification that you
        have withdrawn your consent, we will no longer process your information
        for the purpose(s) to which you originally consented unless we are
        required to retain your data for a legal or regulatory purpose.
      </p>
      <h4>Offers and Recommendations </h4>
      <p>
        I authorize MLFT to use my credit information to provide personalized
        offer and/or recommend Products and Services based on the eligibility
        creteria of such Products and Services to me from time to time. I
        acknowledge receiving notification of offers, to be contacted by MLFT
        through various communication channels and contact details provided by
        me to MLFT.  I expressly waive any restriction rights available to me or
        any NDNC request made by me under the Telecom Commercial Communications
        Customer Preference Regulations in relation to solicitation, offers made
        by MLFT. I acknowledge and agree that any credit facility provided to me
        pursuant to any activities on the Site shall be governed by credit
        criteria of such lending partners and there may be additional terms and
        conditions which shall govern such transactions conducted by me on the
        Site.
      </p>
      <h4>Other terms and conditions</h4>
      <p>
        <ol>
          <li>
            I agree that the terms and conditions may be amended from time to
            time without any notice; which changes shall be available on the
            Site. I understand that MLFT may at its sole discretion may amend,
            discontinue the Services in part or whole as it deems fit at any
            time without a requirement of prior notice or consent.
          </li>
          <li>
            I understand that MLFT reserves all the rights to suspend,
            terminate, my account at its sole discretion at any time for any
            reason but not limiting to breach of the terms of use by me, of if
            the information provided by me is incorrect, obtained or is intended
            to be used for fraudulent purposes.
          </li>
          <li>
            Any disputes relating to the credit information can be raised with
            respective bureau on their mobile application/website.
          </li>
          <li>
            I understand that MLFT is only acting as a facilitator for providing
            a Platform for me to access my credit information and MLFT does not
            warrant that the functions contained in on the Site will be
            uninterrupted or error free, free of viruses or other harmful
            components, but shall take commercially reasonable efforts to
            correct the delays in operation of the Site.
          </li>
          <li>
            In this document; <br /> “MLFT”, “Company” means MyLead FinTech
            Private Limited; The “Site” means any web site, mobile site, or
            application of MLFT The “Service/s” means any information,
            documents, assistance, or services we may provide to you through our
            Site including but not limiting to credit information; <br /> and
            “I” “me” “my” and “myself” “Customer” mean anyone who visits,
            accesses, or uses our Site or requests Services from us through our
            Site. <br /> “Bureau” shall mean credit information company which is
            authorized under the Laws of India to provide comprehensive credit
            information by collecting, collating and issuing credit information,
            pertaining to both commercial and consumer borrowers, to a closed
            user group of members. <br /> In relation to provision of the
            Services mentioned herein; Bureau shall mean “TransUnion CIBIL”.
          </li>
        </ol>
        <p>
          If you require any further information, please do not hesitate to
          contact [support@finobuddy.com]
        </p>
      </p>
    </div>
  );
}

export default PrivacyPolicy;
