import { serializable, alias, object, list, primitive } from "serializr";

export class OrderFilterParams {
  @serializable(alias("search_text", primitive()))
  search?: string;

  @serializable(alias("page", primitive()))
  page?: number;

  @serializable(alias("product_type", primitive()))
  productTypeValue?: string;
}
