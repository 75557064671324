import React, { useState } from "react";
import "./baPersonalDetails.scss";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import { Button, Col, Modal, Row } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import { aadharFormValidation } from "./aadharFormValidation";
import { BusinessAssociateService } from "../../../services/BusinessAssociateService/businessAssociate.service";
import { panFormValidation } from "./panFormValidation";
import { maskNumber } from "../../../shared/utils/maskNumber";

interface BAPersonalDetailsProps {
  businessAssociate: BusinessAssociate;
  onUpdateSuccess: (ba: BusinessAssociate) => void;
}

function BAPersonalDetails({
  businessAssociate,
  onUpdateSuccess,
}: BAPersonalDetailsProps) {
  const [showAadharForm, setShowAadharForm] = useState(false);

  const [showPanForm, setShowPanForm] = useState(false);

  const [formLoading, setFormLoading] = useState(false);

  const [formValues, setFormValues] =
    useState<BusinessAssociate>(businessAssociate);

  const handleSubmit = (values: BusinessAssociate) => {
    const ba = Object.assign(new BusinessAssociate(), values);
    setFormLoading(true);
    BusinessAssociateService.updateBusinessAssociate(
      ba,
      (ba: BusinessAssociate) => {
        setShowPanForm(false);
        setShowAadharForm(false);
        onUpdateSuccess(ba);
      },
      () => {},
      () => {
        setFormLoading(false);
      }
    );
  };

  const handlePanSubmit = (values: BusinessAssociate) => {};

  const handleToggleAadharForm = () => setShowAadharForm(!showAadharForm);

  const handleTogglePanForm = () => setShowPanForm(!showPanForm);

  return (
    <div className="ba-personal-details">
      <div className="ba-personal-details__section">
        <h3 className="text-primary">Basic Details</h3>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <div className="ba-personal-details__label">Address</div>
            <div className="ba-personal-details__value">
              {businessAssociate?.addresses[0]?.street || "-"}{" "}
              {businessAssociate?.addresses[0]?.city?.name || "-"}{" "}
              {businessAssociate?.addresses[0]?.city?.provinceName || "-"}{" "}
              {businessAssociate?.addresses[0]?.zipcode || "-"}
            </div>
          </Col>
          <Col span={12}>
            <div className="ba-personal-details__label">Mobile</div>
            <div className="ba-personal-details__value">
              {businessAssociate?.mobile || "-"}
            </div>
          </Col>
          <Col span={12}>
            <div className="ba-personal-details__label">Email</div>
            <div className="ba-personal-details__value">
              {businessAssociate?.email || "-"}
            </div>
          </Col>
        </Row>
      </div>

      <div className="ba-personal-details__section">
        <h3 className="text-primary">Highest Educational Qualification</h3>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <div className="ba-personal-details__label">
              University/Institution
            </div>
            <div className="ba-personal-details__value">
              {businessAssociate?.qualifications &&
              businessAssociate?.qualifications?.length > 0
                ? businessAssociate.qualifications[0].institutionName
                : "-"}
            </div>
          </Col>
          <Col span={12}>
            <div className="ba-personal-details__label">Degree name</div>
            <div className="ba-personal-details__value">
              {businessAssociate?.qualifications &&
              businessAssociate?.qualifications?.length > 0
                ? businessAssociate.qualifications[0].specialityName
                : "-"}
            </div>
          </Col>
          <Col span={12}>
            <div className="ba-personal-details__label">
              Year of Certification
            </div>
            <div className="ba-personal-details__value">
              {businessAssociate?.qualifications &&
              businessAssociate?.qualifications?.length > 0
                ? businessAssociate.qualifications[0].yearOfCompletion
                : "-"}
            </div>
          </Col>
        </Row>
      </div>
      <div className="ba-personal-details__section">
        <h3 className="text-primary">Professional qualification</h3>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <div className="ba-personal-details__label">
              University/Institution
            </div>
            <div className="ba-personal-details__value">
              {businessAssociate?.qualifications &&
              businessAssociate?.qualifications?.length > 1
                ? businessAssociate.qualifications[1].institutionName
                : "-"}
            </div>
          </Col>
          <Col span={12}>
            <div className="ba-personal-details__label">Degree name</div>
            <div className="ba-personal-details__value">
              {businessAssociate?.qualifications &&
              businessAssociate?.qualifications?.length > 0
                ? businessAssociate.qualifications[1].specialityName
                : "-"}
            </div>
          </Col>
          <Col span={12}>
            <div className="ba-personal-details__label">
              Year of Certification
            </div>
            <div className="ba-personal-details__value">
              {businessAssociate?.qualifications &&
              businessAssociate?.qualifications?.length > 0
                ? businessAssociate.qualifications[1].yearOfCompletion
                : "-"}
            </div>
          </Col>
        </Row>
      </div>
      <div className="ba-personal-details__section">
        <h3 className="text-primary">KYC Verification</h3>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <div className="ba-personal-details__label">Aadhar</div>
            <div className="ba-personal-details__value">
              <Row align="middle">
                <Col span={20}>
                  {maskNumber(businessAssociate?.aadharNumber || "-")}
                </Col>
                {/* <Col span={4} className="ba-perosnal-details__edit">
                  <EditOutlined onClick={handleToggleAadharForm} />
                </Col> */}
              </Row>
            </div>
          </Col>
          <Col span={12}>
            <div className="ba-personal-details__label">PAN</div>
            <div className="ba-personal-details__value">
              <Row align="middle">
                <Col span={20}>
                  {maskNumber(businessAssociate?.panNumber || "-")}
                </Col>
                {/* <Col span={4} className="ba-perosnal-details__edit">
                  <EditOutlined onClick={handleTogglePanForm} />
                </Col> */}
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className="ba-personal-details__section">
        <h3 className="text-primary">Bank Account</h3>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <div className="ba-personal-details__label">Bank name</div>
            <div className="ba-personal-details__value">
              {businessAssociate?.bankDetail?.bankName || "-"}
            </div>
          </Col>
          <Col span={12}>
            <div className="ba-personal-details__label">
              Account Holder Name
            </div>
            <div className="ba-personal-details__value">
              {businessAssociate?.bankDetail?.accountHolderName || "-"}
            </div>
          </Col>
          <Col span={12}>
            <div className="ba-personal-details__label">Account number</div>
            <div className="ba-personal-details__value">
              {businessAssociate?.bankDetail?.accountNumber || "-"}
            </div>
          </Col>
          <Col span={12}>
            <div className="ba-personal-details__label">IFSC Code</div>
            <div className="ba-personal-details__value">
              {businessAssociate?.bankDetail?.ifscCode || "-"}
            </div>
          </Col>
        </Row>
      </div>
      <div className="ba-personal-details__section">
        <h3 className="text-primary">Brokerage Group Details</h3>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <div className="ba-personal-details__label">
              Brokerage Group name
            </div>
            <div className="ba-personal-details__value">
              {businessAssociate?.brokerageGroupName || "NA"}
            </div>
          </Col>
        </Row>
      </div>
      <div className="ba-personal-details__section">
        <h3 className="text-primary">References</h3>
        <Row gutter={[20, 20]}>
          {businessAssociate?.referenceContacts?.map((reference, i) => (
            <Col span={12} key={reference?.id}>
              <div className="ba-personal-details__label mb-2">
                Reference {i + 1}
              </div>
              <div className="ba-personal-details__value mb-2">
                {reference?.name || "-"}
              </div>
              <div className="ba-personal-details__value mb-2">
                {reference?.email || "-"}
              </div>
              <div className="ba-personal-details__value mb-2">
                {reference?.mobile || "-"}
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <Modal
        onCancel={handleToggleAadharForm}
        visible={showAadharForm}
        destroyOnClose
        footer={null}
        className="ba-personal-details__modal"
      >
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={aadharFormValidation}
        >
          {({ values, errors, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <InputField
                  title="Aadhar number"
                  type="text"
                  name="aadharNumber"
                  placeholder="Enter Number"
                />
                <div className="text-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!isValid || !dirty || formLoading}
                    loading={formLoading}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      <Modal
        onCancel={handleTogglePanForm}
        visible={showPanForm}
        destroyOnClose
        footer={null}
        className="ba-personal-details__modal"
      >
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={panFormValidation}
        >
          {({ values, errors, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <InputField
                  title="Pan number"
                  type="text"
                  name="panNumber"
                  placeholder="Enter PAN Number"
                />
                <div className="text-right">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!isValid || !dirty || formLoading}
                    loading={formLoading}
                  >
                    Update
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}

export default BAPersonalDetails;
