import React, { useState, useEffect } from "react";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import { Checkbox } from "antd";
import { ProfileCompletionStatusEnum } from "../../../../enums/profileCompletionStatus.enum";
import "./baProfileCompletionFilters.scss";
import { BaFilterParams } from "../../../../models/BAFilterParams/baFilterParams.model";
import { BaFilterService } from "../../../../services/BAFilter/baFilter.service";
import AppLoader from "../../../../shared/components/AppLoader";

interface BAProfileCompletionFiltersProps {
  filterParams: BaFilterParams;
  refreshData: boolean;
  onUpdate: (filterParams: BaFilterParams) => void;
}

function BAProfileCompletionFilters({
  filterParams,
  refreshData,
  onUpdate,
}: BAProfileCompletionFiltersProps) {
  const [loading, setLoading] = useState(false);

  const [profileCompletionStatusOptions, setProfileCompletionStatusOptions] =
    useState<CheckboxOptionType[]>([]);

  const handleFetchStatuses = () => {
    setLoading(true);
    BaFilterService.fetchProfileCompletionStatuses(
      filterParams,
      (profileCompletionStatuses: string[]) => {
        const statusOptions = [...(new Set(profileCompletionStatuses) as any)];
        let options = [];
        for (let status of statusOptions) {
          if (status) {
            options.push({
              label: status.split("_").join(" "),
              value: status as CheckboxValueType,
            });
          }
        }
        setProfileCompletionStatusOptions(options);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    handleFetchStatuses();
  }, []);

  useEffect(() => {
    if (refreshData) {
      // handleFetchStatuses();
    }
  }, [refreshData]);

  return (
    <div className="ba-profile-completion-filters">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Checkbox.Group
          value={filterParams.profileCompletionStatuses}
          onChange={(profileCompletionStatuses) => {
            const params = Object.assign(new BaFilterParams(), filterParams);
            params.profileCompletionStatuses =
              profileCompletionStatuses as string[];
            onUpdate(params);
          }}
          options={profileCompletionStatusOptions}
        />
      )}
    </div>
  );
}

export default BAProfileCompletionFilters;
