import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { ProductTypeMeta } from "../../models/ProductTypeMeta/productTypeMeta.model";
import { deserialize, serialize } from "serializr";

export default class ProductTypeMetaService {
  static fetchProductTypeMeta(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.PRODUCT_TYPE_META)
      .then((response) => {
        const productTypeMetas = deserialize(
          ProductTypeMeta,
          response.data["meta_product_types"]
        );
        onSuccess(productTypeMetas);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
