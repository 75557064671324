import {
  AUTHENTICATED,
  REQUEST_LOGOUT,
  UNAUTHENTICATED,
} from "../definitions/authConstants";
import { CreateReducer } from "../../shared/utils/createReducer";
import { ActionProps } from "../../shared/types/action.type";
import { BusinessAssociate } from "../../models/BusinessAssociate/businessAssociate.model";
import { Employee } from "../../models/Employee/employee.model";

export interface AuthState {
  authenticated: boolean;
  employee?: Employee;
}

export interface AuthReducerProps extends AuthState {
  setAuthenticated: (employee: Employee) => ActionProps;
  setUnAuthenticated: () => ActionProps;
}

const initState: AuthState = {
  authenticated: false,
  employee: undefined,
};

const authReducer = CreateReducer(initState, {
  [AUTHENTICATED](state: AuthState, action: ActionProps): AuthState {
    const { authenticated, employee } = action?.payload;
    return {
      ...state,
      authenticated,
      employee,
    };
  },
  [UNAUTHENTICATED](state: AuthState, action: ActionProps): AuthState {
    const { authenticated } = action?.payload;
    return { ...state, authenticated, employee: undefined };
  },
  [REQUEST_LOGOUT](state: AuthState, action: ActionProps): AuthState {
    const { authenticated } = action?.payload;
    localStorage.removeItem("token");
    localStorage.removeItem("employee");

    return { ...state, authenticated, employee: undefined };
  },
});

export default authReducer;
