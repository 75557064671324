import { serializable, alias, object, list, primitive } from "serializr";

export class Category {
  @serializable(alias("id", primitive()))
  id?: string;
  @serializable(alias("category_id", primitive()))
  categoryId?: string;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("category_type", primitive()))
  categoryType?: string;

  @serializable(alias("description", primitive()))
  description?: string;
}
