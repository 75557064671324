import React from "react";
import { Select } from "antd";

const demoStatus = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
  { label: "Under Review", value: "underReview" },
  { label: "Pending e-Sign", value: "pendingeSign" },
  { label: "Completed", value: "completed" },
];

const notificationColumns = [
  {
    title: " NOTIFICATION TYPE",
    dataIndex: "notificationType",
    key: "notificationType",
  },
  {
    title: "MESSAGE",
    dataIndex: "message",
    key: "message",
  },
  {
    title: "DATE",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "TIME",
    dataIndex: "time",
    key: "time",
  },
  {
    title: "STATUS",
    dataIndex: "status",
    key: "status",
    render: (status: string) => {
      return (
        <Select
          //loading={}
          className={`notification__status-select ${status}`}
          options={demoStatus}
          dropdownMatchSelectWidth={false}
          defaultValue={status}
          style={{ width: 140 }}
          //onchange{}={}
        />
      );
    },
  },
];
const sampleNotificationTable = [
  {
    notificationType: "Product based Notification",
    message: "20% off on OPD package",
    date: "12-11-2021",
    time: "11:00am",
    status: "active",
  },
  {
    notificationType: "Communication based Notification",
    message: "Wishing for Tamil Nadu new year for people",
    date: "01-11-2021",
    time: "01:00am",
    status: "Under Review",
  },
  {
    notificationType: "Product based Notification",
    message: "30% off  on OPD Package",
    date: "05-02-2021",
    time: "0:00am",
    status: "Inactive",
  },
  {
    notificationType: "Communication based Notification",
    message: "Wishing a very Happy Diwali",
    date: "09-10-2021",
    time: "10:00pm",
    status: "active",
  },
  {
    notificationType: "Product based Notification",
    message: "Product Lauch date is in the month of December",
    date: "12-11-2021",
    time: "11:00pm",
    status: "Pending e-Sign",
  },
  {
    notificationType: "Communication based Notification",
    message: "New year Vacation for last week of December",
    date: "12-11-2021",
    time: "11:05am",
    status: "active",
  },
  {
    notificationType: "Product based Notification",
    message: "New Beta version Available for device ",
    date: "15-11-2021",
    time: "07:00pm",
    status: "Inactive",
  },
  {
    notificationType: "Communication based Notification",
    message: "20% off on OPD package",
    date: "20-11-2021",
    time: "10:08am",
    status: "New",
  },
  {
    notificationType: "Product based Notification",
    message: "Holi Vacation on 3rd march 2022",
    date: "22-11-2021",
    time: "09:00am",
    status: "Under Review",
  },
  {
    notificationType: "Communication based Notification",
    message: "20% off on OPD package",
    date: "29-11-2021",
    time: "11:00am",
    status: "Inactive",
  },
  {
    notificationType: "Communication based Notification",
    message: "Wishing you a very happy good Friday",
    date: "01-11-2021",
    time: "11:00am",
    status: "Under Review",
  },
  {
    notificationType: "Product based Notification",
    message: "40% off on OPD package",
    date: "13-10-2021",
    time: "06:00pm",
    status: "Pending e-Sign",
  },
];
export { notificationColumns, sampleNotificationTable, demoStatus };
