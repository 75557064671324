export enum IndustryType {
  FINANCIAL_SERVICE = "financial_services",
  TECHNOLOGY = "technology",
  CONSULTING = "consulting",
  MANUFACTURING = "manufacturing",
  CUSTOMER_PRODUCTS = " consumer_products",
  RETAILS = "retails",
  TELECOMMUNICATIONS = "telecommunications",
  FOOD_AND_BEVERAGES = "food_and_beverages",
  MEDIA_AND_ENTERTAINMENT = "media_and_entertainment",
}
