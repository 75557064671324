import { serializable, alias, object, list, primitive } from "serializr";
import { Employee } from "../../models/Employee/employee.model";
class BannerImage {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("attachment_url", primitive()))
  attachmentUrl?: string;
}

class ProvinceBanners {
  @serializable(alias("province_id", primitive()))
  provinceId?: number;
}
class CityBanners {
  @serializable(alias("city_id", primitive()))
  cityId?: number;
}

export class Banner {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("page_type", primitive()))
  pageType?: string = "home_page";
  @serializable(alias("viewable_user_type", primitive()))
  viewableUserType?: string;
  @serializable(alias("category_id", primitive()))
  categoryId?: number;
  @serializable(alias("category_type", primitive()))
  categoryType?: string;

  @serializable(alias("banner_image_id", primitive()))
  bannerImageId?: number;
  @serializable(alias("position", primitive()))
  position?: number;
  @serializable(alias("redirect_url", primitive()))
  redirectUrl?: string;
  @serializable(alias("updated_at", primitive()))
  updatedAt?: string;
  @serializable(alias("is_slider", primitive()))
  isSlider?: boolean;
  @serializable(alias("banner_image", object(BannerImage)))
  bannerImage?: BannerImage;

  @serializable(alias("employee", object(Employee)))
  employee?: Employee;

  @serializable(alias("province_ids", list(primitive())))
  provinceIds?: [];
  @serializable(alias("city_ids", list(primitive())))
  cityIds?: [];

  @serializable(alias("city_banners", list(object(CityBanners))))
  cityBanners?: CityBanners[] = [];

  @serializable(alias("province_banners", list(object(ProvinceBanners))))
  provinceBanners?: ProvinceBanners[] = [];
}
