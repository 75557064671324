import React, { useEffect, useState } from "react";
import {
  Input,
  Radio,
  Button,
  Row,
  Col,
  Pagination,
  TablePaginationConfig,
} from "antd";
import "./assignBA.scss";
import { BusinessAssociateService } from "../../../services/BusinessAssociateService/businessAssociate.service";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import CustomerService from "../../../services/Customer/customer.service";
import AppLoader from "../../../shared/components/AppLoader";
import { BaFilterParams } from "../../../models/BAFilterParams/baFilterParams.model";
import EmployeeService from "../../../services/Employee/employee.service";
import { Employee } from "../../../models/Employee/employee.model";
import { ApplicableUser } from "../../../enums/applicableUser.enum";
import { debounce } from "lodash";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";

interface AssignBAProps {
  selectedCustomerIds: number[];
  onSuccess: () => void;
}

function AssignBA({ selectedCustomerIds, onSuccess }: AssignBAProps) {
  const [searchText, setSearchText] = useState("");
  const [searchRMText, setSearchRMText] = useState("");
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [baPagination, setBaPagination] = useState<PaginationDetails>();
  const [rmPagination, setRmPagination] = useState<PaginationDetails>();

  const [businessAssociates, setBusinessAssociates] = useState<
    BusinessAssociate[]
  >([]);

  const [selectedBAId, setSelectedBAId] = useState<number>();
  const [selectedRMId, setSelectedRMId] = useState<number>();
  const [baLoading, setBALoading] = useState(false);
  const [rmLoading, setRMLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);

  const handleFetchBAs = (search?: string, page?: number) => {
    setBALoading(true);
    const filter = Object.assign(new BaFilterParams(), {
      search: search,
      page: page ?? 1,
    });
    BusinessAssociateService.fetchBusinessAssociates(
      // searchText,
      filter,
      // 1,
      (
        businessAssociates: BusinessAssociate[],
        baPagination: PaginationDetails
      ) => {
        setBusinessAssociates(businessAssociates);
        setBaPagination(baPagination);
      },
      () => {},
      () => {
        setBALoading(false);
      }
    );
  };
  const handleFetchRMs = (searchText?: string, page?: number) => {
    const filter = Object.assign(new BaFilterParams(), {
      search_text: searchText,
      page: page ?? 1,
      // role: "RM",
    });
    setRMLoading(true);
    EmployeeService.fetchEmployees(
      filter,
      (employees: Employee[], rmPagination: PaginationDetails) => {
        setEmployees(employees);
        setRmPagination(rmPagination);
      },
      () => {},
      () => {
        setRMLoading(false);
      }
    );
  };

  const handleSubmit = () => {
    if (selectedBAId) {
      setLoading(true);
      CustomerService.assignBA(
        selectedCustomerIds,
        selectedBAId,
        () => {
          onSuccess();
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
    if (selectedRMId) {
      setLoadingForm(true);
      BusinessAssociateService.assignCustomerRM(
        selectedCustomerIds,
        selectedRMId,
        () => {
          onSuccess();
        },
        () => {},
        () => {
          setLoadingForm(false);
        }
      );
    }
  };

  useEffect(() => {
    handleFetchBAs(searchText);
    handleFetchRMs();
  }, []);
  const handleSearch = debounce((searchText: string, type: string) => {
    type === "RM" ? handleFetchRMs(searchText) : handleFetchBAs(searchText);
  }, 500);

  return (
    <div className="assign-ba">
      <h2 className="text-primary">Assign BA</h2>

      <div>
        <div className="mb-3">
          <Row>
            <Col span={8}>
              <Input.Search
                className=""
                placeholder="Search BA Name"
                onChange={(e) => {
                  handleSearch(e.target.value, "BA");
                }}
              />
            </Col>
          </Row>
        </div>
        {baLoading ? (
          <AppLoader loading={baLoading} />
        ) : (
          <Radio.Group
            className="assign-ba__radio-group"
            onChange={(event) => {
              setSelectedBAId(event.target.value);
              setSelectedRMId(undefined);
            }}
          >
            {businessAssociates.map((ba, i) => (
              <Radio value={ba?.id} key={ba?.id}>
                {ba?.firstName} {ba?.lastName}
              </Radio>
            ))}
          </Radio.Group>
        )}
        <div className="text-right mt-5">
          <Pagination
            className="mb-5"
            defaultCurrent={1}
            total={baPagination?.totalCount}
            current={baPagination?.currentPage}
            onChange={(page) => {
              handleFetchBAs(searchText, page);
            }}
          />

          <Button
            loading={loading}
            type="primary"
            onClick={handleSubmit}
            disabled={!selectedBAId || loading || selectedRMId != undefined}
          >
            Assign BA
          </Button>
        </div>

        {/* Assign RM */}
        <div className="assign-rm mt-5">
          <h2 className="text-primary">Assign RM</h2>
          <div className="mb-3">
            <Row>
              <Col span={8}>
                <Input.Search
                  className=""
                  placeholder="Search RM Name"
                  onChange={(e) => {
                    handleSearch(e.target.value, "RM");
                  }}
                />
              </Col>
            </Row>
          </div>
          {rmLoading ? (
            <AppLoader loading={rmLoading} />
          ) : (
            <Radio.Group
              className="assign-rm__radio-group"
              onChange={(event) => {
                setSelectedRMId(event.target.value);
                setSelectedBAId(undefined);
              }}
            >
              {employees.map((rm, i) => (
                <Radio value={rm?.id} key={rm?.id}>
                  {rm?.firstName} {rm?.lastName}
                </Radio>
              ))}
            </Radio.Group>
          )}
        </div>

        <div className="text-right mt-5">
          <Pagination
            className="mb-5"
            total={rmPagination?.totalCount}
            current={rmPagination?.currentPage}
            onChange={(page) => {
              handleFetchRMs(searchText, page);
            }}
          />

          <Button
            loading={loadingForm}
            type="primary"
            onClick={handleSubmit}
            disabled={!selectedRMId || loadingForm}
          >
            Assign RM
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AssignBA;
