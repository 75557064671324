import React, { useEffect, useState } from "react";
import "./baCommissionList.scss";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import { Button, Col, Drawer, Row, Table, Tooltip } from "antd";
import { CommissionTypeEnum } from "../../../enums/commissionType.enum";
import { Commission } from "../../../models/Commission/commission.model";
import CommissionService from "../../../services/Commission/commission.service";
import AppLoader from "../../../shared/components/AppLoader";
import moment from "moment";
import UpdateCommissionStatus from "../UpdateCommissionStatus";
import transactionIcon from "../../../assets/images/transaction.png";

interface BACommissionListProps {
  businessAssociate: BusinessAssociate;
}

interface CurrentCommission {
  isVisible: boolean;
  id: number | undefined;
}

function BACommissionList({ businessAssociate }: BACommissionListProps) {
  const [loading, setLoading] = useState(false);

  const [activeStatus, setActiveStatus] = useState(CommissionTypeEnum.ACCRUED);

  const [totalAmount, setTotalAmount] = useState("");
  const [currentCommission, setCurrentCommission] =
    useState<CurrentCommission>();

  const [commissions, setCommissions] = useState<Commission[]>([]);

  const [showUpdateStatus, setShowUpdateSuccess] = useState(false);

  const [selectedCommissionIds, setSelectedCommissionIds] = useState<number[]>(
    []
  );

  const handleToggleUpdateStatus = () =>
    setShowUpdateSuccess(!showUpdateStatus);

  const handleFetchCommissions = () => {
    if (businessAssociate.id) {
      setLoading(true);
      CommissionService.fetchCommissions(
        businessAssociate.id,
        activeStatus,
        (commissions: Commission[], totalAmount: string) => {
          setCommissions(commissions);
          setTotalAmount(totalAmount);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  };

  const handleUpdateSuccess = () => {
    handleFetchCommissions();
    setShowUpdateSuccess(false);
    setSelectedCommissionIds([]);
  };

  const rowSelection = {
    selectedRowKeys: selectedCommissionIds,
    onChange: (selectedRowKeys: React.Key[], commissions: Commission[]) => {
      const commissionIds = commissions.map((commission) => commission.id ?? 0);
      setSelectedCommissionIds(commissionIds);
    },
  };

  useEffect(() => {
    handleFetchCommissions();
  }, [activeStatus]);

  const columns = [
    {
      title: "CUSTOMER ID",
      dataIndex: "customerId",
      key: "customerId",
    },
    {
      title: "CUSTOMER NAME",
      dataIndex: "customerName",
      key: "customerName",
    },
    {
      title: "PRODUCT NAME",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "PRODUCT VALUE",
      dataIndex: "productValue",
      key: "productValue",
      render: (value: string) => (value ? `₹${value}` : "-"),
    },
    {
      title: "TRANSACTION DATE",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: "BROKERAGE AMOUNT",
      dataIndex: "brokerageValue",
      key: "brokerageValue",
      render: (value: string) => (value ? `₹${value}` : "-"),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      width: "20%",
      render: (status: string, commission: Commission) => (
        <div className="text-capitalize">
          {status ?? "-"}{" "}
          {activeStatus === CommissionTypeEnum.SETTLED &&
          currentCommission &&
          commission?.id == currentCommission.id ? (
            <Tooltip
              color={"white"}
              title={
                <div className="text-primary-black">
                  <div>TRANSACTION REFERENCE NO</div>{" "}
                  <h4>{commission?.transactionRef ?? "-"}</h4>
                </div>
              }
            >
              <img
                src={transactionIcon}
                className="contact-image"
                alt="address Image"
              />
            </Tooltip>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  const sample = [
    {
      title: "hello",
      status: "active",
      id: 1,
    },
    {
      title: "Kumar",
      status: "active",
      id: 2,
    },
  ];
  const handleRow = (ba: Commission, rowIndex: number | undefined) => {
    return {
      onMouseEnter: () =>
        setCurrentCommission({ isVisible: true, id: ba.id ?? 0 }),
      onMouseLeave: () =>
        setCurrentCommission({ isVisible: false, id: undefined }),
    };
  };

  return (
    <div className="ba-commission-list">
      <Row className="ba-commission-list__header" gutter={[20, 0]}>
        <Col span={12} className="ba-commission-list__btn-group">
          <Button
            onClick={() => setActiveStatus(CommissionTypeEnum.ACCRUED)}
            className={
              activeStatus === CommissionTypeEnum.ACCRUED ? "active" : ""
            }
          >
            Accrued
          </Button>
          <Button
            onClick={() => setActiveStatus(CommissionTypeEnum.PENDING)}
            className={
              activeStatus === CommissionTypeEnum.PENDING ? "active" : ""
            }
          >
            Pending
          </Button>
          <Button
            onClick={() => setActiveStatus(CommissionTypeEnum.SETTLED)}
            className={
              activeStatus === CommissionTypeEnum.SETTLED ? "active" : ""
            }
          >
            Settled
          </Button>
        </Col>
        <Col span={12} className="text-right">
          <Button
            type="primary"
            onClick={handleToggleUpdateStatus}
            disabled={selectedCommissionIds.length === 0}
          >
            Update Status
          </Button>
        </Col>
      </Row>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div className="mt-4">
          <Table
            onRow={handleRow}
            rowKey={(record) => record.id || Math.random()}
            rowSelection={{
              preserveSelectedRowKeys: true,
              type: "checkbox",
              ...rowSelection,
            }}
            dataSource={commissions}
            columns={columns}
            size="small"
          />
          <Drawer
            width="50vw"
            onClose={handleToggleUpdateStatus}
            visible={showUpdateStatus}
            destroyOnClose
            footer={null}
          >
            <UpdateCommissionStatus
              totalAmount={totalAmount}
              selectedCommissionIds={selectedCommissionIds}
              onSuccess={handleUpdateSuccess}
            />
          </Drawer>
        </div>
      )}
    </div>
  );
}

export default BACommissionList;
