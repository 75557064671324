import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { BrokerageGroup } from "../../models/BrokerageGroup/brokerageGroup.model";
import { deserialize, serialize } from "serializr";

export default class BrokerageGroupService {
  static fetchBrokerageGroups(
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.BROKERAGE_GROUPS)
      .then((response) => {
        const brokerageGroups = deserialize(
          BrokerageGroup,
          response.data["brokerage_groups"]
        );
        onSuccess(brokerageGroups);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static showBrokerageGroup(
    brokerageGroupId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.BROKERAGE_GROUPS + "/" + brokerageGroupId;
    axiosInstance
      .get(API_URL)
      .then((response) => {
        const brokerageGroup = deserialize(
          BrokerageGroup,
          response.data["brokerage_group"]
        );
        onSuccess(brokerageGroup);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static createBrokerageGroup(
    brokerageGroup: BrokerageGroup,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const brokerageGroupJSON = serialize(brokerageGroup);
    axiosInstance
      .post(ApiRoutes.BROKERAGE_GROUPS, brokerageGroupJSON)
      .then((response) => {
        const brokerageGroup = deserialize(
          BrokerageGroup,
          response.data["brokerage_group"]
        );
        onSuccess(brokerageGroup);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateBrokerageGroup(
    brokerageGroup: BrokerageGroup,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.BROKERAGE_GROUPS + "/" + brokerageGroup.id;
    const brokerageGroupJSON = serialize(brokerageGroup);
    axiosInstance
      .put(API_URL, brokerageGroupJSON)
      .then((response) => {
        const brokerageGroup = deserialize(
          BrokerageGroup,
          response.data["brokerage_group"]
        );
        onSuccess(brokerageGroup);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static deleteBrokerageGroup(
    brokerageGroupId: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const API_URL = ApiRoutes.BROKERAGE_GROUPS + "/" + brokerageGroupId;
    axiosInstance
      .delete(API_URL)
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
