import { serializable, alias, object, list, primitive } from "serializr";

import { Employee } from "../Employee/employee.model";

export class Note {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("note_text", primitive()))
  noteText?: string;

  @serializable(alias("noted_by", object(Employee)))
  notedBy?: Employee;
}
