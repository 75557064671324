import React from "react";
import "./customerExistingLoan.scss";
import { Customer } from "../../../models/Customer/customer.model";
import { Row, Col } from "antd";

interface CustomerExistingLoanProps {
  customer: Customer;
}

function CustomerExistingLoan({ customer }: CustomerExistingLoanProps) {
  return (
    <div className="customer-existing-loan">
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <div className="customer-existing-loan__label">Loan Type</div>
          <div className="customer-existing-loan__value">
            {customer?.existingLoan?.loanTypes
              ? customer.existingLoan.loanTypes.join(", ")
              : "-"}
          </div>
        </Col>
        <Col span={12}>
          <div className="customer-existing-loan__label">EMI</div>
          <div className="customer-existing-loan__value">
            ₹{customer?.existingLoan?.emi || "0"}
          </div>
        </Col>
        <Col span={12}>
          <div className="customer-existing-loan__label">Bank Name</div>
          <div className="customer-existing-loan__value">
            {customer?.existingLoan?.institution || "-"}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default CustomerExistingLoan;
