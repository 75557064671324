import React from "react";
import "./orderProductDetails.scss";
import { Order } from "../../../../models/Order/order.model";
import { Col, Row } from "antd";

interface OrderProductDetailsProps {
  order: Order;
}

function OrderProductDetails({ order }: OrderProductDetailsProps) {
  return (
    <div className="order-product-details">
      <Row>
        {order?.product?.details?.map((productDetail, i) => (
          <Col span={24} key={i}>
            <div className="order-product-details__label">
              {productDetail?.label}
            </div>
            <div className="order-product-details__value">
              {productDetail?.type === "pop-up" && productDetail?.value ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: productDetail.value.replace(/\n/g, "<br />"),
                  }}
                />
              ) : (
                productDetail?.value ?? "-"
              )}
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default OrderProductDetails;
