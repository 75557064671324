import { City } from "../City/city.model";

import { serializable, alias, object, list, primitive } from "serializr";

export class Location {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("city", object(City)))
  city?: City;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("latitude", primitive()))
  latitude?: string;

  @serializable(alias("longitude", primitive()))
  longitude?: string;
}
