import React, { useState, useEffect } from "react";
import { Column } from "@ant-design/charts";
import { Select } from "antd";

const { Option } = Select;

const BAOnboarding: React.FC = () => {
  var status = [
    {
      name: "Under Review",
      month: "Jan",
      activeReviewData: 12,
    },
    {
      name: "Under Review",
      month: "Feb",
      activeReviewData: 23,
    },
    {
      name: "Under Review",
      month: "Mar",
      activeReviewData: 34,
    },
    {
      name: "Under Review",
      month: "Apr",
      activeReviewData: 99,
    },
    {
      name: "Under Review",
      month: "May",
      activeReviewData: 52,
    },
    {
      name: "Under Review",
      month: "Jun",
      activeReviewData: 35,
    },
    {
      name: "Under Review",
      month: "Jul",
      activeReviewData: 37,
    },
    {
      name: "Under Review",
      month: "Aug",
      activeReviewData: 42,
    },
    {
      name: "Under Review",
      month: "Sept",
      activeReviewData: 35,
    },
    {
      name: "Under Review",
      month: "Oct",
      activeReviewData: 35,
    },
    {
      name: "Under Review",
      month: "Nov",
      activeReviewData: 45,
    },
    {
      name: "Under Review",
      month: "Dec",
      activeReviewData: 39,
    },
    {
      name: "Active",
      month: "Jan",
      activeReviewData: 18,
    },
    {
      name: "Active",
      month: "Feb",
      activeReviewData: 28,
    },
    {
      name: "Active",
      month: "Mar",
      activeReviewData: 39,
    },
    {
      name: "Active",
      month: "Apr",
      activeReviewData: 81,
    },
    {
      name: "Active",
      month: "May",
      activeReviewData: 47,
    },
    {
      name: "Active",
      month: "Jun",
      activeReviewData: 20,
    },
    {
      name: "Active",
      month: "Jul",
      activeReviewData: 74,
    },
    {
      name: "Active",
      month: "Aug",
      activeReviewData: 45,
    },
    {
      name: "Active",
      month: "Sept",
      activeReviewData: 88,
    },
    {
      name: "Active",
      month: "Oct",
      activeReviewData: 92,
    },
    {
      name: "Active",
      month: "Nov",
      activeReviewData: 35,
    },
    {
      name: "Active",
      month: "Dec",
      activeReviewData: 35,
    },
  ];
  var config = {
    data: status,
    isGroup: true,
    xField: "month",
    yField: "activeReviewData",
    seriesField: "name",

    label: {
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
  };
  const OnboardingStatus = [
    { label: "Under Review", value: "UnderReview" },
    { label: "Pending e-sign BA ", value: "PendingE-signBA" },
    { label: "Pending e-sign Admin", value: "PendingE-signAdmin" },
    { label: "Active", value: "active" },

    { label: "Inactive ", value: "inactive" },
    { label: "Incomplete ", value: "incomplete" },
  ];
  const OnboardingYear = [
    { label: "2020", value: "2020" },
    { label: "2019", value: "2019" },
    { label: "2018", value: "2018" },
    { label: "2017", value: "2017" },
  ];

  return (
    <>
      <h3>BA Onboarding</h3>
      <div className="ba-onboarding__select">
        <Select
          placeholder="Status"
          options={OnboardingStatus}
          dropdownMatchSelectWidth={false}
        />
        <Select
          placeholder="Year"
          options={OnboardingYear}
          dropdownMatchSelectWidth={false}
        />
      </div>
      <Column {...config} />
    </>
  );
};

export default BAOnboarding;
