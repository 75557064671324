import { Button, CheckboxOptionType, Col, Input, Row } from "antd";
import { Checkbox } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { debounce } from "lodash";
import React, { FC } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BaFilterParams } from "../../../../models/BAFilterParams/baFilterParams.model";
import { City } from "../../../../models/City/city.model";
import { Employee } from "../../../../models/Employee/employee.model";
import { Province } from "../../../../models/Province/province.model";
import { AppRoutes } from "../../../../routes/routeConstants/appRoutes";
import { FilterOptions } from "../../../../services/CustomerFilter/customerFilter.service";
import AppLoader from "../../../../shared/components/AppLoader";
import "./detailList.scss";

const keys = ["names", "ba_ids", "cities", "states", "rm_ids"];

interface DetailListProps {
  activeKey: string;
  index: string;
  searchTitle: string;
  applyFilter?: () => void;
  resetFilter?: () => void;
  filterType: string;
  onUpdate: (filterParams: BaFilterParams, type?: string) => void;
  filterParams: BaFilterParams;
  service: (
    filterParams: FilterOptions,
    onSuccess: (values: Employee[] | City[] | Province[] | Employee[]) => void,
    onFinal: () => void
  ) => void;
}

// export const getParams = (location: any, parsed?: boolean, search?: string) => {
//   let params: any = { search_text: search };
//   const urlParams = new URLSearchParams(location.search);
//   for (let key of keys) {
//     const value = urlParams.get(key);
//     if (value) {
//       params = {
//         ...params,
//         [key]: parsed ? value?.split(",") : value,
//       };
//     }
//   }
//   return params;
// };

const DetailList: FC<DetailListProps> = (props) => {
  const {
    activeKey,
    index,
    service,
    searchTitle,
    applyFilter,
    resetFilter,
    onUpdate,
    filterParams,
    filterType,
  } = props;

  const location = useLocation();
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>();

  const history = useHistory();

  const [options, setOptions] = useState<CheckboxOptionType[]>([]);
  const [loading, setLoading] = useState(false);

  const handleService = (search?: string) => {
    if (activeKey === index) {
      setLoading(true);
      service(
        { ...filterParams, search_text: search },
        (values: any) => {
          const options = [];
          for (let value of values) {
            options.push({
              label: value?.name
                ? value?.name
                : value?.firstName + " " + value?.lastName,
              value: value?.id,
            });
            options?.sort((a, b) => a?.label?.localeCompare(b?.label));
          }
          setOptions(options);
        },
        () => {
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    handleService();
  }, [activeKey]);

  // const handleChange = (checkedValue: CheckboxValueType[]) => {
  //   setCheckedList(checkedValue);
  //   let params: any = {
  //     ...getParams(),
  //   };

  //   params = {
  //     ...params,
  //     [keys[parseInt(index)]]: checkedValue.join(","),
  //   };

  //   const search = new URLSearchParams(params).toString();
  //   history.replace({
  //     pathname: AppRoutes.CUSTOMERS,
  //     search,
  //   });
  // };
  const handleSearch = debounce((searchText: string) => {
    handleService(searchText);
  }, 500);

  return (
    <div className="detail-list">
      <Row justify="end" className="mt-5">
        <Col>
          <Button
            onClick={() => {
              onUpdate(new BaFilterParams(), "reset");
              setCheckedList([]);
            }}
          >
            Reset
          </Button>
        </Col>
        <Col>
          <Button type="primary" className="ml-2" onClick={applyFilter}>
            Apply
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Input.Search
            className="ml-4 mb-5"
            placeholder={searchTitle}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Checkbox.Group
          // value={checkedList}
          // onChange={handleChange}
          // options={options}

          value={
            Object.assign({}, { ...(filterParams as any) })[filterType as any]
          }
          onChange={(names) => {
            const params = Object.assign(new BaFilterParams(), {
              ...filterParams,
              [filterType]: names,
            });
            // params[filterType] = names as string[];

            onUpdate(params, "");
          }}
          options={options}
        />
      )}
    </div>
  );
};

export default DetailList;
