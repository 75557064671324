import { Select, Table, TablePaginationConfig } from "antd";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { ProfileStatusEnum } from "../../../enums/profileStatus.enum";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { SurveyQuestions as SurveyQuestionsModel } from "../../../models/SurveyQuestions/surveyQuestions.model";
import SurveyQuestionsService from "../../../services/MyProfile/SurveyQuestions/surveyQuestions.service";
import AppLoader from "../../../shared/components/AppLoader";
import TableComponent from "../../../shared/components/TableComponent";
import "./surveyQuestions.scss";
import ImageUnavailableIcon from "../../../assets/images/imageUnavailable.jpg";
// import { sampleSurveyData, surveyColumns } from "./surveyQuestionsTable";

interface SurveyQuestionsProps {
  onEdit: (surveyQuestion: SurveyQuestionsModel) => void;
  updateForm: boolean;
}
const profileStatus = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

const SurveyQuestions: FC<SurveyQuestionsProps> = (props) => {
  const { onEdit, updateForm } = props;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [surveyPagination, setSurveyPagination] = useState<PaginationDetails>();
  const {
    fetchSurveyQuestionsList,
    surveyQuestionList,
    updateSurveyQuestions,
    loading,
  } = SurveyQuestionsService();

  const surveyColumns = [
    {
      title: " BANNER",
      dataIndex: "banner",
      key: "banner",
      render: (__: string, banner: SurveyQuestionsModel) => {
        return (
          <div className="banner-upload__img ">
            <a
              onClick={(e) => e.stopPropagation()}
              target="_blank"
              href={banner?.questionBannerImage?.attachmentUrl}
            >
              {banner?.questionBannerImage?.attachmentUrl ? (
                <img
                  src={banner?.questionBannerImage?.attachmentUrl}
                  alt="image"
                />
              ) : (
                <img src={ImageUnavailableIcon} alt="image" />
              )}
            </a>{" "}
          </div>
        );
      },
    },
    {
      title: "QUESTION",
      dataIndex: "question",
      key: "question",
    },
    {
      title: "ANSWER TYPE",
      dataIndex: "questionType",
      key: "questionType",
      render: (questionType: string, banner: SurveyQuestionsModel) => {
        return (
          <span className="text-capitalize">
            {questionType.replace("_", " ")}
          </span>
        );
      },
    },

    {
      title: "MODIFIED BY",
      dataIndex: "modifiedBy",
      key: "modifiedBy",
      render: (_: string, banner: SurveyQuestionsModel) => {
        return `${banner?.employee?.firstName || "-"}`;
      },
      /* render: (_: string, banner: SurveyQuestions) => {
      return `${banner?.employee?.firstName || "-"} ${
        banner?.employee?.lastName || "-"
      }`;
    }, */
    },
    {
      title: "MODIFIED DATE",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (date: string) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (status: string, banner: SurveyQuestionsModel) => {
        return (
          <div onClick={(e) => e.stopPropagation()}>
            <Select
              className={`survey-questions__status-select text-capitalize ${
                banner?.status ? "active" : "inactive"
              } `}
              options={profileStatus}
              dropdownMatchSelectWidth={false}
              value={banner?.status ? "active" : "inactive"}
              style={{ width: 140 }}
              onChange={(value) => {
                handleUpdateStatus(banner, value);
              }}
            />
          </div>
        );
      },
    },
  ];

  const handleUpdateStatus = (survey: SurveyQuestionsModel, status: string) => {
    console.log({ status: status });
    const surveyQuestion = Object.assign(new SurveyQuestionsModel(), {
      ...survey,
      status: status === ProfileStatusEnum?.ACTIVE ? true : false,
    });

    updateSurveyQuestions(
      surveyQuestion,
      surveyQuestion?.id?.toString() ?? "",
      () => {
        handleFetchSurveyQuestion();
      }
    );
  };

  const handleRow = (survey: SurveyQuestionsModel) => {
    return {
      onClick: (event: any) => {
        return onEdit(survey);
      },
    };
  };
  const handleTableChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination?.current || 1);
  };

  const handleFetchSurveyQuestion = () =>
    fetchSurveyQuestionsList(
      currentPage,
      (data: SurveyQuestionsModel[], surveyPagination: PaginationDetails) => {
        setSurveyPagination(surveyPagination);
      }
    );
  useEffect(() => {
    handleFetchSurveyQuestion();
  }, []);
  useEffect(() => {
    handleFetchSurveyQuestion();
  }, [currentPage, updateForm]);
  return (
    <div className="survey-questions">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Table
          className="survey-questions__table"
          columns={surveyColumns}
          dataSource={surveyQuestionList}
          loading={loading}
          rowKey="bannerId"
          onRow={handleRow}
          onChange={handleTableChange}
          pagination={{
            total: surveyPagination?.totalCount,
            current: surveyPagination?.currentPage,
            showTotal: (total: number, range: [number, number]) => (
              <p>
                Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                <b>{`${total.toLocaleString()}`}</b>
              </p>
            ),
          }}
        />
      )}
    </div>
  );
};

export default SurveyQuestions;
