import { Role } from "../Role/role.model";
import { serializable, alias, object, primitive, list } from "serializr";
import { FileAttachment } from "../FileAttachment/fileAttachment.model";
import { Address } from "../Address/address.model";
import { ServingLocation } from "../ServingLocation/servingLocation.model";
import { AddressTypeEnum } from "../../enums/addressType.enum";
import { ProfileCompletionStatusEnum } from "../../enums/profileCompletionStatus.enum";

export class ReportingUser {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;
}

export class Employee {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("first_name", primitive()))
  firstName?: string;

  @serializable(alias("password", primitive()))
  password?: string;

  @serializable(alias("grant_type", primitive()))
  grantType: string = "password";

  @serializable(alias("communication_city_name", primitive()))
  communicationCityName?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("joining_date", primitive()))
  joiningDate?: string;

  @serializable(alias("role_id", primitive()))
  roleId?: number;

  locationIds: number[] = [];

  @serializable(alias("role", object(Role)))
  role?: Role;

  @serializable(alias("profile_pic", object(FileAttachment)))
  profilePic?: FileAttachment;

  @serializable(alias("business_associate_count", primitive()))
  businessAssociateCount?: number;
  @serializable(alias("profile_completion_status", primitive()))
  profileCompletionStatus?: ProfileCompletionStatusEnum;

  @serializable(alias("mobile", primitive()))
  mobile?: string;

  @serializable(alias("position", primitive()))
  position?: string;

  @serializable(alias("qualification", primitive()))
  qualification?: string;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("reporting_user_id", primitive()))
  reportingUserId?: number;

  @serializable(alias("reporting_user", object(ReportingUser)))
  reportingUser?: ReportingUser;
  @serializable(alias("password_reset_otp", primitive()))
  passwordResetOTP?: string;

  @serializable(alias("addresses", list(object(Address))))
  addresses: Address[] = [];

  @serializable(alias("serving_locations", list(object(ServingLocation))))
  servingLocations: ServingLocation[] = [];

  residentialAddress?: Address = Object.assign(new Address(), {
    ...new Address(),
    addressType: AddressTypeEnum.RESIDENTIAL,
  });

  communicationAddress?: Address = Object.assign(new Address(), {
    ...new Address(),
    addressType: AddressTypeEnum.COMMUNICATION,
  });

  getAddresses() {
    this.communicationAddress = this.addresses.find(
      (address) => address.addressType === AddressTypeEnum.COMMUNICATION
    );
    if (this.communicationAddress?.city) {
      this.communicationAddress.cityId = this.communicationAddress.city.id;
      this.communicationAddress.provinceId =
        this.communicationAddress?.city.provinceId;
    }
    this.residentialAddress = this.addresses.find(
      (address) => address.addressType === AddressTypeEnum.RESIDENTIAL
    );
    if (this.residentialAddress?.city) {
      this.residentialAddress.cityId = this.residentialAddress.city.id;
      this.residentialAddress.provinceId =
        this.residentialAddress?.city.provinceId;
    }
  }

  setAddresses() {
    this.addresses = [];
    if (this.communicationAddress?.zipcode) {
      this.addresses.push(this.communicationAddress);
    }
    if (this.residentialAddress?.zipcode) {
      this.addresses.push(this.residentialAddress);
    }
  }

  setFormValues() {
    this.roleId = this.role?.id;
    this.reportingUserId = this.reportingUser?.id;
    this.getAddresses();
    this.locationIds = this.servingLocations.map(
      (location) => location.location?.id || 0
    );
  }
}
