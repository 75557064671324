import React, { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import ActivityLog from "../../models/ActivityLog/activityLog.model";

export default class ActivityLogService {
  static fetchActivityLogData(
    userId: string,
    userType: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.ACTIVITY_LOG, {
        params: { user_id: userId, user_type: userType },
      })
      .then((response) => {
        const data = deserialize(ActivityLog, response.data["activity_logs"]);
        onSuccess(data);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}

// const ActivityLogService = () => {
//   const [activityLogData, setactivityLogData] = useState<ActivityLog[]>([]);
//   const [loading, setloading] = useState(false);

//   const fetchActivityLogData = async (
//     userId: string,
//     userType: string,
//     onSuccess?: Function
//   ) => {
//     setloading(true);
//     try {
//       const response = await axiosInstance.get(ApiRoutes.ACTIVITY_LOG, {
//         params: { user_id: userId, user_type: userType },
//       });
//       if (response.data?.success) {
//         const data = deserialize(
//           ActivityLog,
//           response.data["activity_logs"] as ActivityLog[]
//         );
//         setactivityLogData(data);
//         if (onSuccess) onSuccess();
//         return;
//       }
//     } catch (error) {
//     } finally {
//       setloading(false);
//     }
//   };

//   return {
//     fetchActivityLogData,
//     activityLogData,
//     loading,
//   };
// };
// export default ActivityLogService;
