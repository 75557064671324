import { serializable, alias, object, list, primitive } from "serializr";
import { FileAttachment } from "../FileAttachment/fileAttachment.model";

class GroupedAttachment {
  @serializable(alias("attachment_type", primitive()))
  attachmentType?: string;

  @serializable(alias("attachments", list(object(FileAttachment))))
  attachments?: FileAttachment[] = [];
}

export class ProductRequest {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("additional_comments", primitive()))
  additionalComments?: string;

  @serializable(alias("customer_name", primitive()))
  customerName?: string;

  @serializable(alias("payable_emi", primitive()))
  payableEmi?: string;

  @serializable(alias("property_proof", primitive()))
  propertyProof?: string;

  @serializable(alias("rate_of_interest", primitive()))
  rateOfInterest?: string;

  @serializable(alias("tenure", primitive()))
  tenure?: string;

  @serializable(alias("customer_id", primitive()))
  customerId?: string;

  @serializable(alias("service_provider_name", primitive()))
  serviceProviderName?: string;

  @serializable(alias("meta_product_type_name", primitive()))
  metaProductTypeName?: string;

  @serializable(alias("amount", primitive()))
  amount?: string;

  @serializable(alias("date_of_application", primitive()))
  dateOfApplication?: string;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("address_proof_id", primitive()))
  addressProofId?: number;

  @serializable(alias("banking_proof_id", primitive()))
  bankingProofId?: number;

  @serializable(alias("business_proof_id", primitive()))
  businessProofId?: number;

  @serializable(alias("employment_details_id", primitive()))
  employmentDetailsId?: number;

  @serializable(alias("income_proof_id", primitive()))
  incomeProofId?: number;

  @serializable(alias("other_proof_id", primitive()))
  otherProofId?: number;

  @serializable(alias("address_proof", object(FileAttachment)))
  addressProof?: FileAttachment;

  @serializable(alias("banking_proof", object(FileAttachment)))
  bankingProof?: FileAttachment;

  @serializable(alias("business_proof", object(FileAttachment)))
  businessProof?: FileAttachment;

  @serializable(
    alias("product_request_attachments", list(object(FileAttachment)))
  )
  productRequestAttachments?: FileAttachment[] = [];

  @serializable(alias("grouped_attachments", list(object(GroupedAttachment))))
  groupedAttachments?: GroupedAttachment[] = [];

  @serializable(alias("employment_details", object(FileAttachment)))
  employmentDetails?: FileAttachment;

  @serializable(alias("income_proof", object(FileAttachment)))
  incomeProof?: FileAttachment;

  @serializable(alias("other_proof", object(FileAttachment)))
  otherProof?: FileAttachment;
}
