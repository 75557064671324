import React, { useState } from "react";
import "./productRequestApplicationDetails.scss";
import { ProductRequest } from "../../../models/ProductRequest/productRequest.model";
import { Col, Row, Select } from "antd";
import { ProductRequestStatusEnum } from "../../../enums/productRequestStatus.enum";
import ProductRequestService from "../../../services/ProductRequest/productRequest.service";

interface ProductRequestApplicationDetailsProps {
  productRequest: ProductRequest;
  onUpdateSuccess: (productRequest: ProductRequest) => void;
}

function ProductRequestApplicationDetails({
  productRequest,
  onUpdateSuccess,
}: ProductRequestApplicationDetailsProps) {
  const [statusLoading, setStatusLoading] = useState(false);

  const productRequestStatuses = [
    { label: "Submitted", value: ProductRequestStatusEnum.SUBMITTED },
    { label: "Sanction Query", value: ProductRequestStatusEnum.SANCTION_QUERY },
    { label: "In Progress", value: ProductRequestStatusEnum.IN_PROGRESS },
    {
      label: "Disbursement Query",
      value: ProductRequestStatusEnum.DISBURSEMENT_QUERY,
    },
    { label: "Disbursed", value: ProductRequestStatusEnum.DISBURSED },
    { label: "Not Sanctioned", value: ProductRequestStatusEnum.NOT_SANCTIONED },
  ];

  const handleUpdateStatus = (status: string) => {
    const request = Object.assign(new ProductRequest(), {
      ...productRequest,
      status,
    });
    setStatusLoading(true);
    ProductRequestService.updateProductRequest(
      request,
      (productRequest: ProductRequest) => {
        onUpdateSuccess(productRequest);
      },
      () => {},
      () => {
        setStatusLoading(false);
      }
    );
  };

  return (
    <div className="product-request-application-details">
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <div className="product-request-application-details__label">
            Amount
          </div>
          <div className="product-request-application-details__value">
            {productRequest?.amount || "-"}
          </div>
        </Col>
        <Col span={24}>
          <div className="product-request-application-details__label">
            Product type
          </div>
          <div className="product-request-application-details__value">
            {productRequest?.metaProductTypeName || "-"}
          </div>
        </Col>
        <Col span={24}>
          <div className="product-request-application-details__label">
            Tenor
          </div>
          <div className="product-request-application-details__value">
            {productRequest?.tenure || "-"}
          </div>
        </Col>
        <Col span={24}>
          <div className="product-request-application-details__label">
            Rate of Interest
          </div>
          <div className="product-request-application-details__value">
            {productRequest?.rateOfInterest || "-"}
          </div>
        </Col>
        <Col span={24}>
          <div className="product-request-application-details__label">
            Payable EMI
          </div>
          <div className="product-request-application-details__value">
            {productRequest?.payableEmi || "-"}
          </div>
        </Col>
        <Col span={24}>
          <div className="product-request-application-details__label">
            Additional Comments
          </div>
          <div className="product-request-application-details__value">
            {productRequest?.additionalComments || "-"}
          </div>
        </Col>
        <Col span={24}>
          <div className="product-request-application-details__label">
            Status
          </div>
          <div className="product-request-application-details__value">
            <Select
              loading={statusLoading}
              className="product-request-application-details__status-select"
              options={productRequestStatuses}
              dropdownMatchSelectWidth={false}
              defaultValue={productRequest?.status}
              style={{ width: 140 }}
              onChange={handleUpdateStatus}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default ProductRequestApplicationDetails;
