import { alias, primitive, serializable } from "serializr";

export class FamilyMember {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("user_id", primitive()))
  userId?: number;

  @serializable(alias("age", primitive()))
  age?: number;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("updated_at", primitive()))
  updatedAt?: string;
}
