import React from "react";
import { Faqs } from "../../../models/MyProfileSetting/myProfileSetting.model";

const faqColumns = [
  {
    title: " FAQ NAME",
    dataIndex: "faqName",
    key: "faqName",
  },
  {
    title: "PRODUCT",
    dataIndex: "productType",
    key: "productType",
    /* render: (faqs: Faqs) => {
      return faqs?.productType?.name || "--";
    }, */
  },
  {
    title: "LINK",
    dataIndex: "link",
    key: "link",
    render: () => {
      return (
        <a target="_blank" href="https://admin.uat.finobuddy.com/individual-ba">
          admin.uat.finobuddy.com
        </a>
      );
    },
    /*    render: (faqs: Faqs) => {
      return (
        <div>
          <a target="_blank" href={faqs?.faqAttachment?.attachmentUrl}>
            {faqs?.faqAttachment?.attachmentUrl || "--"}
          </a>
        </div>
      );
    }, */
  },

  {
    title: "USER VIEW",
    dataIndex: "viewableUserType",
    key: "viewableUserType",
  },
  {
    title: "MODIFIED BY",
    dataIndex: "modifiedBy",
    key: "modifiedBy",
    /*   render: (faqs: Faqs) => {
      return (
        <div>
          {` ${faqs?.modifiedby?.firstName} ${faqs?.modifiedby?.lastName} `}
        </div>
      );
    }, */
  },
  {
    title: "MODIFIED DATE",
    dataIndex: "updatedAt",
    key: "updatedAt",
  },
];
const sampleFAQTable = [
  {
    faqName: "Tempor nisi",
    productType: "Health assure",
    link: "shbs",
    updatedAt: "01-11-2021",
    viewableUserType: "Customer",
    modifiedBy: "Kevin Banks",
  },
  {
    faqName: "Morbi cras odio",
    productType: "Bajaj Finance",
    updatedAt: "04-11-2021",
    viewableUserType: "Customer",
    modifiedBy: "Anthony Barrett",
  },
  {
    faqName: "Nulla ultricies",
    productType: "Will Service",
    updatedAt: "11-10-2021",
    viewableUserType: "Customer",
    modifiedBy: "Tom Ellis",
  },
  {
    faqName: "Consequat arcu",
    productType: "Career Guide",
    updatedAt: "10-11-2021",
    viewableUserType: "Business Associate",
    modifiedBy: "Kevin Banks",
  },
  {
    faqName: "Lectus eget",
    productType: "Bajaj Finance",
    updatedAt: "12-11-2021",
    viewableUserType: "Customer",
    modifiedBy: "Kevin Banks",
  },
  {
    faqName: "Tempor nisi",
    productType: "Health Assure",
    updatedAt: "12-11-2021",
    viewableUserType: "Business Associate",
    modifiedBy: "Tom Ellis",
  },
  {
    faqName: "Tempor nisi",
    productType: "Health Assure",
    updatedAt: "12-11-2021",
    viewableUserType: "Business Associate",
    modifiedBy: "Anthony Barrett",
  },
  {
    faqName: "Tempor nisi",
    productType: "Health Assure",
    updatedAt: "25-11-2021",
    viewableUserType: "Business Associate",
    modifiedBy: "Kevin Banks",
  },
  {
    faqName: "Tempor nisi",
    productType: "Health Assure",
    updatedAt: "30-11-2021",
    viewableUserType: "Business Associate",
    modifiedBy: "Anthony Barrett",
  },
  {
    faqName: "Tempor nisi",
    productType: "Health Assure",
    updatedAt: "28-11-2021",
    viewableUserType: "Business Associate",
    modifiedBy: "Tom Ellis",
  },
  {
    faqName: "Tempor nisi",
    productType: "Health Assure",
    updatedAt: "23-11-2021",
    viewableUserType: "Business Associate",
    modifiedBy: "Kevin Banks",
  },
];
export { faqColumns, sampleFAQTable };
