import React, { useState } from "react";
import {
  Row,
  Col,
  Radio,
  Button,
  Switch,
  DatePicker,
  Space,
  TimePicker,
  Upload,
  message,
} from "antd";
import { Form, Formik } from "formik";
import PageTitle from "../../../shared/components/PageTitle";
import InputField from "../../../shared/components/InputField";
import DropdownButton from "antd/lib/dropdown/dropdown-button";
import DropdownField from "../../../shared/components/DropdownField";
import { Notification } from "../../../models/Notification/notification.model";
import { DropdownModeEnum } from "../../../enums/dropdownMode.enum";
import { InboxOutlined } from "@ant-design/icons";
import moment from "moment";

import "./notificationForm.scss";
interface NotificationFormProps {
  handleFormVisibility: () => void;
}
function NotificationForm(props: NotificationFormProps) {
  const { handleFormVisibility } = props;
  const { Dragger } = Upload;
  const [formLoading, setFormLoading] = useState(false);
  const [formValues, setFormValues] = useState(new Notification());
  const [radioValue, setRadioValue] = useState(1);
  const [switchChange, setSwitchChange] = useState(false);

  const [notificationType, setnotificationType] = useState();

  const handleSubmit = (values: Notification) => {};

  const productDemo = [
    {
      label: "OPD packages",
      value: "opdPackages",
    },
    {
      label: "Health Assure",
      value: "healthAssure",
    },
    {
      label: "Bajaj Finance",
      value: "bajajFinance",
    },
    {
      label: "Will Service",
      value: "willService",
    },
    {
      label: "Career Guide",
      value: "careerGuide",
    },
  ];
  const stateDemo = [
    {
      label: "Jharkhand",
      value: "jharkhand",
    },
    {
      label: "New Delhi",
      value: "newDelhi",
    },
    {
      label: "Karnataka",
      value: "Karnataka",
    },
    {
      label: "Tamil Nadu",
      value: "tamilNadu",
    },
    {
      label: "Telangana",
      value: "telangana",
    },
    {
      label: "Uttar Pradesh",
      value: "uttarPradesh",
    },
  ];
  const cityDemo = [
    {
      label: "Ranchi",
      value: "ranchi",
    },
    {
      label: "Chennai",
      value: "Chennai",
    },
    {
      label: "Kolkata",
      value: "kolkata",
    },
    {
      label: "Bangalore",
      value: "bangalore",
    },
    {
      label: "Mumbai",
      value: "mumbai",
    },
    {
      label: "Hyderabad",
      value: "hyderabad",
    },
  ];
  const notificationOptions = [
    {
      label: "Product Based Notification",
      value: "productNotification",
    },
    {
      label: "Communication Based Notification",
      value: "productNotification",
    },
  ];
  const radioOptions = [
    {
      label: "Customer",
      value: "customer",
    },
    {
      label: "Business Associate",
      value: "businessAssociate",
    },
    {
      label: "Others",
      value: "others",
    },
  ];

  return (
    <div className="notification-form">
      <div className="notification-form__heading">
        <h2 className="text-primary mb-5">New Notification </h2>
        {/* <span className="ml-5 mt-3 text-green">
          Probable number of Customers: 275
        </span> */}
      </div>
      <div className="notification-form__form">
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
        >
          {({ values, errors, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <Row gutter={[10, 0]}>
                  <Col span={24} className="mb-5">
                    <Radio.Group
                      options={radioOptions}
                      onChange={(e) => setRadioValue(e.target.value)}
                      value={radioValue}
                    ></Radio.Group>
                  </Col>

                  <Col span={12}>
                    <DropdownField
                      title="Notification Type"
                      name="notificationType"
                      options={notificationOptions}
                      value={notificationType}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Content Type"
                      required
                      onChange={(value) => {
                        setnotificationType(value);
                      }}
                    />
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Select Product"
                      name="product"
                      options={productDemo}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Content Type"
                      required
                    />
                  </Col>

                  <Col span={24} className="mb-5">
                    <div className="notification-form__switch">
                      <span className="mr-2">Create Rule</span>
                      <Switch
                        onChange={() => {
                          setSwitchChange(!switchChange);
                        }}
                      />
                      <span className="ml-2"> Upload Excel</span>
                    </div>
                  </Col>

                  {!switchChange ? (
                    <Col span={12}>
                      <DropdownField
                        title="State"
                        name="state"
                        mode={DropdownModeEnum.MULTIPLE}
                        options={stateDemo}
                        setFieldValue={setFieldValue}
                        placeHolder="Select Content Type"
                        required
                      />
                    </Col>
                  ) : (
                    ""
                  )}
                  {!switchChange ? (
                    <Col span={12}>
                      <DropdownField
                        title="City"
                        name="city"
                        mode={DropdownModeEnum.MULTIPLE}
                        options={cityDemo}
                        setFieldValue={setFieldValue}
                        placeHolder="Select Content Type"
                        required
                      />
                    </Col>
                  ) : (
                    ""
                  )}

                  {switchChange ? (
                    <Col span={24}>
                      <Dragger {...props}>
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          Drag and drop files here
                        </p>
                        <p className="ant-upload-hint">
                          or <br />
                          <span className="primary"> Browse File</span>
                        </p>
                      </Dragger>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col span={12}>
                    <p>Date</p>
                    <DatePicker
                      className="w-100"
                      size={"large"} /* onChange={onChange} */
                    />
                  </Col>
                  <Col span={12}>
                    <p>Time</p>
                    <TimePicker
                      className="w-100"
                      size={"large"}
                      //   onChange={onChange}
                      defaultOpenValue={moment("00:00:00", "HH:mm:ss ")}
                    />
                  </Col>
                  <Col span={24}>
                    <InputField
                      title="Message"
                      type="textarea"
                      name="message"
                      placeholder="Enter Message"
                    />
                  </Col>

                  <Col span={21} className="text-right mt-4 ml-1">
                    <Button
                      type="text"
                      loading={formLoading}
                      disabled={!isValid || formLoading}
                      onClick={handleFormVisibility}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col span={3} className="text-right mt-4">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={formLoading}
                      disabled={!isValid || formLoading}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default NotificationForm;
