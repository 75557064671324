import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Table, Row, Col, Radio, Select } from "antd";
import { BusinessAssociate } from "../../models/BusinessAssociate/businessAssociate.model";
import { BusinessAssociateService } from "../../services/BusinessAssociateService/businessAssociate.service";
import AppLoader from "../../shared/components/AppLoader";
import "./activityLog.scss";
import moment from "moment";
import ActivityLogModel from "../../models/ActivityLog/activityLog.model";
import ActivityLogService from "../../services/ActivityLog/activityLog.service";
import { BaFilterParams } from "../../models/BAFilterParams/baFilterParams.model";
import { ApplicableUser } from "../../enums/applicableUser.enum";
import { SearchOutlined } from "@ant-design/icons";
import searchBarIcon from "../../assets/images/searchBar.png";

interface ActivityLogProps {}

function ActivityLog(props: ActivityLogProps) {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  // const { activityLogData, fetchActivityLogData } = ActivityLogService();
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [userRadioValue, setUserRadioValue] = useState<ApplicableUser>(
    ApplicableUser.BUSINESS_ASSOCIATE
  );
  const [businessAssociates, setBusinessAssociates] = useState<
    BusinessAssociate[]
  >([]);
  const [activityLogData, setactivityLogData] = useState<ActivityLogModel[]>(
    []
  );

  const columns = [
    {
      title: " DATE ",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date: string) => moment(date).format("DD/MM/YYYY"),
    },

    {
      title: "ACTIVITY",
      dataIndex: "activityText",
      key: "activityText",
      render: (_: string, activity: ActivityLogModel) => {
        return activity?.activityType;
      },
    },

    {
      title: "LOCATION",
      dataIndex: "location",
      key: "location",
      render: (_: string, activity: ActivityLogModel) => {
        return activity?.activityLocationLatitude
          ? activity?.activityLocationLatitude +
              " " +
              activity?.activityLocationLongitude
          : "--";
      },
    },
    {
      title: "CHANGED AREA",
      dataIndex: "changedArea",
      key: "changedArea",
      render: (_: string, activity: ActivityLogModel) => {
        return activity?.activityType;
      },
    },

    {
      title: "MODIFIED BY",
      dataIndex: "modifiedBy",
      key: "modifiedBy",
      render: (_: string, activity: ActivityLogModel) => {
        return activity?.modifiedBy
          ? activity?.modifiedBy?.firstName +
              " " +
              activity?.modifiedBy?.lastName
          : "--";
      },
    },

    {
      title: "ROLE",
      dataIndex: "modifiedUserRole",
      key: "modifiedUserRole",
    },
  ];

  const radioOptions = [
    {
      label: "Customer",
      value: ApplicableUser.CUSTOMER,
    },
    {
      label: "Business Associate",
      value: ApplicableUser.BUSINESS_ASSOCIATE,
    },
  ];

  const getBAActivityLog = (id: string) => {
    setLoading(true);
    ActivityLogService.fetchActivityLogData(
      id,
      userRadioValue,
      (data: ActivityLogModel[]) => {
        setactivityLogData(data);
        setIsTableVisible(true);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleFetchBAs = () => {
    setLoading(true);
    BusinessAssociateService.fetchBusinessAssociates(
      // searchText,
      new BaFilterParams(),
      // 1,
      (businessAssociates: BusinessAssociate[]) => {
        setBusinessAssociates(businessAssociates);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const baOptions = businessAssociates?.map((ba) => {
    return (
      <Option value={`${ba?.id}`} key={ba?.id}>
        {`${ba?.firstName} ${ba?.lastName}`}
      </Option>
    );
  });

  useEffect(() => {
    handleFetchBAs();
  }, []);

  return (
    <div className="activity-log">
      <Row align="middle">
        <Col span={12}>
          <h1 className="text-primary">
            <span>Audit Trail </span>
          </h1>
        </Col>
      </Row>
      <Row>
        <Col span={6} className="mt-2">
          <Radio.Group
            options={radioOptions}
            onChange={(e) => setUserRadioValue(e.target.value)}
            value={userRadioValue}
          ></Radio.Group>
        </Col>

        <Col span={18}>
          <Select
            suffixIcon={<SearchOutlined />}
            showSearch
            placeholder="Search by Name"
            onChange={getBAActivityLog}
            filterOption={(input, option) =>
              option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {baOptions}
          </Select>
        </Col>
      </Row>

      {isTableVisible ? (
        <div className="mt-4">
          <Table dataSource={activityLogData} columns={columns} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default ActivityLog;
