import React from "react";
import "./termsAndConditions.scss";

interface TermsAndConditionsProps {}

function TermsAndConditions(props: TermsAndConditionsProps) {
  return (
    <div className="terms-and-conditions">
      <h2 className="text-primary">Terms and Conditions</h2>
      <p>
        By accessing the Platform or availing any service provided therein, (i.e
        Proprietary services from MyLead Fintech Pvt. Ltd towards fulfillment of
        life’s goals delivered vide its proprietary mobile application
        “FinoBuddy” and the Business Associates connected with MyLead Fintech
        Pvt. Ltd) from MyLead Fintech as applicable, I hereby expressly agree to
        be bound by Terms of Use, Privacy policy of the Platform (defined
        hereinafter) and following terms and conditions:
        <ol>
          <li>
            I acknowledge that MyLead Fintech Pvt. Ltd[“MLFT”] vide its
            proprietary “FinoBuddy” Mobile application/website/Mobile
            Applications [hereinafter referred to as “Platform”] is a digital
            platform, engaged by its Product Partners (i.e. Banks, Non-Banking
            Financial Companies (NBFCs), Housing Finance Companies (HFC) and
            Financial institutions, governed by RBI/NHB and various
            non-financial partners governed under applicable law of the land), 
            to provide services of customer acquisition, preliminary credit and
            request processing support activities, fulfilment services and
            post-acquisition services as applicable and governed by service
            agreement with these Product Partners.  MLFT is also a Corporate
            Agent (Composite) registered under Companies Act, 2013 to solicit
            and service the products.
          </li>
          <li>
            I understand that I would be required to share and submit Personal
            identifiable information and sensitive personal information
            [collectively referred as “Personal information”] on the Platform in
            connection with my loan/investment / other services, as applicable
            and that, use and processing of my Personal Information shall be
            governed by the Privacy policy of this Platform.
          </li>
          <li>
            I confirm that I have read the information on various products,
            including MITC (Most Important Terms and Conditions) as applicable,
            applicable fees and changes, if any and as available on this
            Platform. I affirm that all information provided by me are correct,
            complete, and up-to-date and no information as required to process
            my application has been withheld. I will keep MLFT informed of any
            change in the information provided by me.
          </li>
          <li>
            I agree and authorize MLFT to share my information with its
            respective Product Partners in connection with my application.
            Further, I authorize that I may be contacted by MLFT or its Partners
            vide email, SMS, phone call, WhatsApp or any other mode of
            communication as provided/available.
          </li>
          <li>
            I authorize MLFT and its business associates to share all
            information provided by me or obtained as a result of my product
            application on the Platform, including but not limited to my family
            details, existing loans, repayment history, declared income details
            for verification purposes with other third parties including but not
            limited to, service providers, banks, financial institutions, credit
            bureaus, statutory bodies, or any other party as they deem
            necessary. I shall not hold MLFT, its affiliates, group companies or
            its business associates, liable for use/sharing of the information
            as mentioned above.
          </li>
          <li>
            I accept that my product application is subject to approval.
            approval/rejection of my application and Sanction of facility
            granted to me is the sole decision of the Partners of MLFT. MLFT
            holds no warranty and does not make any representation or commitment
            of approval/rejection of my application or the sanction of request
            or a commitment to fulfill the offerings on the Platform.
          </li>
          <li>
            I understand that my application may be rejected by a Partner of
            MLFT, without specifically assigning a reason to reject the
            application. MLFT or the Product Partners will have no warranty or
            liability for such rejections.
          </li>
          <li>
            I acknowledge that any facility limit of any particular facility
            issued shall be absolute discretion of the Partners and I may not
            always receive my indicated choice of product variant.
          </li>
          <li>
            Facility pricing shown are minimum available and may vary depending
            on my personal circumstances, request amount and MLFT’s partner
            approval criteria. Relevant calculators are provided on the Platform
            are only as self-help planning tools. Results depend on many
            factors, including the assumptions I provide. MLFT does not
            guarantee its accuracy or applicability to my facility application.
          </li>
          <li>
            Any decision to apply for a facility is solely at my own risk and
            will hold MLFT harmless for any loss or damage that I may suffer,
            whether directly or indirectly. Display of products of various
            Partners on the Platform does not in any way imply, or constitute
            any endorsement, recommendation, advice, or approval from MLFT in
            favour/against the Partners and/or their products.
          </li>
          <li>
            I agree and consent to receive communications in relation to
            customized recommendations, promotional offers, information on
            various other products and services available on this Platform by
            MLFT via email, SMS, WhatsApp, phone call, App notifications or any
            other means of communication. I expressly waive any registration
            made I under DND/NCPR list under TRAI regulations, when contacted
            via SMS or phone call.
            <br />
            <br />
            I understand that I am required to create an account by providing
            accurate and correct information and MLFT is under no obligation to
            verify the information I provide.
            <br />
            <br />
            I understand that MLFT has the right to reject the request for
            creation of my account if the information submitted appears
            incomplete, inaccurate and fraudulent.
            <br />
            <br />
            I undertake to access any information, material, documents on
            Platform, provided by itself or any third party but accessible to
            the Client, or avail any Service only through the authorised access
            provided by MLFT.
            <br />
            <br />
            I undertake to ensure that any material/information/data uploaded by
            me on the Platform is strictly of nature and context as required by
            the Platform for the relevant Service and is not in violation of any
            applicable law.
            <br />
            <br />
            I am 18 years of age or over. I understand that If I am under 18 and
            if wish to access or use the Services, my parents or legal guardian
            must acknowledge and agree to this T&C. Should my parents or legal
            guardian fail to agree or acknowledge the T&C, I shall immediately
            discontinue its use.
            <br />
            <br />
          </li>
        </ol>
      </p>
      <h4>User Declaration</h4>
      <p>
        By using this mobile application/mobile application/website , you
        confirm that you are a resident of India and have attained the age of
        majority to enter into a binding contract and that you are not a person
        barred from receiving services under the laws of India or other
        applicable jurisdiction and will only use the mobile application/mobile
        application/website to make legitimate transactions for you or for
        another person for whom you are legally authorized to act (and will
        inform such other persons about the terms of use and/or Privacy Policy)
        that apply to the purchase/transactions you have made on their behalf
        (including all rules and restrictions applicable thereto). You also
        confirm that the statements, answers and/ or information given by you
        are true and complete in all respects and that you are authorized to
        transact/purchase on behalf of other person(s). You agree that if you
        undertake any financial transaction through the application/mobile
        application/website, MLFT will conduct due diligence and seek mandatory
        information required for KYC purpose; and as a user you hereby authorise
        MLFT to facilitate your transaction in accordance with the prevailing
        Laws and regulations, including Prevention of Money Laundering Act and
        its rules. Any payment towards the services availed shall be remitted
        only through the requestors bank account or from a joint bank account in
        which the requestor is a joint holder. While using the Mobile
        application/mobile application/website to communicate with MLFT, you
        shall communicate with your registered mobile/telephone number and/or
        e-mail address. Thereby, you agree to receive communication from MLFT on
        your registered mobile/telephone number and/or e-mail address.
        Notwithstanding whether your telephone/mobile is registered with NDNC
        and or notwithstanding the Telecom Regulatory Authority of India [TRAI]
        restrictions/guidelines on unsolicited telecalls, by you accessing this
        Mobile application/mobile application/website and
        applying/enquiring/verifying any of the products/services offered by
        MLFT and or you making a request to call back, it is deemed that you
        have expressly authorized MLFT to call back or send you text messages or
        messages through Chatbots for solicitation and procurement of various
        products and services of MLFT. In case if you may not wish to receive
        any calls/messages, Site, you agree NOT to, by any means indulge in
        illegal or unauthorized activities including but not limited to (i) Use
        the Site for unlawful purposes; (ii) Collect information about the users
        without their express consent; (iii) Engage in spamming or flooding;
        (iv) Transmit any software or other materials that contain any virus or
        other harmful or disruptive component; (v) Remove any copyright,
        trademark or other proprietary rights notices contained in the Site;
        (vi) Copy, mirror any part of the Site without our prior permission;
        (vii) Permit or help anyone without access to use the Mobile
        application/mobile application/website through your username and
        password or otherwise; and (viii) posting any defamatory messages to
        either in print/electronic/social media; (ix) Impersonate any person or
        entity, falsely claim an affiliation with any person or entity, or
        access the Accounts of others without permission, forge/fabricate
        another persons’ digital signature, misrepresent the source, identity,
        or content of information transmitted via the Site, perform any other
        similar fraudulent activity or otherwise send or receive what MLFT
        reasonably believes to be potentially fraudulent funds; (x) Infringe
        MLFT or any third party’s intellectual property rights, rights of
        publicity or rights of privacy; (xi) Use any robot, spider, other
        automatic device, or manual process to monitor or copy the mobile
        application/mobile application/website without prior written permission;
        (xii) Use any device, software or routine to bypass mobile
        application/mobile application/website exclusion headers, or interfere
        or attempt to interfere, with the mobile application/mobile
        application/website; (xiii) Remove, circumvent, disable, damage or
        otherwise interfere with security-related features of the mobile
        application/website or features that enforce limitations on the use of
        the Site; (xiv) Reverse engineer, decompile, disassemble or otherwise
        attempt to discover the source code of the mobile application/mobile
        application/website or any part thereof; (xv) Use the Site in any manner
        that could damage, disable, overburden, or impair it, including but not
        limited to using Site in an automated manner; (xvi) Intentionally
        interfere with or damage or corrupt operation of the Site or any user’s
        enjoyment of them, by any means, including but not limited to uploading
        or otherwise disseminating viruses, adware, spyware, worms, or other
        malicious code;(xv) attempt to gain any unauthorized access to the
        Platform, the server on which the Platform is hosted or any other
        database, server or computer connected to the Platform; (collectively
        referred to as “Prohibited Conduct”) You voluntarily consent to register
        as a member of MLFT Community to get personalized and exclusive
        products, offers and services from MLFT in collaboration with its
        business partners for meeting your life’s needs while you access any
        Products or Services on MLFT Web-Site and mobile application.
      </p>
      <h4>Indemnity </h4>
      <p>
        You agree to indemnify and hold us and our affiliates, successors and
        assigns, officers, directors, employees, agents, representatives,
        licensors, advertisers, suppliers, and operational service providers
        harmless from and against any and all losses, expenses, damages, costs
        and expenses (including attorneys’ fees), resulting from your use of the
        Services and / or any violation of this T&C including any Prohibited
        Conduct. We reserve the right to assume the exclusive defense and
        control of any demand, claim or action arising hereunder or in
        connection with the Website/Mobile Application and all negotiations for
        settlement or compromise. You agree to fully cooperate with us in the
        defense of any such demand, claim, action, settlement or compromise
        negotiations, as requested by us.
      </p>
      <h4>Use of Material and Information </h4>
      <p>
        The content (material, information, data, news, etc.) contained on this
        mobile application / website is provided for general information only
        and should not be construed as any kind of advice or be used as a basis
        for making business/commercial decisions. You are requested to exercise
        caution and/or seek independent professional advice before entering any
        investment or financial obligation based on the content contained on
        this Site. Images used on this Site and the models photographed in them
        are for representative purposes only. The content on this Site should
        NOT be regarded as an offer, solicitation, invitation, advice or
        recommendation to buy or sell financial instruments/ non-financial
        products and services of MLFT, its group companies, business partners,
        or any of its affiliates. Products and Services are available only at
        the discretion of MLFT, subject to the individual contractual terms and
        conditions of the respective entities’ products and services basis which
        such products & services are offered and such products and services may
        be withdrawn or amended at any time without notice. In the event of any
        conflict between the terms and conditions of specific products and
        services displayed on the mobile application/mobile application/website
        vis-à-vis the terms and conditions laid down by MLFT’s business partner
        or MLFT’s group company or affiliate (‘Product owner’), the terms and
        conditions laid down by the Product owner shall prevail.
      </p>
      <h4>No Representation or Warranty </h4>
      <p>
        No information sent to any user through this mobile application / Site
        or available on this mobile application / Site shall constitute any
        representation or warranty by MLFT, or its Group Companies, Subsidiaries
        or Affiliates regarding the credit-worthiness, financial performance or
        prospects, solvency, or viability of any company or other legal entity
        or the business carried on by such entity. This Site may contain
        statements of various professionals/ experts/ analysts, etc. MLFT does
        not represent/ endorse the accuracy, reliability of any of statements/
        information by any such person. It is the responsibility of the user of
        this Site to independently verify and evaluate the accuracy,
        completeness, reliability and usefulness of any statements, services or
        other information provided on this Site. All information in this Site is
        being provided under the condition and understanding that the same is
        not being interpreted or relied on as legal, accounting, tax, financial,
        investment or other professional advice, or as advice on specific facts
        or matters. MLFT may at any time (without being obliged to do so)
        update, edit, alter and or remove any information in whole or in part
        that may be available on this Site and shall not be held responsible for
        all or any actions that may subsequently result in any loss, damage and
        or liability. Nothing contained herein is to be construed as any advice
        or recommendation to use any product or process. Though MLFT will
        endeavour that information contained on this Site is obtained from
        sources which are reliable, however MLFT does not warrant such
        information’s completeness or accuracy. MLFT does not represent or
        warrant that the Mobile application / website will be uninterruptedly
        available. Please note that (a) the Mobile application / website may not
        meet your requirements; (b) your access may be interrupted; (c) there
        may be delays, failures, errors or omissions or loss of transmitted
        information, that viruses or other contaminating or destructive
        properties may be transmitted or that damage may occur to your computer
        system. It is clarified that internet transactions may be subject to
        interruption, transmission blackout, delayed transmission and incorrect
        data transmission. MLFT is not liable for malfunctions in communications
        facilities (that may affect the accuracy or timeliness of messages and
        transactions you may initiate. <br />
        You have sole responsibility for ensuring adequate protection and back
        up of data and/or equipment and for undertaking reasonable and
        appropriate precautions to scan for computer viruses or other
        destructive properties. MLFT makes no representations or warranties
        regarding the accuracy, functionality or performance of any third-party
        software or website or application that may be used relating to the
        Mobile application / website.
      </p>
      <h4>Payment Terms</h4>
      <p>
        I agree to pay the charges for the Services as stipulated by MLFT from
        time to time, as applicable. I understand that there may be additional
        charges for use of payment gateway or bank charges which may be
        applicable. MLFT shall not be liable to refund in case of any
        cancellation, rejection or no credit information report can be generated
        based on the information provided by me. The charges shall be inclusive
        of all taxes, fee including payment gateway charges if any. I undertake
        that the payment instrument used by me belongs to me or I am fully
        authorized to make use of such instruments to make payments. I
        understand the payment gateways services for such payment may not belong
        to MLFT and may be provided by third-parties. I shall not hold MLFT
        responsible for the error and  MLFT shall not be obligated to indemnify
        me for any loss, damage, claim suffered by me due to use of such payment
        instruments and/or payment gateways.
      </p>
      <h4>Limitation of Liability </h4>
      <p>
        MLFT, its group companies, business partners, subsidiary companies, its
        affiliates, and their directors and employees accept no liability for
        any loss or damage arising directly or indirectly (including special,
        incidental or consequential, punitive, or exemplary loss, dam age or
        expenses) from your use of this Mobile application / website or any
        linked Mobile application / website howsoever arising, and including any
        loss, damage or expense arising from, but not limited to, any defect,
        error, omission, interruption, imperfection, fault, mistake or
        inaccuracy with this Mobile application / website, its contents or
        associated services, or due to any unavailability of the Mobile
        application / website or any part thereof or any contents or associated
        services. MLFT reserves the right to change the information provided on
        or via this Mobile application / website, including the terms of this
        disclaimer, at any time and without notice. However, MLFT shall not
        undertake to update the content contained on this Site from time to
        time. You are obliged to exercise your independent diligence on the
        information provided on or via this Mobile application / website before
        arriving at any decision and you will be solely responsible for your
        actions. MLFT shall not be held responsible for all or any actions that
        may subsequently result in any loss, damage and or liability on account
        of such change in the information on this Mobile application / website.
      </p>
      <h4>Intellectual Property Rights</h4>
      <p>
        Nothing contained in our mobile application/website should be construed
        as granting any license or right to use any Trade Marks displayed on our
        mobile application/website. MLFT, retains all rights (including
        copyrights, trademarks, patents as well as any other intellectual
        property right) in relation to all information provided on or via this
        Site (including all texts, graphics and logos). Users are prohibited
        from using, modifying, copying, distributing, transmitting, displaying,
        publishing, selling, licensing, creating derivative works or using any
        Content available on or through the mobile application/website for any
        purpose without written permission of MLFT of such or such other
        parties. The materials on this mobile application/website are protected
        by copyright and no part of such materials may be modified, reproduced,
        stored in a retrieval system, transmitted (in any form or by any means),
        copied, distributed, used for creating derivative works or used in any
        other way for commercial or public purposes without the prior written
        consent of MLFT.
      </p>
      <h4>Linked Mobile application/websites</h4>
      <p>
        This Site may contain links to other mobile application/websites of
        group companies, business partners, collaborators Subsidiary companies
        and Affiliate companies of MLFT. This Site may also contain links to
        external mobile application/websites, having further linked mobile
        application/websites, controlled or offered by third parties
        (Non-Affiliates of MLFT), to help you find relevant mobile
        application/websites, services and/or products which may be of interest
        to you, quickly and easily. The contents displayed or products /
        services offered on such linked mobile application/websites or any
        quality of the products/ services are not endorsed, verified or
        monitored by MLFT. MLFT is also not responsible for the owners or
        operators of such external mobile application/websites or for any
        products or services they supply or for the contents of their mobile
        application/websites and do not give or enter into any conditions,
        warranties, express or implied; or other terms or representations in
        relation to any of these or accept any liability in relation to any of
        these (including any liability arising out of any claim that the content
        of any external mobile application/website to which this mobile
        application/website includes a link infringes the intellectual property
        rights of any third party).
      </p>
      <h4>Governing Law </h4>
      <p>
        This Site and disclaimer shall be governed by and construed in
        accordance with all applicable laws of India. All disputes arising out
        of or relating to this disclaimer or use of this mobile
        application/website shall be submitted to the exclusive jurisdiction of
        the courts of Mumbai. By accessing this mobile application / website and
        any of its webpages, it is deemed that you are agreeing to the terms set
        out above.
      </p>
    </div>
  );
}

export default TermsAndConditions;
