import { serializable, alias, object, list, primitive } from "serializr";

export class PaginationDetails {
  @serializable(alias("current_page", primitive()))
  currentPage?: number;
  @serializable(alias("next_page", primitive()))
  nextPage?: number;
  @serializable(alias("prev_page", primitive()))
  prevPage?: number;
  @serializable(alias("total_pages", primitive()))
  totalPages?: number;
  @serializable(alias("total_count", primitive()))
  totalCount?: number;
}
