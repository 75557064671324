import React, { FC } from "react";
import { Radio, RadioChangeEvent } from "antd";
import { OptionProps } from "../../types/option.type";
import "./radioField.scss";
import { ErrorMessage, Field } from "formik";
import Error from "../Error";

interface RadioFieldProps {
  value?: string | number | boolean;
  options: OptionProps[];
  title?: string;
  name: string;
  defaultValue?: string;
  setFieldValue: (name: string, value: any) => void;
}

const RadioField: FC<RadioFieldProps> = (props) => {
  const { value, options, title, name, setFieldValue, defaultValue } = props;

  const handleChange = (event: RadioChangeEvent) => {
    setFieldValue(name, event.target.value);
  };
  return (
    <Field name={name}>
      {({ field, form: { touched, errors, setFieldTouched }, meta }: any) => {
        return (
          <div className="radio-field mb-4">
            <label htmlFor="">{title}</label>
            <Radio.Group
              onChange={handleChange}
              value={value}
              defaultValue={defaultValue}
            >
              {options.map(({ label, value }) => (
                <Radio value={value}>{label}</Radio>
              ))}
            </Radio.Group>
            <ErrorMessage name={name}>
              {(message: string) => (
                <Error className={`${name}__error`} message={message} />
              )}
            </ErrorMessage>
          </div>
        );
      }}
    </Field>
  );
};

export default RadioField;
