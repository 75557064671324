import { serializable, alias, object, list, primitive } from "serializr";

export class BaFilterParams {
  @serializable(alias("rm_ids", list(primitive())))
  rmIds: number[] = [];

  @serializable(alias("ba_ids", list(primitive())))
  baIds: number[] = [];
  @serializable(alias("states", list(primitive())))
  stateIds: number[] = [];

  @serializable(alias("cities", list(primitive())))
  cityIds: number[] = [];

  @serializable(alias("localities", list(primitive())))
  localityIds: number[] = [];

  @serializable(alias("profile_completion_statuses", list(primitive())))
  profileCompletionStatuses: string[] = [];

  @serializable(alias("statuses", list(primitive())))
  statuses: string[] = [];

  @serializable(alias("search_text", primitive()))
  search?: string;

  @serializable(alias("page", primitive()))
  page?: number;

  @serializable(alias("role", primitive()))
  role?: string;
  @serializable(alias("names", list(primitive())))
  names: string[] = [];
}
