import React, { useEffect, useState } from "react";
import "./customerDetail.scss";
import { useParams } from "react-router-dom";
import ComingSoon from "../../../shared/components/ComingSoon";
import PageTitle from "../../../shared/components/PageTitle";
import AppLoader from "../../../shared/components/AppLoader";
import { Button, Col, Input, Popover, Row, Tabs, Tooltip } from "antd";
import { MailOutlined, PhoneOutlined, PlusOutlined } from "@ant-design/icons";
import userAvatarPlaceholder from "../../../assets/images/userAvatarPlaceholder.jpeg";
import { Customer } from "../../../models/Customer/customer.model";
import CustomerPersonalDetails from "../CustomerPersonalDetails";
import CustomerExistingLoan from "../CustomerExistingLoan";
import CustomerService from "../../../services/Customer/customer.service";
import CustomerAdditionalInformation from "../CustomerAdditionalInformation";
import moment from "moment";
import avatarPlaceholder from "../../../assets/images/userAvatarPlaceholder.jpeg";
import NoteService from "../../../services/Note/note.service";
import { Note } from "../../../models/Note/note.model";
import CustomerProducts from "../CustomerProducts";
import CustomerSurvey from "../CustomerSurvey";
import CustomerBureauReport from "../CustomerBureauReport";

interface CustomerDetailProps {}

interface CustomerDetailParams {
  customerId: string;
}

function CustomerDetail(props: CustomerDetailProps) {
  const [customer, setCustomer] = useState<Customer>();

  const [noteFormLoading, setNoteFormLoading] = useState(false);

  const params: CustomerDetailParams = useParams();
  const [noteText, setNoteText] = useState<string>();
  const [notesValue, setNotesValue] = useState<Note[]>([]);

  const [loading, setLoading] = useState(false);

  const handleChangeTab = () => {};

  const userDetailTabs = [
    {
      title: "Personal Details",
      component: customer ? (
        <CustomerPersonalDetails customer={customer} />
      ) : null,
    },
    {
      title: "Existing Loan",
      component: customer ? <CustomerExistingLoan customer={customer} /> : null,
    },
    {
      title: "Additional Information",
      component: customer ? (
        <CustomerAdditionalInformation customer={customer} />
      ) : null,
    },
    {
      title: "Products",
      component: customer ? <CustomerProducts customer={customer} /> : null,
    },
    {
      title: "Survey",
      component: customer ? <CustomerSurvey customer={customer} /> : null,
    },
    {
      title: "Bureau Report",
      component: customer ? <CustomerBureauReport customer={customer} /> : null,
    },
  ];

  useEffect(() => {
    if (params.customerId) {
      setLoading(true);
      CustomerService.showCustomer(
        Number(params.customerId),
        (customer: Customer) => {
          setCustomer(customer);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }

    handleFetchCustomerNotes();
  }, []);
  const handleFetchCustomerNotes = () => {
    if (params?.customerId) {
      NoteService.fetchNotes(
        Number(params?.customerId),
        "customer",
        (notes: Note[]) => {
          setNotesValue(notes);
        },
        () => {},
        () => {}
      );
    }
  };

  const handleCreateNotes = () => {
    if (params?.customerId) {
      setNoteFormLoading(true);
      NoteService.createNotes(
        Number(params?.customerId),
        "customer",
        Object.assign(new Note(), {
          noteText,
        }),
        (note: Note) => {
          handleFetchCustomerNotes();
        },
        () => {},
        () => {
          setNoteText(undefined);
          setNoteFormLoading(false);
        }
      );
    }
  };

  return (
    <div className="customer-detail">
      {customer?.firstName && (
        <PageTitle title={customer?.firstName + " " + customer?.lastName} />
      )}
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Row gutter={[20, 20]}>
          <Col span={17}>
            <Tabs
              defaultActiveKey="0"
              size="small"
              style={{ marginBottom: 32 }}
              onChange={handleChangeTab}
            >
              {userDetailTabs.map(({ title, component }, i) => (
                <Tabs.TabPane tab={title} key={i}>
                  {component}
                </Tabs.TabPane>
              ))}
            </Tabs>
          </Col>
          <Col span={7}>
            <div className="customer-detail__profile-card">
              {/*{customer?.id && (
                      <Link
                          to={generatePath(AppRoutes.INTERNAL_USER_EDIT, {
                            internalUserId: employee?.id,
                          })}
                          className="internal-user-detail__edit"
                      >
                        <EditOutlined />
                      </Link>
                  )}*/}
              <img
                className="customer-detail__profile-pic"
                src={
                  customer?.profilePic?.attachmentUrl ?? userAvatarPlaceholder
                }
                alt="Profile picture"
              />
              <h3 className="text-primary">
                {customer?.firstName} {customer?.lastName}
              </h3>
              <div className="customer-detail__contact">
                <Tooltip
                  title={
                    <div>
                      <div>Email</div> <div>{customer?.email}</div>
                    </div>
                  }
                >
                  <MailOutlined />
                </Tooltip>
                <Tooltip
                  title={
                    <div>
                      <div>Mobile</div> <div>{customer?.mobile}</div>
                    </div>
                  }
                >
                  <PhoneOutlined />
                </Tooltip>
              </div>
              <div className="ba-detail__notes text-left mt-4">
                <h3 className="text-primary">
                  <span className="ba-detail__notes-title mt-5">Notes</span>
                  <Popover
                    content={
                      <div className="ba-detail__popover-content">
                        <p className="text-bold text-primary">Notes</p>
                        <Input.TextArea
                          style={{ width: "300px" }}
                          value={noteText}
                          onChange={(e) => setNoteText(e.target.value)}
                        />
                        <div className="mt-3 text-right">
                          <Button
                            type="primary"
                            disabled={!noteText}
                            loading={noteFormLoading}
                            onClick={handleCreateNotes}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    }
                    trigger="click"
                  >
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      className="rounded-btn ml-2"
                    />
                  </Popover>
                </h3>
                {notesValue.map((note, i) => (
                  <div key={i} className="ba-detail__note ml-5">
                    <Row gutter={[20, 20]}>
                      <Col span={6}>
                        <img src={avatarPlaceholder} alt="User" />
                      </Col>
                      <Col span={18}>
                        <div className="ba-detail__note-label">
                          {note?.notedBy?.firstName} {note?.notedBy?.lastName}
                        </div>
                        <div className="ba-detail__note-value">
                          {moment(note.createdAt).format("DD/MM/YYYY hh:mm a")}
                        </div>
                      </Col>
                      <Col span={24}>{note.noteText}</Col>
                    </Row>
                  </div>
                ))}
              </div>
              {/*<div className="mt-2">
                <Select
                  className="ba-detail__status-select"
                  options={profileStatuses}
                  dropdownMatchSelectWidth={false}
                  defaultValue={businessAssociate?.status}
                  style={{ width: 140 }}
                  onChange={handleUpdateStatus}
                />
              </div>*/}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default CustomerDetail;
