import { serializable, alias, object, list, primitive } from "serializr";

export class Commission {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("customer_id", primitive()))
  customerId?: number;

  @serializable(alias("customer_name", primitive()))
  customerName?: string;

  @serializable(alias("product_name", primitive()))
  productName?: string;

  @serializable(alias("product_value", primitive()))
  productValue?: string;

  @serializable(alias("brokerage_value", primitive()))
  brokerageValue?: string;

  @serializable(alias("transaction_ref", primitive()))
  transactionRef?: string;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;
}
