import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import axiosInstance from "../../interceptor/axiosInstance";
import { Commission } from "../../models/Commission/commission.model";
import { deserialize, serialize } from "serializr";

export default class CommissionService {
  static fetchCommissions(
    baId: number,
    status: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.COMMISSIONS, {
        params: {
          business_associate_id: baId,
          status,
        },
      })
      .then((response) => {
        const commissions = deserialize(
          Commission,
          response.data["commissions"]
        );
        onSuccess(commissions, response.data["total_amount"]);
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }

  static updateCommission(
    commissionIds: number[],
    transactionReference: string,
    status: string,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .put(ApiRoutes.COMMISSIONS, {
        commission_ids: commissionIds,
        status,
        transaction_ref: transactionReference,
      })
      .then((response) => {
        onSuccess();
      })
      .catch((error) => {
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
