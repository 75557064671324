import React, { useEffect, useState } from "react";
import "./internaluserDetail.scss";
import { EditOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { useHistory, useParams, Link, generatePath } from "react-router-dom";
import { Col, Row, Select, Tabs, Tooltip } from "antd";
import AppLoader from "../../../shared/components/AppLoader";
import UserPersonalDetails from "../UserPersonalDetails";
import { Employee } from "../../../models/Employee/employee.model";
import ComingSoon from "../../../shared/components/ComingSoon";
import PageTitle from "../../../shared/components/PageTitle";
import EmployeeService from "../../../services/Employee/employee.service";
import userAvatarPlaceholder from "../../../assets/images/userAvatarPlaceholder.jpeg";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import BusinessCreditPoints from "../BusinessCreditPoints";
import { ApplicableUser } from "../../../enums/applicableUser.enum";

const { TabPane } = Tabs;

interface InternalUserDetailProps {}

interface InternalUserDetailParams {
  internalUserId: string;
}

function InternalUserDetail(props: InternalUserDetailProps) {
  const [employee, setEmployee] = useState<Employee>();

  const params: InternalUserDetailParams = useParams();

  const [loading, setLoading] = useState(false);

  const handleChangeTab = () => {};

  const userDetailTabs = [
    {
      title: "Personal Details",
      component: employee ? <UserPersonalDetails employee={employee} /> : null,
    },
    { title: "Reimbursement Claimed", component: <ComingSoon /> },
    {
      title: "Business Credit Points",
      component:
        employee?.role?.name === ApplicableUser?.RELATIONSHIP_MANAGER ? (
          <BusinessCreditPoints employee={employee} />
        ) : (
          <ComingSoon />
        ),
    },
  ];

  useEffect(() => {
    if (params.internalUserId) {
      setLoading(true);
      EmployeeService.showEmployee(
        Number(params.internalUserId),
        (employee: Employee) => {
          setEmployee(employee);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  }, []);

  return (
    <div className="internal-user-detail">
      {employee?.firstName && (
        <PageTitle title={employee?.firstName + " " + employee?.lastName} />
      )}
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <Row gutter={[20, 20]}>
          <Col span={17}>
            <Tabs
              defaultActiveKey="0"
              size="small"
              style={{ marginBottom: 32 }}
              onChange={handleChangeTab}
            >
              {userDetailTabs.map(({ title, component }, i) => (
                <TabPane tab={title} key={i}>
                  {component}
                </TabPane>
              ))}
            </Tabs>
          </Col>
          <Col span={7}>
            <div className="internal-user-detail__profile-card">
              {employee?.id && (
                <Link
                  to={generatePath(AppRoutes.INTERNAL_USER_EDIT, {
                    internalUserId: employee?.id,
                  })}
                  className="internal-user-detail__edit"
                >
                  <EditOutlined />
                </Link>
              )}
              <img
                className="internal-user-detail__profile-pic"
                src={
                  employee?.profilePic?.attachmentUrl ?? userAvatarPlaceholder
                }
                alt="Profile picture"
              />
              <h3 className="text-primary">
                {employee?.firstName} {employee?.lastName}
              </h3>
              <div className="internal-user-detail__contact">
                <Tooltip
                  title={
                    <div>
                      <div>Email</div> <div>{employee?.email}</div>
                    </div>
                  }
                >
                  <MailOutlined />
                </Tooltip>
                <Tooltip
                  title={
                    <div>
                      <div>Mobile</div> <div>{employee?.mobile}</div>
                    </div>
                  }
                >
                  <PhoneOutlined />
                </Tooltip>
              </div>
              {/*<div className="mt-2">
                <Select
                  className="ba-detail__status-select"
                  options={profileStatuses}
                  dropdownMatchSelectWidth={false}
                  defaultValue={businessAssociate?.status}
                  style={{ width: 140 }}
                  onChange={handleUpdateStatus}
                />
              </div>*/}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default InternalUserDetail;
