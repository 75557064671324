import { serializable, alias, object, list, primitive } from "serializr";

export class Requirement {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("description", primitive()))
  description?: string;

  @serializable(alias("customer_id", primitive()))
  customerId?: number;

  @serializable(alias("info_gathered", primitive()))
  infoGathered?: string;
}
