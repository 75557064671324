import React from "react";
import "./customerAdditionalInformation.scss";
import { Customer } from "../../../models/Customer/customer.model";
import { Row, Col } from "antd";

interface CustomerAdditionalInformationProps {
  customer: Customer;
}

function CustomerAdditionalInformation({
  customer,
}: CustomerAdditionalInformationProps) {
  return (
    <div className="customer-additional-information">
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <div className="customer-additional-information__label">
            Collateral
          </div>
          <div className="customer-additional-information__value">
            {customer?.collateral || "-"}
          </div>
        </Col>
        <Col span={12}>
          <div className="customer-additional-information__label">Job Type</div>
          <div className="customer-additional-information__value text-capitalize">
            {customer?.employmentDetail?.jobType?.split("_").join(" ")}
          </div>
        </Col>
        <Col span={12}>
          <div className="customer-additional-information__label">
            Company name
          </div>
          <div className="customer-additional-information__value">
            {customer?.employmentDetail?.companyName || "-"}
          </div>
        </Col>
        <Col span={24}>
          <div className="customer-additional-information__label">
            Company address
          </div>
          <div className="customer-additional-information__value">
            {customer?.employmentDetail?.companyAddress || "-"}
          </div>
        </Col>
        <Col span={12}>
          <div className="customer-additional-information__label">
            Bank Name
          </div>
          <div className="customer-additional-information__value">
            {customer?.bankDetail?.bankName || "-"}
          </div>
        </Col>
        <Col span={12}>
          <div className="customer-additional-information__label">
            Account number
          </div>
          <div className="customer-additional-information__value">
            {customer?.bankDetail?.accountNumber || "-"}
          </div>
        </Col>
        <Col span={12}>
          <div className="customer-additional-information__label">
            IFSC Code
          </div>
          <div className="customer-additional-information__value">
            {customer?.bankDetail?.ifscCode || "-"}
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <h3 className="text-primary"> Family Members</h3>
        </Col>
        <Col span={8}>
          <div className="customer-additional-information__label">Name</div>
        </Col>
        <Col span={8}>
          <div className="customer-additional-information__label">Age</div>
        </Col>
        <Col span={8} />
        {customer?.familyDetails?.map((familyMember) => (
          <React.Fragment>
            <Col span={8}>
              <div className="customer-additional-information__value">
                {familyMember?.name}
              </div>
            </Col>
            <Col span={8}>
              <div className="customer-additional-information__value">
                {familyMember?.age}
              </div>
            </Col>
            <Col span={8} />
          </React.Fragment>
        ))}
      </Row>
    </div>
  );
}

export default CustomerAdditionalInformation;
