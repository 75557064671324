import { alias, primitive, serializable } from "serializr";

export class City {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("name", primitive()))
  name?: string;

  @serializable(alias("province_id", primitive()))
  provinceId?: number;

  @serializable(alias("province_name", primitive()))
  provinceName?: string;

  @serializable(alias("latitude", primitive()))
  latitude?: string;

  @serializable(alias("longitude", primitive()))
  longitude?: string;
}
