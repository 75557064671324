import React, { useEffect, useState } from "react";
import { Form, Formik, Field } from "formik";
import { Button, Col, Row, Radio, Upload, message, Select, Input } from "antd";
import { useHistory, useParams } from "react-router-dom";
import "./assignBA.scss";
import { BAInactiveStatus } from "../../../models/BusinessAssociate/businessAssociate.model";
import RadioField from "../../../shared/components/RadioField";

import { BusinessAssociateService } from "../../../services/BusinessAssociateService/businessAssociate.service";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import AssignBrokerageGroup from "../AssignBrokerageGroup";
import { BaFilterParams } from "../../../models/BAFilterParams/baFilterParams.model";

interface AssignBusinessAssociateProps {
  currentBaId: number;
  closeHandler: () => void;
}
interface AssignBusinessAssociateParams {}
function AssignBusinessAssociate({
  currentBaId,
  closeHandler,
}: AssignBusinessAssociateProps) {
  {
    const history = useHistory();
    const params: AssignBusinessAssociateParams = useParams();
    const [formLoading, setFormLoading] = useState(false);
    const [formValues, setFormValues] = useState(new BAInactiveStatus());
    const [searchText, setSearchText] = useState<string>("");
    const [updateLoading, setUpdateLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filterParams, setFilterParams] = useState<BaFilterParams>(
      new BaFilterParams()
    );
    const [businessAssociates, setBusinessAssociates] = useState<
      BusinessAssociate[]
    >([]);
    const handleSearch = (searchText: any) => {
      setSearchText(searchText);
      handleFetchBusinessAssociates(searchText);
    };

    const handleFetchBusinessAssociates = (searchText: string) => {
      setLoading(true);
      BusinessAssociateService.fetchBusinessAssociates(
        // searchText,
        filterParams,
        // 1,
        (businessAssociates: BusinessAssociate[]) => {
          setBusinessAssociates(businessAssociates);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    };
    useEffect(() => {
      handleFetchBusinessAssociates(searchText);
    }, [filterParams, searchText]);
    const { Dragger } = Upload;
    const handleSubmit = (values: BAInactiveStatus) => {
      setUpdateLoading(true);
      BusinessAssociateService.setBAInactiveStatus(
        Number(currentBaId),
        values,

        () => {},

        () => {},
        () => {
          setUpdateLoading(false);
          closeHandler();
        }
      );
    };

    return (
      <div className="learning-module-form ">
        <div className="learning-module-form __content-wrapper ">
          <h2 className="text-primary">Assign Business Associate</h2>
          <div className="learning-module-form __form">
            <div className="ba-list__search">
              <Input.Search
                className="ba-list__search"
                placeholder="Search by Name"
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    handleSearch(e.target.value);
                  }
                }}
                style={{ width: 300 }}
              />
            </div>
            <Formik
              enableReinitialize
              initialValues={formValues}
              onSubmit={() => {}}
            >
              {({ values, errors, isValid, dirty, setFieldValue }) => {
                return (
                  <Form>
                    <Row gutter={[10, 0]}>
                      <Col span={24} className="mt-5">
                        <RadioField
                          name="baId"
                          value={values.baId ?? undefined}
                          options={businessAssociates.map((ba) => ({
                            value: ba.id,
                            label: `${ba.firstName} ${ba.lastName} `,
                          }))}
                          setFieldValue={setFieldValue}
                        ></RadioField>
                      </Col>

                      <Col span={24} className="text-right mt-4 cancel-btn">
                        <Button
                          type="text"
                          loading={formLoading}
                          onClick={() =>
                            handleSubmit({
                              baId: null,
                              assignToRm: true,
                            })
                          }
                        >
                          Cancel
                        </Button>
                      </Col>
                    </Row>
                    {/*  */}
                    <Col span={24} className="text-right save-btn">
                      <Button
                        onClick={() =>
                          handleSubmit({ ...values, assignToRm: false })
                        }
                        type="primary"
                        loading={formLoading}
                        disabled={!isValid || formLoading || !dirty}
                      >
                        Assign
                      </Button>
                    </Col>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

export default AssignBusinessAssociate;
