import { ContactPerson } from "../ContactPerson/contactPerson.model";

import { serializable, alias, object, list, primitive } from "serializr";

export class ProductTypeDetail {
  @serializable(alias("address", primitive()))
  address?: string;

  @serializable(alias("banner", primitive()))
  banner?: string;

  @serializable(alias("contact_persons", list(object(ContactPerson))))
  contactPersons: ContactPerson[] = [];

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("legal_entity_name", primitive()))
  legalEntityName?: string;

  @serializable(alias("logo", primitive()))
  logo?: string;

  @serializable(alias("mobile", primitive()))
  mobile?: string;

  @serializable(alias("service_provider_type", primitive()))
  serviceProviderType?: string;
}
