import { Table } from "antd";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import React, { FC, HTMLAttributes } from "react";
import "./tableComponent.scss";
import { TableProps as RcTableProps } from "rc-table/lib/Table";
import {
  TableRowSelection,
  TableCurrentDataSource,
  SorterResult,
  GetPopupContainer,
  SortOrder,
  TableLocale,
  FilterValue,
} from "antd/lib/table/interface";
import TableLoader from "../TableLoader";
import { SizeType } from "antd/lib/config-provider/SizeContext";

interface TableComponentProps {
  columns: ColumnsType<any> | undefined;
  rowKey: any;
  className?: string;
  dataSource: any[] | undefined;
  pagination?: false | TablePaginationConfig | undefined;
  loading?: boolean;
  onRow?: any;
  scroll?: RcTableProps<any>['scroll'] & {
    scrollToFirstRowOnChange?: boolean;
  };
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<any> | SorterResult<any>[],
    extra: TableCurrentDataSource<any>
  ) => void;
  total?: number;
  loadingCount?: number;
  size?: SizeType
}

const TableComponent: FC<TableComponentProps> = (props) => {
  const {
    dataSource,
    columns,
    pagination = false,
    className,
    loading,
    onRow = () => { },
    rowKey,
    total,
    onChange = () => { },
    loadingCount = 15,
    size = "middle",
    scroll = {
      scrollToFirstRowOnChange: true
    }
  } = props;


  return (
    <div className={"table__container " + className}>
      <Table
        size={size}
        scroll={scroll}
        dataSource={loading ? [] : dataSource}
        columns={columns}
        pagination={pagination}
        rowKey={rowKey}
        onChange={onChange}
        locale={{
          emptyText: loading ? <TableLoader length={loadingCount} /> : null
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              onRow(record);
            },
          };
        }}
      />
    </div>
  );
};

export default TableComponent;
