import React, { useEffect, useState } from "react";
import { Input, Radio, Button, Pagination } from "antd";
import "./assignRM.scss";
import faker from "faker";
import { Employee } from "../../../models/Employee/employee.model";
import EmployeeService from "../../../services/Employee/employee.service";
import { BusinessAssociateService } from "../../../services/BusinessAssociateService/businessAssociate.service";
import AppLoader from "../../../shared/components/AppLoader";
import { debounce } from "lodash";
import { BaFilterParams } from "../../../models/BAFilterParams/baFilterParams.model";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { ApplicableUser } from "../../../enums/applicableUser.enum";

interface AssignRMProps {
  selectedBAIds: number[];
  onSuccess: () => void;
}

function AssignRM({ selectedBAIds, onSuccess }: AssignRMProps) {
  const [employees, setEmployees] = useState<Employee[]>([]);

  const [selectedEmployeeId, setSelectedEmployeeId] = useState<number>();

  const [formLoading, setFormLoading] = useState(false);
  const [rmPagination, setRmPagination] = useState<PaginationDetails>();
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (selectedEmployeeId) {
      setFormLoading(true);
      BusinessAssociateService.assignRM(
        selectedBAIds,
        selectedEmployeeId,
        () => {
          onSuccess();
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };
  const handleFetchEmployee = (searchText?: string, page?: number) => {
    setLoading(true);
    const filter = Object.assign(new BaFilterParams(), {
      search_text: searchText,
      page: page,
      // role: "RM",
    });
    EmployeeService.fetchEmployees(
      filter,
      (employees: Employee[], pagination: PaginationDetails) => {
        setEmployees(employees);
        setRmPagination(pagination);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    handleFetchEmployee();
  }, []);

  const handleSearch = debounce((searchText: string) => {
    handleFetchEmployee(searchText);
  }, 500);
  return (
    <div className="assign-rm">
      <h2 className="text-primary">Assign Relationship Manager</h2>
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          <div className="mb-3">
            <Input.Search
              className="assign-rm__search"
              placeholder="Search by name"
              style={{ width: 200 }}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
            />
          </div>
          <Radio.Group
            className="assign-rm__radio-group"
            onChange={(event) => {
              setSelectedEmployeeId(event.target.value);
            }}
          >
            {employees.map((rm, i) => (
              <Radio value={rm?.id} key={rm?.id}>
                {rm?.firstName} {rm?.lastName}
              </Radio>
            ))}
          </Radio.Group>

          <div className="text-right mt-5">
            <>
              <Pagination
                className="mb-5"
                defaultCurrent={1}
                total={rmPagination?.totalCount}
                current={rmPagination?.currentPage}
                onChange={(page) => {
                  handleFetchEmployee("", page);
                }}
              />

              <Button
                type="primary"
                loading={formLoading}
                onClick={handleSubmit}
                disabled={!selectedEmployeeId || formLoading}
              >
                Assign
              </Button>
            </>
          </div>
        </div>
      )}
    </div>
  );
}

export default AssignRM;
