import React, { useEffect, useState } from "react";
import PageTitle from "../../../shared/components/PageTitle";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import { Button, Col, Row } from "antd";
import { Employee } from "../../../models/Employee/employee.model";
import { internalUserFormValidation } from "./internalUserFormValidation";
import DropdownField from "../../../shared/components/DropdownField";
import "./internalUserForm.scss";
import MetaService, {
  IDropdownOption,
} from "../../../services/MetaService/meta.service";
import AddressField from "../../../shared/components/AddressField";
import { Location } from "../../../models/Location/location.model";
import { DropdownModeEnum } from "../../../enums/dropdownMode.enum";
import EmployeeService from "../../../services/Employee/employee.service";
import { useHistory, useParams } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { Role } from "../../../models/Role/role.model";
import { ServingLocation } from "../../../models/ServingLocation/servingLocation.model";
import { BaFilterParams } from "../../../models/BAFilterParams/baFilterParams.model";

interface InternalUserFormProps {}

interface InternalUserFormParams {
  internalUserId: string;
}

function InternalUserForm(props: InternalUserFormProps) {
  const history = useHistory();

  const params: InternalUserFormParams = useParams();

  const [formLoading, setFormLoading] = useState(false);

  const [formValues, setFormValues] = useState(new Employee());

  const [roleOptions, setRoleOptions] = useState<IDropdownOption[]>([]);

  const [locationOptions, setLocationOptions] = useState<IDropdownOption[]>([]);

  const [managerOptions, setManagerOptions] = useState<IDropdownOption[]>([]);

  const handleSubmit = (values: Employee) => {
    const employee = Object.assign(new Employee(), values);
    employee.servingLocations = employee.locationIds.map((locationId) => {
      return Object.assign(new ServingLocation(), {
        locationId,
      });
    });
    employee.setAddresses();
    setFormLoading(true);
    if (params.internalUserId) {
      EmployeeService.updateEmployee(
        employee,
        () => {
          history.push(AppRoutes.INTERNAL_USERS);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    } else {
      EmployeeService.createEmployee(
        employee,
        () => {
          history.push(AppRoutes.INTERNAL_USERS);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  const handleFetchLocations = (provinceId: number, cityId: number) => {
    MetaService.fetchLocations(
      provinceId,
      cityId,
      (locations: Location[]) => {
        setLocationOptions(
          locations.map((location) => ({
            label: location.name,
            value: location.id,
          }))
        );
      },
      () => {},
      () => {}
    );
  };

  useEffect(() => {
    if (params.internalUserId) {
      EmployeeService.showEmployee(
        Number(params.internalUserId),
        (employee: Employee) => {
          setFormValues(employee);
          if (
            employee?.communicationAddress?.provinceId &&
            employee?.communicationAddress?.cityId
          ) {
            handleFetchLocations(
              employee?.communicationAddress?.provinceId,
              employee?.communicationAddress?.cityId
            );
          }
        },
        () => {},
        () => {}
      );
    }
    MetaService.fetchRoles(
      (roles: Role[]) => {
        setRoleOptions(
          roles.map((role) => ({
            label: role.name,
            value: role.id,
          }))
        );
      },
      () => {},
      () => {}
    );

    EmployeeService.fetchEmployees(
      new BaFilterParams(),
      (employees: Employee[]) => {
        setManagerOptions(
          employees.map((employee) => ({
            label: employee.firstName + " " + employee.lastName,
            value: employee.id,
          }))
        );
      },
      () => {},
      () => {}
    );
  }, []);

  return (
    <div className="internal-user-form">
      <PageTitle title="Internal User" />
      <div className="internal-user-form__content-wrapper">
        <h2 className="text-primary">
          {params?.internalUserId ? "Edit" : "New"} Employee
        </h2>
        <div className="internal-user-form__form">
          <Formik
            enableReinitialize
            initialValues={formValues}
            onSubmit={handleSubmit}
            validationSchema={internalUserFormValidation}
          >
            {({ values, errors, isValid, dirty, setFieldValue }) => {
              return (
                <Form>
                  <Row gutter={[20, 0]}>
                    <Col span={12}>
                      <InputField
                        title="First name"
                        type="text"
                        name="firstName"
                        placeholder="Enter First name"
                        required
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Last name"
                        type="text"
                        name="lastName"
                        placeholder="Enter Last name"
                        required
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Email"
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        required
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Mobile"
                        type="text"
                        name="mobile"
                        placeholder="Enter Mobile"
                        required
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Position"
                        type="text"
                        name="position"
                        placeholder="Enter Position"
                        required
                      />
                    </Col>
                    <Col span={12}>
                      <DropdownField
                        title="Role"
                        name="roleId"
                        options={roleOptions}
                        value={values.roleId}
                        setFieldValue={setFieldValue}
                        placeHolder="Select Role"
                        required
                      />
                    </Col>
                    <Col span={12}>
                      <InputField
                        title="Educational Qualification"
                        type="text"
                        name="qualification"
                        placeholder="Enter Qualification"
                        required
                      />
                    </Col>
                    <Col span={12}>
                      <DropdownField
                        title="Reporting Manager"
                        name="reportingUserId"
                        options={managerOptions}
                        value={values.reportingUserId}
                        setFieldValue={setFieldValue}
                        placeHolder="Select Reporting Manager"
                        required
                      />
                    </Col>
                    <AddressField
                      required
                      provinceKey="communicationAddress.provinceId"
                      provinceValue={values?.communicationAddress?.provinceId}
                      cityKey="communicationAddress.cityId"
                      cityValue={values?.communicationAddress?.cityId}
                      setFieldValue={setFieldValue}
                      values={values}
                      zipcodeKey="communicationAddress.zipcode"
                      streetKey="communicationAddress.street"
                      onCityChange={(value) => {
                        setFieldValue("communicationAddress.cityId", value);
                        if (values?.communicationAddress?.provinceId) {
                          handleFetchLocations(
                            values.communicationAddress.provinceId,
                            value
                          );
                        }
                      }}
                    />
                    <Col span={12}>
                      <DropdownField
                        title="Serving Locations"
                        mode={DropdownModeEnum.MULTIPLE}
                        name="locationIds"
                        options={locationOptions}
                        value={values.locationIds}
                        setFieldValue={setFieldValue}
                        placeHolder="Select Locations"
                        required
                      />
                    </Col>
                    <Col span={24} className="text-right mt-4">
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={formLoading}
                        disabled={!isValid || formLoading}
                      >
                        Proceed
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default InternalUserForm;
