import {
  serializable,
  alias,
  object,
  list,
  primitive,
  custom,
  SKIP,
} from "serializr";
import { Product } from "../Product/product.model";

interface TransactionDetails {
  [x: string]: string;
}

class ProductDetails {
  @serializable(alias("amount", primitive()))
  amount?: number;
  @serializable(alias("name", primitive()))
  name?: string;
}

export class BureauReport {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("score", primitive()))
  score?: number;

  @serializable(alias("customer_id", primitive()))
  customerId?: number;
  @serializable(alias("order_type", primitive()))
  orderType?: string;

  @serializable(alias("customer_name", primitive()))
  customerName?: string;

  @serializable(alias("product_name", primitive()))
  productName?: string;

  @serializable(alias("product_value", primitive()))
  productValue?: string;

  @serializable(alias("brokerage_value", primitive()))
  brokerageValue?: string;

  @serializable(alias("transaction_ref", primitive()))
  transactionRef?: string;

  @serializable(alias("product", object(ProductDetails)))
  product?: ProductDetails;

  @serializable(alias("order_status", primitive()))
  orderStatus?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(
    alias(
      "transaction_id",
      custom(
        () => SKIP,
        (values) => {
          return values;
        }
      )
    )
  )
  transactionId?: TransactionDetails;
}
