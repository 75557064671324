export enum ProductTypesEnum {
  HEALTH_ASSURE = "Health Pass",
  CAREER_GUIDE = "Career Coach",
  BAJAJ_FINSERV_FD = "Bajaj Finserv Fixed Deposit",
  SAVING_ACCOUNT_AXIS_BANK = "Savings Account by Axis Bank",
  CURRENT_ACCOUNT_AXIS_BANK = "Current Account by Axis Bank",
  CREDIT_SERVICES = "Credit Services",
  WILL_SERVICES = "Will Writing",
  SAVING_ACCOUNT_YES_BANK = "Savings Account by Yes Bank",
  CREDIT_CARD_YES_BANK = "Credit Card by Yes Bank",
}
