import React, { useEffect, FC, useState } from "react";
import PageTitle from "../../../shared/components/PageTitle";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import RadioField from "../../../shared/components/RadioField";
import {
  Button,
  Col,
  Divider,
  Input,
  Row,
  Select,
  Space,
  Typography,
  Upload,
} from "antd";
import { myLearningModuleFormValidation } from "./learningModuleFormValidation";
import DropdownField from "../../../shared/components/DropdownField";
import {
  MyLearning,
  MyLearningModuleForm,
  MyLearningModuleType,
} from "../../../models/MyLearning/myLearning.model";
import { Province } from "../../../models/Province/province.model";
import { City } from "../../../models/City/city.model";
import "./learningModuleForm.scss";
import MetaService, {
  IDropdownOption,
} from "../../../services/MetaService/meta.service";
import { DropdownModeEnum } from "../../../enums/dropdownMode.enum";
import { useHistory, useParams } from "react-router-dom";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { ContentTypeEnum } from "../../../enums/contentType.enum";

import { ApplicableUser } from "../../../enums/applicableUser.enum";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import MyLearningService from "../../../services/MyLearning/myLearning.service";
import { OptionProps } from "../../../shared/types/option.type";
import { values } from "lodash";
const { Option } = Select;
const { Link } = Typography;
interface LearningModuleFormProps {
  learning: MyLearning;
  handleLearningForm: () => void;
  onLearningUpdate: () => void;
}

const LearningModuleForm: FC<LearningModuleFormProps> = (props) => {
  const { handleLearningForm, onLearningUpdate, learning } = props;
  const history = useHistory();
  const [formValues, setFormValues] = useState(new MyLearningModuleForm());
  const [provinceCityLoading, setprovinceCityLoading] = useState(false);
  var [roleOptions, setRoleOptions] = useState<IDropdownOption[]>([]);
  const [roleValue, setRoleValue] = useState("");
  const [moduleType, setModuleType] = useState<IDropdownOption[]>([]);
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);

  const [provinceOptions, setProvinceOptions] = useState<IDropdownOption[]>([]);
  const [provinceIds, setProvinceIds] = useState<[]>([]);
  const [cityIds, setCityIds] = useState<[]>([]);
  const [cityOptions, setCityOptions] = useState<IDropdownOption[]>([]);
  const [dropdownName, setDropdownName] = useState("");

  const {
    addNewCourse,
    loading,
    fetchLearningModuleTypes,
    learningModuleType,
    updateLearningModule,
  } = MyLearningService();

  const contentType = [
    {
      label: "PDF",
      value: ContentTypeEnum.PDF,
    },
    {
      label: "Video URL",
      value: ContentTypeEnum.VIDEO_URL,
    },
    {
      label: "Text Only",
      value: ContentTypeEnum.TEXT_ONLY,
    },
  ];
  const handleSelectAllOptions = (value: string[], type: string) => {
    const options = type === "province" ? provinceOptions : cityOptions;
    if (value.includes("all")) {
      const ids = options
        .map((val) => val.value)
        .filter((val, i) => val !== "all");
      return ids;
    }
  };

  const handleFetchProvince = () => {
    setprovinceCityLoading(true);
    MetaService.fetchProvinces(
      (provinces: Province[]) => {
        const province = provinces.map((province) => ({
          label: province.name,
          value: province.id,
        }));
        setProvinceOptions([{ label: "All", value: "all" }, ...province]);
        handleChangeProvince(provinceIds);
        // setProvinceOptions(province);
      },
      () => {},
      () => {
        setprovinceCityLoading(false);
      }
    );
  };

  useEffect(() => {
    handleFetchProvince();
    fetchLearningModuleTypes((learningModuleTypes: MyLearningModuleType[]) => {
      setModuleType(
        learningModuleTypes.map((type) => ({
          label: type.name,
          value: type.id,
        }))
      );
    });
  }, []);
  const handleChangeProvince = (provinceIds: []) => {
    setprovinceCityLoading(true);
    MetaService.fetchMultipleCities(
      provinceIds,
      (cities: City[]) => {
        const city = cities.map((city) => ({
          label: city.name,
          value: city.id,
        }));
        setCityOptions([{ label: "All", value: "all" }, ...city]);
        // setCityOptions(city);
      },
      () => {},
      () => {
        setprovinceCityLoading(false);
      }
    );
  };

  useEffect(() => {
    if (learning?.id) {
      const city = learning?.cityLearningModules.map((val) => val?.cityId);
      const province = learning?.provinceLearningModule.map(
        (val) => val?.provinceId
      );

      setFormValues(
        Object.assign({
          ...learning,
          cityIds: city,
          provinceIds: province,
          pdfContentId: learning?.pdfContent?.id,
        })
      );
      setRoleValue(learning?.contentType ?? "");
    }
  }, [learning]);

  useEffect(() => {
    if (provinceIds.length > 0) handleChangeProvince(provinceIds);
  }, [provinceIds]);

  const applicableUserData = [
    {
      label: "Customer",
      value: ApplicableUser.CUSTOMER,
    },
    {
      label: "Business Associate",
      value: ApplicableUser.BUSINESS_ASSOCIATE,
    },
    {
      label: "Both",
      value: ApplicableUser.BOTH,
    },
  ];

  const handleSubmit = async (values: MyLearningModuleForm) => {
    setFormSubmitLoading(true);
    values = { ...values, status: true };

    learning?.id
      ? await updateLearningModule(learning?.id, values, () => {
          handleLearningForm();
          onLearningUpdate();
        })
      : await addNewCourse(values, () => {
          handleLearningForm();
          onLearningUpdate();
        });

    setFormSubmitLoading(false);
  };
  const handleDropdownNameChange = () => {
    setModuleType([
      ...moduleType,
      { label: dropdownName, value: moduleType.length + 1 },
    ]);
  };

  return (
    <div className="learning-module-form">
      <h1 className="text-primary ml-5 mb-5">
        {learning?.id ? "Edit Learning Module" : "New Learning Module"}
      </h1>
      <div className="learning-module-form__content-wrapper ">
        <h2 className="text-primary"></h2>
        <div className="learning-module-form__form">
          <Formik
            enableReinitialize
            initialValues={formValues}
            onSubmit={handleSubmit}
            validationSchema={myLearningModuleFormValidation}
          >
            {({
              values,
              errors,
              isValid,
              dirty,
              setFieldValue,
              handleChange,
            }) => {
              console.log(values);
              return (
                <Form>
                  <Row gutter={[20, 0]}>
                    <Col span={12} className="applicable-user">
                      <RadioField
                        name="userView"
                        value={values.userView}
                        options={applicableUserData}
                        setFieldValue={setFieldValue}
                      />
                    </Col>
                    <Col span={12}>
                      <DropdownField
                        required
                        title="Module Type"
                        name="learningModuleTypeId"
                        options={moduleType}
                        value={values.learningModuleTypeId}
                        setFieldValue={setFieldValue}
                        placeHolder="Select Module Type"

                        /* dropdownRender={(moduleType) => (
                          <>
                            {moduleType}
                            <Divider className="learning-module-form__divider" />
                            <Space className="learning-module-form__space">
                              <Input
                                placeholder="Enter Module Type"
                                value={dropdownName}
                                onChange={(e) =>
                                  setDropdownName(e.target.value)
                                }
                              />
                              <Link
                                className="learning-module-form__link ml-2"
                                onClick={handleDropdownNameChange}
                              >
                                <PlusOutlined /> Add item
                              </Link>
                            </Space>
                          </>
                        )} */
                      />
                    </Col>

                    <Col span={12}>
                      <InputField
                        required
                        title="Module Name"
                        type="text"
                        name="moduleName"
                        placeholder="Enter Module Name"
                      />
                    </Col>
                    <Col span={12}>
                      <DropdownField
                        required
                        title="Content Type"
                        name="contentType"
                        options={contentType}
                        value={values.contentType}
                        placeHolder="Select Content Type"
                        onChange={(value) => {
                          setFieldValue("contentType", value);
                          setRoleValue(value);
                        }}
                      />
                    </Col>
                    <Col span={12}>
                      <DropdownField
                        showSearch={true}
                        required
                        title="State"
                        name="provinceIds"
                        mode={DropdownModeEnum.MULTIPLE}
                        options={provinceOptions}
                        value={values.provinceIds}
                        onChange={(value) => {
                          const allProvinceIds = handleSelectAllOptions(
                            value,
                            "province"
                          );
                          setFieldValue(
                            "provinceIds",
                            allProvinceIds ? allProvinceIds : value
                          );
                          setProvinceIds(
                            allProvinceIds ? allProvinceIds : value
                          );
                        }}
                        placeHolder="Select State"
                        loading={provinceCityLoading}
                      />
                    </Col>

                    <Col span={12}>
                      <DropdownField
                        showSearch={true}
                        required
                        title="City"
                        name="cityIds"
                        mode={DropdownModeEnum.MULTIPLE}
                        options={cityOptions}
                        value={values?.cityIds}
                        setFieldValue={setFieldValue}
                        placeHolder="Select City"
                        loading={provinceCityLoading}
                        onChange={(value) => {
                          const allCityIds = handleSelectAllOptions(
                            value,
                            "city"
                          );
                          setFieldValue(
                            "cityIds",
                            allCityIds ? allCityIds : value
                          );
                        }}
                      />
                    </Col>

                    {roleValue == ContentTypeEnum.PDF ? (
                      <Col span={24}>
                        <AttachmentUpload
                          accept="application/pdf"
                          name="pdfContentId"
                          placeholder="Browse File"
                          setFieldValue={setFieldValue}
                          value={learning?.pdfContent}
                          required={values.pdfContentId ? false : true}
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                    {roleValue == ContentTypeEnum.TEXT_ONLY ? (
                      <Col span={24}>
                        <InputField
                          title="Notes"
                          type="textarea"
                          name="notes"
                          placeholder="Enter Message"
                          defaultValue={values?.notes}
                          required={roleValue == ContentTypeEnum.TEXT_ONLY}
                        />
                      </Col>
                    ) : (
                      ""
                    )}
                    {roleValue == ContentTypeEnum.VIDEO_URL ? (
                      <Col span={12}>
                        <InputField
                          title="External Link"
                          type="url"
                          name="externalLink"
                          placeholder="Enter external link"
                          required={roleValue == ContentTypeEnum.VIDEO_URL}
                        />
                      </Col>
                    ) : (
                      ""
                    )}

                    <Col span={24} className="text-right mt-4">
                      <Button
                        type="text"
                        // htmlType="submit"
                        onClick={handleLearningForm}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={formSubmitLoading}
                        disabled={
                          !isValid ||
                          !dirty ||
                          formSubmitLoading ||
                          (values.contentType === "pdf" && !values.pdfContentId)
                        }
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default LearningModuleForm;
