import React, { useEffect, useState } from "react";
import "./customerList.scss";
import { useHistory, generatePath, Link, useLocation } from "react-router-dom";
import {
  Button,
  Table,
  Tooltip,
  Row,
  Col,
  Drawer,
  Input,
  TablePaginationConfig,
  Popover,
} from "antd";
import AppLoader from "../../../shared/components/AppLoader";
import { Customer } from "../../../models/Customer/customer.model";
import CustomerService from "../../../services/Customer/customer.service";
import moment from "moment";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { PlusOutlined, FilterOutlined } from "@ant-design/icons";
import AssignBA from "../AssignBA";
import Filter from "../../../shared/components/Filter";
import CustomersFilter from "../CustomersFilter";

import { FilterOptions } from "../../../services/CustomerFilter/customerFilter.service";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { debounce } from "lodash";
import useFilters from "../../../shared/hooks/useFilters";
import { BaFilterParams } from "../../../models/BAFilterParams/baFilterParams.model";
import { serialize } from "v8";
import informationIcon from "../../../assets/images/information.png";
import { CustomerProfileStatus } from "../../../constants/profileStatus";

interface CustomerListProps {}

function CustomerList(props: CustomerListProps) {
  const history = useHistory();

  const location = useLocation();

  const [searchText, setSearchText] = useState("");

  const [loading, setLoading] = useState(false);

  const [customers, setCustomers] = useState<Customer[]>([]);

  const [selectedCustomerIds, setSelectedCustomerIds] = useState<number[]>([]);

  const [showAssignBA, setShowAssignBA] = useState(false);

  const handleToggleAssignBA = () => setShowAssignBA(!showAssignBA);
  const [customerPagination, setCustomerPagination] =
    useState<PaginationDetails>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { getParams, setParams } = useFilters();
  const [filterParams, setFilterParams] = useState<BaFilterParams>(
    new BaFilterParams()
  );

  const handleUpdateBASuccess = () => {
    handleFetchCustomers({ ...filterParams, search: searchText });
    setShowAssignBA(false);
    setSelectedCustomerIds([]);
  };

  const handleRow = (customer: Customer, rowIndex: number | undefined) => {
    return {
      onClick: (event: any) => {
        history.push(
          generatePath(AppRoutes.CUSTOMER_DETAIL, {
            customerId: customer.id,
          })
        );
      },
    };
  };

  const rowSelection = {
    selectedRowKeys: selectedCustomerIds,
    onChange: (selectedRowKeys: React.Key[], customers: Customer[]) => {
      const customerIds = customers.map((customer) => customer.id ?? 0);
      setSelectedCustomerIds(customerIds);
    },
    /*getCheckboxProps: (ba: BusinessAssociate) => ({
      disabled: ba.status !== ProfileStatusEnum.ACTIVE,
    }),*/
  };

  const handleFetchCustomers = (paramFilter: BaFilterParams) => {
    setLoading(true);

    CustomerService.fetchCustomers(
      paramFilter,
      (customers: Customer[], customerPagination: PaginationDetails) => {
        setCustomers(customers);
        setCustomerPagination(customerPagination);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    handleFetchCustomers(getParams());
  }, []);
  useEffect(() => {
    handleFetchCustomers(getParams());
  }, [currentPage]);

  const columns = [
    {
      title: "CUSTOMER",
      dataIndex: "id",
      key: "id",
      render: (text: string, customer: Customer, index: number) => {
        return (
          <div>
            {customer?.firstName} {customer?.lastName}
          </div>
        );
      },
    },
    {
      title: "BA NAME",
      dataIndex: "id",
      key: "id",
      render: (text: string, customer: Customer, index: number) => {
        return (
          <div>
            {customer?.businessAssociate?.firstName}{" "}
            {customer?.businessAssociate?.lastName}
          </div>
        );
      },
    },
    {
      title: "RM NAME",
      dataIndex: "rmName",
      key: "rmName",
      render: (rmName: string, customer: Customer, index: number) => {
        return (
          <div>
            {`${customer?.relationshipManager?.firstName ?? "-"} ${
              customer?.relationshipManager?.lastName ?? ""
            }`}
          </div>
        );
      },
    },
    {
      title: "PRODUCTS CLOSED",
      dataIndex: "noOfProductsClosed",
      key: "noOfProductsClosed",
    },
    {
      title: "DATE OF REGISTRATION",
      dataIndex: "dateOfRegistration",
      key: "dateOfRegistration",
      render: (date: string) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: "ASSOCIATION",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string, customer: Customer, index: number) => {
        return createdAt ? (
          <div className="text-capitalize">
            {Math.floor(
              moment(new Date()).diff(new Date(createdAt), "months", true)
            )}
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      title: () => {
        return (
          <div>
            <Popover
              placement="topLeft"
              content={
                <div>
                  {CustomerProfileStatus?.map((status, index) => {
                    return (
                      <div className="text-secondary-black">
                        <span className="mr-2" style={{ color: status?.color }}>
                          {status?.title}{" "}
                        </span>
                        {status?.description}
                      </div>
                    );
                  })}
                </div>
              }
            >
              PROFILING STATUS{" "}
              <img className="status-info" src={informationIcon} />
            </Popover>
          </div>
        );
      },

      dataIndex: "profileCompletionStatus",
      key: "profileCompletionStatus",
      width: "20%",
      render: (status: string, customer: Customer, index: number) => {
        return (
          <div className={`text-capitalize ${status}`}>
            {status ? status.split("_").join(" ") : "-"}
          </div>
        );
      },
    },
  ];

  const handleApplyFilter = (
    filterParams: BaFilterParams,
    closeModal: () => void,
    type?: string
  ) => {
    const updatedFilter = getParams();

    const params = {
      ...updatedFilter,
      ...filterParams,
      page: 1,
    };

    setParams(params);
    setFilterParams(Object.assign(new BaFilterParams(), filterParams));
    handleFetchCustomers({
      ...params,
      search: searchText,
    });
    if (type != "reset") closeModal();
  };

  const resetFilter = () => {
    // handleFetchCustomers({
    //   search: searchText,
    // });
    history.replace(AppRoutes.CUSTOMERS);
  };
  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current,
    };
    handleFetchCustomers(updatedFilters);
  };
  const handleSearch = debounce((searchText) => {
    setSearchText(searchText);
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      search: searchText,
    };
    setParams(updatedFilters);
    handleFetchCustomers({
      ...filterParams,
      search: searchText,
      page: currentPage,
    });
  }, 500);

  return (
    <div className="customer-list">
      <Row align="middle">
        <Col span={12}>
          <h1 className="text-primary">
            <span>Customers </span>

            <Tooltip title="Add">
              <Link to={AppRoutes.CUSTOMER_ADD}>
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  className="rounded-btn ml-2"
                />
              </Link>
            </Tooltip>
          </h1>
        </Col>
        <Col span={12} className="text-right">
          <Button
            type="primary"
            onClick={handleToggleAssignBA}
            disabled={selectedCustomerIds.length === 0}
          >
            Assign BA/RM
          </Button>
        </Col>
      </Row>
      <div className="filter-wrapper">
        <Filter
          renderTrigger={({ openModal }) => (
            <Button
              type="default"
              className="ba-list__filter-btn"
              onClick={openModal}
            >
              <FilterOutlined /> Filter
            </Button>
          )}
          renderModalContent={({ closeModal }) => (
            <CustomersFilter
              applyFilter={() => {
                closeModal();
              }}
              onSubmit={(filter, type) => {
                handleApplyFilter(filter, closeModal, type);
              }}
              resetFilter={() => {
                resetFilter();
                // closeModal();
              }}
            />
          )}
        />

        <Input.Search
          className="customer-list__search"
          placeholder="Search by name"
          onChange={(e) => {
            handleSearch(e?.target?.value);
          }}
          style={{ width: 200 }}
        />
      </div>

      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          {/*<Input.Search
            className="internal-user-list__search"
            placeholder="Search by name"
            style={{ width: 200 }}
          />*/}
          <div className="mt-4">
            <Table
              rowKey={(record) => record.id || Math.random()}
              rowSelection={{
                preserveSelectedRowKeys: true,
                type: "checkbox",
                ...rowSelection,
              }}
              dataSource={customers}
              columns={columns}
              onRow={handleRow}
              pagination={{
                total: customerPagination?.totalCount,
                current: customerPagination?.currentPage,
                showTotal: (total: number, range: [number, number]) => (
                  <p>
                    Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                    <b>{`${total.toLocaleString()}`}</b>
                  </p>
                ),
              }}
              onChange={handleTableChange}
            />
          </div>
          <Drawer
            width="50vw"
            onClose={handleToggleAssignBA}
            visible={showAssignBA}
            destroyOnClose
            footer={null}
            className="customer-list__assign-ba"
          >
            <AssignBA
              selectedCustomerIds={selectedCustomerIds}
              onSuccess={handleUpdateBASuccess}
            />
          </Drawer>
        </div>
      )}
    </div>
  );
}

export default CustomerList;
