import { serializable, alias, object, list, primitive } from "serializr";
import { Employee } from "../Employee/employee.model";

export class SurveyOptions {
  @serializable(alias("answer", primitive()))
  answer?: string;
  @serializable(alias("answer1", primitive()))
  answer1?: string;
  @serializable(alias("points", primitive()))
  points?: number;
}
class QuestionBannerImage {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("attachment_url", primitive()))
  attachmentUrl?: string;
}
export class SurveyQuestionsForm {
  @serializable(alias("question", primitive()))
  question?: string;
  @serializable(alias("status", primitive()))
  status?: boolean;

  @serializable(alias("question_type", primitive()))
  questionType?: string = "boolean";
  @serializable(alias("question_banner_image_id", primitive()))
  questionBannerImageId?: number;

  @serializable(alias("survey_options", list(object(SurveyOptions))))
  surveyOptions?: SurveyOptions[];
  @serializable(alias("question_banner_image", object(QuestionBannerImage)))
  questionBannerImage?: QuestionBannerImage;
}

export class SurveyQuestions {
  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("question", primitive()))
  question?: string;

  @serializable(alias("status", primitive()))
  status?: boolean;

  @serializable(alias("question_type", primitive()))
  questionType?: string;

  @serializable(alias("updated_at", primitive()))
  updatedAt?: string;

  @serializable(alias("employee", object(Employee)))
  employee?: Employee;

  @serializable(alias("id", primitive()))
  id?: string;
  @serializable(alias("question_banner_image", object(QuestionBannerImage)))
  questionBannerImage?: QuestionBannerImage;

  @serializable(alias("survey_options", list(object(SurveyOptions))))
  surveyOptions?: SurveyOptions[];
}
