import React, { useState } from "react";
import axiosInstance from "../../interceptor/axiosInstance";
import {
  Banner,
  Faqs,
} from "../../models/MyProfileSetting/myProfileSetting.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";

const MyProfileSettingService = () => {
  const [loading, setLoading] = useState(false);
  const [bannerList, setBannerList] = useState<Banner[]>([]);
  const [faqsList, setFaqsList] = useState<Faqs[]>([]);

  const fetchBannerList = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(ApiRoutes.BANNER_LIST);
      const data = deserialize(Banner, response.data["banners"] as Banner[]);
      setBannerList(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const fetchFaqsList = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(ApiRoutes.BANNER_LIST);
      const data = deserialize(Faqs, response.data["faqs"] as Faqs[]);
      setFaqsList(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return { fetchBannerList, bannerList, fetchFaqsList, faqsList, loading };
};
export default MyProfileSettingService;
