import React, { useState } from "react";
import "./forgotPasswordForm.scss";
import { Form, Formik } from "formik";
import InputField from "../../../shared/components/InputField";
import { Link, useHistory } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import { Button } from "antd";

import AuthService from "../../../services/AuthService/auth.service";
import { OtpTypeEnum } from "../../../enums/otpType.enum";
import ResetPasswordForm from "../ResetPasswordForm";
import { Employee } from "../../../models/Employee/employee.model";
import { forgetPasswordFormValidation } from "./forgetPasswordFormValidation";

interface ForgotPasswordFormProps {}

function ForgotPasswordForm(props: ForgotPasswordFormProps) {
  const [formValues, setFormValues] = useState(new Employee());

  const [formLoading, setFormLoading] = useState(false);

  const [isEmail, setIsEmail] = useState(false);

  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);

  const history = useHistory();

  const handleSubmit = (values: Employee) => {
    const employee = Object.assign(new Employee(), values);
    setFormValues(values);
    if (values.email) {
      const regex = /\S+@\S+\.\S+/;
      const isEmail = regex.test(values.email);
      setIsEmail(isEmail);
      if (!isEmail) {
        employee.mobile = values.email;
      }
      setFormLoading(true);
      AuthService.sendOTP(
        employee,
        isEmail ? OtpTypeEnum.EMAIL : OtpTypeEnum.MOBILE,
        () => {
          setShowResetPasswordForm(true);
        },
        () => {},
        () => {
          setFormLoading(false);
        }
      );
    }
  };

  return (
    <div className="forgot-password-form">
      {showResetPasswordForm ? (
        <React.Fragment>
          <ResetPasswordForm
            email={isEmail ? formValues.email : undefined}
            mobile={!isEmail ? formValues.email : undefined}
          />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <h1>Forgot Password</h1>
          <Formik
            initialValues={formValues}
            onSubmit={handleSubmit}
            validationSchema={forgetPasswordFormValidation}
          >
            {({ values, errors, isValid, dirty, setFieldValue }) => {
              return (
                <Form>
                  <InputField
                    title="Email/Phone"
                    type="text"
                    name="email"
                    placeholder="Enter email or phone"
                  />
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!isValid || !dirty || formLoading}
                    loading={formLoading}
                  >
                    Forgot Password
                  </Button>
                  <div className="text-right mt-2">
                    <span className="text-secondary-black">
                      Already have an account?{" "}
                    </span>
                    <Link to={AppRoutes.LOGIN}>
                      <Button type="link">Signin</Button>
                    </Link>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </React.Fragment>
      )}
    </div>
  );
}

export default ForgotPasswordForm;
