import { serializable, alias, object, primitive, list } from "serializr";
import {
  BusinessAssociate,
  RelationshipManager,
} from "../BusinessAssociate/businessAssociate.model";
import { FileAttachment } from "../FileAttachment/fileAttachment.model";
import { CustomerLoan } from "../CustomerLoan/customerLoan.model";
import { EmploymentDetail } from "../EmploymentDetail/employmentDetail.model";
import { BankDetail } from "../BankDetail/bankDetail.model";
import { FamilyMember } from "../FamilyMember/familyMember.model";
import { Address } from "../Address/address.model";
import { AddressTypeEnum } from "../../enums/addressType.enum";
import { Requirement } from "../Requirement/requirement.model";
import { SurveyQuestions } from "../SurveyQuestions/surveyQuestions.model";
import { Product } from "../Product/product.model";
import { BureauReport } from "../BureauReport/bureauReport.model";

class AnswerValue {
  @serializable(alias("survey_option_value", primitive()))
  surveyOptionValue?: number;
}
export class SurveyResponses {
  @serializable(alias("answer_value", list(object(AnswerValue))))
  answerValue: AnswerValue[] = [];
  @serializable(alias("survey_question", object(SurveyQuestions)))
  surveyQuestion?: SurveyQuestions;
  @serializable(alias("points", primitive()))
  points?: number;
  @serializable(alias("date", primitive()))
  date?: string;
}
export class Customer {
  @serializable(alias("id", primitive()))
  id?: number;
  @serializable(alias("date_of_registration", primitive()))
  dateOfRegistration?: string;

  @serializable(alias("first_name", primitive()))
  firstName?: string;
  @serializable(alias("rm_name", primitive()))
  rmName?: string;
  @serializable(alias("rm", object(RelationshipManager)))
  relationshipManager?: RelationshipManager;
  @serializable(alias("bureau_reports", list(object(BureauReport))))
  bureauReport: BureauReport[] = [];
  @serializable(alias("family_details", list(object(FamilyMember))))
  familyDetails: FamilyMember[] = [];
  @serializable(alias("business_associate", object(BusinessAssociate)))
  businessAssociate?: BusinessAssociate;

  @serializable(alias("products", list(object(Product))))
  product: Product[] = [];

  @serializable(alias("employment_detail", object(EmploymentDetail)))
  employmentDetail?: EmploymentDetail;

  @serializable(alias("survey_responses", list(object(SurveyResponses))))
  surveyResponses: SurveyResponses[] = [];

  @serializable(alias("bank_detail", object(BankDetail)))
  bankDetail?: BankDetail;

  @serializable(alias("collateral", primitive()))
  collateral?: string;

  @serializable(alias("last_name", primitive()))
  lastName?: string;

  @serializable(alias("email", primitive()))
  email?: string;

  @serializable(alias("mobile", primitive()))
  mobile?: string;

  @serializable(alias("dob", primitive()))
  dob?: string;

  @serializable(alias("gender", primitive()))
  gender?: string;

  @serializable(alias("profession", primitive()))
  profession?: string;

  @serializable(alias("requirements", list(object(Requirement))))
  requirements?: Requirement[] = [];

  @serializable(alias("gross_salary", primitive()))
  grossSalary?: number;

  @serializable(alias("annual_income", primitive()))
  annualIncome?: string;

  @serializable(alias("requirement_notes", primitive()))
  requirementNotes?: string;

  @serializable(alias("occupation_desc", primitive()))
  occupationDesc?: string;

  @serializable(alias("profile_pic", object(FileAttachment)))
  profilePic?: FileAttachment;

  @serializable(alias("existing_loan", object(CustomerLoan)))
  existingLoan?: CustomerLoan;

  @serializable(alias("other_identity_proof", primitive()))
  otherIdentityProof?: string;

  @serializable(alias("aadhaar_front_page", primitive()))
  aadhaarFrontPage?: string;

  @serializable(alias("aadhaar_back_page", primitive()))
  aadhaarBackPage?: string;

  @serializable(alias("no_of_products_closed", primitive()))
  noOfProductsClosed?: number;

  @serializable(alias("pan_card", primitive()))
  panCard?: string;

  @serializable(alias("profile_completion_status", primitive()))
  profileCompletionStatus?: string;

  @serializable(alias("is_mobile_verified", primitive()))
  isMobileVerified?: string;

  @serializable(alias("is_email_verified", primitive()))
  isEmailVerified?: string;

  @serializable(alias("addresses", list(object(Address))))
  addresses: Address[] = [];

  residentialAddress?: Address = Object.assign(new Address(), {
    ...new Address(),
    addressType: AddressTypeEnum.RESIDENTIAL,
  });

  communicationAddress?: Address;

  @serializable(alias("aadhaar_number", primitive()))
  aadhaarNumber?: string;

  @serializable(alias("is_aadhaar_verified", primitive()))
  isAadhaarVerified?: string;

  @serializable(alias("pan_number", primitive()))
  panNumber?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("is_pan_verified", primitive()))
  isPanVerified?: string;

  @serializable(alias("has_logged_in", primitive()))
  hasLoggedIn?: string;

  getAddresses() {
    this.communicationAddress = this.addresses.find(
      (address) => address.addressType === AddressTypeEnum.COMMUNICATION
    );
    if (this.communicationAddress?.city) {
      this.communicationAddress.cityId = this.communicationAddress.city.id;
      this.communicationAddress.provinceId =
        this.communicationAddress?.city.provinceId;
    }
    this.residentialAddress = this.addresses.find(
      (address) => address.addressType === AddressTypeEnum.RESIDENTIAL
    );
    if (this.residentialAddress?.city) {
      this.residentialAddress.cityId = this.residentialAddress.city.id;
      this.residentialAddress.provinceId =
        this.residentialAddress?.city.provinceId;
    }
  }

  setAddresses() {
    this.addresses = [];
    if (this?.communicationAddress?.cityId) {
      this.addresses.push(this.communicationAddress);
    }
    if (this?.residentialAddress?.cityId) {
      this.addresses.push(this.residentialAddress);
    }
  }
}
