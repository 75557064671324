import React, { useEffect, useState } from "react";
import { CheckboxOptionType, CheckboxValueType } from "antd/lib/checkbox/Group";
import { Location } from "../../../../models/Location/location.model";
import { Checkbox, Col, Input, Row } from "antd";
import { BaFilterParams } from "../../../../models/BAFilterParams/baFilterParams.model";
import { BaFilterService } from "../../../../services/BAFilter/baFilter.service";
import { debounce } from "lodash";

interface BALocalityFiltersProps {
  filterParams: BaFilterParams;
  refreshData: boolean;
  onUpdate: (filterParams: BaFilterParams) => void;
}

function BALocalityFilters({
  filterParams,
  refreshData,
  onUpdate,
}: BALocalityFiltersProps) {
  const [loading, setLoading] = useState(false);

  const [localityOptions, setLocalityOptions] = useState<CheckboxOptionType[]>(
    []
  );

  const handleFetchLocalities = (search?: string) => {
    setLoading(true);
    BaFilterService.fetchLocalitites(
      { ...filterParams, search },
      (locations: Location[]) => {
        const location = locations.map((location) => ({
          label: location.name,
          value: location.id as CheckboxValueType,
        }));
        location?.sort(
          (a, b) => a?.label?.localeCompare(b?.label ?? "") as any
        );
        setLocalityOptions(location);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    handleFetchLocalities();
  }, []);
  const handleSearch = debounce((searchText: string) => {
    handleFetchLocalities(searchText);
  }, 500);

  return (
    <div>
      <Row>
        <Col span={8}>
          <Input.Search
            className="ml-4 mb-5"
            placeholder="Search Locality Name"
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Checkbox.Group
        value={filterParams.localityIds}
        onChange={(localityIds) => {
          const params = Object.assign(new BaFilterParams(), filterParams);
          params.localityIds = localityIds as number[];
          onUpdate(params);
        }}
        options={localityOptions}
      />
    </div>
  );
}

export default BALocalityFilters;
