import * as Yup from "yup";

export const baFormValidation = Yup.object().shape({
  email: Yup.string()
    .email("Email is not valid!")
    .required("Email is required!"),
  firstName: Yup.string().nullable().required("First name is required!"),
  lastName: Yup.string().nullable().required("Last name is required!"),
  mobile: Yup.string()
    .nullable()
    .required("Mobile number is required!")
    .min(10, "Please enter 10 digits!")
    .max(10, "Please enter 10 digits!"),
});
