import { serializable, alias, object, list, primitive } from "serializr";
import { FileAttachment } from "../FileAttachment/fileAttachment.model";

export class Notification {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("state", primitive()))
  state?: string;

  @serializable(alias("city", primitive()))
  city?: string;

  @serializable(alias("read_by_name", primitive()))
  readByName?: string;

  @serializable(alias("received_date", primitive()))
  receivedDate?: string;
  @serializable(alias("notification_content", primitive()))
  notificationContent?: string;
  @serializable(alias("profile_pic", object(FileAttachment)))
  profilePic?: FileAttachment;

  @serializable(alias("received_from_name", primitive()))
  receivedFromName?: string;

  @serializable(alias("received_time", primitive()))
  receivedTime?: string;

  @serializable(alias("status", primitive()))
  status?: string;
}
