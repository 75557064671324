import React, { useState, FC } from "react";
import { Col, Row, Drawer } from "antd";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import "./baAdditionalInfo.scss";
import editIcon from "../../../assets/images/edit.png";
import BAAdditionalInfoForm from "../BAAdditionalInfoForm";
import { toTitleCase } from "../../../shared/utils/StringFormater";

interface BAAdditionalInfoProps {
  businessAssociate: BusinessAssociate;
  onUpdateSuccess: (ba: BusinessAssociate) => void;
}

const BAAdditionalInfo: FC<BAAdditionalInfoProps> = (props) => {
  const { businessAssociate, onUpdateSuccess } = props;
  const [form, setForm] = useState(false);
  const handleForm = () => setForm(!form);

  const baInfoTopLeft = [
    {
      label: "Nos of clients in current business",
      value: businessAssociate?.noOfClients || "--",
    },
    {
      label: "Citizenship",
      value: toTitleCase(businessAssociate?.citizenship || "--"),
    },
    {
      label: "Constitution",
      value: toTitleCase(businessAssociate?.constituency || "--"),
    },
    {
      label: "Company Name",
      value: toTitleCase(
        businessAssociate?.employmentDetail?.companyName || "--"
      ),
    },
    {
      label: "Nature of Business",
      value: toTitleCase(
        businessAssociate?.employmentDetail?.businessNature || "--"
      ),
    },
    {
      label: "Designation",
      value: businessAssociate?.employmentDetail?.designation || "--",
    },
    {
      label: "Gross monthly income",
      value: toTitleCase(
        businessAssociate?.employmentDetail?.grossMonthlyIncome || "--"
      ),
    },
    {
      label: "Date of Discussion",
      value: businessAssociate?.dateOfDiscussion || "--",
    },
  ];
  const baInfoTopRight = [
    {
      label: "Marital Status",
      value: toTitleCase(businessAssociate?.maritalStatus || "--"),
    },
    {
      label: "Gender",
      value: toTitleCase(businessAssociate?.gender || "--"),
    },
    {
      label: "Current Employment type",
      value: businessAssociate?.employmentDetail?.jobType || "--",
    },
    {
      label: "Business Name",
      value: businessAssociate?.employmentDetail?.businessName || "--",
    },
    {
      label: "Industry type",
      value: businessAssociate?.employmentDetail?.industryType || "--",
    },
    {
      label: "Years in Business / service",
      value: businessAssociate?.employmentDetail?.yearsOfService || "--",
    },
    {
      label: "Monthly expense in business",
      value:
        businessAssociate?.employmentDetail?.monthlyBusinessExpense || "--",
    },
  ];

  const baInfoBottomLeft = [
    {
      label: "Anniversary Date",
      value: businessAssociate?.dateOfAnniversary || "--",
    },
    {
      label: "Vehicle",
      value: businessAssociate?.additionalInfo?.vehicle || "--",
    },

    {
      label: "Investments",
      value: businessAssociate?.additionalInfo?.investments || "--",
    },
    {
      label: "Other Liabilities",
      value: businessAssociate?.additionalInfo?.otherLiabilities || "--",
    },
  ];
  const baInfoBottomRight = [
    {
      label: "Children education (Age/ Current Education status)",
      value: businessAssociate?.additionalInfo?.childrenEducation || "--",
    },
    {
      label: "Assets",
      value: businessAssociate?.additionalInfo?.assets || "--",
    },
    {
      label: "Insurance",
      value: businessAssociate?.insurance || "--",
    },
  ];
  const memberNames = [
    {
      name: "saurav",
      age: 23,
    },
    {
      name: "Kumar",
      age: 24,
    },
    {
      name: "Ranjith",
      age: 28,
    },
  ];

  return (
    <div className="ba-additional-info">
      <Row gutter={[20, 20]}>
        <Col span={10}>
          {baInfoTopLeft?.map((baAdditionalInfo, i) => (
            <div className="ba-addition-info__details" key={i}>
              <div className="ba-additional-info__label text-grey opacity-1 ">
                {baAdditionalInfo.label}
              </div>
              <div className="ba-additional-info__value mb-3">
                {baAdditionalInfo.value}
              </div>
            </div>
          ))}
        </Col>

        <Col span={8}>
          {baInfoTopRight?.map((baAdditionalInfo, i) => (
            <div className="ba-addition-info__details" key={i}>
              <div className="ba-additional-info__label text-grey opacity-1  ">
                {baAdditionalInfo.label}
              </div>

              <div className="ba-additional-info__value mb-3">
                {baAdditionalInfo.value}
              </div>
            </div>
          ))}
        </Col>
        <Col span={6} className="ba-additional-info__edit text-right ">
          <img src={editIcon} onClick={handleForm} />
        </Col>

        <Col span={6}>
          <span className="text-grey opacity-1">Family Members</span>

          {businessAssociate?.familyDetails?.map((member, i) => {
            return (
              <div className="ba-additional-info__label " key={i}>
                {member?.name || "--"}
              </div>
            );
          })}
        </Col>
        <Col span={18}>
          {businessAssociate?.familyDetails?.map((member, i) => {
            return (
              <div className="ba-additional-info__label " key={i}>
                {member?.age || "--"}
              </div>
            );
          })}
        </Col>
        <Col span={10}>
          {baInfoBottomLeft?.map((baAdditionalInfo, i) => (
            <div className="ba-addition-info__details" key={i}>
              <div className="ba-additional-info__label text-grey opacity-1 ">
                {baAdditionalInfo.label}
              </div>
              <div className="ba-additional-info__value mb-3">
                {baAdditionalInfo.value}
              </div>
            </div>
          ))}
        </Col>
        <Col span={14}>
          {baInfoBottomRight?.map((baAdditionalInfo, i) => (
            <div key={i}>
              <div className="ba-additional-info__label text-grey opacity-1  ">
                {baAdditionalInfo.label}
              </div>
              <div className="ba-additional-info__value mb-3">
                {baAdditionalInfo.value}
              </div>
            </div>
          ))}
        </Col>
        <Drawer
          width="60vw"
          onClose={handleForm}
          visible={form}
          destroyOnClose
          footer={null}
        >
          <BAAdditionalInfoForm
            businessAssociate={businessAssociate}
            handleFormVisibility={() => {
              setForm(false);
            }}
            onUpdateSuccess={onUpdateSuccess}
          />
        </Drawer>
      </Row>
    </div>
  );
};

export default BAAdditionalInfo;
