import React, { useEffect, useState } from "react";
import "./productTypeList.scss";
import AppLoader from "../../../shared/components/AppLoader";
import { Table } from "antd";
import { ProductTypeMeta } from "../../../models/ProductTypeMeta/productTypeMeta.model";
import ProductTypeMetaService from "../../../services/ProductTypeMeta/productTypeMeta.service";

interface ProductTypeListProps {}

function ProductTypeList(props: ProductTypeListProps) {
  const [productTypes, setProductTypes] = useState<ProductTypeMeta[]>([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    ProductTypeMetaService.fetchProductTypeMeta(
      (productTypes: ProductTypeMeta[]) => {
        setProductTypes(productTypes);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  const columns = [
    {
      title: "TYPE",
      dataIndex: "typeName",
      key: "typeName",
    },
    {
      title: "CATEGORY",
      dataIndex: "categoryName",
      key: "categoryName",
    },
    {
      title: "DESCRIPTION",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <div className="product-type-list">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          <div>
            <Table dataSource={productTypes} columns={columns} />
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductTypeList;
