import React, { useEffect, useState } from "react";
import "./baList.scss";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import IndividualBAModal from "../IndivualBAInactiveModal/index";

import { BusinessAssociateService } from "../../../services/BusinessAssociateService/businessAssociate.service";
import {
  Table,
  Select,
  Input,
  Row,
  Col,
  Button,
  Drawer,
  Tooltip,
  Modal,
  Checkbox,
  TablePaginationConfig,
  Popover,
  Progress,
} from "antd";
import {
  FilterOutlined,
  FrownFilled,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import AppLoader from "../../../shared/components/AppLoader";
import moment from "moment";
import {
  useHistory,
  generatePath,
  Link,
  useParams,
  useLocation,
} from "react-router-dom";
import { ProfileStatusEnum } from "../../../enums/profileStatus.enum";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import AssignRM from "../AssignRM";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";
import BAFilterForm from "../BAFilterForm";
import BAForm from "../BAForm";
import AssignBrokerageGroup from "../AssignBrokerageGroup";
import { BaFilterParams } from "../../../models/BAFilterParams/baFilterParams.model";
import AuthContainer from "../../../store/container/AuthContainer";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";
import { debounce, divide } from "lodash";
import useFilters from "../../../shared/hooks/useFilters";
import webImage from "../../../assets/images/baSource.png";
import profileImage from "../../../assets/images/target.png";
import mailIcon from "../../../assets/images/email.png";
import phoneIcon from "../../../assets/images/phone.png";
import informationIcon from "../../../assets/images/information.png";
import { getFirstLetterName } from "../../../shared/utils/getFirstLetterName";
import { ProfileCompletionStatusEnum } from "../../../enums/profileCompletionStatus.enum";
import { IDropdownOption } from "../../../services/MetaService/meta.service";

import {
  BAProfileStatus,
  BAprofileStatuses,
} from "../../../constants/profileStatus";
import { removeUnderscore } from "../../../shared/utils/removeUnderscore";
import CustomModal from "../../../shared/components/CustomModal";
interface BAListProps extends AuthReducerProps {}
interface BAListParams {
  baId: string;
}
interface CurrentContact {
  isVisible: boolean;
  id: number | undefined;
}

function BAList({ employee }: BAListProps) {
  const history = useHistory();
  const params: BAListParams = useParams();

  const [showAssignRM, setShowAssignRM] = useState(false);

  const [showAssignBrokerageGroup, setShowAssignBrokerageGroup] =
    useState(false);

  const [showFilterForm, setShowFilterForm] = useState(false);
  const [showBAForm, setShowBAForm] = useState(false);

  const handleToggleBAForm = () => setShowBAForm(!showBAForm);

  const handleToggleAssignRM = () => setShowAssignRM(!showAssignRM);

  const handleToggleAssignBrokerageGroup = () =>
    setShowAssignBrokerageGroup(!showAssignBrokerageGroup);

  const handleToggleFilterForm = () => setShowFilterForm(!showFilterForm);
  const location = useLocation();

  const [businessAssociates, setBusinessAssociates] = useState<
    BusinessAssociate[]
  >([]);
  const [currentContact, setCurrentContact] = useState<CurrentContact>();

  const [activeBA, setActiveBA] = useState<BusinessAssociate>();
  const [profileStatus, setProfileStatus] = useState<IDropdownOption[]>([]);

  const [updateLoading, setUpdateLoading] = useState(false);

  const [loading, setLoading] = useState(false);

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDownloadVisible, setIsDownloadVisible] = useState(false);
  const [currentBaId, setCurrentBaId] = useState<number>();
  const [baPagination, setBaPagination] = useState<PaginationDetails>();

  const { getParams, setParams } = useFilters();

  const profileStatuses = [
    { label: "Incomplete", value: ProfileStatusEnum.INCOMPLETE },
    { label: "Under Review", value: ProfileStatusEnum.UNDER_REVIEW },
    { label: "Pending E-Sign BA", value: ProfileStatusEnum.PENDING_E_SIGN_BA },
    {
      label: "Pending E-Sign Admin",
      value: ProfileStatusEnum.PENDING_E_SIGN_ADMIN,
    },
    { label: "Active", value: ProfileStatusEnum.ACTIVE },
    { label: "Inactive", value: ProfileStatusEnum.INACTIVE },
    { label: "Rejected", value: ProfileStatusEnum.REJECTED },
  ];

  const [filterParams, setFilterParams] = useState<BaFilterParams>(
    new BaFilterParams()
  );

  const [selectedBAIds, setSelectedBAIds] = useState<number[]>([]);

  const handleUpdateStatus = (ba: BusinessAssociate) => (status: string) => {
    setActiveBA(ba);

    if (status == ProfileStatusEnum.INACTIVE) setIsModalVisible(true);

    const businessAssociate = Object.assign(new BusinessAssociate(), {
      ...ba,
      status,
    });
    delete businessAssociate.qualifications;
    delete businessAssociate.referenceContacts;
    delete businessAssociate.bankDetail;
    delete businessAssociate.addresses;
    setUpdateLoading(true);
    BusinessAssociateService.updateBusinessAssociate(
      businessAssociate,
      (ba: BusinessAssociate) => {
        setCurrentBaId(ba.id);
        const baIndex = businessAssociates.findIndex(
          (businessAssociate) => businessAssociate.id === ba.id
        );
        if (baIndex >= 0) {
          businessAssociates[baIndex] = businessAssociate;
        }
        setBusinessAssociates([...businessAssociates]);
      },
      () => {},
      () => {
        setUpdateLoading(false);
      }
    );
  };

  const handleBAInviteSuccess = (ba: BusinessAssociate) => {
    handleFetchBusinessAssociates(getParams());
    setShowBAForm(false);
  };

  const handleRow = (ba: BusinessAssociate, rowIndex: number | undefined) => {
    return {
      onMouseEnter: () =>
        setCurrentContact({ isVisible: true, id: ba.id ?? 0 }),
      onMouseLeave: () =>
        setCurrentContact({ isVisible: false, id: undefined }),
      onClick: (event: any) => {
        history.push({
          pathname: generatePath(AppRoutes.INDIVIDUAL_BA_DETAIL, {
            baId: ba.id,
          }),
        });
      },
    };
  };

  const columns = [
    {
      title: () => {
        return (
          <div>
            <Popover
              placement="right"
              content={
                <div>
                  {BAProfileStatus?.map((status, index) => {
                    return (
                      <div className="text-secondary-black">
                        <span className="mr-2" style={{ color: status?.color }}>
                          {status?.title}{" "}
                        </span>
                        {status?.description}
                      </div>
                    );
                  })}
                </div>
              }
            >
              NAME <img className="status-info" src={informationIcon} />
            </Popover>
          </div>
        );
      },
      dataIndex: "id",
      key: "id",
      width: "25%",
      render: (status: string, ba: BusinessAssociate, index: number) => {
        return (
          <div>
            <span className="mr-5 contact-profile__default-image">
              <Progress
                type="circle"
                strokeColor="#26AD10"
                percent={handleCheckStatusPercentage(
                  ba?.profileCompletionStatus ?? ""
                )}
                width={50}
                format={(percent) =>
                  ba?.profilePic?.attachmentUrl ? (
                    <Tooltip
                      placement="topLeft"
                      title={`${removeUnderscore(
                        ba?.profileCompletionStatus ?? ""
                      )}`}
                    >
                      <img
                        className="contact-profile__image mr-3"
                        src={ba?.profilePic?.attachmentUrl}
                      />
                    </Tooltip>
                  ) : (
                    <Tooltip
                      placement="topLeft"
                      title={` ${removeUnderscore(
                        ba?.profileCompletionStatus ?? ""
                      )}`}
                    >
                      {getFirstLetterName(ba?.firstName + " " + ba?.lastName)}
                    </Tooltip>
                  )
                }
              />
            </span>

            {ba?.firstName + " " + ba?.lastName}
          </div>
        );
      },
    },
    {
      title: "MANAGER",
      dataIndex: "relationshipManager",
      key: "relationshipManager",
      render: (
        relationshipManager: string,
        ba: BusinessAssociate,
        index: number
      ) => {
        return ba?.relationshipManager
          ? ba?.getRelationshipManagerName()
          : "NA";
      },
    },
    {
      title: "CLIENTS",
      dataIndex: "noOfClients",
      key: "noOfClients",
    },
    {
      title: "ASSOCIATION (MONTHS)",
      dataIndex: "association",
      key: "association",

      render: (association: string, ba: BusinessAssociate, index: number) =>
        association ?? "-",
    },
    {
      title: "LAST LOGIN",
      dataIndex: "lastLoginTime",
      key: "lastLoginTime",

      render: (lastLoginTime: string, ba: BusinessAssociate, index: number) => {
        return (
          <div>{lastLoginTime ? moment(lastLoginTime).fromNow() : "NA"}</div>
        );
      },
    },
    {
      title: "DATE OF REGISTRATION",
      dataIndex: "dateOfRegistration",
      key: "dateOfRegistration",
      render: (date: string) => moment(date).format("DD/MM/YYYY"),
    },
    {
      title: () => {
        return (
          <div>
            <Popover
              content={
                <div>
                  {BAprofileStatuses?.map((status, index) => {
                    return (
                      <div className="text-secondary-black">
                        <span className="mr-2" style={{ color: status?.color }}>
                          {status?.title}{" "}
                        </span>
                        {status?.description}
                      </div>
                    );
                  })}
                </div>
              }
            >
              STATUS <img className="status-info" src={informationIcon} />
            </Popover>
          </div>
        );
      },
      dataIndex: "status",
      key: "status",
      width: "30%",
      render: (status: string, ba: BusinessAssociate, index: number) => {
        return (
          <div className="my-select-btn" onClick={(e) => e.stopPropagation()}>
            <Select
              loading={activeBA?.id === ba?.id && updateLoading}
              className={`${status?.replace("_", "-")} ba-list__status-select `}
              options={profileStatuses}
              dropdownMatchSelectWidth={false}
              defaultValue={status}
              style={{ width: 140 }}
              onChange={handleUpdateStatus(ba)}
            />
          </div>
        );
      },
    },
    {
      title: "CITY",
      dataIndex: "city",
      key: "city",

      render: (city: string, ba: BusinessAssociate, index: number) => {
        return currentContact && ba?.id == currentContact.id ? (
          <div key={index} className="ba-list__icons">
            <Tooltip
              color={"white"}
              title={
                <div className="text-primary-black">
                  <div>BA SOURCE</div> <h4>Reference from Another BA</h4>
                </div>
              }
            >
              <img
                src={webImage}
                className="contact-image"
                alt="address Image"
              />
            </Tooltip>
            <Tooltip
              color={"white"}
              title={
                <div className="text-primary-black">
                  <div>TARGET/ ACHIEVED AMOUNT</div>{" "}
                  <h4>₹7,00,000/₹5,50,000</h4>
                </div>
              }
            >
              <img
                src={profileImage}
                className="contact-image"
                alt="profile Image"
              />
            </Tooltip>
            <Tooltip
              className="text-white"
              color={"white"}
              title={
                <div className="text-primary-black">
                  <div>MOBILE</div> <h4>{ba?.mobile}</h4>
                </div>
              }
            >
              <img src={phoneIcon} className="contact-image" alt="phone" />
            </Tooltip>
            <Tooltip
              color={"white"}
              title={
                <div className="text-primary-black">
                  <div>EMAIL</div> <h4>{ba?.email}</h4>
                </div>
              }
            >
              <img src={mailIcon} className="contact-image" alt="email" />
            </Tooltip>
          </div>
        ) : (
          <span className="text-capitalize">
            {ba?.location
              ? ba?.location?.city?.name +
                "," +
                ba?.location?.city?.provinceName
              : "-"}
          </span>
        );
      },
    },
  ];

  const handleFetchBusinessAssociates = (param: BaFilterParams) => {
    setLoading(true);

    const para = Object.assign(new BaFilterParams(), param);

    BusinessAssociateService.fetchBusinessAssociates(
      para,

      (
        businessAssociates: BusinessAssociate[],
        baPagination: PaginationDetails
      ) => {
        setBusinessAssociates(businessAssociates);
        setBaPagination(baPagination);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const rowSelection = {
    selectedRowKeys: selectedBAIds,
    onChange: (
      selectedRowKeys: React.Key[],
      businessAssociates: BusinessAssociate[]
    ) => {
      const baIds = businessAssociates.map((ba) => ba.id ?? 0);
      const activeStatuses = businessAssociates.map(
        (ba) => ba.status === ProfileStatusEnum.ACTIVE
      );
      if (activeStatuses.includes(false)) {
        Notification({
          type: NotificationTypes.ERROR,
          message: "Please make sure the selected BAs are in active status",
        });
      } else {
        setSelectedBAIds(baIds);
      }
    },
    getCheckboxProps: (ba: BusinessAssociate) => ({
      disabled: ba.status !== ProfileStatusEnum.ACTIVE,
    }),
  };

  const handleUpdateRMSuccess = () => {
    handleFetchBusinessAssociates(getParams());
    setShowAssignRM(false);
    setShowAssignBrokerageGroup(false);
    setSelectedBAIds([]);
  };

  const handleDownloadBA = () => {
    setDownloadLoading(true);
    BusinessAssociateService.downloadBAs(
      () => {},
      () => {},
      () => {
        setDownloadLoading(false);
        setIsDownloadVisible(true);
      }
    );
  };

  const handleApplyFilter = (filterParams: BaFilterParams, type?: string) => {
    const updatedFilter = getParams();

    const params = {
      ...updatedFilter,
      ...filterParams,
      page: 1,
    };

    setParams(params);
    setFilterParams(Object.assign(new BaFilterParams(), filterParams));
    handleFetchBusinessAssociates(params);
    if (type != "reset") setShowFilterForm(false);
  };

  useEffect(() => {
    handleFetchBusinessAssociates(getParams());
  }, []);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      page: pagination.current,
    };
    setParams(updatedFilters);
    handleFetchBusinessAssociates({ ...updatedFilters });
  };
  const handleSearch = debounce((searchText: any) => {
    const prevFilter = getParams();
    const updatedFilters = {
      ...prevFilter,
      search: searchText,
    };
    setParams(updatedFilters);
    handleFetchBusinessAssociates(updatedFilters);
  }, 500);

  const handleCheckStatusPercentage = (status: string) => {
    const statusNames = Object.values(ProfileCompletionStatusEnum);
    const statusNamePercentage = statusNames.map((status, index) => ({
      label: status,
      value: (index + 1) * (100 / statusNames?.length),
    }));
    const currentStatus = statusNamePercentage?.find((profile) => {
      if (profile?.label === status) return profile?.value;
    });
    if (currentStatus) return Number(Math.round(currentStatus?.value));
    else return 0;
  };

  return (
    <div className="ba-list">
      <Row align="middle">
        <Col span={12}>
          <h1 className="text-primary">
            <span>Individual BA</span>
            <Tooltip title="Add">
              <Button
                onClick={handleToggleBAForm}
                type="primary"
                icon={<PlusOutlined />}
                className="rounded-btn ml-2"
              />
            </Tooltip>

            {employee?.role?.name === UserRoleEnum.SUPER_ADMIN && (
              <Button
                type="primary"
                onClick={handleDownloadBA}
                disabled={downloadLoading}
                loading={downloadLoading}
                className="ml-2"
              >
                Download BAs
              </Button>
            )}
          </h1>
        </Col>
        <Col span={12} className="text-right">
          <Button
            className="secondary-btn mr-2"
            onClick={handleToggleAssignBrokerageGroup}
            disabled={selectedBAIds.length === 0}
          >
            Assign Brokerage Group
          </Button>

          <Button
            type="primary"
            onClick={handleToggleAssignRM}
            disabled={selectedBAIds.length === 0}
          >
            Assign RM
          </Button>
        </Col>
      </Row>
      <div>
        <Button
          type="default"
          className="ba-list__filter-btn"
          onClick={handleToggleFilterForm}
        >
          <FilterOutlined /> Filter
        </Button>
        <Input.Search
          className="ba-list__search"
          placeholder="Search by name, email or mobile, BA Id"
          onChange={(e: any) => {
            handleSearch(e.target.value);
          }}
          style={{ width: 300 }}
        />
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <div className="mt-4">
            <Table
              rowKey={(record) => record.id || Math.random()}
              rowSelection={{
                preserveSelectedRowKeys: true,
                type: "checkbox",
                ...rowSelection,
              }}
              dataSource={businessAssociates}
              columns={columns}
              onRow={handleRow}
              pagination={{
                total: baPagination?.totalCount,
                current: baPagination?.currentPage,
                showTotal: (total: number, range: [number, number]) => (
                  <p>
                    Showing <b>{` ${range[0]} - ${range[1]}`}</b> of{" "}
                    <b>{`${total.toLocaleString()}`}</b>
                  </p>
                ),
              }}
              onChange={handleTableChange}
            />
          </div>
        )}

        <Drawer
          width="50vw"
          onClose={handleToggleAssignBrokerageGroup}
          visible={showAssignBrokerageGroup}
          destroyOnClose
          footer={null}
          className="ba-list__assign-brokerage"
        >
          <AssignBrokerageGroup
            selectedBAIds={selectedBAIds}
            onSuccess={handleUpdateRMSuccess}
          />
        </Drawer>

        <Drawer
          width="50vw"
          onClose={handleToggleAssignRM}
          visible={showAssignRM}
          destroyOnClose
          footer={null}
          className="ba-list__assign-rm"
        >
          <AssignRM
            selectedBAIds={selectedBAIds}
            onSuccess={handleUpdateRMSuccess}
          />
        </Drawer>
        <Drawer
          width="70vw"
          onClose={handleToggleFilterForm}
          visible={showFilterForm}
          destroyOnClose
          footer={null}
        >
          <BAFilterForm onSubmit={handleApplyFilter} />
        </Drawer>
        <Drawer
          width="50vw"
          onClose={handleToggleBAForm}
          visible={showBAForm}
          destroyOnClose
          footer={null}
        >
          <BAForm onSuccess={handleBAInviteSuccess} />
        </Drawer>
      </div>
      <CustomModal
        title={
          "Your request will be processed and you will receive an email shortly"
        }
        isCancelButton={false}
        visible={isDownloadVisible}
        okHandler={() => setIsDownloadVisible(false)}
        type={NotificationTypes.SUCCESS}
      />
      <div>
        {isModalVisible ? (
          <IndividualBAModal
            isModalVisible={isModalVisible}
            currentBaId={currentBaId || 0}
            closeHandler={() => setIsModalVisible(false)}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AuthContainer(BAList);
