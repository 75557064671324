import React, { useEffect, useState } from "react";
import { Button, Tabs } from "antd";
import "./baFilterForm.scss";
import BARMFilters from "./BARMFilters";
import BAStateFilters from "./BAStateFilters";
import BACityFilters from "./BACityFilters";
import BALocalityFilters from "./BALocalityFilters";
import BAProfileCompletionFilters from "./BAProfileCompletionFilters";
import BAStatusFilters from "./BAStatusFilters";
import { BaFilterParams } from "../../../models/BAFilterParams/baFilterParams.model";

import { useLocation } from "react-router-dom";
import useFilters from "../../../shared/hooks/useFilters";

const { TabPane } = Tabs;

interface BAFilterFormProps {
  onSubmit: (filterParams: BaFilterParams, type?: string) => void;
}

function BAFilterForm({ onSubmit }: BAFilterFormProps) {
  const [tabKey, setTabKey] = useState<number>(0);

  const [refreshData, setRefreshData] = useState(false);

  const [filterParams, setFilterParams] = useState<BaFilterParams>(
    new BaFilterParams()
  );

  const handleUpdateFilters = (filterParams: BaFilterParams) => {
    setFilterParams(filterParams);
  };

  const handleApply = () => {
    onSubmit(filterParams);
  };

  const { getParams, setParams } = useFilters();

  const handleReset = () => {
    // onSubmit(new BaFilterParams());
    setFilterParams(new BaFilterParams());
    onSubmit(new BaFilterParams(), "reset");
    setRefreshData(true);
  };
  useEffect(() => {
    setFilterParams(getParams());
  }, []);
  const filterTabs = [
    {
      title: "Relationship Manager",
      component: (
        <BARMFilters
          refreshData={refreshData}
          filterParams={filterParams}
          onUpdate={handleUpdateFilters}
        />
      ),
    },
    {
      title: "State",
      component: (
        <BAStateFilters
          refreshData={refreshData}
          filterParams={filterParams}
          onUpdate={handleUpdateFilters}
        />
      ),
    },
    {
      title: "City",
      component: (
        <BACityFilters
          refreshData={refreshData}
          filterParams={filterParams}
          onUpdate={handleUpdateFilters}
        />
      ),
    },
    {
      title: "Locality",
      component: (
        <BALocalityFilters
          refreshData={refreshData}
          filterParams={filterParams}
          onUpdate={handleUpdateFilters}
        />
      ),
    },
    {
      title: "Profile Completion",
      component: (
        <BAProfileCompletionFilters
          refreshData={refreshData}
          filterParams={filterParams}
          onUpdate={handleUpdateFilters}
        />
      ),
    },
    {
      title: "Status",
      component: (
        <BAStatusFilters
          refreshData={refreshData}
          onUpdate={handleUpdateFilters}
          filterParams={filterParams}
        />
      ),
    },
  ];
  return (
    <div className="ba-filter-form">
      <h2 className="text-primary"> Filters </h2>
      <Tabs
        tabPosition="left"
        key={tabKey}
        onChange={() => {
          setRefreshData(true);
          setTimeout(() => {
            setRefreshData(false);
          }, 500);
        }}
      >
        {filterTabs.map((tab, i) => (
          <TabPane tab={tab.title} key={i}>
            <div className="mt-3 text-right">
              <Button onClick={handleReset}>Reset</Button>
              <Button type="primary" className="ml-2" onClick={handleApply}>
                Apply
              </Button>
            </div>
            {tab.component}
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
}

export default BAFilterForm;
