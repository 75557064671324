import React, { useState, useEffect } from "react";
import { Table, Select, Drawer } from "antd";
import { BusinessAssociate } from "../../../models/BusinessAssociate/businessAssociate.model";
import moment from "moment";
import AppLoader from "../../../shared/components/AppLoader";
import ActivityLog from "../../../models/ActivityLog/activityLog.model";
import "./baActivity.scss";
import { useParams } from "react-router-dom";
import ActivityLogService from "../../../services/ActivityLog/activityLog.service";
import { ApplicableUser } from "../../../enums/applicableUser.enum";

interface BAActivityLogProps {
  businessAssociate: BusinessAssociate;
}

interface BAActivityLogParams {
  baId: string;
}
const columns = [
  {
    title: " DATE ",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (date: string) => moment(date).format("DD/MM/YYYY"),
  },

  {
    title: "ACTIVITY",
    dataIndex: "activityText",
    key: "activityText",
    render: (_: string, log: ActivityLog) => {
      return log?.ActivityText;
    },
  },
  {
    title: "CHANGED AREA",
    dataIndex: "activityType",
    key: "activityType",
    render: (_: string, activity: ActivityLog) => {
      return activity?.activityType;
    },
  },

  {
    title: "MODIFIED BY",
    dataIndex: "modifiedBy",
    key: "modifiedBy",
    render: (_: string, activity: ActivityLog) => {
      return activity?.modifiedBy
        ? activity?.modifiedBy?.firstName + " " + activity?.modifiedBy?.lastName
        : "--";
    },
  },
  {
    title: "ROLE",
    dataIndex: "modifiedUserRole",
    key: "modifiedUserRole",
  },
];
const sampleBannerTable = [
  {
    createdAt: "01-11-2021",
    activityText: "Assigned BA to the Customer",
    modifiedBy: "Kevin Banks",
    modifiedUserRole: "Super Admin",
    activityType: "Profile",
  },
  {
    createdAt: "04-11-2021",
    activityText: "Assigned BA to the Customer",
    modifiedBy: "Anthony Barrett",
    modifiedUserRole: "Business Associate",
    activityType: "Application",
  },
  {
    createdAt: "11-10-2021",
    activityText: "Assigned BA to the Customer",
    modifiedBy: "Tom Ellis",
    modifiedUserRole: "Business Associate",
    activityType: "Profile",
  },
  {
    createdAt: "10-11-2021",
    activityText: "Assigned new Task",
    modifiedBy: "Kevin Banks",
    modifiedUserRole: "Business Associate",
    activityType: "Profile",
  },
  {
    createdAt: "12-11-2021",
    activityText: "Assigned BA to the Customer",
    modifiedBy: "Kevin Banks",
    modifiedUserRole: "Super Admin",
    activityType: "Application",
  },
  {
    createdAt: "12-11-2021",
    activityText: "Assigned new Task",
    modifiedBy: "Tom Ellis",
    modifiedUserRole: "Super Admin",
    activityType: "Application",
  },
  {
    createdAt: "12-11-2021",
    activityText: "Assigned new Task",
    modifiedBy: "Anthony Barrett",
    modifiedUserRole: "Business Associate",
    activityType: "Profile",
  },
  {
    createdAt: "25-11-2021",
    activityText: "Assigned new Task",
    modifiedBy: "Kevin Banks",
    modifiedUserRole: "Super Admin",
    activityType: "Profile",
  },
  {
    createdAt: "30-11-2021",
    activityText: "Assigned new Task",
    modifiedBy: "Anthony Barrett",
    modifiedUserRole: "Super Admin",
    activityType: "Profile",
  },
  {
    createdAt: "28-11-2021",
    activityText: "Assigned new Task",
    modifiedBy: "Tom Ellis",
    modifiedUserRole: "Business Associate",
    activityType: "Application",
  },
  {
    createdAt: "23-11-2021",
    activityText: "Assigned new Task",
    modifiedBy: "Kevin Banks",
    modifiedUserRole: "Super Admin",
    activityType: "Profile",
  },
];
function BAActivityLog({ businessAssociate }: BAActivityLogProps) {
  const [form, setForm] = useState(false);
  const handleForm = () => setForm(!form);
  const [activityLogData, setactivityLogData] = useState<ActivityLog[]>([]);
  const [loading, setLoading] = useState(false);

  const params: BAActivityLogParams = useParams();

  useEffect(() => {
    setLoading(true);
    ActivityLogService.fetchActivityLogData(
      params?.baId,
      ApplicableUser.BUSINESS_ASSOCIATE,
      (data: ActivityLog[]) => {
        setactivityLogData(data);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  }, []);

  return (
    <div className="BA-activity-log">
      {loading ? (
        <AppLoader loading={loading} />
      ) : (
        <div>
          <div>
            <Table dataSource={activityLogData} columns={columns} />
          </div>
        </div>
      )}
    </div>
  );
}

export default BAActivityLog;
