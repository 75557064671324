import { alias, custom, primitive, serializable } from "serializr";

export class FileAttachment {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("attachment_upload_id", primitive()))
  attachmentUploadId?: number;

  @serializable(alias("attachment_type", primitive()))
  attachmentType?: string;

  @serializable(alias("attachment_url", primitive()))
  attachmentUrl?: string;

  @serializable(
    alias(
      "attachment",
      custom(
        (file) => file,
        (json) => {}
      )
    )
  )
  attachment?: File;
}
