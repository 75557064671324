import React, { useState } from "react";
import axiosInstance from "../../../interceptor/axiosInstance";
import { Faqs } from "../../../models/MyProfileSetting/myProfileSetting.model";
import { Banner } from "../../../models/Banner/banner.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../../shared/components/Notification";
import { ApiRoutes } from "../../../routes/routeConstants/apiRoutes";
import { NotificationTypes } from "../../../enums/notificationTypes";
import { Category } from "../../../models/Category/category.model";
import { PaginationDetails } from "../../../models/Pagination/pagination.model";

const BannerService = () => {
  const [loading, setLoading] = useState(false);
  const [bannerList, setBannerList] = useState<Banner[]>([]);
  const fetchBannerList = async (currentPage: number, onSuccess: Function) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(ApiRoutes.BANNER_LIST, {
        params: {
          page: currentPage,
        },
      });
      const data = deserialize(Banner, response.data["banners"] as Banner[]);
      const bannerPagination = deserialize(
        PaginationDetails,
        response.data["meta"]
      );
      setBannerList(data);
      onSuccess(data, bannerPagination);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const addNewBanner = async (bannerValues: Banner, onSuccess: Function) => {
    setLoading(true);
    const data = serialize(Banner, bannerValues);
    try {
      const response = await axiosInstance.post(ApiRoutes.ADD_BANNER, {
        banner: data,
      });
      if (response) {
        Notification({
          message: "New Banner Created!",
          description: response.data?.message,
          type: NotificationTypes.SUCCESS,
        });
        const data = deserialize(Banner, response.data["banner"]);
        onSuccess(data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const updateBanner = async (
    bannerValues: Banner,
    bannerId: string,
    onSuccess: Function
  ) => {
    const data = serialize(Banner, bannerValues);
    setLoading(true);
    try {
      const response = await axiosInstance.put(
        ApiRoutes.UPDATE_BANNER.replace(":id", bannerId),
        {
          banner: data,
        }
      );

      if (response) {
        Notification({
          message: "Banner Updated!",
          description: response.data?.message,
          type: NotificationTypes.SUCCESS,
        });

        onSuccess(response);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const fetchProductName = async (
    productType: string,
    onSuccess: (category: Category[]) => void
  ) => {
    try {
      const response = await axiosInstance.get(ApiRoutes.PRODUCT_NAME, {
        params: { category_type: productType },
      });
      const data = deserialize(
        Category,
        response.data["categories"] as Category[]
      );

      onSuccess(data);
    } catch (error) {
    } finally {
    }
  };

  return {
    addNewBanner,
    loading,
    updateBanner,
    fetchBannerList,
    bannerList,
    fetchProductName,
    setBannerList,
  };
};
export default BannerService;
