import { serializable, alias, object, list, primitive } from "serializr";
import { Location } from "../Location/location.model";

export class ServingLocation {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("location_id", primitive()))
  locationId?: number;

  @serializable(alias("location", object(Location)))
  location?: Location;
}
