import axiosInstance from "../../interceptor/axiosInstance";
import { Order } from "../../models/Order/order.model";
import { deserialize, serialize } from "serializr";
import Notification from "../../shared/components/Notification";
import { NotificationTypes } from "../../enums/notificationTypes";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { PaginationDetails } from "../../models/Pagination/pagination.model";
import { Notification as NotificationModel } from "../../models/Notification/notification.model";
import { BaFilterParams } from "../../models/BAFilterParams/baFilterParams.model";

export default class Notifications {
  static fetchNotifications(
    paramFilter: BaFilterParams,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    axiosInstance
      .get(ApiRoutes.NOTIFICATION, { params: paramFilter })
      .then((response) => {
        const notifications = deserialize(
          NotificationModel,
          response.data["admin_notifications"]
        );
        const pagination = deserialize(
          PaginationDetails,
          response.data["meta"]
        );

        onSuccess(notifications, pagination);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
  static updateNotification(
    notification: NotificationModel,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const params = serialize(NotificationModel, notification);
    axiosInstance
      .put(ApiRoutes.NOTIFICATION + "/" + notification?.id, {
        admin_notification: params,
      })
      .then((response) => {
        const notification = deserialize(
          NotificationModel,
          response.data["admin_notification"]
        );

        onSuccess(notification);
      })
      .catch((error) => {
        Notification({
          message: error,
          type: NotificationTypes.ERROR,
        });
        onError();
      })
      .finally(() => {
        onFinal();
      });
  }
}
