import axiosInstance from "../../interceptor/axiosInstance";
import { ApiRoutes } from "../../routes/routeConstants/apiRoutes";
import { deserialize } from "serializr";
import { FileAttachment } from "../../models/FileAttachment/fileAttachment.model";

export default class FileService {
  static uploadFile(
    attachment: File,
    onSuccess: Function,
    onError: Function,
    onFinal: () => void
  ) {
    const formData = new FormData();
    formData.append("attachment", attachment);
    axiosInstance
      .post(ApiRoutes.UPLOAD_FILES, formData)
      .then((response) => {
        const uploadedFile = deserialize(
          FileAttachment,
          response.data["uploaded_file"]
        );
        onSuccess(uploadedFile);
      })
      .catch((error) => {
        onError(error);
      })
      .finally(() => {
        onFinal();
      });
  }
}
