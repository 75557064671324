import React, { useEffect, useState } from "react";
import { Row, Col, Radio, Button, Upload, notification } from "antd";
import { Form, Formik } from "formik";
import PageTitle from "../../../shared/components/PageTitle";
import InputField from "../../../shared/components/InputField";
import DropdownField from "../../../shared/components/DropdownField";
import { Banner } from "../../../models/Banner/banner.model";
import { DropdownModeEnum } from "../../../enums/dropdownMode.enum";
import { InboxOutlined } from "@ant-design/icons";
import { values } from "lodash";
import "./bannerForm.scss";
import AttachmentUpload from "../../../shared/components/AttachmentUpload";
import { BannerPageEnum } from "../../../enums/bannerPage.enum";
import MetaService, {
  IDropdownOption,
} from "../../../services/MetaService/meta.service";
import { Province } from "../../../models/Province/province.model";
import { City } from "../../../models/City/city.model";
import BannerService from "../../../services/MyProfile/Banner/banner.service";
import { Category } from "../../../models/Category/category.model";
import { toTitleCase } from "../../../shared/utils/StringFormater";
import { bannerFormValidation } from "./bannerFormValidation";

interface BannerFormProps {
  handleFormVisibility: () => void;
  editBanner: Banner | undefined;
  onSuccess: () => void;
}
function BannerForm(props: BannerFormProps) {
  const { handleFormVisibility, editBanner, onSuccess } = props;
  const [formLoading, setFormLoading] = useState(false);
  const [formValues, setFormValues] = useState(new Banner());
  const [page, setPage] = useState<string>(BannerPageEnum.HOMEPAGE);
  const [provinceCityLoading, setprovinceCityLoading] = useState(false);
  const [provinceOptions, setProvinceOptions] = useState<IDropdownOption[]>([]);
  const [provinceIds, setProvinceIds] = useState<[]>([]);
  const [cityOptions, setCityOptions] = useState<IDropdownOption[]>([]);
  const [productNameOption, setProductNameOption] = useState<IDropdownOption[]>(
    []
  );

  const {
    addNewBanner,
    loading,
    updateBanner,
    fetchProductName,
    fetchBannerList,
    setBannerList,
  } = BannerService();

  const handleSubmit = (values: Banner) => {
    if (editBanner?.id) {
      delete values?.cityBanners;
      delete values?.provinceBanners;
      updateBanner(
        values,
        editBanner?.id?.toString(),
        (updatedBanner: Banner) => {
          handleFormVisibility();
          onSuccess();
        }
      );
    } else
      addNewBanner(values, (updatedBanner: Banner) => {
        handleFormVisibility();
        onSuccess();
      });
  };

  const handleFetchProductName = () => {
    fetchProductName(page, (category) => {
      const categoryNames = category?.map((val, index) => ({
        label: val?.name,
        value: val?.categoryId,
      }));
      setProductNameOption(categoryNames);
    });
  };

  useEffect(() => {
    if (page !== BannerPageEnum.HOMEPAGE) {
      handleFetchProductName();
    }
  }, [page]);
  useEffect(() => {
    if (editBanner?.id) {
      const city = editBanner?.cityBanners?.map((val) => val?.cityId);
      const province = editBanner?.provinceBanners?.map(
        (val) => val?.provinceId
      );

      setFormValues(
        Object.assign({
          ...editBanner,
          cityIds: city,
          provinceIds: province,
          bannerImageId: editBanner?.bannerImage?.id,
        })
      );

      setPage(editBanner.pageType ?? "");
    }
  }, [editBanner]);

  const positionOption = [
    {
      label: "1",
      value: 1,
    },
    {
      label: "2",
      value: 2,
    },
    {
      label: "3",
      value: 3,
    },
    {
      label: "4",
      value: 4,
    },
    {
      label: "5",
      value: 5,
    },
  ];
  const pageOption = [
    {
      label: "Home page",
      value: BannerPageEnum.HOMEPAGE,
    },
    {
      label: "Product ",
      value: BannerPageEnum.PRODUCT,
    },
    {
      label: "Product Category",
      value: BannerPageEnum.PRODUCT_CATEGORY,
    },
    {
      label: "Product Type",
      value: BannerPageEnum.PRODUCT_TYPE,
    },
  ];

  const viewOption = [
    {
      label: "Customer",
      value: "customer",
    },
    {
      label: "Business Associate",
      value: "business_associate",
    },
    {
      label: "Both",
      value: "both",
    },
  ];

  const handleSelectAllOptions = (value: string[], type: string) => {
    const options = type === "province" ? provinceOptions : cityOptions;
    if (value.includes("all")) {
      const ids = options
        .map((val) => val.value)
        .filter((val, i) => val !== "all");
      return ids;
    }
  };

  const handleFetchProvince = () => {
    setprovinceCityLoading(true);
    MetaService.fetchProvinces(
      (provinces: Province[]) => {
        const province = provinces.map((province) => ({
          label: province.name,
          value: province.id,
        }));
        setProvinceOptions([{ label: "All", value: "all" }, ...province]);
        handleChangeProvince(provinceIds);
        // setProvinceOptions(province);
      },
      () => {},
      () => {
        setprovinceCityLoading(false);
      }
    );
  };

  useEffect(() => {
    handleFetchProvince();
  }, []);
  const handleChangeProvince = (provinceIds: []) => {
    setprovinceCityLoading(true);
    MetaService.fetchMultipleCities(
      provinceIds,
      (cities: City[]) => {
        const city = cities.map((city) => ({
          label: city.name,
          value: city.id,
        }));
        setCityOptions([{ label: "All", value: "all" }, ...city]);
      },
      () => {},
      () => {
        setprovinceCityLoading(false);
      }
    );
  };

  useEffect(() => {
    if (provinceIds.length > 0) handleChangeProvince(provinceIds);
  }, [provinceIds]);

  return (
    <div className="banner-form">
      <div className="banner-form__heading">
        <h1 className="text-primary mb-5 text-bold">
          {editBanner?.id ? "Edit Banner" : "Add New Banner"}
          {/* <span className="banner__total-customer">
            Probable no of Customers: 167
          </span> */}
        </h1>
      </div>

      <div className="banner-form__form">
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={bannerFormValidation}
        >
          {({ values, errors, isValid, dirty, setFieldValue }) => {
            return (
              <Form>
                <Row gutter={[10, 20]}>
                  <Col span={24} className="">
                    <Radio.Group
                      name="viewableUserType"
                      options={viewOption}
                      onChange={(e) => {
                        setFieldValue("viewableUserType", e.target.value);
                      }}
                      value={values?.viewableUserType}
                    ></Radio.Group>
                  </Col>
                  <Col span={12}>
                    <DropdownField
                      title="Page"
                      name="pageType"
                      options={pageOption}
                      value={values?.pageType}
                      placeHolder="Select Page"
                      onChange={(value) => {
                        setPage(value);
                        setFieldValue("pageType", value);
                      }}
                    />
                  </Col>

                  {page !== BannerPageEnum.HOMEPAGE && (
                    <Col span={12}>
                      <DropdownField
                        title="Product Name"
                        name="categoryId"
                        value={values?.categoryId}
                        options={productNameOption}
                        setFieldValue={setFieldValue}
                        placeHolder="Select product Type"
                        onChange={(value) => {
                          setFieldValue(
                            "categoryType",
                            toTitleCase(page).replace(" ", "")
                          );
                          setFieldValue("categoryId", value);
                        }}
                      />
                    </Col>
                  )}
                  <Col span={12}>
                    <DropdownField
                      title="Position"
                      name="position"
                      options={positionOption}
                      value={values?.position}
                      setFieldValue={setFieldValue}
                      placeHolder="Select Position"
                    />
                  </Col>
                  {page !== BannerPageEnum.HOMEPAGE && (
                    <Col span={12}>
                      <InputField
                        title="Link"
                        type="text"
                        name="redirectUrl"
                        placeholder="Enter Link"
                        required
                      />
                    </Col>
                  )}
                </Row>{" "}
                <Row gutter={[10, 20]}>
                  <Col span={24}>
                    <AttachmentUpload
                      accept="image/jpeg,image/png,image/jpg, application/pdf"
                      name="bannerImageId"
                      placeholder="Upload File"
                      setFieldValue={setFieldValue}
                      value={editBanner?.bannerImage}
                    />
                  </Col>

                  <Col span={12}>
                    <DropdownField
                      showSearch={true}
                      required
                      title="State"
                      name="provinceIds"
                      mode={DropdownModeEnum.MULTIPLE}
                      options={provinceOptions}
                      value={values.provinceIds}
                      onChange={(value) => {
                        const allProvinceIds = handleSelectAllOptions(
                          value,
                          "province"
                        );
                        setFieldValue(
                          "provinceIds",
                          allProvinceIds ? allProvinceIds : value
                        );
                        setProvinceIds(allProvinceIds ? allProvinceIds : value);
                      }}
                      placeHolder="Select State"
                      loading={provinceCityLoading}
                    />
                  </Col>

                  <Col span={12}>
                    <DropdownField
                      showSearch={true}
                      required
                      title="City"
                      name="cityIds"
                      mode={DropdownModeEnum.MULTIPLE}
                      options={cityOptions}
                      value={values?.cityIds}
                      setFieldValue={setFieldValue}
                      placeHolder="Select City"
                      loading={provinceCityLoading}
                      onChange={(value) => {
                        const allCityIds = handleSelectAllOptions(
                          value,
                          "city"
                        );
                        setFieldValue(
                          "cityIds",
                          allCityIds ? allCityIds : value
                        );
                      }}
                    />
                  </Col>
                  {page === BannerPageEnum.HOMEPAGE && (
                    <Col span={12}>
                      <InputField
                        title="Link"
                        type="text"
                        name="redirectUrl"
                        placeholder="Enter Link"
                        required
                      />
                    </Col>
                  )}
                </Row>
                <Row gutter={[10, 20]}>
                  <Col span={22} className="text-right  ml-1 mt-4">
                    <Button
                      type="text"
                      loading={formLoading}
                      onClick={handleFormVisibility}
                    >
                      Cancel
                    </Button>
                  </Col>
                  <Col span={2} className="text-right mt-4">
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={loading}
                      disabled={!isValid || loading || !dirty}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default BannerForm;
