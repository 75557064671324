import React, { ReactNode, useEffect, useState } from "react";
import "./attachmentUpload.scss";
import { ErrorMessage, Field } from "formik";
import Error from "../Error";
import { Row, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import FileDetail from "../FileDetail";
import { FileAttachment } from "../../../models/FileAttachment/fileAttachment.model";
import AppLoader from "../AppLoader";
import App from "../../../App";
import FileService from "../../../services/FileService/file.service";
import uploadIcon from "../../../assets/images/fileUpload.png";

interface AttachmentUploadProps {
  name: string;
  title?: string;
  placeholder: string | ReactNode;
  multiple?: boolean;
  accept: string;
  setFieldValue: Function;
  value?: FileAttachment;
  onChange?: Function;
  required?: boolean;
}

function AttachmentUpload(props: AttachmentUploadProps) {
  let inputRef: any;

  const {
    name,
    title,
    required,
    placeholder,
    multiple,
    setFieldValue,
    accept,
    value,
    onChange,
  } = props;

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [fileExtension, setFileExtension] = useState<string>();
  const excelFile = ["xls", "xlsx", "csv"];

  const handleClick = () => {
    inputRef?.click();
  };

  const handleChange = (e: any) => {
    if (multiple) {
      /*      const files: File[] = newAttachments.concat(Array.from(e.target.files));
      setFieldValue(name, files);*/
    } else {
      const file = e.target.files[0];
      setFileExtension(file?.name?.toString()?.split(".").pop().toLowerCase());
      setLoading(true);
      FileService.uploadFile(
        file,
        (attachment: FileAttachment) => {
          onChange
            ? onChange(attachment.id)
            : setFieldValue(name, attachment.id);
          setImageUrl(attachment.attachmentUrl);
        },
        () => {},
        () => {
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {}, []);

  return (
    <Field name={name}>
      {({ field, form: { touched, errors }, meta }: any) => (
        <div className="attachment-upload">
          {title && <div className="attachment-upload__title">{title}</div>}
          {required && <sup className="text-danger">*</sup>}
          <Row className="attachment-upload__attachment-list" gutter={[15, 15]}>
            <Col span={24} className="attachment-upload__img-wrapper">
              <div className="attachment-upload__input" onClick={handleClick}>
                {loading ? (
                  <AppLoader loading={loading} />
                ) : (
                  <React.Fragment>
                    <div className="attachment-upload__content">
                      {" "}
                      <img src={uploadIcon} alt="" className="" />
                      <p> {placeholder}</p>
                    </div>
                  </React.Fragment>
                )}
              </div>
              <input
                required={required}
                ref={(ref) => (inputRef = ref)}
                type="file"
                multiple={multiple}
                onChange={handleChange}
                accept={accept}
                style={{ display: "none" }}
              />
            </Col>
            {imageUrl ? (
              <div className="attachment-upload__attachment-wrapper">
                {imageUrl?.includes("pdf") ? (
                  <iframe src={imageUrl} className="attachment-upload__img" />
                ) : excelFile?.indexOf(fileExtension || "") > -1 ? (
                  <h4 className="text-primary ml-2">File Uploaded</h4>
                ) : (
                  <img
                    src={imageUrl}
                    alt="Attachment"
                    className="attachment-upload__img"
                  />
                )}
              </div>
            ) : value ? (
              <div className="attachment-upload__attachment-wrapper">
                {value?.attachmentUrl?.includes("pdf") ? (
                  <iframe
                    src={value.attachmentUrl}
                    className="attachment-upload__img"
                  />
                ) : (
                  <img
                    src={value.attachmentUrl}
                    alt="Attachment"
                    className="attachment-upload__img"
                  />
                )}
              </div>
            ) : null}
          </Row>
          <ErrorMessage name={name}>
            {(message: string) => <Error message={message} />}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
}

export default AttachmentUpload;
