import React from "react";
import { MaritalStatus } from "../../../enums/maritalStatus.enum";
import { Citizenship } from "../../../enums/citizenship.enum";
import { Gender } from "../../../enums/gender.enum";
import { JobType } from "../../../enums/jobType.enum";
import { IndustryType } from "../../../enums/industryType.enum";

const maritalStatus = [
  {
    label: "Married",
    value: MaritalStatus.MARRIED,
  },
  {
    label: "Unmarried",
    value: MaritalStatus.UNMARRIED,
  },
];
const citizenship = [
  {
    label: "Indian",
    value: Citizenship.INDIAN,
  },
  {
    label: "Others",
    value: Citizenship.OTHERS,
  },
];
const gender = [
  {
    label: "Male",
    value: Gender.MALE,
  },
  {
    label: "Female",
    value: Gender.FEMALE,
  },
];
const jobType = [
  {
    label: "Salaried Professional",
    value: JobType.SALARIED_PROFESSIONAL,
  },
  {
    label: "Self Employed Professional",
    value: JobType.SELF_EMPLOYED_PROFESSIONAL,
  },
  {
    label: "Self Employed Non Professional Traders",
    value: JobType.SELF_EMPLOYED_NON_PROFESSIONAL_TRADERS,
  },
  {
    label: "Self Employed Non Professional Traders",
    value: JobType.SELF_EMPLOYED_NON_PROFESSIONAL_TRADERS,
  },
  {
    label: "Other Profession",
    value: JobType.OTHER_PROFESSION,
  },
];

const industryType = [
  {
    label: "Financial Services",
    value: IndustryType.FINANCIAL_SERVICE,
  },
  {
    label: "Technology",
    value: IndustryType.TECHNOLOGY,
  },
  {
    label: "Consulting",
    value: IndustryType.CONSULTING,
  },
  {
    label: "Manufacturing",
    value: IndustryType.MANUFACTURING,
  },
  {
    label: "Customer Products",
    value: IndustryType.CUSTOMER_PRODUCTS,
  },
  {
    label: "Retails",
    value: IndustryType.RETAILS,
  },
  {
    label: "Telecommunications",
    value: IndustryType.TELECOMMUNICATIONS,
  },
  {
    label: "Food and Beverages",
    value: IndustryType.FOOD_AND_BEVERAGES,
  },
  {
    label: "Media and Entertainment",
    value: IndustryType.MEDIA_AND_ENTERTAINMENT,
  },
];
const numberValues = () => {
  let numberList = [];
  for (var i = 1; i <= 50; i++) {
    numberList.push({ label: i, value: i });
  }
  return numberList;
};
const numberList = numberValues();

export {
  citizenship,
  gender,
  industryType,
  jobType,
  maritalStatus,
  numberList,
};
