import { Product } from "../Product/product.model";

import {
  serializable,
  alias,
  object,
  list,
  primitive,
  custom,
  SKIP,
} from "serializr";
interface TransactionDetails {
  [x: string]: string;
}
class KYCDetails {
  @serializable(alias("gender", primitive()))
  gender?: string;
  @serializable(alias("pan", primitive()))
  pan?: string;
  @serializable(alias("address", primitive()))
  address?: string;
  @serializable(alias("dob", primitive()))
  dob?: string;
}
class NomineeDetails {
  @serializable(alias("nominee_name", primitive()))
  nomineeName?: string;
  @serializable(alias("nominee_dob", primitive()))
  nomineeDob?: string;
  @serializable(alias("pan", primitive()))
  pan?: string;
  @serializable(alias("nominee_address_line_1", primitive()))
  nomineeAddressLine1?: string;
  @serializable(alias("nominee_address_city", primitive()))
  nomineeAddressCity?: string;
  @serializable(alias("nominee_address_state", primitive()))
  nomineeAddressState?: string;
  @serializable(alias("nominee_address_zipcode", primitive()))
  nomineeAddressZipcode?: number;
  @serializable(alias("fd_receipt", primitive()))
  fdReceipt?: string;
}

class OrderDetails {
  @serializable(alias("class_name", primitive()))
  className?: string;
  @serializable(alias("career_guide_post_guidance_session_booked", primitive()))
  sessionBooked?: boolean;

  @serializable(alias("email", primitive()))
  email?: string;
  @serializable(alias("yes_bank_status", primitive()))
  yesBankStatus?: string;

  @serializable(alias("full_name", primitive()))
  fullName?: string;

  @serializable(alias("guidance_amount", primitive()))
  guidanceAmount?: string;

  @serializable(alias("guidance_session_required", primitive()))
  guidanceSessionRequired?: string;

  @serializable(alias("institution_name", primitive()))
  institutionName?: string;

  @serializable(alias("is_submitted", primitive()))
  isSubmitted?: string;

  @serializable(alias("mobile", primitive()))
  mobile?: string;

  @serializable(alias("original_amount", primitive()))
  originalAmount?: string;

  @serializable(alias("profile", primitive()))
  profile?: string;

  @serializable(alias("website_url", primitive()))
  websiteUrl?: string;
  @serializable(alias("application_id", primitive()))
  applicationId?: string;
  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("amount", primitive()))
  amount?: number;
  @serializable(alias("total_amount", primitive()))
  totalAmount?: number;
  @serializable(alias("interest_rate", primitive()))
  interestRate?: number;
  @serializable(alias("tenure", primitive()))
  tenure?: number;
  @serializable(alias("interest_payout_frequency", primitive()))
  interestPayoutFrequency?: string;
  @serializable(alias("kyc_details", object(KYCDetails)))
  KYCDetails?: KYCDetails;
  @serializable(alias("nominee_deatils", object(NomineeDetails)))
  NomineeDetails?: NomineeDetails;
  @serializable(alias("business_associate_uid", primitive()))
  businessAssociateUid?: string;
}

export class Order {
  @serializable(alias("id", primitive()))
  id?: string;

  @serializable(alias("order_type", primitive()))
  orderType?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("order_status", primitive()))
  orderStatus?: string;
  @serializable(alias("product_type", primitive()))
  productType?: string;

  @serializable(alias("customer_name", primitive()))
  customerName?: string;

  @serializable(alias("product", object(Product)))
  product?: Product;
  @serializable(alias("uploaded_file_id", primitive()))
  uploadedFileId?: number;
  @serializable(alias("bureau_report_status", primitive()))
  bureauReportStatus?: string;
  @serializable(alias("customer_mobile", primitive()))
  customerMobile?: string;

  @serializable(alias("order_details", object(OrderDetails)))
  orderDetails?: OrderDetails;
  @serializable(
    alias(
      "transaction_id",
      custom(
        () => SKIP,
        (values) => {
          return values;
        }
      )
    )
  )
  transactionId?: TransactionDetails;
}
