import React from "react";
import moment from "moment";
import { Banner as BannerModel } from "../../../models/Banner/banner.model";
import { validateUrlProtocol } from "../../../shared/utils/validateUrlProtocol";
import { getDomainName } from "../../../shared/utils/getDomainName";
import { toTitleCase } from "../../../shared/utils/StringFormater";

const bannercolumns = [
  {
    title: " BANNER",
    dataIndex: "banner",
    key: "banner",
    render: (__: string, banner: BannerModel) => {
      return (
        <div className="banner-upload__img ">
          <a
            onClick={(e) => e.stopPropagation()}
            target="_blank"
            href={banner?.bannerImage?.attachmentUrl}
          >
            <img src={banner?.bannerImage?.attachmentUrl} alt="image" />
          </a>{" "}
        </div>
      );
    },
  },
  {
    title: "LINK",
    dataIndex: "attachmentUrl",
    key: "attachmentUrl",
    render: (__: string, banner: BannerModel) => {
      return (
        <a
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          href={validateUrlProtocol(banner?.redirectUrl ?? "")}
        >
          {getDomainName(banner?.redirectUrl ?? "")}
        </a>
      );
    },
  },
  {
    title: "BANNER PAGE",
    dataIndex: "bannerPage",
    key: "bannerPage",
    render: (__: string, banner: BannerModel) => {
      return toTitleCase(banner?.pageType ?? "") || "--";
    },
  },
  {
    title: "POSITION",
    dataIndex: "position",
    key: "position",
    render: (__: string, banner: BannerModel) => {
      return banner?.position || "--";
    },
  },

  {
    title: "MODIFIED BY",
    dataIndex: "modifiedBy",
    key: "modifiedBy",
    render: (_: string, banner: BannerModel) => {
      return `${banner?.employee?.firstName || "-"}`;
    },
    // render: (_: string, banner: BannerModel) => {
    //     return `${banner?.employee?.firstName || "-"} ${
    //       banner?.employee?.lastName || "-"
    //     }`;
    //   },
  },
  {
    title: "MODIFIED DATE",
    dataIndex: "updatedAt",
    key: "updatedAt",
    render: (date: string) => moment(date).format("DD/MM/YYYY"),
  },
];

export { bannercolumns };
