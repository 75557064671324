import { serializable, alias, object, list, primitive } from "serializr";
import { City } from "../City/city.model";

export class Address {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("address_type", primitive()))
  addressType?: string;

  @serializable(alias("street", primitive()))
  street?: string;

  @serializable(alias("address_line_2", primitive()))
  addressLine2?: string;

  @serializable(alias("city", object(City)))
  city?: City;

  @serializable(alias("city_id", primitive()))
  cityId?: number;

  @serializable(alias("city_name", primitive()))
  cityName?: string;

  @serializable(alias("province_id", primitive()))
  provinceId?: number;

  @serializable(alias("province_name", primitive()))
  provinceName?: string;

  @serializable(alias("zipcode", primitive()))
  zipcode?: string;
}
